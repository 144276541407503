<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Szállító</mat-label>
			   <input matInput name="inputSzallito" [ngModel]="targetObj.getSzallito()" (ngModelChange)="targetObj.setSzallito($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Típus</mat-label>
			   <textarea matInput name="inputTipus" rows="3" [ngModel]="targetObj.getTipus()" (ngModelChange)="targetObj.setTipus($event)" required></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>A komponens aránya</mat-label>
			   <input matInput name="inputAarany" type="number" step="1" min="0" [ngModel]="targetObj.getAarany()" (ngModelChange)="targetObj.setAarany($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>B komponens aránya</mat-label>
			   <input matInput name="inputBarany" type="number" step="1" min="0" [ngModel]="targetObj.getBarany()" (ngModelChange)="targetObj.setBarany($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field>
			   <mat-label>Kezdete</mat-label>
			   <input matInput name="inputKezdete" [ngModel]="targetObj.getKezdete()" (ngModelChange)="targetObj.setKezdete($event)" [owlDateTime]="inputKezdetePicker" [owlDateTimeTrigger]="inputKezdetePicker" required>
			   <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputKezdetePicker></owl-date-time>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddUserComponent} from 'src/app/components/settings/user/component/adduser/adduser.component';
import {ModUserComponent} from 'src/app/components/settings/user/component/moduser/moduser.component';
import {UsersComponent} from 'src/app/components/settings/user/component/users/users.component';

import {UserService} from 'src/app/components/settings/user/service/user.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddUserComponent,
		ModUserComponent,
		UsersComponent
	],
	entryComponents: [
		AddUserComponent,
		ModUserComponent,
		UsersComponent
	],
	providers: [
		UserService
	],
	exports: [
		AddUserComponent,
		ModUserComponent,
		UsersComponent
	],
})
export class UserModule {
	public static readonly compArray = [
		AddUserComponent,
		ModUserComponent,
		UsersComponent
	];

	public static readonly compMap = UserModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in UserModule.compMap) ? UserModule.compMap[TAG].NAME : '';
	}
}
import {ProjectModel } from 'src/app/components/settings/project/model/project.model';

export class SzamlaModel {

	private id: number = null;
	private projectid:number = null;
	private project:ProjectModel = new ProjectModel({});
	private szamlatipus:number = null;
	private sorszam:string = '';
	private teljesites:string = '';
	private kiallitas: string = '';
	private hatarido:string = '';
	private penznem:string = '';
	private brutto:number = null;
	private szamla:string = '';
	private kifizetve:number = null;
	private beerkezes:string = '';
	private modifiedby: number = null;
	private modifiedtime: string = '';	


	constructor(json) {

		this.id = json && json.id || null;
		this.projectid = json && json.projectid || null;
		this.project = new ProjectModel(json && json.project ||{})
		this.szamlatipus = json && json.szamlatipus || null;
		this.sorszam = json && json.sorszam || '';
		this.teljesites = json && json.teljesites || '';
		this.kiallitas = json && json.kiallitas || '';
		this.hatarido = json && json.hatarido || '';
		this.penznem = json && json.penznem || '';
		this.brutto = json && json.brutto || null;
		this.szamla = json && json.szamla || '';
		this.kifizetve = json && json.kifizetve || null;
        this.beerkezes=json && json.beerkezes || '';
		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getProjectid() {
        return this.projectid;
    }

    setProjectid(value) {
        this.projectid = value;
    }

    getProjectModel() {
        return this.project;
    }

    setProjectModel(value) {
        this.project = value;
    }

    getSzamlatipus() {
        return this.szamlatipus;
    }

    setSzamlatipus(value) {
        this.szamlatipus = value;
    }

    getSorszam() {
        return this.sorszam;
    }

    setSorszam(value) {
        this.sorszam = value;
    }

    getTeljesites() {
        return this.teljesites;
    }

    setTeljesites(value) {
        this.teljesites = value;
    }

    getKiallitas() {
        return this.kiallitas;
    }

    setKiallitas(value) {
        this.kiallitas = value;
    }

    getHatarido() {
        return this.hatarido;
    }

    setHatarido(value) {
        this.hatarido = value;
    }

    getPenznem() {
        return this.penznem;
    }

    setPenznem(value) {
        this.penznem = value;
    }

    getBrutto() {
        return this.brutto;
    }

    setBrutto(value) {
        this.brutto = value;
    }

    getSzamla() {
        return this.szamla;
    }

    setSzamla(value) {
        this.szamla = this.szamla;
    }

    getKifizetve() {
        return this.kifizetve;
    }

    setKifizetve(value) {
        this.kifizetve = value;
    }

    getBeerkezes() {
        return this.beerkezes;
    }

    setBeerkezes(value) {
        this.beerkezes = value;
    }

	
}

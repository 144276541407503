import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {TaskFormsModule} from 'src/app/components/task/dialog/task-dialog/taskforms/taskforms.module';

import {TaskDialog} from 'src/app/components/task/dialog/task-dialog/task-dialog';
import {ExtraDataModule} from 'src/app/components/task/dialog/task-dialog/extradata/extradata.module';

@NgModule({
	imports: [
		CommonModules,
		TaskFormsModule,
		ExtraDataModule
	],
	declarations: [
		TaskDialog
	],
	entryComponents: [
		TaskDialog
	],
	exports: [
		TaskDialog
	],
})
export class TaskDialogModule {}
import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {JovahagyasComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/jovahagyas.component';
import {JovahagyasConfigComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/config/jovahagyasconfig.component';

import {JovahagyasService} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/jovahagyas.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		JovahagyasComponent,
		JovahagyasConfigComponent
	],
	entryComponents: [
		JovahagyasComponent,
		JovahagyasConfigComponent
	],
	providers: [
		JovahagyasService
	],
	exports: [
		JovahagyasComponent,
		JovahagyasConfigComponent
	],
})
export class JovahagyasModule {
	public static readonly component = JovahagyasComponent;
}
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezSzallitasComponent} from 'src/app/components/settings/raktar/lemezszallitas/component/addlemezszallitas/addlemezszallitas.component';
import {ModLemezSzallitasComponent} from 'src/app/components/settings/raktar/lemezszallitas/component/modlemezszallitas/modlemezszallitas.component';
import {LemezSzallitasokComponent} from 'src/app/components/settings/raktar/lemezszallitas/component/lemezszallitasok/lemezszallitasok.component';
import {DelLemezSzallitasComponent} from 'src/app/components/settings/raktar/lemezszallitas/component/dellemezszallitas/dellemezszallitas.component';

import { LemezSzallitasService } from 'src/app/components/raktar/service/lemezszallitas.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezSzallitasComponent,
		ModLemezSzallitasComponent,
		LemezSzallitasokComponent,
		DelLemezSzallitasComponent
	],
	entryComponents: [
		AddLemezSzallitasComponent,
		ModLemezSzallitasComponent,
		LemezSzallitasokComponent,
		DelLemezSzallitasComponent
	],
	providers: [
		LemezSzallitasService
	],
	exports: [
		AddLemezSzallitasComponent,
		ModLemezSzallitasComponent,
		LemezSzallitasokComponent,
		DelLemezSzallitasComponent
	],
})
export class LemezSzallitasModule {
	public static readonly compArray = [
		AddLemezSzallitasComponent,
		ModLemezSzallitasComponent,
		LemezSzallitasokComponent,
		DelLemezSzallitasComponent
	];

	public static readonly compMap = LemezSzallitasModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezSzallitasModule.compMap) ? LemezSzallitasModule.compMap[TAG].NAME : '';
	}
}
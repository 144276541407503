import {Component, Inject} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {SzamlazasService} from 'src/app/components/szamlazas/service/szamlazas.service';

import {CommonModel} from 'src/app/model/common.model';

import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {ListsModel} from 'src/app/model/lists.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';

export interface Szamla {
	id: number;
	projectid:number;
	project:ProjectModel;
	szamlatipus:number;
	sorszam:string;
	teljesites:any;
	kiallitas:any;
	hatarido:any;
	penznem:string;
	brutto:number;
	szamla:string;
	kifizetve:number;
	beerkezes:any;
	modifiedby:number;
	modifiedtime:string;
	deletedby:string;
	deletedtime:any;	

}

@Component({
	selector: 'app-szamlazas-dialog',
	templateUrl: './szamlazas-dialog.html',
	styleUrls: ['./szamlazas-dialog.scss']
})
export class SzamlazasDialog {

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	loading: number = 0;
	szamlatypes:Array<any>=[
		{text:"Díjbekérő-előlegszámla", value:1},
		{text:"Számla", value:2}
	]

	targetObj: Szamla = {
		id: null,
		projectid:null,
		project:new ProjectModel({}),
		szamlatipus:null,
		sorszam:null,
		teljesites:null,
		kiallitas:null,
		hatarido:null,
		penznem:null,
		brutto:null,
		szamla:null,
		kifizetve:null,
		beerkezes:null,
		modifiedby:null,
		modifiedtime:null,
		deletedby:null,
		deletedtime:null
	}


	listsModel: any = ListsModel;
	kulonbozet:number=0;

	constructor(
		private szamlazasService: SzamlazasService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<SzamlazasDialog>,
		private sanitizer: DomSanitizer
	) {
		let szamlaId = this.data.szamlaId;

		this.loading++;
		this.szamlazasService.get(szamlaId).subscribe(
			res => this.handleGetSzamlaResponse(res),
			error => this.handleGetSzamlaError(error)
		);

		
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpSzamla: Szamla = {...this.targetObj};
		for (let key in tmpSzamla) {
			tmpSzamla[key] = this.transform(tmpSzamla[key]);
		}

		return tmpSzamla;
	}


	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
		this.alertDialogRef.afterClosed().subscribe(result => {
			this.alertDialogRef = null;
			//this.dialogRef.close(false);
		});
	}



	handleGetSzamlaError(serverError) {
		this.handleError(serverError);
	}

	handleGetSzamlaResponse(serverResponse) {
		this.loading--;
		this.targetObj = {
			id: serverResponse.id,
			projectid: serverResponse.projectid,
			project: new ProjectModel(serverResponse.project),
			szamlatipus: Number(serverResponse.szamlatipus),
			sorszam: serverResponse.sorszam,
			teljesites: CommonModel.isDate(serverResponse.teljesites) ? (new Date(Date.parse(serverResponse.teljesites))) : null,
			kiallitas: CommonModel.isDate(serverResponse.kiallitas) ? (new Date(Date.parse(serverResponse.kiallitas))) : null,
			hatarido: CommonModel.isDate(serverResponse.hatarido) ? (new Date(Date.parse(serverResponse.hatarido))) : null,
			penznem: serverResponse.penznem,
			brutto: serverResponse.brutto,
			szamla: serverResponse.szamla,
			kifizetve: serverResponse.kifizetve,
			beerkezes: CommonModel.isDate(serverResponse.beerkezes) ? (new Date(Date.parse(serverResponse.beerkezes))) : null,
			modifiedby: serverResponse.modifiedby,
			modifiedtime: serverResponse.modifiedtime,
			deletedby: serverResponse.deletedby,
			deletedtime: serverResponse.deletedtime
		};
		this.kulonbozet=this.targetObj.brutto-this.targetObj.kifizetve;
	}



	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.dialogRef.close(true);
	}

	save() {

		//if (this.targetObj.kezdes != null && typeof this.targetObj.kezdes == 'object' && this.targetObj.kezdes.constructor.name ==                          this.targetObj.befejezes != null && typeof this.targetObj.befejezes == 'object' && this.targetObj.befejezes.constructor.name ==                          let tmpKezdes: Date = new Date(this.targetObj                         let tmpBefejezes: Date = new Date(this.targetObj.be                         let tmpKezdesText = (tmpKezdes.getFullYear() + "-" + ("0" + (tmpKezdes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpKezdes.getDate()).sub                         let tmpBefejezesText = (tmpBefejezes.getFullYear() + "-" + ("0" + (tmpBefejezes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpBefejezes.getDate()).subs                         this.l                         this.szamlazasService.setDatum(Number(this.targetObj.id), tmpKezdesText, tmpBefejezesText).s                              res => this.handleSaveRespo                              error => this.handleSaveErr                                                              this.alertDialogRef = this.dialog.open(Alert                              disableClo                              panelClass: 'alertDialo                              autoFoc                                             this.alertDialogRef.componentInstance.dialogMessage = 'Érvénytelen                    }

		let tmpSzamla: Szamla = this.dataTransform();
		let save: boolean = true;

		if (!tmpSzamla.szamlatipus) {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = "A számlatípus mező kitöltése kötelező";

			save = false;
		}

		if (save) {
			this.saveFunction(tmpSzamla)
		}
	}

	saveFunction(tmpSzamla) {
		this.loading++;
		this.szamlazasService.saveData(tmpSzamla).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	bruttoChange(value){
		this.targetObj.brutto=value;
		this.kulonbozet=this.targetObj.brutto-this.targetObj.kifizetve;
	}
	kifizetveChange(value){
		this.targetObj.kifizetve=value;
		this.kulonbozet=this.targetObj.brutto-this.targetObj.kifizetve;
	}


}

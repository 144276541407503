import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {AuthService} from 'src/app/services/auth.service';
import {TaskTemplateService} from 'src/app/components/settings/tasktemplate/service/tasktemplate.service';

import {TaskFormsModule} from 'src/app/components/task/dialog/task-dialog/taskforms/taskforms.module';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {TaskformConfigDialog} from 'src/app/components/task/dialog/taskform-config-dialog/taskform-config-dialog';

@Component({
	selector: TaskTemplateService.LIST_TAG,
	templateUrl: './tasktemplates.component.html',
	styleUrls: ['./tasktemplates.component.scss']
})
export class TaskTemplatesComponent {

	public static readonly TAG = TaskTemplateService.LIST_TAG;
	public static readonly NAME = "Feladat sablonok";
	getSettingName() {return TaskTemplatesComponent.NAME;}

	public static readonly _requiredPermissions = ['Modules:TaskTemplate:list', 'Modules:TaskTemplate:add', 'Modules:TaskTemplate:mod'];
	public static readonly _adminOnly = false;
	public static readonly _icon = ['fas', 'file'];
	public static readonly _category = "Feladatok";

	alertDialogRef: MatDialogRef<AlertDialog>;
	taskformConfigDialogRef: MatDialogRef<TaskformConfigDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	displayedColumns: string[] = ['id', 'tt_name', 'tt_desc', 'tt_objecttype', 'categoryname', 'action'];
	dataSource: MatTableDataSource<TaskTemplateModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	taskFormsModule: any = TaskFormsModule;

	filterValue: string = "";

	constructor(
		private authService: AuthService,
		private taskTemplateService: TaskTemplateService,
		public dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getName(),
				row.getDesc(),
				(row.getObjectType() in TaskFormsModule.compMap) ? TaskFormsModule.compMap[row.getObjectType()].NAME : '',
				row.getCategoryModel().getName()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<TaskTemplateModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new TaskTemplateModel(serverResponse[i]));
		}

		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'tt_name': return item.getName();
				case 'tt_desc': return item.getDesc();
				case 'tt_objecttype': return TaskFormsModule.getCompName(item.getObjectType());
				case 'categoryname': return item.getCategoryModel().getName();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.taskTemplateService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	add() {
		this.loadSettingComponent.emit(TaskTemplateService.ADD_TAG);
	}

	mod(id) {
		sessionStorage.setItem(AppConstants.PARAM_MODTASKTEMPLATE_TASKTEMPLATEID, id);
		this.loadSettingComponent.emit(TaskTemplateService.MOD_TAG);
	}

	config(templateId, objecttype) {
		this.taskformConfigDialogRef = this.dialog.open(TaskformConfigDialog, {
			disableClose: true,
			panelClass: 'taskformConfigDialogWindow',
			autoFocus: false,
			data: {
				templateId: templateId,
				objecttype: objecttype
			}
		});
	}

	hasConfig(component: string) {
		if (component in TaskFormsModule.compMap) {
			if (TaskFormsModule.compMap[component].configComponent != null) {
				return true;
			}
		}

		return false;
	}

}

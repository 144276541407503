import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';

import {AuthService} from 'src/app/services/auth.service';

@Injectable({
	providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean |
		Observable<boolean> | Promise<boolean> {
		return this.authService.loggedIn();
	}

	constructor(private authService: AuthService) {}
}

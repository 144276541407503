import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';
import {AddTaskDialog} from 'src/app/dialog/add-task-dialog/add-task-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {ProfileDialog} from 'src/app/dialog/profile-dialog/profile-dialog';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		AlertDialog,
		ConfirmDialog,
		StornoConfirmDialog,
		AddTaskDialog,
		NextTasksDialog,
		ProfileDialog
	],
	entryComponents: [
		AlertDialog,
		ConfirmDialog,
		StornoConfirmDialog,
		AddTaskDialog,
		NextTasksDialog,
		ProfileDialog
	],
	exports: [
		AlertDialog,
		ConfirmDialog,
		StornoConfirmDialog,
		AddTaskDialog,
		NextTasksDialog,
		ProfileDialog
	],
})
export class DialogsModule {}
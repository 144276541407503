<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field appearance="standard">
						 <mat-label>Döntés címke</mat-label>
						 <input matInput name="inputDonteslabel" [(ngModel)]="formdata.donteslabel">
					</mat-form-field>
					<mat-form-field appearance="standard">
						 <mat-label>Döntés időpontja címke</mat-label>
						 <input matInput name="inputDontesidopontjalabel" [(ngModel)]="formdata.dontesidopontjalabel">
					</mat-form-field>
					<mat-form-field appearance="standard">
						 <mat-label>Kész lekérdezés</mat-label>
						 <textarea matInput name="inputDonequery" rows="5" [(ngModel)]="formdata.donequery"></textarea>
					</mat-form-field>
			   </form>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeParentDialog.emit(null)">Mégsem</button>
	 </div>
</div>
import {CommonModel} from "src/app/model/common.model";
import {HabMinosegModel} from "src/app/components/settings/raktar/habminoseg/model/habminoseg.model";
import {HabSzintModel} from "src/app/components/raktar/model/habszint.model";
import { RaktarModel } from "src/app/components/settings/raktar/habminoseg/model/raktar.model";

export class HabSzallitasModel {

	private id: number = null;

	private habminosegid: number = null;
	private habminoseg: HabMinosegModel = new HabMinosegModel({});
	private idopont: any = null;
	private szallitolevel: string = '';
	private amennyiseg: number = null;
	private anetto: number = null;
	private ameres: number = null;
	private bmennyiseg: number = null;
	private bnetto: number = null;
	private bmeres: number = null;
	private habszintidelotte: number = null;
	private habszintelotte: HabSzintModel = new HabSzintModel({}, null);
	private habszintidutana: number = null;
	private habszintutana: HabSzintModel = new HabSzintModel({}, null);

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.habminosegid = json && json.habminosegid || null;
		this.habminoseg = new HabMinosegModel(json && json.habminoseg || {});
		this.idopont = json && json.idopont || null;
		this.idopont = CommonModel.isDate(this.idopont) ? (new Date(Date.parse(this.idopont))) : null;
		this.szallitolevel = json && json.szallitolevel || '';
		this.amennyiseg = json && json.amennyiseg || null;
		this.anetto = json && json.anetto || null;
		this.ameres = json && json.ameres || null;
		this.bmennyiseg = json && json.bmennyiseg || null;
		this.bnetto = json && json.bnetto || null;
		this.bmeres = json && json.bmeres || null;
		this.habszintidelotte = json && json.habszintidelotte || null;
		this.habszintelotte = new HabSzintModel(json && json.habszintelotte || {}, null);
		this.habszintidutana = json && json.habszintidutana || null;
		this.habszintutana = new HabSzintModel(json && json.habszintutana || {}, null);

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getHabminosegid() {
		return this.habminosegid;
	}

	setHabminosegid(value) {
		this.habminosegid = value;
	}

	getHabMinosegModel() {
		return this.habminoseg;
	}

	getIdopont() {
		return this.idopont;
	}

	setIdopont(value) {
		this.idopont = new Date(value);
	}

	getIdopontString() {
		if (this.idopont instanceof Date) {
			return (this.idopont.getFullYear() + "-" + ("0" + (this.idopont.getMonth() + 1)).substr(-2) + "-" + ("0" + this.idopont.getDate()).substr(-2) + " " + ("0" + this.idopont.getHours()).substr(-2) + ":" + ("0" + this.idopont.getMinutes()).substr(-2) + ":00");
		} else {
			return '';
		}
	}

	getSzallitolevel() {
		return this.szallitolevel;
	}

	setSzallitolevel(value) {
		this.szallitolevel = value;
	}

	getAmennyiseg() {
		return this.amennyiseg;
	}

	setAmennyiseg(value) {
		this.amennyiseg = value;
	}

	getAnetto() {
		return this.anetto;
	}

	setAnetto(value) {
		this.anetto = value;
	}

	getAmeres() {
		return this.ameres;
	}

	setAmeres(value) {
		this.ameres = value;
	}

	getAmertKg(avalto: number, atartaly: number) {
		if (this.ameres >= 0 && avalto > 0 && atartaly > 0 && atartaly > this.ameres) {
			return Math.round((atartaly - this.ameres) * avalto);
		} else {
			return 0;
		}
	}

	getAmertKgDiff(){
		return this.getHabSzintidutanaModel()
		.getAmertKg(RaktarModel.HabAvalto,RaktarModel.HabAtartaly)-
		this.getHabSzintidelotteModel()
		.getAmertKg(RaktarModel.HabAvalto,RaktarModel.HabAtartaly)
	}

	getBmennyiseg() {
		return this.bmennyiseg;
	}

	setBmennyiseg(value) {
		this.bmennyiseg = value;
	}

	getBnetto() {
		return this.bnetto;
	}

	setBnetto(value) {
		this.bnetto = value;
	}

	getBmeres() {
		return this.bmeres;
	}

	setBmeres(value) {
		this.bmeres = value;
	}

	getBmertKg(bvalto: number, btartaly: number) {
		if (this.bmeres >= 0 && bvalto > 0 && btartaly > 0 && btartaly > this.bmeres) {
			return Math.round((btartaly - this.bmeres) * bvalto);
		} else {
			return 0;
		}
	}

	getBmertKgDiff(){
		return this.getHabSzintidutanaModel()
		.getBmertKg(RaktarModel.HabBvalto,RaktarModel.HabBtartaly)-
		this.getHabSzintidelotteModel()
		.getBmertKg(RaktarModel.HabBvalto,RaktarModel.HabBtartaly)
	}

	getHabSzintidelotte() {
		return this.habszintidelotte;
	}

	setHabSzintidelotte(value) {
		this.habszintidelotte = value;
	}

	getHabSzintidelotteModel() {
		return this.habszintelotte;
	}

	getHabSzintidutana() {
		return this.habszintidutana;
	}

	setHabSzintidutana(value) {
		this.habszintidutana = value;
	}

	getHabSzintidutanaModel() {
		return this.habszintutana;
	}
}

<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Feladat sablon</mat-label>
			   <mat-select name="inputTemplate" [ngModel]="targetObj.getTemplateid()" (ngModelChange)="targetObj.setTemplateid($event)" (openedChange)="templateFilter.value=null; filterTemplates(null);" required>
						   <input matInput #templateFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterTemplates($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let template of filteredTemplates | customsort:'getName'" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Szerepkör</mat-label>
			   <mat-select name="inputRole" [ngModel]="targetObj.getRoleid()" (ngModelChange)="targetObj.setRoleid($event)" (openedChange)="roleFilter.value=null; filterRoles(null);" required>
						   <input matInput #roleFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterRoles($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let role of filteredRoles | customsort:'getName'" [value]="role.getId()">{{role.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Szereptípus</mat-label>
			   <mat-select name="inputRoletype" [ngModel]="targetObj.getRoletype()" (ngModelChange)="targetObj.setRoletype($event)" required>
					<mat-option *ngFor="let roletype of listsModel.RoleTypeList | customsort:'getName'" [value]="roletype.getValue()">{{roletype.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
<mat-toolbar>
	 <mat-toolbar-row>
		  <span>{{ getSettingName() }}</span>
	 </mat-toolbar-row>
</mat-toolbar>
<div class="loading-wrapper tableContainer my-4">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>

	 <app-table-filter style="width: 100%;" filterId="habszallitasok" [(filter)]="filterValue" (keyupFn)="applyFilter()" placeholder="Szűrő..."></app-table-filter>

	 <div class="table-wrapper mat-elevation-z8">
		  <table mat-table [dataSource]="dataSource" matSort>

			   <!-- ID Column -->
			   <ng-container matColumnDef="id">
					<th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  #
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getId()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Szallitolevel Column -->
			   <ng-container matColumnDef="szallitolevel">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							 Szállítólevél
						</div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
						 	 {{row.getSzallitolevel()}}
					 	</div>
					</td>
		   	   </ng-container>

			   <!-- Amennyiseg Column -->
			   <ng-container matColumnDef="amennyiseg">
				 	<th mat-header-cell *matHeaderCellDef mat-sort-header>
				 		<div class="data-wrapper">
				 			A mennyiség
				 		 </div>
				 	</th>
				 	<td mat-cell *matCellDef="let row">
				 		<div class="data-wrapper">
				 			  {{row.getAmennyiseg()}}
				 		 </div>
				 	</td>
			   </ng-container>

			   <!-- Anetto Column -->
			   <ng-container matColumnDef="anetto">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							A nettó
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getAnetto()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Bmennyiseg Column -->
			   <ng-container matColumnDef="bmennyiseg">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							B mennyiség
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getBmennyiseg()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Bnetto Column -->
			   <ng-container matColumnDef="bnetto">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						B nettó
					 </div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						  {{row.getBnetto()}}
					 </div>
				</td>
		       </ng-container>

			   <!-- Akulonbseg Column -->
			   <ng-container matColumnDef="akulonbseg">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						A különbség
					 </div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						  {{row.getAmertKgDiff()}}
					 </div>
				</td>
		       </ng-container>

			   <!-- Bkulonbseg Column -->
			   <ng-container matColumnDef="bkulonbseg">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						B különbség
					 </div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						  {{row.getBmertKgDiff()}}
					 </div>
				</td>
		       </ng-container>

			   <!-- Idopont Column -->
			   <ng-container matColumnDef="idopont">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Időpont
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getIdopontString()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Action Column -->
			   <ng-container matColumnDef="action">
					<th class="col-action" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'plus']" size="lg" (click)="add();" matTooltip="Új" *hasPermission="'Modules:HabSzallitas:add'"></fa-icon>
							  <fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadData();" matTooltip="Frissítés"></fa-icon>
						 </div>
					</th>
					<td class="col-action" mat-cell *matCellDef="let row;">
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'pencil-alt']" size="lg" (click)="mod(row.getId());" matTooltip="Módosítás" *hasPermission="'Modules:HabSzallitas:mod'"></fa-icon>
							  <fa-icon [icon]="['fas', 'trash']" size="lg" (click)="del(row.getId());" matTooltip="Törlés" *hasPermission="'Modules:HabSzallitas:del'"></fa-icon>
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>

		  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
	 </div>
</div>
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddProjectComponent} from 'src/app/components/settings/project/component/addproject/addproject.component';
import {ModProjectComponent} from 'src/app/components/settings/project/component/modproject/modproject.component';
import {ProjectsComponent} from 'src/app/components/settings/project/component/projects/projects.component';

import {ProjectService} from 'src/app/components/settings/project/service/project.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddProjectComponent,
		ModProjectComponent,
		ProjectsComponent
	],
	entryComponents: [
		AddProjectComponent,
		ModProjectComponent,
		ProjectsComponent
	],
	providers: [
		ProjectService
	],
	exports: [
		AddProjectComponent,
		ModProjectComponent,
		ProjectsComponent
	],
})
export class ProjectModule {
	public static readonly compArray = [
		AddProjectComponent,
		ModProjectComponent,
		ProjectsComponent
	];

	public static readonly compMap = ProjectModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in ProjectModule.compMap) ? ProjectModule.compMap[TAG].NAME : '';
	}
}
import {ListItemModel} from 'src/app/model/listitem.model';

export class ListsModel {

	public static readonly RoleType: string = 'RoleTypeList';
	public static readonly RoleType_LETREHOZAS: ListItemModel = new ListItemModel(10, "Létrehozás");
	public static readonly RoleType_OLVASAS: ListItemModel = new ListItemModel(20, "Olvasás");
	public static readonly RoleType_IRAS: ListItemModel = new ListItemModel(30, "Szerkesztés");
	public static readonly RoleTypeList: Array<ListItemModel> = [
		ListsModel.RoleType_LETREHOZAS,
		ListsModel.RoleType_OLVASAS,
		ListsModel.RoleType_IRAS
	];

	public static readonly TaskStatus: string = 'TaskStatusList';
	public static readonly TaskStatus_LETREHOZVA: ListItemModel = new ListItemModel(10, "Létrehozva");
	public static readonly TaskStatus_FOLYAMATBAN: ListItemModel = new ListItemModel(20, "Folyamatban");
	public static readonly TaskStatus_KESZ: ListItemModel = new ListItemModel(30, "Kész");
	public static readonly TaskStatus_SZTORNOZVA: ListItemModel = new ListItemModel(40, "Sztornózva");
	public static readonly TaskStatusList: Array<ListItemModel> = [
		ListsModel.TaskStatus_LETREHOZVA,
		ListsModel.TaskStatus_FOLYAMATBAN,
		ListsModel.TaskStatus_KESZ,
		ListsModel.TaskStatus_SZTORNOZVA
	];

	public static readonly TaskAssignType: string = 'TaskAssignTypeList';
	public static readonly TaskAssignType_KEZI_DELEGALAS: ListItemModel = new ListItemModel(10, "Kézi delegálás");
	public static readonly TaskAssignType_SZEREPKORRE_DELEGALAS: ListItemModel = new ListItemModel(20, "Szerepkörre delegálás");
	public static readonly TaskAssignType_PROJEKT_VEZETOHOZ_DELEGALAS: ListItemModel = new ListItemModel(30, "Projekt vezetőhöz delegálás");
	public static readonly TaskAssignTypeList: Array<ListItemModel> = [
		ListsModel.TaskAssignType_KEZI_DELEGALAS,
		ListsModel.TaskAssignType_SZEREPKORRE_DELEGALAS,
		ListsModel.TaskAssignType_PROJEKT_VEZETOHOZ_DELEGALAS
	];

	public static readonly TaskFilter: string = 'TaskFilterList';
	public static readonly TaskFilter_MINDEN_FELADAT: ListItemModel = new ListItemModel(10, "Minden feladat");
	public static readonly TaskFilter_LEJART_FELADATOK: ListItemModel = new ListItemModel(20, "Lejárt feladatok");
	public static readonly TaskFilter_AKTUALIS_FELADATOK: ListItemModel = new ListItemModel(30, "Napi feladatok");
	public static readonly TaskFilter_KOVETKEZO_FELADATOK: ListItemModel = new ListItemModel(40, "Következő feladatok");
	public static readonly TaskFilter_KESZ_FELADATOK: ListItemModel = new ListItemModel(50, "Kész feladatok");
	public static readonly TaskFilterList: Array<ListItemModel> = [
		ListsModel.TaskFilter_MINDEN_FELADAT,
		ListsModel.TaskFilter_LEJART_FELADATOK,
		ListsModel.TaskFilter_AKTUALIS_FELADATOK,
		ListsModel.TaskFilter_KOVETKEZO_FELADATOK,
		ListsModel.TaskFilter_KESZ_FELADATOK
	];

	public static readonly ProjectStatus: string = 'ProjectStatusList';
	public static readonly ProjectStatus_FOLYAMATBAN: ListItemModel = new ListItemModel(10, "Folyamatban");
	public static readonly ProjectStatus_LEZART: ListItemModel = new ListItemModel(20, "Lezárt");
	public static readonly ProjectStatus_SZTORNOZVA: ListItemModel = new ListItemModel(30, "Sztornózva");
	public static readonly ProjectStatusList: Array<ListItemModel> = [
		ListsModel.ProjectStatus_FOLYAMATBAN,
		ListsModel.ProjectStatus_LEZART,
		ListsModel.ProjectStatus_SZTORNOZVA
	];

	//gabler listák
	public static readonly SzerelesAllapot: string = 'RoleTypeList';
	public static readonly SzerelesAllapot_ELOJEGYEZVE: ListItemModel = new ListItemModel(10, "Előjegyezve");
	public static readonly SzerelesAllapot_VEGLEGESITVE: ListItemModel = new ListItemModel(20, "Véglegesítve");
	public static readonly SzerelesAllapotList: Array<ListItemModel> = [
		ListsModel.SzerelesAllapot_ELOJEGYEZVE,
		ListsModel.SzerelesAllapot_VEGLEGESITVE
	];

	public static getListItem(itemValue: number | string, itemType: string) {
		let returnVal: ListItemModel = new ListItemModel(null, "");

		if (itemValue == "" || itemValue == null || itemValue == undefined ||
			itemType == "" || itemType == null || itemType == undefined) {
			return returnVal;
		}

		let list: Array<ListItemModel> = [];

		switch (itemType) {
			case ListsModel.RoleType: list = this.RoleTypeList; break;
			case ListsModel.TaskStatus: list = this.TaskStatusList; break;
			case ListsModel.TaskAssignType: list = this.TaskAssignTypeList; break;
			case ListsModel.TaskFilter: list = this.TaskFilterList; break;
			case ListsModel.ProjectStatus: list = this.ProjectStatusList; break;
		}

		list.forEach(function (listItem) {
			if (listItem.getValue() == itemValue) {
				returnVal = listItem;
				return;
			}
		});

		return returnVal;
	}


	private id: number = null;

	private l_type: string = '';
	private l_value: number = null;
	private l_name: string = '';
	private l_order: number = null;
	private l_lang: string = '';

	private l_modifiedby: number = null;
	private l_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.l_type = json && json.l_type || '';
		this.l_value = json && json.l_value || null;
		this.l_name = json && json.l_name || '';
		this.l_order = json && json.l_order || null;
		this.l_lang = json && json.l_lang || '';

		this.l_modifiedby = json && json.l_modifiedby || null;
		this.l_modifiedtime = json && json.l_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getType() {
		return this.l_type;
	}

	setType(value) {
		this.l_type = value;
	}

	getValue() {
		return this.l_value;
	}

	setValue(value) {
		this.l_value = value;
	}

	getName() {
		return this.l_name;
	}

	setName(value) {
		this.l_name = value;
	}

	getOrder() {
		return this.l_order;
	}

	setOrder(value) {
		this.l_order = value;
	}

	getLang() {
		return this.l_lang;
	}

	setLang(value) {
		this.l_lang = value;
	}
}

import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {RoleService} from 'src/app/components/settings/role/service/role.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: RoleService.LIST_TAG,
	templateUrl: './roles.component.html',
	styleUrls: ['./roles.component.scss']
})
export class RolesComponent {

	public static readonly TAG = RoleService.LIST_TAG;
	public static readonly NAME = "Szerepkörök";
	getSettingName() {return RolesComponent.NAME;}

	public static readonly _requiredPermissions = ['Modules:Role:list', 'Modules:Role:add', 'Modules:Role:mod'];
	public static readonly _adminOnly = false;
	public static readonly _icon = ['fas', 'key'];
	public static readonly _category = "Szervezet";

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	displayedColumns: string[] = ['id', 'r_name', 'r_desc', 'action'];
	dataSource: MatTableDataSource<RoleModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	filterValue: string = "";

	constructor(
		private roleService: RoleService,
		public dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getName(),
				row.getDesc()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<RoleModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new RoleModel(serverResponse[i]));
		}

		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'r_name': return item.getName();
				case 'r_desc': return item.getDesc();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.roleService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	add() {
		this.loadSettingComponent.emit(RoleService.ADD_TAG);
	}

	mod(id) {
		sessionStorage.setItem(AppConstants.PARAM_MODROLE_ROLEID, id);
		this.loadSettingComponent.emit(RoleService.MOD_TAG);
	}

}

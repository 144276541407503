import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {PartnerModel} from 'src/app/components/settings/partner/model/partner.model';

@Injectable()
export class PartnerService {

	public static readonly API_TYPE: string = 'partner';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + PartnerService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + PartnerService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + PartnerService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + PartnerService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-partners';
	public static readonly ADD_TAG: string = 'setting-addpartner';
	public static readonly MOD_TAG: string = 'setting-modpartner';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(PartnerService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(PartnerService.GET_API, params, {params: parameters});
	}

	add(obj: PartnerModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('pa_kod', obj.getKod());
		params.append('pa_nev', obj.getNev());
		params.append('pa_varos', obj.getVaros());
		params.append('pa_iranyitoszam', obj.getIranyitoszam());
		params.append('pa_utca', obj.getUtca());
		params.append('pa_kozterulet', obj.getKozterulet());
		params.append('pa_hazszam', obj.getHazszam());
		params.append('pa_adoszam', obj.getAdoszam());
		params.append('pa_bankszamla', obj.getBankszamla());
		params.append('pa_weboldal', obj.getWeboldal());
		params.append('pa_vevo', String((obj.isVevo() === true || obj.isVevo() == 1 || obj.isVevo() == 'true') ? 1 : 0));
		params.append('pa_gyarto', String((obj.isGyarto() === true || obj.isGyarto() == 1 || obj.isGyarto() == 'true') ? 1 : 0));
		params.append('pa_beszallito', String((obj.isBeszallito() === true || obj.isBeszallito() == 1 || obj.isBeszallito() == 'true') ? 1 : 0));
		params.append('pa_viszontelado', String((obj.isViszontelado() === true || obj.isViszontelado() == 1 || obj.isViszontelado() == 'true') ? 1 : 0));
		params.append('pa_kedvezmeny', String(obj.getKedvezmeny()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(PartnerService.ADD_API, params, {params: parameters});
	}

	mod(obj: PartnerModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('pa_kod', obj.getKod());
		params.append('pa_nev', obj.getNev());
		params.append('pa_varos', obj.getVaros());
		params.append('pa_iranyitoszam', obj.getIranyitoszam());
		params.append('pa_utca', obj.getUtca());
		params.append('pa_kozterulet', obj.getKozterulet());
		params.append('pa_hazszam', obj.getHazszam());
		params.append('pa_adoszam', obj.getAdoszam());
		params.append('pa_bankszamla', obj.getBankszamla());
		params.append('pa_weboldal', obj.getWeboldal());
		params.append('pa_vevo', String((obj.isVevo() === true || obj.isVevo() == 1 || obj.isVevo() == 'true') ? 1 : 0));
		params.append('pa_gyarto', String((obj.isGyarto() === true || obj.isGyarto() == 1 || obj.isGyarto() == 'true') ? 1 : 0));
		params.append('pa_beszallito', String((obj.isBeszallito() === true || obj.isBeszallito() == 1 || obj.isBeszallito() == 'true') ? 1 : 0));
		params.append('pa_viszontelado', String((obj.isViszontelado() === true || obj.isViszontelado() == 1 || obj.isViszontelado() == 'true') ? 1 : 0));
		params.append('pa_kedvezmeny', String(obj.getKedvezmeny()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(PartnerService.MOD_API, params, {params: parameters});
	}
}

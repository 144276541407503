export class LemezAnyagminosegModel {

	private id: number = null;

	private fajsuly: number = null;

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.fajsuly = json && json.fajsuly || null;

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getFajsuly() {
		return this.fajsuly;
	}

	setFajsuly(value) {
		this.fajsuly = value;
	}
}

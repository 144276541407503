<mat-toolbar *ngIf="hasTitle()" color="primary">
	 <mat-toolbar-row>
		  <span>{{dialogTitle}}</span>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
	 <p class="dialogText">{{dialogMessage}}</p>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Indkolás</mat-label>
			   <textarea matInput name="inputReason" rows="5" [(ngModel)]="reason" required></textarea>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="dialogRef.close(reason)" [disabled]="!targetForm.valid">{{dialogYesButton}}</button>
		  <button mat-flat-button color="warn" (click)="dialogRef.close(false)">{{dialogNoButton}}</button>
	 </div>
</mat-dialog-content>
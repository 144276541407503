export class CommonModel {
	public static replaceHunChars(text: string): string {
		let mapObj = {'á': 'a', 'é': 'e', 'í': 'i', 'ű': 'u', 'ú': 'u', 'ü': 'u', 'ő': 'o', 'ó': 'o', 'ö': 'o'};
		let regex = new RegExp(Object.keys(mapObj).join("|"), "gi");

		text = text.replace(regex, function (matched) {
			return mapObj[matched];
		});

		return text;
	}

	public static isDate(date: string): boolean {
		let regexp = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/g;
		return regexp.test(date);
	}

	public static isDateTime(datetime: string): boolean {
		let regexp = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])( )([0-1]\d|2[0-3])(:([0-5]\d)){1,2})$/g;
		return regexp.test(datetime);
	}

	public static dateDiff(date1: Date, date2: Date) {
		return Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
	}

	public static getWeekNumber(dt: Date): number {
		var tdt = new Date(dt.valueOf());
		var dayn = (dt.getDay() + 6) % 7;
		tdt.setDate(tdt.getDate() - dayn + 3);
		var firstThursday = tdt.getTime();
		tdt.setMonth(0, 1);
		if (tdt.getDay() !== 4) {
			tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
		}
		return 1 + Math.ceil((firstThursday - tdt.getTime()) / 604800000);
	}

	public static getDateString(date:Date) {
		
		return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).substr(-2) + "-" + ("0" + date.getDate()).substr(-2) + " " + ("0" + date.getHours()).substr(-2) + ":" + ("0" + date.getMinutes()).substr(-2) + ":00");
	}
}

<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Szülő feladat</mat-label>
			   <mat-select name="inputParent" [ngModel]="targetObj.getParent()" (ngModelChange)="targetObj.setParent($event)" disabled>
					<mat-option *ngFor="let template of templates" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Gyermek feladat</mat-label>
			   <mat-select name="inputChild" [ngModel]="targetObj.getChild()" (ngModelChange)="targetObj.setChild($event)" disabled>
					<mat-option *ngFor="let template of templates" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Delegálás típusa</mat-label>
			   <mat-select name="inputAssigntype" [ngModel]="targetObj.getAssigntype()" (ngModelChange)="targetObj.setAssigntype($event)" disabled>
					<mat-option *ngFor="let assigntype of listsModel.TaskAssignTypeList" [value]="assigntype.getValue()">{{assigntype.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
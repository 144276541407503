import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';

@Injectable()
export class HabSzallitasService {

	public static readonly API_TYPE: string = 'habszallitas';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/add_request';
	public static readonly ADD_WITH_HABSZINTEK_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/add_with_habszintek_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + HabSzallitasService.API_TYPE + '/del_request';
	
	public static readonly LIST_TAG: string = 'setting-habszallitasok';
	public static readonly ADD_TAG: string = 'setting-addhabszallitas';
	public static readonly MOD_TAG: string = 'setting-modhabszallitas';
	public static readonly DEL_TAG: string = 'setting-delhabszallitas';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzallitasService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzallitasService.GET_API, params, {params: parameters});
	}

	add(obj: HabSzallitasModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('idopont', obj.getIdopontString());
		params.append('szallitolevel', obj.getSzallitolevel());
		params.append('amennyiseg', String(obj.getAmennyiseg()));
		params.append('anetto', String(obj.getAnetto()));
		params.append('ameres', String(obj.getAmeres()));
		params.append('bmennyiseg', String(obj.getBmennyiseg()));
		params.append('bnetto', String(obj.getBnetto()));
		params.append('bmeres', String(obj.getBmeres()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzallitasService.ADD_API, params, {params: parameters});
	}
	addWithHabszintek(obj:HabSzallitasModel){
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('idopont', obj.getIdopontString());
		params.append('szallitolevel', obj.getSzallitolevel());
		params.append('amennyiseg', String(obj.getAmennyiseg()));
		params.append('anetto', String(obj.getAnetto()));
		params.append('bmennyiseg', String(obj.getBmennyiseg()));
		params.append('bnetto', String(obj.getBnetto()));
		params.append('habszintidelotte',String(obj.getHabSzintidelotte()));
		params.append('habszintidutana',String(obj.getHabSzintidutana()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzallitasService.ADD_WITH_HABSZINTEK_API, params, {params: parameters});
	}
	mod(obj: HabSzallitasModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id',String(obj.getId()))
		params.append('idopont', obj.getIdopontString());
		params.append('szallitolevel', obj.getSzallitolevel());
		params.append('habszintidelotte',String(obj.getHabSzintidelotte()));
		params.append('habszintidutana',String(obj.getHabSzintidutana()));
		params.append('amennyiseg', String(obj.getAmennyiseg()));
		params.append('anetto', String(obj.getAnetto()));
		params.append('bmennyiseg', String(obj.getBmennyiseg()));
		params.append('bnetto', String(obj.getBnetto()));
		

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzallitasService.MOD_API, params, {params: parameters});
	}

	del(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzallitasService.DEL_API, params, {params: parameters});
	}
}

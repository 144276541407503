import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FileUploader} from 'ng2-file-upload';

import {FileTypeService} from 'src/app/components/settings/filetype/service/filetype.service';
import {ProjectService} from 'src/app/components/settings/project/service/project.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {TaskCommentService} from 'src/app/components/task/service/taskcomment.service';
import {AuthService} from 'src/app/services/auth.service';

import {CommonModel} from 'src/app/model/common.model';
import {AppConstants} from 'src/app/model/appconstants.model';

import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskFileModel} from 'src/app/components/task/model/taskfile.model';
import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';
import {TaskCommentModel} from 'src/app/components/task/model/taskcomment.model';
import {ListsModel} from 'src/app/model/lists.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {TaskDialog} from 'src/app/components/task/dialog/task-dialog/task-dialog';
import {TaskDeadlineDialog} from 'src/app/components/task/dialog/task-deadline-dialog/task-deadline-dialog';

export interface CustomProjectData {
	name: string;
	value: string;
}

@Component({
	selector: 'app-projectinfo',
	templateUrl: './projectinfo.component.html',
	styleUrls: ['./projectinfo.component.scss']
})
export class ProjectInfoComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	taskDialogRef: MatDialogRef<TaskDialog>;
	taskDeadlineDialogRef: MatDialogRef<TaskDeadlineDialog>;

	loading: number = 0;

	displayedColumns: string[] = ['id', 'p_number', 'p_name', 'p_desc', 'managername', 'partnernev', 'p_status'];
	dataSource: MatTableDataSource<ProjectModel>;

	customDataDisplayedColumns: string[] = ['name', 'value'];
	customDataDataSource: MatTableDataSource<CustomProjectData>;

	taskDisplayedColumns: string[] = ['id', 'taskname', 't_deadline', 't_createdtime', 't_assignedtime', 't_donetime', 'owner', 'action'];
	taskDataSource: MatTableDataSource<TaskModel>;

	@ViewChild('taskPaginator', {static: false}) taskPaginator: MatPaginator;
	@ViewChild('taskSort', {static: false}) taskSort: MatSort;

	taskFilterValue: string = "";

	commentDisplayedColumns: string[] = ['id', 'username', 'tc_comment', 'datetime'];
	commentDataSource: MatTableDataSource<TaskCommentModel>;

	@ViewChild('commentPaginator', {static: false}) commentPaginator: MatPaginator;
	@ViewChild('commentSort', {static: false}) commentSort: MatSort;

	commentFilterValue: string = "";

	listsModel: any = ListsModel;

	targetObj: ProjectModel = new ProjectModel({});
	tabname: string = '';
	customdata: Array<CustomProjectData> = [];

	ableToModFiles: boolean = false;

	filetypes: Array<FileTypeModel> = [];

	uploader: FileUploader = new FileUploader({isHTML5: true});

	constructor(
		private fileTypeService: FileTypeService,
		private projectService: ProjectService,
		private taskService: TaskService,
		private taskCommentService: TaskCommentService,
		private authService: AuthService,
		private router: Router,
		private dialog: MatDialog
	) {
		if (!this.getProjectID()) {
			this.back();
		} else {
			this.uploader.onAfterAddingFile = (item) => {
				item.formData = -1;
			}

			this.loading++;
			this.fileTypeService.getAll().subscribe(
				res => this.handleGetFileTypesResponse(res),
				error => this.handleGetFileTypesError(error)
			);

			this.loading++;
			this.projectService.get(Number(this.getProjectID())).subscribe(
				res => this.handleGetProjectResponse(res),
				error => this.handleGetProjectError(error)
			);

			this.loadTaskData();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	applyTaskFilter() {
		this.taskDataSource.filter = CommonModel.replaceHunChars(this.taskFilterValue.trim().toLowerCase());

		if (this.taskDataSource.paginator && this.taskDataSource.paginator.pageIndex > this.taskDataSource.paginator.pageSize) {
			this.taskDataSource.paginator.lastPage();
		}
	}

	applyCommentFilter() {
		this.commentDataSource.filter = CommonModel.replaceHunChars(this.commentFilterValue.trim().toLowerCase());

		if (this.commentDataSource.paginator && this.commentDataSource.paginator.pageIndex > this.commentDataSource.paginator.pageSize) {
			this.commentDataSource.paginator.lastPage();
		}
	}

	handleGetProjectTasksError(serverError) {
		this.handleError(serverError);
	}

	handleGetProjectTasksResponse(serverResponse) {
		this.loading--;
		let objects: Array<TaskModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new TaskModel(serverResponse[i]));
		}

		this.taskDataSource = new MatTableDataSource(objects);
		this.taskDataSource.paginator = this.taskPaginator;
		this.taskDataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'taskname': return item.getTemplateModel().getName();
				case 't_deadline': return item.getDeadline();
				case 'owner': return item.getOwnerModel().getFullName();

				default: return item[property];
			}
		};
		this.taskDataSource.sort = this.taskSort;
		this.taskDataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getTemplateModel().getName(),
				row.getDeadline(),
				row.getOwnerModel().getFullName()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.applyTaskFilter();
	}

	loadTaskData() {
		this.loading++;
		this.projectService.getTasks(Number(this.getProjectID())).subscribe(
			res => this.handleGetProjectTasksResponse(res),
			error => this.handleGetProjectTasksError(error)
		);
	}

	openTaskDialog(id: number) {
		this.taskDialogRef = this.dialog.open(TaskDialog, {
			disableClose: true,
			panelClass: 'taskDialogWindow',
			autoFocus: false,
			data: {
				taskId: id
			}
		});

		this.taskDialogRef.afterClosed().subscribe(result => {
			this.loading++;
			this.projectService.get(Number(this.getProjectID())).subscribe(
				res => this.handleGetProjectResponse(res),
				error => this.handleGetProjectError(error)
			);
			this.loadTaskData();
			this.taskDialogRef = null;
		});
	}

	uploadFiles() {
		for (let i = 0; i < this.uploader.queue.length; i++) {
			let fileItem = this.uploader.queue[i];
			let file = fileItem._file
			if (fileItem.formData == null || fileItem.formData < 1) {
				this.alertDialogRef = this.dialog.open(AlertDialog, {
					disableClose: true,
					panelClass: 'alertDialogWindow',
					autoFocus: false
				});

				this.alertDialogRef.componentInstance.dialogMessage = "Fájl típus megadása kötelező '" + file.name + "' nevű fájlnál";

				break;
			} else {
				this.loading++;
				this.taskService.uploadFile(this.targetObj.getId(), null, fileItem.formData, file).subscribe(
					res => this.handleFileUploadResponse(res),
					error => this.handleFileUploadError(error)
				);
			}
		}
	}

	getTaskStatusClass(row: TaskModel) {
		if (row.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) {
			return 'completedTask';
		} else if (row.getStatus() == ListsModel.TaskStatus_SZTORNOZVA.getValue()) {
			return 'stornedTask';
		} else if (CommonModel.isDateTime(row.getDeadline())) {
			let date1 = new Date(Date.parse(row.getDeadline()));
			let date2 = new Date();

			if (date1.getTime() < date2.getTime()) {
				return 'expiredTask';
			}
		}

		return '';
	}

	handleFileUploadError(serverError) {
		this.handleError(serverError);
	}

	handleFileUploadResponse(serverResponse) {
		this.loading--;
		let uploadedFile: TaskFileModel = new TaskFileModel(serverResponse.file);
		for (let i = 0; i < this.uploader.queue.length; i++) {
			if (this.uploader.queue[i]._file.name == uploadedFile.getOriginalname()) {
				this.uploader.queue[i].remove();
			}
		}

		if (this.uploader.queue.length < 1) {
			this.loading++;
			this.projectService.get(Number(this.getProjectID())).subscribe(
				res => this.handleGetProjectResponse(res),
				error => this.handleGetProjectError(error)
			);
		}
	}

	downloadFile(fileid) {
		let newTab = window.open(TaskService.DOWNLOAD_FILE_API + '/' + this.authService.getToken() + '/' + this.targetObj.getId() + '/' + fileid);
		if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = "Fájl letöltése előtt engedélyeznie kell az oldalon a felugró ablakokat";
		}
	}

	deleteFile(fileid) {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli ezt a fájlt?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.taskService.deleteFile(fileid).subscribe(
					res => this.handleFileDeleteResponse(res),
					error => this.handleFileDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	openTaskDeadlineDialog(id: number, deadline: string) {
		this.taskDeadlineDialogRef = this.dialog.open(TaskDeadlineDialog, {
			disableClose: true,
			panelClass: 'taskDeadlineDialogWindow',
			autoFocus: false,
			data: {
				taskId: id,
				deadline: deadline,
			}
		});

		this.taskDeadlineDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loadTaskData();
			}

			this.taskDeadlineDialogRef = null;
		});
	}

	canEditDeadline(status, owner, manager) {
		let userid = this.authService.getUser().getId();
		if ((status == ListsModel.TaskStatus_LETREHOZVA.getValue() || status == ListsModel.TaskStatus_FOLYAMATBAN.getValue()) && (owner == userid || manager == userid || this.authService.isAdmin())) {
			return true;
		} else {
			return false;
		}
	}

	handleFileDeleteError(serverError) {
		this.handleError(serverError);
	}

	handleFileDeleteResponse(serverResponse) {
		this.loading--;
		this.loading++;
		this.projectService.get(Number(this.getProjectID())).subscribe(
			res => this.handleGetProjectResponse(res),
			error => this.handleGetProjectError(error)
		);
	}

	handleGetFileTypesError(serverError) {
		this.handleError(serverError);
	}

	handleGetFileTypesResponse(serverResponse) {
		this.loading--;
		this.filetypes = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.filetypes.push(new FileTypeModel(serverResponse[i]));
		}
	}

	handleGetProjectResponse(serverResponse) {
		this.loading--;

		this.targetObj = new ProjectModel(serverResponse);
		let objects: Array<ProjectModel> = [this.targetObj];

		this.dataSource = new MatTableDataSource(objects);

		if (this.targetObj.getManager() == this.authService.getUser().getId() || this.authService.isAdmin()) {
			this.ableToModFiles = true;
		} else {
			this.ableToModFiles = false;
		}

		this.loading++;
		this.projectService.getCustomData(Number(this.getProjectID())).subscribe(
			res => this.handleGetCustomProjectDataResponse(res),
			error => this.handleGetCustomProjectDataError(error)
		);

		this.loading++;
		this.taskCommentService.getAllByProjectId(Number(this.getProjectID())).subscribe(
			res => this.handleGetTaskCommentResponse(res),
			error => this.handleGetTaskCommentError(error)
		);
	}

	handleGetProjectError(serverError) {
		this.handleError(serverError);
	}

	handleGetCustomProjectDataResponse(serverResponse) {
		this.loading--;

		if (serverResponse.tabname.length > 0 && serverResponse.customdata.length == 2 && serverResponse.customdata[0].length > 0 && serverResponse.customdata[0].length == serverResponse.customdata[1].length) {
			this.tabname = serverResponse.tabname;
			let names: Array<string> = serverResponse.customdata[0];
			let values: Array<string> = serverResponse.customdata[1];
			this.customdata = [];

			for (let i = 0; i < names.length; i++) {
				this.customdata.push({name: names[i], value: values[i]});
			}
			this.customDataDataSource = new MatTableDataSource(this.customdata);
		}
	}

	handleGetTaskCommentError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskCommentResponse(serverResponse) {
		this.loading--;
		let objects: Array<TaskCommentModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new TaskCommentModel(serverResponse[i]));
		}

		this.commentDataSource = new MatTableDataSource(objects);
		this.commentDataSource.paginator = this.commentPaginator;
		this.commentDataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'username': return item.getUserModel().getFullName();
				case 'tc_comment': return item.getComment();
				case 'datetime': return item.getDateTime();

				default: return item[property];
			}
		};
		this.commentDataSource.sort = this.commentSort;
		this.commentDataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getComment(),
				row.getUserModel().getFullName(),
				row.getDateTime()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.applyCommentFilter();
	}

	handleGetCustomProjectDataError(serverError) {
		this.handleError(serverError);
	}

	getProjectID() {
		return sessionStorage.getItem(AppConstants.PARAM_OPENPROJECTINFO_PROJECTID);
	}

	delProjectID() {
		sessionStorage.removeItem(AppConstants.PARAM_OPENPROJECTINFO_PROJECTID);
	}

	back() {
		this.router.navigateByUrl('/projektek');
	}

	ngOnDestroy() {
		this.delProjectID();
	}

}

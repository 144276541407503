import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

@Injectable()
export class UserService {

	public static readonly API_TYPE: string = 'user';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + UserService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + UserService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + UserService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + UserService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-users';
	public static readonly ADD_TAG: string = 'setting-adduser';
	public static readonly MOD_TAG: string = 'setting-moduser';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(UserService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(UserService.GET_API, params, {params: parameters});
	}

	add(obj: UserModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('username', (obj.getUsername()));
		params.append('u_firstname', (obj.getFirstName()));
		params.append('u_lastname', obj.getLastName());
		params.append('u_email', obj.getEmail());
		params.append('u_phone', obj.getPhone());
		params.append('u_language', obj.getLanguage());
		if (obj.getDeputy() != null) {
			params.append('u_deputy', String(obj.getDeputy()));
		}
		params.append('u_hidden', String((obj.isHidden() === true || obj.isHidden() == 1 || obj.isHidden() == 'true') ? 1 : 0));
		params.append('u_disabled', String((obj.isDisabled() === true || obj.isDisabled() == 1 || obj.isDisabled() == 'true') ? 1 : 0));
		params.append('password', obj.getPassword());
		params.append('password_confirm', obj.getPasswordConfirm());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(UserService.ADD_API, params, {params: parameters});
	}

	mod(obj: UserModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('username', obj.getUsername());
		params.append('u_firstname', obj.getFirstName());
		params.append('u_lastname', obj.getLastName());
		params.append('u_email', obj.getEmail());
		params.append('u_phone', obj.getPhone());
		params.append('u_language', obj.getLanguage());
		if (obj.getDeputy() != null) {
			params.append('u_deputy', String(obj.getDeputy()));
		}
		params.append('u_hidden', String((obj.isHidden() === true || obj.isHidden() == 1 || obj.isHidden() == 'true') ? 1 : 0));
		params.append('u_disabled', String((obj.isDisabled() === true || obj.isDisabled() == 1 || obj.isDisabled() == 'true') ? 1 : 0));
		params.append('password', obj.getPassword());
		params.append('password_confirm', obj.getPasswordConfirm());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(UserService.MOD_API, params, {params: parameters});
	}
}

<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			<div class="szamla-wrapper px-2 py2">
			   <div class="col-14 mat-elevation-z4 my-2">
				<table class="table tetelek-table">
					 <thead>
						  <tr>
							   <th>Számla típus</th>
							   <th>Alap bizonylat</th>
							   <th>Teljesítés dátuma</th>
							   <th>Kiállítás dátuma</th>
							   <th>Fizetési határidő</th>
							   <th>Pénznem</th>
							   <th>Bruttó összeg</th>
							   <th>Kifizetve-bruttó</th>
							   <th>Különbözet</th>
							   <th>Sorszám</th>
							   <th>Beérkezés dátuma</th>
							   <th *ngIf="writeable">
									<span style="cursor: pointer;" (click)="addTetel()"><fa-icon [icon]="['fas', 'plus']" size="lg" matTooltip="Új tétel"></fa-icon></span>
							   </th>
						  </tr>
					 </thead>
					 <tbody>
						  <tr *ngFor="let tetel of formdata; let tetelIdx = index">
							  <td *ngIf="writeable">
								<mat-select [(ngModel)]="tetel.szamlatipus">
									<mat-option *ngFor="let type of szamlatypes" [value]="type.value">{{type.text}}</mat-option>
								</mat-select>
							  </td>
							  <td *ngIf="!writeable"><span> {{ getSzamlaTypeName(tetel.szamlatipus) }} </span></td>
							  <td *ngIf="writeable">
								  <input matInput name="inputSorszam" [(ngModel)]="tetel.sorszam" placeholder="-- Alap bizonylat --">
							  </td>
					 		  <td *ngIf="!writeable"><span> {{ tetel.sorszam }} </span></td>
					 		  <td *ngIf="writeable">
									<input matInput name="inputTeljesites" [(ngModel)]="tetel.teljesites" placeholder="-- Teljesítés dátuma --" [owlDateTime]="inputTeljesitesPicker" [owlDateTimeTrigger]="inputTeljesitesPicker" [disabled]="!writeable">
									<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputTeljesitesPicker></owl-date-time>
				   			  </td>
				   			  <td *ngIf="!writeable"><span> {{ tetel.teljesites }} </span></td>
				   			  <td *ngIf="writeable">
									<input matInput name="inputKiallitas" [(ngModel)]="tetel.kiallitas" placeholder="-- Kiállítás dátuma --" [owlDateTime]="inputKiallitasPicker" [owlDateTimeTrigger]="inputKiallitasPicker" [disabled]="!writeable">
									<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputKiallitasPicker></owl-date-time>
			   				  </td>
			   				  <td *ngIf="!writeable"><span> {{ tetel.kiallitas }} </span></td>
			   				  <td *ngIf="writeable">
									<input matInput name="inputHatarido" [(ngModel)]="tetel.hatarido" placeholder="-- Fizetési határidő --" [owlDateTime]="inputHataridoPicker" [owlDateTimeTrigger]="inputHataridoPicker" [disabled]="!writeable">
									<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputHataridoPicker></owl-date-time>
		   					  </td>
		   					  <td *ngIf="!writeable"><span> {{ tetel.hatarido }} </span></td>
		   				      <td *ngIf="writeable">
						  			<input matInput type="text"  name="inputPenznem" [(ngModel)]="tetel.penznem" placeholder="-- Pénznem --">
					 		  </td>
							  <td *ngIf="!writeable"><span> {{ tetel.penznem }} </span></td>
					 		  <td *ngIf="writeable">
						  			<input matInput type="number" step="1" name="inputBrutto" [ngModel]="tetel.brutto" (ngModelChange)="bruttoChange($event,tetelIdx)" placeholder="-- Bruttó összeg --">
					 		  </td>
					 		  <td *ngIf="!writeable" class="right-alignment"><span> {{  formatNumber(tetel.brutto) }} </span></td>
							  <td *ngIf="writeable">
								<input matInput type="number" step="1" name="inputKifizetve" [ngModel]="tetel.kifizetve" (ngModelChange)="kifizetveChange($event,tetelIdx)" placeholder="-- Kifizetve-bruttó --">
						 	  </td>
						      <td *ngIf="!writeable" class="right-alignment"><span> {{ formatNumber(tetel.kifizetve) }} </span></td>
							  
							  <td class="right-alignment"><span>{{formatNumber(tetel.brutto-tetel.kifizetve)}} </span></td>
							  
							  <td *ngIf="writeable">
								<input matInput name="inputSzamla" [(ngModel)]="tetel.szamla" placeholder="-- Számla sorszám --">
						  	  </td>
						  	  <td *ngIf="!writeable"><span> {{ tetel.szamla }} </span></td>
							  <td *ngIf="writeable">
								<input matInput name="inputBeerkezes" [(ngModel)]="tetel.beerkezes" placeholder="-- Beérkezés dátuma --" [owlDateTime]="inputBeerkezesPicker" [owlDateTimeTrigger]="inputBeerkezesPicker" [disabled]="!writeable">
								<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputBeerkezesPicker></owl-date-time>
							  </td>
							  <td *ngIf="!writeable"><span> {{ tetel.beerkezes }} </span></td>

					 		  <td *ngIf="writeable">
						  			<span style="cursor: pointer;" (click)="removeTetel(tetelIdx)">
										<fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Tétel törlése"></fa-icon>
						  			</span>
					 		  </td>
						</tr>
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="right-alignment">{{formatNumber(brutto)}}</td>
							<td class="right-alignment">{{formatNumber(kifizetve)}}</td>
							<td class="right-alignment">{{formatNumber(brutto-kifizetve)}}</td>
							<td></td>
							<td></td>
							<td *ngIf="writeable"></td>
						</tr>
					 </tbody>
				</table>
			   </div>
		   </div>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" *ngIf="writeable">Mentés</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
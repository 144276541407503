import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddHabSzallitasComponent} from 'src/app/components/settings/raktar/habszallitas/component/addhabszallitas/addhabszallitas.component';
import {ModHabSzallitasComponent} from 'src/app/components/settings/raktar/habszallitas/component/modhabszallitas/modhabszallitas.component';
import {HabSzallitasokComponent} from 'src/app/components/settings/raktar/habszallitas/component/habszallitasok/habszallitasok.component';
import { DelHabSzallitasComponent } from 'src/app/components/settings/raktar/habszallitas/component/delhabszallitas/delhabszallitas.component';

import { HabSzallitasService } from 'src/app/components/raktar/service/habszallitas.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddHabSzallitasComponent,
		ModHabSzallitasComponent,
		HabSzallitasokComponent,
		DelHabSzallitasComponent
	],
	entryComponents: [
		AddHabSzallitasComponent,
		ModHabSzallitasComponent,
		HabSzallitasokComponent,
		DelHabSzallitasComponent
	],
	providers: [
		HabSzallitasService
	],
	exports: [
		AddHabSzallitasComponent,
		ModHabSzallitasComponent,
		HabSzallitasokComponent,
		DelHabSzallitasComponent
	],
})
export class HabSzallitasModule {
	public static readonly compArray = [
		AddHabSzallitasComponent,
		ModHabSzallitasComponent,
		HabSzallitasokComponent,
		DelHabSzallitasComponent
	];

	public static readonly compMap = HabSzallitasModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in HabSzallitasModule.compMap) ? HabSzallitasModule.compMap[TAG].NAME : '';
	}
}
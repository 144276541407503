import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {JovahagyasService} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/jovahagyas.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {JovahagyasConfigComponent, Config} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/config/jovahagyasconfig.component';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {ListsModel} from 'src/app/model/lists.model';

export interface Jovahagyas {
	dontes: any;
	dontesidopontja: any;
}

@Component({
	selector: 'form-jovahagyas',
	templateUrl: './jovahagyas.component.html',
	styleUrls: ['./jovahagyas.component.scss']
})
export class JovahagyasComponent {

	public static readonly TAG: string = 'form-jovahagyas';
	public static readonly NAME: string = 'Jóváhagyás';
	public static readonly configComponent = JovahagyasConfigComponent;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	formdata: Jovahagyas = {
		dontes: null,
		dontesidopontja: null
	};

	formconfig: Config = {
		donteslabel: null,
		dontesidopontjalabel: null,
		donequery: null
	}

	dontes_visible: boolean = false;
	dontesidopontja_visible: boolean = false;

	constructor(
		public jovahagyasService: JovahagyasService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.jovahagyasService.getConf(this.taskObject.getTemplateModel().getId()).subscribe(
			res => this.handleGetConfResponse(res),
			error => this.handleGetConfError(error)
		);

		this.increaseLoading.emit(null);
		this.jovahagyasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpFormdata = {...this.formdata};
		for (let key in tmpFormdata) {
			tmpFormdata[key] = this.transform(tmpFormdata[key]);
		}

		return tmpFormdata;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.jovahagyasService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.jovahagyasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
					res => this.handleSaveDoneResponse(res),
					error => this.handleSaveDoneError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.jovahagyasService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getconf
	handleGetConfResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.formconfig = serverResponse;

		if ((this.formconfig.donequery.match(/:dontes/g) || []).length == 2 || ((this.formconfig.donequery.match(/:dontes/g) || []).length == 1 && this.formconfig.donequery.indexOf(':dontesidopontja') < 0)) {
			this.dontes_visible = true;
		} else {
			this.formdata.dontes = "1"
		}

		if (this.formconfig.donequery.indexOf(':dontesidopontja') >= 0) {
			this.dontesidopontja_visible = true;
		} else {
			this.formdata.dontesidopontja = new Date();
		}
	}

	handleGetConfError(serverError) {
		this.handleError(serverError);
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		if (serverResponse.formdata.dontes != null) {
			this.formdata.dontes = String(serverResponse.formdata.dontes);
		}

		if (serverResponse.formdata.dontesidopontja != null) {
			this.formdata.dontesidopontja = serverResponse.formdata.dontesidopontja;
		}

		if (CommonModel.isDateTime(this.formdata.dontesidopontja)) {
			this.formdata.dontesidopontja = new Date(Date.parse(this.formdata.dontesidopontja));
		}
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.jovahagyasService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

}
import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';
import {DialogsModule} from 'src/app/dialog/dialogs.module';
import {SettingsModule} from 'src/app/components/settings/settings.module';
import {SzerelesekModule} from 'src/app/components/szereles/module/szerelesek.module';
import {RaktarModule} from 'src/app/components/raktar/module/raktar.module';
import {TaskModule} from 'src/app/components/task/module/task.module';
import {SzamlazasModule} from 'src/app/components/szamlazas/module/szamlazas.module';

import {AppComponent} from 'src/app/app.component';
import {AppRoutingModule} from 'src/app/app-routing.module';

import {LoginComponent} from 'src/app/components/login/login.component';
import {LogoutComponent} from 'src/app/components/logout/logout.component';
import {NavbarComponent} from 'src/app/components/navbar/navbar.component';
import {WelcomeComponent} from 'src/app/components/welcome/welcome.component';
import {ProjectListComponent} from 'src/app/components/projectlist/projectlist.component';
import {ProjectInfoComponent} from 'src/app/components/projectinfo/projectinfo.component';

import {AuthService} from 'src/app/services/auth.service';
import {AuthGuardService} from 'src/app/services/auth-guard.service';
import {AnonymousGuardService} from 'src/app/services/anonymous-guard.service';
import {AdminGuardService} from 'src/app/services/admin-guard.service';
import {PreviousRouteService} from 'src/app/services/previous-route.service';




@NgModule({
	imports: [
		AppRoutingModule,

		CommonModules,
		DialogsModule,
		SettingsModule,
		SzerelesekModule,
		RaktarModule,
		TaskModule,
		SzamlazasModule
	],
	declarations: [
		AppComponent,
		LoginComponent,
		LogoutComponent,
		NavbarComponent,
		WelcomeComponent,
		ProjectListComponent,
		ProjectInfoComponent,
	],
	providers: [
		AuthService,
		AuthGuardService,
		AnonymousGuardService,
		AdminGuardService,
		PreviousRouteService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}


<mat-toolbar color="primary" *ngIf="loggedIn && enabled">
			 <mat-toolbar-row>
		  <img class="brand-logo" src="assets/gabler_logo.jpg" routerLink="/feladatok" style="cursor: pointer;" />
		  <div>
			   <button mat-mini-fab [color]="url=='/feladatok'?'accent':'disabled'" routerLink="/feladatok" matTooltip="Feladatok">
					   <fa-icon [icon]="['fas', 'tasks']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/projektek'?'accent':'disabled'" routerLink="/projektek" matTooltip="Projektek" *hasPermission="'Modules:Project:menu'">
					   <fa-icon [icon]="['fas', 'project-diagram']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/szerelesek'?'accent':'disabled'" routerLink="/szerelesek" matTooltip="Szerelések" *hasPermission="['TaskForm:Szereles:menu', 'TaskForm:Szereles:menuown']">
					   <fa-icon [icon]="['fas', 'tools']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/raktar'?'accent':'disabled'" routerLink="/raktar" matTooltip="Raktár" *hasPermission="[
			   'Modules:HabMinoseg:list', 'Modules:HabMinoseg:add', 'Modules:HabMinoseg:mod', 
			   'Modules:LemezAnyagminoseg:list', 'Modules:LemezAnyagminoseg:add', 'Modules:LemezAnyagminoseg:mod', 
			   'Modules:LemezGyarto:list', 'Modules:LemezGyarto:add', 'Modules:LemezGyarto:mod', 
			   'Modules:LemezRaktar:list', 'Modules:LemezRaktar:add', 'Modules:LemezRaktar:mod', 
			   'Modules:LemezSzelesseg:list', 'Modules:LemezSzelesseg:add', 'Modules:LemezSzelesseg:mod', 
			   'Modules:LemezSzin:list', 'Modules:LemezSzin:add', 'Modules:LemezSzin:mod', 
			   'Modules:LemezTarolo:list', 'Modules:LemezTarolo:add', 'Modules:LemezTarolo:mod', 
			   'Modules:LemezVastagsag:list', 'Modules:LemezVastagsag:add', 'Modules:LemezVastagsag:mod']">
			   		<fa-icon [icon]="['fas', 'warehouse']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/szamlak'?'accent':'disabled'" routerLink="/szamlak" matTooltip="Számlák" *hasPermission="['TaskForm:Szamlak:list']">
						<fa-icon [icon]="['fas', 'dollar-sign']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab color="disabled" matTooltip="Flowchart" *hasPermission="['Modules:TaskTemplate:flowchart']" (click)="openFlowchart()">
					   <fa-icon [icon]="['fas', 'sitemap']" size="lg"></fa-icon>
			   </button>
		  </div>
		  <span class="spacer mobile-view-hide"></span>
		  <div class="mobile-view-hide">
			   <button mat-flat-button matTooltip="Profilom" (click)="openProfile()">
					<span>
						 {{name}}
					</span>
					<fa-icon [icon]="['fas', 'user']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab color="disabled" matTooltip="Beállítások" (click)="openSettings()" *hasPermission="[
					   'Modules:Category:list', 'Modules:Category:add', 'Modules:Category:mod',
					   'Modules:FileType:list', 'Modules:FileType:add', 'Modules:FileType:mod',
					   'Modules:Partner:list', 'Modules:Partner:add', 'Modules:Partner:mod',
					   'Modules:Project:list', 'Modules:Project:add', 'Modules:Project:mod',
					   'Modules:Role:list', 'Modules:Role:add', 'Modules:Role:mod',
					   'Modules:RoleAssign:list', 'Modules:RoleAssign:add', 'Modules:RoleAssign:mod', 'Modules:RoleAssign:del',
					   'Modules:TaskCondition:list', 'Modules:TaskCondition:add', 'Modules:TaskCondition:mod', 'Modules:TaskCondition:del',
					   'Modules:TaskRef:list', 'Modules:TaskRef:add', 'Modules:TaskRef:mod', 'Modules:TaskRef:del',
					   'Modules:TaskRole:list', 'Modules:TaskRole:add', 'Modules:TaskRole:mod', 'Modules:TaskRole:del',
					   'Modules:TaskTemplate:list', 'Modules:TaskTemplate:add', 'Modules:TaskTemplate:mod',
					   'Modules:User:list', 'Modules:User:add', 'Modules:User:mod']">
					<fa-icon [icon]="['fas', 'cog']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/kijelentkezes'?'accent':'disabled'" routerLink="/kijelentkezes" matTooltip="Kijelentkezés">
					   <fa-icon [icon]="['fas', 'sign-out-alt']" size="lg"></fa-icon>
			   </button>
		  </div>
	 </mat-toolbar-row>
	 <mat-toolbar-row class="normal-view-hide">
		  <div>
			   <button mat-flat-button matTooltip="Profilom" (click)="openProfile()">
					<span>
						 {{name}}
					</span>
					<fa-icon [icon]="['fas', 'user']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab color="disabled" matTooltip="Beállítások" (click)="openSettings()" *hasPermission="[
					   'Modules:Category:list', 'Modules:Category:add', 'Modules:Category:mod',
					   'Modules:FileType:list', 'Modules:FileType:add', 'Modules:FileType:mod',
					   'Modules:Partner:list', 'Modules:Partner:add', 'Modules:Partner:mod',
					   'Modules:Project:list', 'Modules:Project:add', 'Modules:Project:mod',
					   'Modules:Role:list', 'Modules:Role:add', 'Modules:Role:mod',
					   'Modules:RoleAssign:list', 'Modules:RoleAssign:add', 'Modules:RoleAssign:mod', 'Modules:RoleAssign:del',
					   'Modules:TaskCondition:list', 'Modules:TaskCondition:add', 'Modules:TaskCondition:mod', 'Modules:TaskCondition:del',
					   'Modules:TaskRef:list', 'Modules:TaskRef:add', 'Modules:TaskRef:mod', 'Modules:TaskRef:del',
					   'Modules:TaskRole:list', 'Modules:TaskRole:add', 'Modules:TaskRole:mod', 'Modules:TaskRole:del',
					   'Modules:TaskTemplate:list', 'Modules:TaskTemplate:add', 'Modules:TaskTemplate:mod',
					   'Modules:User:list', 'Modules:User:add', 'Modules:User:mod']">
					<fa-icon [icon]="['fas', 'cog']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab [color]="url=='/kijelentkezes'?'accent':'disabled'" routerLink="/kijelentkezes" matTooltip="Kijelentkezés">
					   <fa-icon [icon]="['fas', 'sign-out-alt']" size="lg"></fa-icon>
			   </button>
		  </div>
	 </mat-toolbar-row>
</mat-toolbar>

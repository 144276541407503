export class RolePermissionModel {

	private id: number = null;
	private rp_permission: string = '';

	private category: string = '';
	private name: string = '';

	private rp_modifiedby: number = null;
	private rp_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;
		this.rp_permission = json && json.rp_permission || json && json.value || '';

		this.category = json && json.category || '';
		this.name = json && json.name || '';

		this.rp_modifiedby = json && json.rp_modifiedby || null;
		this.rp_modifiedtime = json && json.rp_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getPermission() {
		return this.rp_permission;
	}

	setPermission(value) {
		this.rp_permission = value;
	}

	getCategory() {
		return this.category;
	}

	setCategory(value) {
		this.category = value;
	}

	getName() {
		return this.name;
	}

	setName(value) {
		this.name = value;
	}
}

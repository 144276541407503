export class CategoryModel {

	private id: number = null;

	private c_name: string = '';

	private c_modifiedby: number = null;
	private c_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.c_name = json && json.c_name || '';

		this.c_modifiedby = json && json.c_modifiedby || null;
		this.c_modifiedtime = json && json.c_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.c_name;
	}

	setName(value) {
		this.c_name = value;
	}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {HabSzintModel} from 'src/app/components/raktar/model/habszint.model';
import { CommonModel } from 'src/app/model/common.model';

@Injectable()
export class HabSzintService {

	public static readonly API_TYPE: string = 'habszint';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/get_all_request';
	public static readonly GET_ALL_BY_DATE_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/get_all_by_date_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/get_request';
	public static readonly GET_LAST_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/get_last_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + HabSzintService.API_TYPE + '/del_request';

	public static readonly LIST_TAG: string = 'setting-habszintek';
	public static readonly ADD_TAG: string = 'setting-addhabszint';
	public static readonly MOD_TAG: string = 'setting-modhabszint';
	public static readonly DEL_TAG: string = 'setting-delhabszint';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzintService.GET_ALL_API, params, {params: parameters});
	}

	getAllByDate(date:Date) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('date',CommonModel.getDateString(date));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzintService.GET_ALL_BY_DATE_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzintService.GET_API, params, {params: parameters});
	}

	getLast() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabSzintService.GET_LAST_API, params, {params: parameters});
	}

	add(obj: HabSzintModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('idopont', obj.getIdopontString());
		params.append('ameres', String(obj.getAmeres()));
		params.append('bmeres', String(obj.getBmeres()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzintService.ADD_API, params, {params: parameters});
	}

	mod(obj: HabSzintModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id',String(obj.getId()));
		params.append('idopont', obj.getIdopontString());
		params.append('ameres', String(obj.getAmeres()));
		params.append('bmeres', String(obj.getBmeres()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzintService.MOD_API, params, {params: parameters});
	}

	del(objid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(objid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabSzintService.DEL_API, params, {params: parameters});
	}
}

import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {GablerSzamlazasComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszamlazas/gablerszamlazas.component';

import {GablerSzamlazasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszamlazas/gablerszamlazas.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		GablerSzamlazasComponent
	],
	entryComponents: [
		GablerSzamlazasComponent
	],
	providers: [
		GablerSzamlazasService
	],
	exports: [
		GablerSzamlazasComponent
	],
})
export class GablerSzamlazasModule {
	public static readonly component = GablerSzamlazasComponent;
}
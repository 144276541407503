import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {ExtraDataFuggolegesModule} from 'src/app/components/task/dialog/task-dialog/extradata/extradatafuggoleges/extradatafuggoleges.module';
import {ExtraDataVizszintesModule} from 'src/app/components/task/dialog/task-dialog/extradata/extradatavizszintes/extradatavizszintes.module';

@NgModule({
	imports: [
		CommonModules,
		ExtraDataFuggolegesModule,
		ExtraDataVizszintesModule
	]
})

export class ExtraDataModule {
	public static readonly compArray = [
		ExtraDataFuggolegesModule.component,
		ExtraDataVizszintesModule.component
	];

	public static readonly compMap = ExtraDataModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in ExtraDataModule.compMap) ? ExtraDataModule.compMap[TAG].NAME : '';
	}
}
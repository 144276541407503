import {Component, AfterViewInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CalendarOptions} from '@fullcalendar/angular';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

import {SzerelesekService} from 'src/app/components/szereles/service/szerelesek.service';
import {AuthService} from 'src/app/services/auth.service';

import {CommonModel} from 'src/app/model/common.model';

import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {ListsModel} from 'src/app/model/lists.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {SzerelesDialog} from 'src/app/components/szereles/dialog/szereles-dialog/szereles-dialog';

export interface Szereles {
	id: string;
	projectid: string;
	projectModel: ProjectModel;
	szerelo: number;
	szereloModel: UserModel;
	kezdes: any;
	befejezes: any;
	iranyitoszam: string;
	varos: string;
	utca: string;
	hazszam: string;
	kontakt: string;
	telefon: string;
	szallitas: number;
	segitok: number;
	eszkozigeny: string;
	megjegyzes: string;
	allapot: number;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}

@Component({
	selector: 'app-szerelesek',
	templateUrl: './szerelesek.component.html',
	styleUrls: ['./szerelesek.component.scss']
})
export class SzerelesekComponent implements AfterViewInit {

	alertDialogRef: MatDialogRef<AlertDialog>;
	szerelesDialogRef: MatDialogRef<SzerelesDialog>;

	loading: number = 0;

	calendarView: boolean = true;
	calendarOptions: CalendarOptions = {
		customButtons: {
			switchViewButton: {
				text: 'Ütemterv',
				click: this.switchView.bind(this)
			},
			printButton: {
				text: 'Nyomtatás',
				click: this.printView.bind(this)
			}
		},
		headerToolbar: {
			left: 'today switchViewButton printButton',
			center: 'title',
			right: 'prev,next'
		},
		initialView: 'dayGridMonth',
		locale: 'hu',
		firstDay: 1,
		editable: false,
		height: "85vh",
		buttonText: {
			today: "Aktuális hét"
		},
		eventClick: this.openSzerelesDialog.bind(this)
	};

	ganttDiff: number = 0;
	ganttFirstDay: Date;
	ganttYearNumber: string = '';
	ganttWeekNumber1: number = 0;
	ganttWeekNumber2: number = 0;
	ganttDays: Array<Date> = [];
	ganttSzerelesek: Array<Szereles> = [];

	szerelesek: Array<Szereles> = [];

	listsModel: any = ListsModel;

	freedays: Array<string> = [];

	constructor(
		private szerelesekService: SzerelesekService,
		private authService: AuthService,
		private dialog: MatDialog
	) {
		this.loading++;
		this.szerelesekService.getFreedays().subscribe(
			res => this.handleGetFreedaysResponse(res),
			error => this.handleGetFreedaysError(error)
		);

		this.initGantt();
		this.loadData();
	}

	ngAfterViewInit() {
		setTimeout(() => {this.calendarView = false;}, 500);

	}

	switchView() {
		this.calendarView = !this.calendarView;
	}

	ganttJumpToday() {
		let tmpDate = new Date(this.ganttFirstDay);
		tmpDate.setHours(0, 0, 0, 0);
		let tmpDate2 = new Date();
		tmpDate2.setDate(tmpDate2.getDate() - tmpDate2.getDay() + 1);
		tmpDate2.setHours(0, 0, 0, 0);

		if (tmpDate.getTime() != tmpDate2.getTime()) {
			this.ganttDiff = 0;
			this.ganttFirstDay = new Date(tmpDate2);
			this.setGanttDays();
		}
	}

	initGantt() {
		this.ganttDiff = 0;
		this.ganttFirstDay = new Date();
		this.ganttFirstDay.setDate(this.ganttFirstDay.getDate() - this.ganttFirstDay.getDay() + 1);
		this.setGanttDays();
	}

	isToday(dateParameter: Date) {
		let today: Date = new Date();
		return dateParameter.getDate() === today.getDate() && dateParameter.getMonth() === today.getMonth() && dateParameter.getFullYear() === today.getFullYear();
	}

	setGanttDays() {
		this.ganttDays = [];
		for (let i = 0; i < 14; i++) {
			let tmpDate = new Date(this.ganttFirstDay);
			tmpDate.setDate(tmpDate.getDate() + i);
			this.ganttDays.push(tmpDate);
		}

		let tmpDate2 = new Date(this.ganttFirstDay);
		this.ganttYearNumber = String(tmpDate2.getFullYear());
		this.ganttWeekNumber1 = CommonModel.getWeekNumber(tmpDate2);
		tmpDate2.setDate(tmpDate2.getDate() + 7);
		if (String(tmpDate2.getFullYear()) != this.ganttYearNumber) {
			this.ganttYearNumber += ' - ' + String(tmpDate2.getFullYear());
		}
		this.ganttWeekNumber2 = CommonModel.getWeekNumber(tmpDate2);

		this.setGanttSzerelesek();
	}

	setGanttSzerelesek() {
		this.ganttSzerelesek = [];
		if (this.ganttDays.length > 0 && this.szerelesek.length > 0) {
			let tmpFirstDate: Date = new Date(this.ganttDays[0]);
			tmpFirstDate.setHours(0, 0, 0, 0);
			let tmpFirstTime: number = tmpFirstDate.getTime();

			let tmpLastDate: Date = new Date(this.ganttDays[this.ganttDays.length - 1]);
			tmpLastDate.setHours(0, 0, 0, 0);
			let tmpLastTime: number = tmpLastDate.getTime();

			this.szerelesek.forEach((szereles) => {
				if (szereles.kezdes != null && typeof szereles.kezdes == 'object' && szereles.kezdes.constructor.name == 'Date') {
					let szerelesDate = new Date(szereles.kezdes);
					szerelesDate.setHours(0, 0, 0, 0);
					let szerelesTime = szerelesDate.getTime();
					if (szerelesTime >= tmpFirstTime && szerelesTime <= tmpLastTime) {
						this.ganttSzerelesek.push(szereles);
						return;
					}
				}
				if (szereles.befejezes != null && typeof szereles.befejezes == 'object' && szereles.befejezes.constructor.name == 'Date') {
					let szerelesDate = new Date(szereles.befejezes);
					szerelesDate.setHours(0, 0, 0, 0);
					let szerelesTime = szerelesDate.getTime();
					if (szerelesTime >= tmpFirstTime && szerelesTime <= tmpLastTime) {
						this.ganttSzerelesek.push(szereles);
						return;
					}
				}
			});
		}

		this.ganttSzerelesek = this.ganttSzerelesek.sort((n1, n2) => {
			if (n1.kezdes != null && typeof n1.kezdes == 'object' && n1.kezdes.constructor.name == 'Date' && n2.kezdes != null && typeof n2.kezdes == 'object' && n2.kezdes.constructor.name == 'Date') {
				if (n1.kezdes.getTime() > n2.kezdes.getTime()) {
					return 1;
				}

				if (n1.kezdes.getTime() < n2.kezdes.getTime()) {
					return -1;
				}
			}

			return 0;
		});
	}

	checkWorkDay(kezdes: Date, befejezes: Date, ganttDay: Date) {
		let tmpKezdes = new Date(kezdes);
		tmpKezdes.setHours(0, 0, 0, 0);
		let tmpBefejezes = new Date(befejezes);
		tmpBefejezes.setHours(0, 0, 0, 0);
		let tmpGanttDay = new Date(ganttDay);
		tmpGanttDay.setHours(0, 0, 0, 0);

		let tmpKezdesTime = tmpKezdes.getTime();
		let tmpBefejezesTime = tmpBefejezes.getTime();
		let tmpGanttDayTime = tmpGanttDay.getTime();

		if (tmpGanttDayTime >= tmpKezdesTime && tmpGanttDayTime <= tmpBefejezesTime) {
			if (tmpGanttDayTime == tmpKezdesTime && tmpGanttDayTime == tmpBefejezesTime) {
				return 'both';
			} else if (tmpGanttDayTime == tmpKezdesTime) {
				return 'start';
			} else if (tmpGanttDayTime == tmpBefejezesTime) {
				return 'end';
			} else {
				return 'middle';
			}
		} else {
			return false;
		}
	}

	ganttPrevWeek() {
		this.ganttDiff--;
		this.ganttFirstDay.setDate(this.ganttFirstDay.getDate() - 7);
		this.setGanttDays();
	}

	ganttNextWeek() {
		this.ganttDiff++;
		this.ganttFirstDay.setDate(this.ganttFirstDay.getDate() + 7);
		this.setGanttDays();
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;

		this.szerelesek = [];

		for (let i = 0; i < serverResponse.szerelesek.length; i++) {
			let tmpSzereles: Szereles = {
				id: serverResponse.szerelesek[i].id,
				projectid: serverResponse.szerelesek[i].projectid,
				projectModel: new ProjectModel(serverResponse.szerelesek[i].project),
				szerelo: Number(serverResponse.szerelesek[i].szerelo),
				szereloModel: new UserModel(serverResponse.szerelesek[i].szerelesvezeto),
				kezdes: CommonModel.isDate(serverResponse.szerelesek[i].kezdes) ? (new Date(Date.parse(serverResponse.szerelesek[i].kezdes))) : null,
				befejezes: CommonModel.isDate(serverResponse.szerelesek[i].befejezes) ? (new Date(Date.parse(serverResponse.szerelesek[i].befejezes))) : null,
				iranyitoszam: serverResponse.szerelesek[i].iranyitoszam,
				varos: serverResponse.szerelesek[i].varos,
				utca: serverResponse.szerelesek[i].utca,
				hazszam: serverResponse.szerelesek[i].hazszam,
				kontakt: serverResponse.szerelesek[i].kontakt,
				telefon: serverResponse.szerelesek[i].telefon,
				szallitas: serverResponse.szerelesek[i].szallitas,
				segitok: serverResponse.szerelesek[i].segitok,
				eszkozigeny: serverResponse.szerelesek[i].eszkozigeny,
				megjegyzes: serverResponse.szerelesek[i].megjegyzes,
				allapot: serverResponse.szerelesek[i].allapot,
				modifiedby: serverResponse.szerelesek[i].modifiedby,
				modifiedtime: serverResponse.szerelesek[i].modifiedtime,
				deletedby: serverResponse.szerelesek[i].deletedby,
				deletedtime: serverResponse.szerelesek[i].deletedtime
			};

			this.szerelesek.push(tmpSzereles);
		}

		this.setCalendarEvents();
		this.setGanttSzerelesek();
	}

	handleGetFreedaysError(serverError) {
		this.loading--;
	}

	handleGetFreedaysResponse(serverResponse) {
		this.loading--;

		this.freedays = [];

		for (let value of serverResponse.freedays) {
			this.freedays.push(value);
		}
	}

	loadData() {
		this.loading++;
		this.szerelesekService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	setCalendarEvents() {
		this.calendarOptions.events = [];

		for (let i = 0; i < this.szerelesek.length; i++) {
			if (this.szerelesek[i].kezdes instanceof Date && this.szerelesek[i].befejezes instanceof Date) {
				let tmpKezdes = new Date(this.szerelesek[i].kezdes);
				let tmpBefejezes = new Date(this.szerelesek[i].befejezes);
				tmpBefejezes.setDate(tmpBefejezes.getDate() + 1);
				let kezdesText = (tmpKezdes.getFullYear() + "-" + ("0" + (tmpKezdes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpKezdes.getDate()).substr(-2));
				let befejezesText = (tmpBefejezes.getFullYear() + "-" + ("0" + (tmpBefejezes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpBefejezes.getDate()).substr(-2));
				this.calendarOptions.events.push(
					{
						title: this.szerelesek[i].projectModel.getName() + ((this.szerelesek[i].projectModel.getPartnerModel().getNev() != null && this.szerelesek[i].projectModel.getPartnerModel().getNev().length > 0) ? (' (' + this.szerelesek[i].projectModel.getPartnerModel().getNev() + ')') : ''),
						start: kezdesText,
						end: befejezesText,
						classNames: [Number(this.szerelesek[i].allapot) == ListsModel.SzerelesAllapot_ELOJEGYEZVE.getValue() ? (Number(this.szerelesek[i].szerelo) == this.authService.getUser().getId() ? 'eventPrimary' : 'eventAccent') : 'eventWarn'],
						extendedProps: {
							szerelesId: this.szerelesek[i].id
						}
					});
			}
		}
	}

	openSzerelesDialog(info) {
		this.szerelesDialogRef = this.dialog.open(SzerelesDialog, {
			disableClose: false,
			panelClass: 'szerelesDialogWindow',
			autoFocus: false,
			data: {
				szerelesId: typeof info == 'number' ? info : info.event.extendedProps.szerelesId
			}
		});

		this.szerelesDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loadData();
			}
			this.szerelesDialogRef = null;
		});
	}

	filterNoPrint(node) {
		if (node.className != undefined) {
			let classes: Array<string> = node.className.split(' ');
			return !(classes.includes('no-print') || classes.includes('fc-button'));
		}

		return true;
	}

	printView() {
		let node;

		if (this.calendarView) {
			node = document.getElementById('calendar-print');
		} else {
			node = document.getElementById('gantt-print');
		}

		let img;
		let newImage;

		domtoimage.toJpeg(node, {filter: this.filterNoPrint, quality: 1, bgcolor: '#fff'})

			.then((dataUrl) => {

				img = new Image();
				img.src = dataUrl;
				newImage = img.src;

				img.onload = () => {

					let pdf = new jsPDF('l', 'px', 'a4');

					let pdfWidth = pdf.internal.pageSize.getWidth();
					let pdfHeight = pdf.internal.pageSize.getHeight();

					let imgWidth = img.width;
					let imgHeight = img.height;

					var ratioX = pdfWidth / imgWidth;
					var ratioY = pdfHeight / imgHeight;
					var ratio = Math.min(ratioX, ratioY);

					var newWidth = (imgWidth * ratio);
					var newHeight = (imgHeight * ratio);

					pdf.addImage(newImage, 'JPEG', 0, 0, newWidth, newHeight, 'utemterv', 'NONE');
					pdf.output('dataurlnewwindow');

				};


			})
			.catch((error) => {

				console.log(error);

			});
	}

	toNumber(value) {
		return Number(value);
	}

	isFreeday(value: Date) {
		let valueText = (value.getFullYear() + "-" + ("0" + (value.getMonth() + 1)).substr(-2) + "-" + ("0" + value.getDate()).substr(-2));
		let found: boolean = false;

		for (let freeday of this.freedays) {
			if (freeday == valueText || found) {
				found = true;
				break;
			}
		}

		return found;
	}

}

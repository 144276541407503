import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskModel} from 'src/app/components/task/model/task.model';

import {TaskService} from 'src/app/components/task/service/task.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'extra-extradatafuggoleges',
	templateUrl: './extradatafuggoleges.component.html',
	styleUrls: ['./extradatafuggoleges.component.scss']
})
export class ExtraDataFuggolegesComponent {

	public static readonly TAG: string = 'extra-extradatafuggoleges';
	public static readonly NAME: string = 'Függőleges táblázat';

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;
	loaded: boolean = false;

	taskObject: TaskModel = new TaskModel({});

	tableHeader: Array<string> = [];
	tableData: Array<Array<string>> = [];

	constructor(
		private taskService: TaskService,
		public dialog: MatDialog
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.loading++;
		this.taskService.getExtraData(this.taskObject.getId()).subscribe(
			res => this.handleGetTaskExtraDataResponse(res),
			error => this.handleGetTaskExtraDataError(error)
		);
	}

	handleGetTaskExtraDataError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		/*this.alertDialogRef = this.dialog.open(AlertDialog, {
			 disableClose: true,
			 panelClass: 'alertDialogWindow',
			 autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;*/

		console.log(errorText);
	}

	handleGetTaskExtraDataResponse(serverResponse) {
		this.loading--;
		this.loaded = false;
		this.tableHeader = [];
		this.tableData = [];
		if (serverResponse.length > 0) {
			for (let i = 0; i < serverResponse.length; i++) {
				if (i == 0) {
					for (let j = 0; j < serverResponse[i].length; j++) {
						this.tableHeader.push(String(serverResponse[i][j]));
					}
				} else {
					let tmpData: Array<string> = [];
					for (let j = 0; j < serverResponse[i].length; j++) {
						tmpData.push(serverResponse[i][j] == null ? '' : String(serverResponse[i][j]));
					}
					this.tableData.push(tmpData);
				}
			}

			if (this.tableHeader.length > 0) {
				this.loaded = true;
			}
		}
	}
}
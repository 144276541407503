import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddHabSzintComponent} from 'src/app/components/settings/raktar/habszint/component/addhabszint/addhabszint.component';
import {ModHabSzintComponent} from 'src/app/components/settings/raktar/habszint/component/modhabszint/modhabszint.component';
import {HabSzintekComponent} from 'src/app/components/settings/raktar/habszint/component/habszintek/habszintek.component';
import { DelHabSzintComponent } from 'src/app/components/settings/raktar/habszint/component/delhabszint/delhabszint.component';

import { HabSzintService } from 'src/app/components/raktar/service/habszint.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddHabSzintComponent,
		ModHabSzintComponent,
		HabSzintekComponent,
		DelHabSzintComponent
	],
	entryComponents: [
		AddHabSzintComponent,
		ModHabSzintComponent,
		HabSzintekComponent,
		DelHabSzintComponent
	],
	providers: [
		HabSzintService
	],
	exports: [
		AddHabSzintComponent,
		ModHabSzintComponent,
		HabSzintekComponent,
		DelHabSzintComponent
	],
})
export class HabSzintModule {
	public static readonly compArray = [
		AddHabSzintComponent,
		ModHabSzintComponent,
		HabSzintekComponent,
		DelHabSzintComponent
	];

	public static readonly compMap = HabSzintModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in HabSzintModule.compMap) ? HabSzintModule.compMap[TAG].NAME : '';
	}
}
import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {RoleAssignService} from 'src/app/components/settings/role/service/roleassign.service';
import {RoleService} from 'src/app/components/settings/role/service/role.service';
import {UserService} from 'src/app/components/settings/user/service/user.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {RoleAssignModel} from 'src/app/components/settings/role/model/roleassign.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';

@Component({
	selector: RoleAssignService.DEL_TAG,
	templateUrl: './delroleassign.component.html',
	styleUrls: ['./delroleassign.component.scss']
})
export class DelRoleAssignComponent implements OnDestroy {

	public static readonly TAG = RoleAssignService.DEL_TAG;
	public static readonly NAME = "Összerendelés törlése";
	getSettingName() {return DelRoleAssignComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: RoleAssignModel = new RoleAssignModel({});
	roles: Array<RoleModel> = [];
	users: Array<UserModel> = [];

	constructor(
		private roleAssignService: RoleAssignService,
		private roleService: RoleService,
		private userService: UserService,
		public dialog: MatDialog
	) {
		if (!this.getDelID()) {
			this.back();
		} else {
			this.loading++;
			this.roleService.getAll().subscribe(
				res => this.handleGetRolesResponse(res),
				error => this.handleGetRolesError(error)
			);
			this.loading++;
			this.userService.getAll().subscribe(
				res => this.handleGetUsersResponse(res),
				error => this.handleGetUsersError(error)
			);
			this.loading++;
			this.roleAssignService.get(Number(this.getDelID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetRolesError(serverError) {
		this.handleError(serverError);
	}

	handleGetRolesResponse(serverResponse) {
		this.loading--;
		this.roles = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.roles.push(new RoleModel(serverResponse[i]));
		}
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new RoleAssignModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli az összerendelést?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.roleAssignService.del(this.targetObj.getId()).subscribe(
					res => this.handleDeleteResponse(res),
					error => this.handleDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	handleDeleteResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleDeleteError(serverError) {
		this.handleError(serverError);
	}

	getDelID() {
		return sessionStorage.getItem(AppConstants.PARAM_DELROLEASSIGN_ROLEASSIGNID);
	}

	delDelID() {
		sessionStorage.removeItem(AppConstants.PARAM_DELROLEASSIGN_ROLEASSIGNID);
	}

	back() {
		this.loadSettingComponent.emit(RoleAssignService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delDelID();
	}

}
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzintService} from 'src/app/components/raktar/service/habszint.service';

import {HabMinosegModel} from 'src/app/components/settings/raktar/habminoseg/model/habminoseg.model';
import {HabSzintModel} from 'src/app/components/raktar/model/habszint.model';
import {RaktarModel} from 'src/app/components/settings/raktar/habminoseg/model/raktar.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-habmeres',
	templateUrl: './habmeres.component.html',
	styleUrls: ['./habmeres.component.scss']
})
export class HabMeresComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	selectedIdx: number = 0;
	writeable: boolean = true;

	habminoseg: HabMinosegModel = null;
	habszintek: Array<HabSzintModel> = [new HabSzintModel({}, this.habminoseg)];

	raktarModel: any = RaktarModel;

	constructor(
		private habSzintService: HabSzintService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.loading++;
		this.habSzintService.getAll().subscribe(
			res => this.handleGetHabSzintekResponse(res),
			error => this.handleGetHabSzintekError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetHabSzintekError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetHabSzintekResponse(serverResponse) {
		this.loading--;
		let tmpHabSzintek: Array<HabSzintModel> = [];

		this.habminoseg = new HabMinosegModel(serverResponse.habminoseg);

		for (let i = 0; i < serverResponse.habszintek.length; i++) {
			tmpHabSzintek.push(new HabSzintModel(serverResponse.habszintek[i], null));
		}

		tmpHabSzintek.push(new HabSzintModel({}, this.habminoseg));
		tmpHabSzintek[tmpHabSzintek.length - 1].setIdopont(new Date);
		this.habszintek = tmpHabSzintek;
		this.selectedIdx = this.habszintek.length - 1;
		this.writeable = true;
	}

	prev() {
		if (this.selectedIdx > 0) {
			this.selectedIdx--;
			this.writeable = this.selectedIdx + 1 == this.habszintek.length ? true : false;
		}
	}

	next() {
		if (this.selectedIdx + 1 < this.habszintek.length) {
			this.selectedIdx++;
			this.writeable = this.selectedIdx + 1 == this.habszintek.length ? true : false;
		}
	}

	new() {
		this.selectedIdx = this.habszintek.length - 1;
		this.habszintek[this.selectedIdx] = new HabSzintModel({}, this.habminoseg);
		this.habszintek[this.selectedIdx].setIdopont(new Date);
		this.writeable = true;
	}

	save() {
		this.loading++;
		this.habSzintService.add(this.habszintek[this.selectedIdx]).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;

		this.habszintek[this.habszintek.length - 1] = new HabSzintModel(serverResponse.habszint, this.habminoseg);
		this.habszintek.push(new HabSzintModel({}, this.habminoseg));
		this.selectedIdx = this.habszintek.length - 2;
		this.writeable = false;
	}

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

@Injectable()
export class LemezFelhasznalasService {

	public static readonly API_TYPE: string = 'lemezfelhasznalas';

	public static readonly GET_SZALLITASOK_API: string = AppConstants.BASE_URL + '/api/' + LemezFelhasznalasService.API_TYPE + '/get_szallitasok_request';
	public static readonly LEMEZ_FELHASZNALAS_API: string = AppConstants.BASE_URL + '/api/' + LemezFelhasznalasService.API_TYPE + '/lemez_felhasznalas_request';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getSzallitasok() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezFelhasznalasService.GET_SZALLITASOK_API, params, {params: parameters});
	}

	lemezFelhasznalas(lemezszallitasid: number, idopont: any, belsoatmero: number, falvastagsag: number) {
		if (idopont instanceof Date) {
			idopont = (idopont.getFullYear() + "-" + ("0" + (idopont.getMonth() + 1)).substr(-2) + "-" + ("0" + idopont.getDate()).substr(-2) + " " + ("0" + idopont.getHours()).substr(-2) + ":" + ("0" + idopont.getMinutes()).substr(-2) + ":00");
		} else {
			idopont = '';
		}

		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('lemezszallitasid', String(lemezszallitasid));
		params.append('idopont', idopont);
		params.append('belsoatmero', String(belsoatmero));
		params.append('falvastagsag', String(falvastagsag));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezFelhasznalasService.LEMEZ_FELHASZNALAS_API, params, {params: parameters});
	}
}

import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezSzinComponent} from 'src/app/components/settings/raktar/lemezszin/component/addlemezszin/addlemezszin.component';
import {ModLemezSzinComponent} from 'src/app/components/settings/raktar/lemezszin/component/modlemezszin/modlemezszin.component';
import {LemezSzinekComponent} from 'src/app/components/settings/raktar/lemezszin/component/lemezszinek/lemezszinek.component';

import {LemezSzinService} from 'src/app/components/settings/raktar/lemezszin/service/lemezszin.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezSzinComponent,
		ModLemezSzinComponent,
		LemezSzinekComponent
	],
	entryComponents: [
		AddLemezSzinComponent,
		ModLemezSzinComponent,
		LemezSzinekComponent
	],
	providers: [
		LemezSzinService
	],
	exports: [
		AddLemezSzinComponent,
		ModLemezSzinComponent,
		LemezSzinekComponent
	],
})
export class LemezSzinModule {
	public static readonly compArray = [
		AddLemezSzinComponent,
		ModLemezSzinComponent,
		LemezSzinekComponent
	];

	public static readonly compMap = LemezSzinModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezSzinModule.compMap) ? LemezSzinModule.compMap[TAG].NAME : '';
	}
}
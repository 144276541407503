<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Szülő feladat</mat-label>
			   <mat-select name="inputParent" [ngModel]="targetObj.getParent()" (ngModelChange)="targetObj.setParent($event)" (openedChange)="parentFilter.value=null; filterParents(null);" required>
						   <input matInput #parentFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterParents($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let template of filteredParents | customsort:'getName'" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Gyermek feladat</mat-label>
			   <mat-select name="inputChild" [ngModel]="targetObj.getChild()" (ngModelChange)="targetObj.setChild($event)" (openedChange)="childFilter.value=null; filterChilds(null);" required>
						   <input matInput #childFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterChilds($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let template of filteredChilds | customsort:'getName'" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Delegálás típusa</mat-label>
			   <mat-select name="inputAssigntype" [ngModel]="targetObj.getAssigntype()" (ngModelChange)="targetObj.setAssigntype($event)" required>
					<mat-option *ngFor="let assigntype of listsModel.TaskAssignTypeList | customsort:'getName'" [value]="assigntype.getValue()">{{assigntype.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
<div class="loading-wrapper tableContainer my-4">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>

	 <div class="table-wrapper" style="overflow: auto;">
		  <table mat-table class="table-wrapper mx-auto mat-elevation-z1" [dataSource]="dataSource">

			   <!-- ID Column -->
			   <ng-container matColumnDef="id" sticky>
					<th class="col-id" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  #
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getId()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Number Column -->
			   <ng-container matColumnDef="p_number">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Munkaszám
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getNumber()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Name Column -->
			   <ng-container matColumnDef="p_name">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Megnevezés
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Desc Column -->
			   <ng-container matColumnDef="p_desc">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Leírás
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getDesc()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Managername Column -->
			   <ng-container matColumnDef="managername">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Projekt vezető
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getManagerModel().getFullName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Partnernev Column -->
			   <ng-container matColumnDef="partnernev">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Partner
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getPartnerModel().getNev()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Status Column -->
			   <ng-container matColumnDef="p_status" stickyEnd>
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Státusz
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{listsModel.getListItem(row.getStatus(), listsModel.ProjectStatus).getName()}}
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>
	 </div>

	 <mat-tab-group class="col-12 col-md-8 mx-auto my-4 mat-elevation-z1" mat-align-tabs="center">
		  <mat-tab [label]="tabname" *ngIf="tabname.length > 0">
			   <table mat-table class="col-8 table-wrapper mx-auto my-2 mat-elevation-z3" [dataSource]="customDataDataSource">

					<!-- name Column -->
					<ng-container matColumnDef="name">
						 <td mat-cell *matCellDef="let row">
							 <div class="data-wrapper">
								   {{row.name}}
							  </div>
						 </td>
					</ng-container>

					<!-- value Column -->
					<ng-container matColumnDef="value">
						 <td mat-cell *matCellDef="let row">
							 <div class="data-wrapper">
								   {{row.value}}
							  </div>
						 </td>
					</ng-container>

					<tr mat-row *matRowDef="let row; columns: customDataDisplayedColumns;"></tr>
			   </table>
		  </mat-tab>







		  <mat-tab label="Feladatok">
			   <div class="task-table-wrapper">
					<div class="filter-container">
						 <app-table-filter style="margin-left: 10px; width: 100%;" filterId="projectinfo_task" [(filter)]="taskFilterValue" (keyupFn)="applyTaskFilter()" placeholder="Szűrő..."></app-table-filter>
					</div>
					<div class="table-wrapper mat-elevation-z8">
						 <table mat-table [dataSource]="taskDataSource" #taskSort="matSort" matSort>

								<!-- ID Column -->
								<ng-container matColumnDef="id">
								   <th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 #
										</div>
								   </th>
								   <td class="col-id" mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getId()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- taskname Column -->
							  <ng-container matColumnDef="taskname">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Feladat megnevezés
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getTemplateModel().getName()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- t_deadline Column -->
							  <ng-container matColumnDef="t_deadline">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Határidő
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getDeadline()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- t_createdtime Column -->
							  <ng-container matColumnDef="t_createdtime">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Létrehozva
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getCreatedtime()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- t_assignedtime Column -->
							  <ng-container matColumnDef="t_assignedtime">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Felvéve
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getAssignedtime()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- t_donetime Column -->
							  <ng-container matColumnDef="t_donetime">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Befejezve
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getDonetime()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- owner Column -->
							  <ng-container matColumnDef="owner">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Felelős
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getOwnerModel().getFullName()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- Action Column -->
							  <ng-container matColumnDef="action">
								   <th class="col-action" mat-header-cell *matHeaderCellDef>
									   <div class="data-wrapper">
											 <fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadTaskData();" matTooltip="Frissítés"></fa-icon>
										</div>
								   </th>
								   <td class="col-action" mat-cell *matCellDef="let row;">
									   <div class="data-wrapper">
											 <fa-icon [icon]="['fas', 'pencil-alt']" size="lg" (click)="openTaskDialog(row.getId());" matTooltip="Feladat megnyitása"></fa-icon>
											 <fa-icon *ngIf="canEditDeadline(row.getStatus(), row.getOwnerModel().getId(), row.getProjectModel().getManagerModel().getId())" [icon]="['far', 'calendar-alt']" size="lg" (click)="openTaskDeadlineDialog(row.getId(), row.getDeadline());" matTooltip="Határidő"></fa-icon>
										</div>
								   </td>
							  </ng-container>

							  <tr mat-header-row *matHeaderRowDef="taskDisplayedColumns"></tr>
							  <tr mat-row *matRowDef="let row; columns: taskDisplayedColumns;" [ngClass]="{ 'completedTask' : 'completedTask', 'stornedTask' : 'stornedTask', 'expiredTask' : 'expiredTask' }[getTaskStatusClass(row)]"></tr>
						 </table>

						 <mat-paginator #taskPaginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
					</div>
			   </div>
		  </mat-tab>







		  <mat-tab label="Dokumentumok">

			   <mat-accordion>
					<mat-expansion-panel *ngIf="ableToModFiles">
						 <mat-expansion-panel-header>
							  <mat-panel-title>
								   Fájl feltöltés
							  </mat-panel-title>
						 </mat-expansion-panel-header>
						 <div class="col-12" style="margin-bottom: 40px">
							  <input type="file" name="inputFileUpload" ng2FileSelect [uploader]="uploader" multiple  /><br/>
						 </div> 
						 <div class="col-12" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length > 0">
							  <p>Fájlok száma: {{ uploader?.queue?.length }}</p>
							  <div style="overflow: auto;">
								   <table class="table">
										<thead>
											 <tr>
												  <th width="25%">Fájlnév</th>
												  <th>Méret</th>
												  <th>Típus</th>
												  <th></th>
											 </tr>
										</thead>
										<tbody>
											 <tr *ngFor="let item of uploader.queue">
												 <td><strong>{{ item?.file?.name }}</strong></td>
												  <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
												  <td>
													   <div>
															<mat-select name="inputFileType" [(ngModel)]="item.formData" placeholder="-- Típus --">
																 <mat-option *ngFor="let filetype of filetypes | customsort:'getName'" [value]="filetype.getId()">{{filetype.getName()}}</mat-option>
															</mat-select>
													   </div>
												  </td>
												  <td nowrap>
													   <span style="cursor: pointer;"><fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Fájl törlése" (click)="item.remove()"></fa-icon></span>
												  </td>
											 </tr>
										</tbody>
								   </table>
							  </div>

							  <div class="text-align: right;">
								   <button mat-mini-fab color="primary" (click)="uploadFiles()" [disabled]="!uploader.getNotUploadedItems().length">
										<fa-icon [icon]="['fas', 'upload']" size="lg" matTooltip="Összes fájl feltöltése"></fa-icon>
								   </button>
								   <button mat-mini-fab color="warn" class="mx-2" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
										<fa-icon [icon]="['fas', 'trash']" size="lg" matTooltip="Összes fájl törlése"></fa-icon>
								   </button>
							  </div>

						 </div>
					</mat-expansion-panel>

					<mat-expansion-panel>
						 <mat-expansion-panel-header>
							  <mat-panel-title>
								   Feltöltött fájlok
							  </mat-panel-title>
						 </mat-expansion-panel-header>
						 <div class="col-12">
							  <p>Feltöltött fájlok száma: {{ targetObj.getFiles().length }}</p>
							  <div style="overflow: auto;">
								   <table class="table">
										<thead>
											 <tr>
												  <th width="25%">Fájlnév</th>
												  <th>Típus</th>
												  <th>Feltöltő</th>
												  <th>Feltöltve</th>
												  <th></th>
											 </tr>
										</thead>
										<tbody>
											 <tr *ngFor="let file of targetObj.getFiles()">
												 <td>{{ file.getOriginalname() }}</td>
												  <td>{{ file.getFileTypeModel().getName() }}</td>
												  <td>{{ file.getUploaderModel().getFullName() }}</td>
												  <td>{{ file.getUploadTime() }}</td>
												  <td nowrap>
													   <span style="cursor: pointer;" (click)="downloadFile(file.getId())"><fa-icon [icon]="['fas', 'download']" size="lg" matTooltip="Letöltés"></fa-icon></span>
													   <span *hasPermission="'Modules:TaskFile:deletefile'">&nbsp;</span>
													   <span *hasPermission="'Modules:TaskFile:deletefile'"><span style="cursor: pointer;" (click)="deleteFile(file.getId())" *ngIf="ableToModFiles"><fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Törlés"></fa-icon></span></span>
												  </td>
											 </tr>
										</tbody>
								   </table>
							  </div>
						 </div>
					</mat-expansion-panel>
			   </mat-accordion>
		  </mat-tab>







		  <mat-tab label="Megjegyzések">
			   <div class="comment-table-wrapper">
					<div class="filter-container">
						 <app-table-filter style="margin-left: 10px; width: 100%;" filterId="projectinfo_comment" [(filter)]="commentFilterValue" (keyupFn)="applyCommentFilter()" placeholder="Szűrő..."></app-table-filter>
					</div>
					<div class="table-wrapper mat-elevation-z8">
						 <table mat-table [dataSource]="commentDataSource" #commentSort="matSort" matSort>

								<!-- ID Column -->
								<ng-container matColumnDef="id">
								   <th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 #
										</div>
								   </th>
								   <td class="col-id" mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getId()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- username Column -->
							  <ng-container matColumnDef="username">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Munkatárs
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getUserModel().getFullName()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- tc_comment Column -->
							  <ng-container matColumnDef="tc_comment">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Megjegyzés
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper" style="white-space: pre-line">
											 {{row.getComment()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- datetime Column -->
							  <ng-container matColumnDef="datetime">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Időpont
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getDateTime()}}
										</div>
								   </td>
							  </ng-container>

							  <tr mat-header-row *matHeaderRowDef="commentDisplayedColumns"></tr>
							  <tr mat-row *matRowDef="let row; columns: commentDisplayedColumns;"></tr>
						 </table>

						 <mat-paginator #commentPaginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
					</div>
			   </div>
		  </mat-tab>
	 </mat-tab-group>
</div>
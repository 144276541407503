import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {AppConstants} from 'src/app/model/appconstants.model';

import {LemezSzallitasService} from 'src/app/components/raktar/service/lemezszallitas.service';
import {LemezGyartoService} from 'src/app/components/settings/raktar/lemezgyarto/service/lemezgyarto.service';
import {LemezAnyagminosegService } from 'src/app/components/settings/raktar/lemezanyagminoseg/service/lemezanyagminoseg.service';
import {LemezSzinService} from 'src/app/components/settings/raktar/lemezszin/service/lemezszin.service';
import {LemezSzelessegService} from 'src/app/components/settings/raktar/lemezszelesseg/service/lemezszelesseg.service';
import {LemezVastagsagService} from 'src/app/components/settings/raktar/lemezvastagsag/service/lemezvastagsag.service';

import {LemezSzallitasModel} from 'src/app/components/raktar/model/lemezszallitas.model';
import {LemezGyartoModel} from 'src/app/components/settings/raktar/lemezgyarto/model/lemezgyarto.model';
import {LemezSzinModel} from 'src/app/components/settings/raktar/lemezszin/model/lemezszin.model';
import {LemezAnyagminosegModel} from 'src/app/components/settings/raktar/lemezanyagminoseg/model/lemezanyagminoseg.model';
import {LemezSzelessegModel} from 'src/app/components/settings/raktar/lemezszelesseg/model/lemezszelesseg.model';
import {LemezVastagsagModel} from 'src/app/components/settings/raktar/lemezvastagsag/model/lemezvastagsag.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';



@Component({
	selector: LemezSzallitasService.ADD_TAG,
	templateUrl: './dellemezszallitas.component.html',
	styleUrls: ['./dellemezszallitas.component.scss']
})
export class DelLemezSzallitasComponent {

	public static readonly TAG = LemezSzallitasService.DEL_TAG;
	public static readonly NAME = "Lemezszállítás törlése";
	getSettingName() {return DelLemezSzallitasComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: LemezSzallitasModel = new LemezSzallitasModel({});

	gyartok:Array<LemezGyartoModel>=[];
	szinek:Array<LemezSzinModel>=[];
	anyagminosegek:Array<LemezAnyagminosegModel>=[];
	szelessegek:Array<LemezSzelessegModel>=[];
	vastagsagok:Array<LemezVastagsagModel>=[];

	constructor(
		private lemezSzallitasService: LemezSzallitasService,
		private lemezGyartokService: LemezGyartoService,
		private lemezSzinService :LemezSzinService,
		private lemezAnyagminosegService:LemezAnyagminosegService,
		private lemezSzelessegService: LemezSzelessegService,
		private lemezVastagsagService: LemezVastagsagService,
		public dialog: MatDialog
		) {
			this.loading++;
			this.lemezGyartokService.getAll().subscribe(
				res => this.handleGetLemezGyartokResponse(res),
				error => this.handleError(error)
			);
			this.loading++;
			this.lemezSzinService.getAll().subscribe(
				res => this.handleGetLemezSzinekResponse(res),
				error => this.handleError(error)
			);
			this.loading++;
			this.lemezAnyagminosegService.getAll().subscribe(
				res => this.handleGetLemezAnyagminosegekResponse(res),
				error => this.handleError(error)
			);
			this.loading++;
			this.lemezSzelessegService.getAll().subscribe(
				res => this.handleGetLemezSzelessegekResponse(res),
				error => this.handleError(error)
			);
			this.loading++;
			this.lemezVastagsagService.getAll().subscribe(
				res => this.handleGetLemezVastagsagokResponse(res),
				error => this.handleError(error)
			);
	
	}
	
	handleGetLemezGyartokResponse(serverResponse){
		this.loading--;
		for (let i=0; i<serverResponse.length; i++){
			this.gyartok.push(new LemezGyartoModel(serverResponse[i]));
		}
	}
	handleGetLemezSzinekResponse(serverResponse){
		this.loading--;
		for (let i=0; i<serverResponse.length; i++){
			this.szinek.push(new LemezSzinModel(serverResponse[i]));
		}
	}
	handleGetLemezAnyagminosegekResponse(serverResponse){
		this.loading--;
		for (let i=0; i<serverResponse.length; i++){
			this.anyagminosegek.push(new LemezAnyagminosegModel(serverResponse[i]));
		}
	}
	handleGetLemezSzelessegekResponse(serverResponse){
		this.loading--;
		for (let i=0; i<serverResponse.length; i++){
			this.szelessegek.push(new LemezSzelessegModel(serverResponse[i]));
		}
	}
	handleGetLemezVastagsagokResponse(serverResponse){
		this.loading--;
		for (let i=0; i<serverResponse.length; i++){
			this.vastagsagok.push(new LemezVastagsagModel(serverResponse[i]));
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new LemezSzallitasModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a lemezszállítást?";	
		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.lemezSzallitasService.del(this.targetObj.getId()).subscribe(
					res => this.handleDeleteResponse(res),
					error => this.handleDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}
	

	handleDeleteResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleDeleteError(serverError) {
		this.handleError(serverError);
	}

	getDelID() {
		return sessionStorage.getItem(AppConstants.PARAM_DELLEMEZSZALLITAS_LEMEZSZALLITASID);
	}

	delDelID() {
		sessionStorage.removeItem(AppConstants.PARAM_DELLEMEZSZALLITAS_LEMEZSZALLITASID);
	}

	back() {
		this.loadSettingComponent.emit(LemezSzallitasService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delDelID();
	}

}
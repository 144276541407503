import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

@Injectable()
export class LemezBevetelService {

	public static readonly API_TYPE: string = 'lemezbevetel';

	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + LemezBevetelService.API_TYPE + '/get_request';
	public static readonly LEMEZ_BEVETEL_API: string = AppConstants.BASE_URL + '/api/' + LemezBevetelService.API_TYPE + '/lemez_bevetel_request';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	get() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezBevetelService.GET_API, params, {params: parameters});
	}

	lemezBevetel(idopont: any, szallitolevel: string, lemezgyartoid: number, lemezanyagminosegid: number, lemezszinid: number, lemezszelessegid: number, lemezvastagsagid: number, tomeg: number, nettoar: number, lemeztaroloid: number) {
		if (idopont instanceof Date) {
			idopont = (idopont.getFullYear() + "-" + ("0" + (idopont.getMonth() + 1)).substr(-2) + "-" + ("0" + idopont.getDate()).substr(-2) + " " + ("0" + idopont.getHours()).substr(-2) + ":" + ("0" + idopont.getMinutes()).substr(-2) + ":00");
		} else {
			idopont = '';
		}

		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('idopont', idopont);
		params.append('szallitolevel', szallitolevel);
		params.append('lemezgyartoid', String(lemezgyartoid));
		params.append('lemezanyagminosegid', String(lemezanyagminosegid));
		params.append('lemezszinid', String(lemezszinid));
		params.append('lemezszelessegid', String(lemezszelessegid));
		params.append('lemezvastagsagid', String(lemezvastagsagid));
		params.append('tomeg', String(tomeg));
		params.append('nettoar', String(nettoar));
		params.append('lemeztaroloid', String(lemeztaroloid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezBevetelService.LEMEZ_BEVETEL_API, params, {params: parameters});
	}
}

import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FileTypeService} from 'src/app/components/settings/filetype/service/filetype.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: FileTypeService.ADD_TAG,
	templateUrl: './addfiletype.component.html',
	styleUrls: ['./addfiletype.component.scss']
})
export class AddFileTypeComponent {

	public static readonly TAG = FileTypeService.ADD_TAG;
	public static readonly NAME = "Fájl típus hozzáadás";
	getSettingName() {return AddFileTypeComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: FileTypeModel = new FileTypeModel({});

	constructor(
		private fileTypeService: FileTypeService,
		public dialog: MatDialog
	) {}

	submit() {
		this.loading++;
		this.fileTypeService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	back() {
		this.loadSettingComponent.emit(FileTypeService.LIST_TAG);
	}

}
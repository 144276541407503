import {NgModule} from '@angular/core'
import {Router, Routes} from '@angular/router';

import {AppRoutingModule} from 'src/app/app-routing.module';
import {CommonModules} from 'src/app/common.modules';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';
import {HabSzintService} from 'src/app/components/raktar/service/habszint.service';
import {LemezBevetelService} from 'src/app/components/raktar/service/lemezbevetel.service';
import {LemezAthelyezesService} from 'src/app/components/raktar/service/lemezathelyezes.service';
import {LemezBeemelesService} from 'src/app/components/raktar/service/lemezbeemeles.service';
import {LemezFelhasznalasService} from 'src/app/components/raktar/service/lemezfelhasznalas.service';

import {HabBetoltesComponent} from 'src/app/components/raktar/component/habbetoltes/habbetoltes.component';
import {HabMeresComponent} from 'src/app/components/raktar/component/habmeres/habmeres.component';
import {LemezAthelyezesComponent} from 'src/app/components/raktar/component/lemezathelyezes/lemezathelyezes.component';
import {LemezBeemelesComponent} from 'src/app/components/raktar/component/lemezbeemeles/lemezbeemeles.component';
import {LemezBevetelComponent} from 'src/app/components/raktar/component/lemezbevetel/lemezbevetel.component';
import {LemezFelhasznalasComponent} from 'src/app/components/raktar/component/lemezfelhasznalas/lemezfelhasznalas.component';
import {RaktarMenuComponent} from 'src/app/components/raktar/component/raktarmenu/raktarmenu.component';

import {AuthGuardService} from 'src/app/services/auth-guard.service';

const moduleRoutes: Routes = [
	{
		path: 'habbetoltes',
		component: HabBetoltesComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'habmeres',
		component: HabMeresComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'lemezathelyezes',
		component: LemezAthelyezesComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'lemezbeemeles',
		component: LemezBeemelesComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'lemezbevetel',
		component: LemezBevetelComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'lemezfelhasznalas',
		component: LemezFelhasznalasComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'raktar',
		component: RaktarMenuComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
];

@NgModule({
	imports: [
		AppRoutingModule,
		CommonModules
	],
	declarations: [
		HabBetoltesComponent,
		HabMeresComponent,
		LemezAthelyezesComponent,
		LemezBeemelesComponent,
		LemezBevetelComponent,
		LemezFelhasznalasComponent,
		RaktarMenuComponent
	],
	entryComponents: [
		HabBetoltesComponent,
		HabMeresComponent,
		LemezAthelyezesComponent,
		LemezBeemelesComponent,
		LemezBevetelComponent,
		LemezFelhasznalasComponent,
		RaktarMenuComponent
	],
	providers: [
		HabSzallitasService,
		HabSzintService,
		LemezBevetelService,
		LemezAthelyezesService,
		LemezBeemelesService,
		LemezFelhasznalasService
	],
	exports: [
		HabBetoltesComponent,
		HabMeresComponent,
		LemezAthelyezesComponent,
		LemezBeemelesComponent,
		LemezBevetelComponent,
		LemezFelhasznalasComponent,
		RaktarMenuComponent
	]
})
export class RaktarModule {
	constructor(private router: Router) {
		const config = this.router.config;
		let lastElement = config.pop();

		moduleRoutes.forEach(route => {
			config.push(route);
		});

		config.push(lastElement);

		this.router.resetConfig(config);
	}
}
import {RoleModel} from 'src/app/components/settings/role/model/role.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

export class RoleAssignModel {

	private id: number = null;

	private ra_roleid: number = null;
	private role: RoleModel = new RoleModel({});
	private ra_userid: number = null;
	private user: UserModel = new UserModel({});

	private ra_modifiedby: number = null;
	private ra_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.ra_roleid = json && json.ra_roleid || null;
		this.role = new RoleModel(json && json.role || {});
		this.ra_userid = json && json.ra_userid || null;
		this.user = new UserModel(json && json.user || {});

		this.ra_modifiedby = json && json.ra_modifiedby || null;
		this.ra_modifiedtime = json && json.ra_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getRoleid() {
		return this.ra_roleid;
	}

	setRoleid(value) {
		this.ra_roleid = value;
	}

	getRoleModel() {
		return this.role;
	}

	getUserid() {
		return this.ra_userid;
	}

	setUserid(value) {
		this.ra_userid = value;
	}

	getUserModel() {
		return this.user;
	}
}

import {Component, Output, EventEmitter, Inject, InjectionToken} from '@angular/core';

import {AuthService} from 'src/app/services/auth.service';

export interface Setting {
	tag: string;
	name: boolean;
	icon: Array<string>;
}

export interface SettingCategory {
	name: string;
	settings: Array<Setting>;
}

@Component({
	selector: 'setting-settings-main',
	templateUrl: './settings-main.component.html',
	styleUrls: ['./settings-main.component.scss']
})
export class SettingsMainComponent {

	public static readonly compArrayToken = new InjectionToken<Array<Object>>('compArray');

	@Output() loadSettingComponent = new EventEmitter<string>();

	public static readonly TAG = 'setting-settings-main';

	categories: Array<SettingCategory> = [];

	constructor(
		public authService: AuthService,
		@Inject(SettingsMainComponent.compArrayToken) compArray
	) {
		for (let component of compArray) {
			if ('_category' in component) {
				if ((component._adminOnly && this.authService.isAdmin()) || this.authService.hasPermission(component._requiredPermissions)) {
					let categoryIdx = -1;

					this.categories.forEach((category, idx) => {
						if (category.name == component._category) {
							categoryIdx = idx;
							return;
						}
					});

					if (categoryIdx >= 0) {
						this.categories[categoryIdx].settings.push({tag: component.TAG, name: component.NAME, icon: component._icon});
					} else {
						this.categories.push({name: component._category, settings: [{tag: component.TAG, name: component.NAME, icon: component._icon}]});
					}
				}
			}
		}

		for (let idx in this.categories) {
			this.categories[idx].settings = this.categories[idx].settings.sort((n1, n2) => {
				if (n1.name > n2.name) {
					return 1;
				} else if (n1.name < n2.name) {
					return -1;
				} else {
					return 0;
				}
			});
		}

		this.categories = this.categories.sort((n1, n2) => {
			if (n1.name > n2.name) {
				return 1;
			} else if (n1.name < n2.name) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	load(setting: string) {
		this.loadSettingComponent.emit(setting);
	}

	formatName(name: string) {
		return name.replace(/\s/g, '<br/>');
	}
}

import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';
import {PreviousRouteService} from 'src/app/services/previous-route.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

	constructor(
		private authService: AuthService,
		private previousRouteService: PreviousRouteService,
		private router: Router
	) {}

	logout() {
		this.authService.logout();
		this.authService.delToken();
		this.authService.changeAuthStatus(false);
		this.router.navigateByUrl('/bejelentkezes');
	}

	cancel() {
		let prev = this.previousRouteService.getPreviousUrl();

		if (prev != undefined && prev != null && prev.length > 1 && prev != '/kijelentkezes') {
			this.router.navigateByUrl(prev);
		} else {
			this.router.navigateByUrl('/');
		}
	}

}

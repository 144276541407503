import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';

export class TaskRoleModel {

	private id: number = null;

	private tro_templateid: number = null;
	private template: TaskTemplateModel = new TaskTemplateModel({});
	private tro_roleid: number = null;
	private role: RoleModel = new RoleModel({});
	private tro_roletype: number = null;

	private tro_modifiedby: number = null;
	private tro_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.tro_templateid = json && json.tro_templateid || null;
		this.template = new TaskTemplateModel(json && json.template || {});
		this.tro_roleid = json && json.tro_roleid || null;
		this.role = new RoleModel(json && json.role || {});
		this.tro_roletype = json && json.tro_roletype || null;

		this.tro_modifiedby = json && json.tro_modifiedby || null;
		this.tro_modifiedtime = json && json.tro_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getTemplateid() {
		return this.tro_templateid;
	}

	setTemplateid(value) {
		this.tro_templateid = value;
	}

	getTemplateModel() {
		return this.template;
	}

	getRoleid() {
		return this.tro_roleid;
	}

	setRoleid(value) {
		this.tro_roleid = value;
	}

	getRoleModel() {
		return this.role;
	}

	getRoletype() {
		return this.tro_roletype;
	}

	setRoletype(value) {
		this.tro_roletype = value;
	}
}

import {Directive, TemplateRef, ViewContainerRef, Input} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';

@Directive({
	selector: '[hasPermission]'
})
export class hasPermissionDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authService: AuthService
	) {}

	@Input() set hasPermission(permissions: string | Array<string>) {
		let tmpHasPermission: boolean = false;
		let tmpPermissions: Array<string>;

		if (permissions == undefined || permissions == null) {
			tmpHasPermission = true;
		}

		if (!tmpHasPermission && this.authService.isAdmin()) {
			tmpHasPermission = true;
		} else if (!tmpHasPermission) {
			let userPermissions = this.authService.getPermissions();

			if (typeof permissions == 'string') {
				tmpPermissions = [permissions as string];
			} else {
				tmpPermissions = permissions;
			}

			if (tmpPermissions.length > 0) {
				tmpPermissions.forEach(permission => {
					if (typeof permission == 'string' && userPermissions.includes(permission)) {
						tmpHasPermission = true;
						return;
					}
				});
			}
		}

		if (tmpHasPermission) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
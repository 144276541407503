import {Pipe, PipeTransform} from '@angular/core';

import {CommonModel} from 'src/app/model/common.model';

@Pipe({
	name: 'customsort'
})
export class CustomSortPipe implements PipeTransform {

	transform(values: any[], column: string, order: string): any[] {
		if (!values || values.length <= 1) {
			return values;
		}

		if (!order || order == '') {
			order = "asc";
		}

		if (!column || column == '') {
			return values;
		}

		if (column in values[0]) {
			return values.sort((n1, n2) => {
				let val1 = n1[column];
				let val2 = n2[column];

				if (typeof val1 == "function") {
					val1 = n1[column]();
				}

				if (typeof val2 == "function") {
					val2 = n2[column]();
				}

				if (typeof val1 == "string") {
					val1 = CommonModel.replaceHunChars(val1.toLowerCase());
				}

				if (typeof val2 == "string") {
					val2 = CommonModel.replaceHunChars(val2.toLowerCase());
				}

				if (val1 > val2) {
					return order == "asc" ? 1 : -1;
				} else if (val1 < val2) {
					return order == "asc" ? -1 : 1;
				} else {
					return 0;
				}
			});
		} else {
			return values;
		}
	}
}
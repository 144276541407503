import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskRoleService} from 'src/app/components/settings/taskrole/service/taskrole.service';
import {RoleService} from 'src/app/components/settings/role/service/role.service';
import {TaskTemplateService} from 'src/app/components/settings/tasktemplate/service/tasktemplate.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {ListsModel} from 'src/app/model/lists.model';
import {TaskRoleModel} from 'src/app/components/settings/taskrole/model/taskrole.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'setting-addtaskrole',
	templateUrl: './addtaskrole.component.html',
	styleUrls: ['./addtaskrole.component.scss']
})
export class AddTaskRoleComponent {

	public static readonly TAG = 'setting-addtaskrole';
	public static readonly NAME = "Feladat szerep összerendelés";
	getSettingName() {return AddTaskRoleComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: TaskRoleModel = new TaskRoleModel({});
	templates: Array<TaskTemplateModel> = [];
	filteredTemplates: Array<TaskTemplateModel> = [];
	roles: Array<RoleModel> = [];
	filteredRoles: Array<RoleModel> = [];
	listsModel: any = ListsModel;

	constructor(
		private taskRoleService: TaskRoleService,
		private roleService: RoleService,
		private taskTemplateService: TaskTemplateService,
		public dialog: MatDialog
	) {
		this.loading++;
		this.taskTemplateService.getAll().subscribe(
			res => this.handleGetTaskTemplatesResponse(res),
			error => this.handleGetTaskTemplatesError(error)
		);
		this.loading++;
		this.roleService.getAll().subscribe(
			res => this.handleGetRolesResponse(res),
			error => this.handleGetRolesError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetTaskTemplatesError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskTemplatesResponse(serverResponse) {
		this.loading--;
		this.templates = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.templates.push(new TaskTemplateModel(serverResponse[i]));
		}

		this.filteredTemplates = this.templates.filter((obj) => {return true;});
	}

	handleGetRolesError(serverError) {
		this.handleError(serverError);
	}

	handleGetRolesResponse(serverResponse) {
		this.loading--;
		this.roles = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.roles.push(new RoleModel(serverResponse[i]));
		}

		this.filteredRoles = this.roles.filter((obj) => {return true;});
	}

	submit() {
		this.loading++;
		this.taskRoleService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(TaskRoleService.LIST_TAG);
	}

	filterTemplates(val) {
		this.filteredTemplates = this.templates.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterRoles(val) {
		this.filteredRoles = this.roles.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskRoleModel} from 'src/app/components/settings/taskrole/model/taskrole.model';

@Injectable()
export class TaskRoleService {

	public static readonly API_TYPE: string = 'taskrole';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + TaskRoleService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + TaskRoleService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + TaskRoleService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + TaskRoleService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + TaskRoleService.API_TYPE + '/del_request';

	public static readonly LIST_TAG: string = 'setting-taskroles';
	public static readonly ADD_TAG: string = 'setting-addtaskrole';
	public static readonly MOD_TAG: string = 'setting-modtaskrole';
	public static readonly DEL_TAG: string = 'setting-deltaskrole';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskRoleService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskRoleService.GET_API, params, {params: parameters});
	}

	add(obj: TaskRoleModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tro_templateid', String(obj.getTemplateid()));
		params.append('tro_roleid', String(obj.getRoleid()));
		params.append('tro_roletype', String(obj.getRoletype()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRoleService.ADD_API, params, {params: parameters});
	}

	mod(obj: TaskRoleModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('tro_templateid', String(obj.getTemplateid()));
		params.append('tro_roleid', String(obj.getRoleid()));
		params.append('tro_roletype', String(obj.getRoletype()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRoleService.MOD_API, params, {params: parameters});
	}

	del(objid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(objid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRoleService.DEL_API, params, {params: parameters});
	}
}

<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field appearance="standard">
						 <mat-label>Kontakt neve</mat-label>
						 <input matInput name="inputKontaktneve" [(ngModel)]="formdata.kontaktneve" [disabled]="!writeable">
					</mat-form-field>
					<mat-form-field appearance="standard">
						 <mat-label>Kontakt telefonszáma</mat-label>
						 <input matInput name="inputKontakttel" [(ngModel)]="formdata.kontakttel" [disabled]="!writeable">
					</mat-form-field>
					<mat-form-field appearance="standard">
						 <mat-label>Kontakt e-mail címe</mat-label>
						 <input matInput name="inputKontaktemail" [(ngModel)]="formdata.kontaktemail" [disabled]="!writeable">
					</mat-form-field>
					<mat-checkbox matInput name="inputKooperacio" labelPosition="before" [(ngModel)]="formdata.kooperacio" [disabled]="!writeable">Kooperáció szükséges?</mat-checkbox>
					<mat-form-field appearance="standard" *ngIf="formdata.kooperacio">
						 <mat-label>Kooperáció szöveges leírása</mat-label>
						 <textarea matInput name="inputKooperacioleiras" rows="5" [(ngModel)]="formdata.kooperacioleiras" [disabled]="!writeable"></textarea>
					</mat-form-field>
					<mat-checkbox matInput name="inputBeszerzes" labelPosition="before" [(ngModel)]="formdata.beszerzes" [disabled]="!writeable">Beszerzés szükséges?</mat-checkbox>
					<mat-form-field appearance="standard" *ngIf="formdata.beszerzes">
						 <mat-label>Beszerzendő anyagok leírása</mat-label>
						 <textarea matInput name="inputBeszerzesleirasa" rows="5" [(ngModel)]="formdata.beszerzesleirasa" [disabled]="!writeable"></textarea>
					</mat-form-field>
					<mat-checkbox matInput name="inputFelmeres" labelPosition="before" [(ngModel)]="formdata.felmeres" [disabled]="!writeable">Felmérés szükséges?</mat-checkbox>
					<mat-form-field *ngIf="formdata.felmeres">
						 <mat-label>Ügyfél által kért időpont</mat-label>
						 <input matInput name="inputFelmereskertidopont" [(ngModel)]="formdata.felmereskertidopont" [owlDateTime]="inputFelmereskertidopontPicker" [owlDateTimeTrigger]="inputFelmereskertidopontPicker" [disabled]="!writeable">
						 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputFelmereskertidopontPicker></owl-date-time>
					</mat-form-field>
					<mat-checkbox matInput name="inputTervezes" labelPosition="before" [(ngModel)]="formdata.tervezes" [disabled]="!writeable">Hűtőkamra tervezés szükséges?</mat-checkbox>
					<mat-checkbox matInput name="inputEgyebtervezes" labelPosition="before" [(ngModel)]="formdata.egyebtervezes" [disabled]="!writeable">Egyéb tervezés szükséges?</mat-checkbox>
					<mat-checkbox matInput name="inputElolegvan" labelPosition="before" [(ngModel)]="formdata.elolegvan" [disabled]="!writeable">Van-e előleg?</mat-checkbox>
					<mat-checkbox matInput name="inputMasodikelolegvan" labelPosition="before" [(ngModel)]="formdata.masodikelolegvan" [disabled]="!writeable">Van második előleg?</mat-checkbox>
					<mat-form-field>
						 <mat-label>Ügyfél által kért gyártási határidő</mat-label>
						 <input matInput name="inputUgyfelgyartasihatarido" [(ngModel)]="formdata.ugyfelgyartasihatarido" [owlDateTime]="inputUgyfelgyartasihataridoPicker" [owlDateTimeTrigger]="inputUgyfelgyartasihataridoPicker" [disabled]="!writeable">
						 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputUgyfelgyartasihataridoPicker></owl-date-time>
					</mat-form-field>
					<mat-checkbox matInput name="inputSzallitas" labelPosition="before" [(ngModel)]="formdata.szallitas" [disabled]="!writeable">Szállítás szükséges?</mat-checkbox>
					<mat-form-field>
						 <mat-label>Szállítás kért időpontja</mat-label>
						 <input matInput name="inputSzallitaskertidopontja" [(ngModel)]="formdata.szallitaskertidopontja" [owlDateTime]="inputSzallitaskertidopontjaPicker" [owlDateTimeTrigger]="inputSzallitaskertidopontjaPicker" [disabled]="!writeable">
						 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputSzallitaskertidopontjaPicker></owl-date-time>
					</mat-form-field>
					<mat-checkbox matInput name="inputSzereles" labelPosition="before" [(ngModel)]="formdata.szereles" [disabled]="!writeable">Szerelés szükséges?</mat-checkbox>
					<mat-form-field *ngIf="formdata.szereles">
						 <mat-label>Szerelés kért időpontja</mat-label>
						 <input matInput name="inputSzereleskertidopontja" [(ngModel)]="formdata.szereleskertidopontja" [owlDateTime]="inputSzereleskertidopontjaPicker" [owlDateTimeTrigger]="inputSzereleskertidopontjaPicker" [disabled]="!writeable">
						 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputSzereleskertidopontjaPicker></owl-date-time>
					</mat-form-field>
					<mat-checkbox matInput name="inputSzereloszallit" labelPosition="before" [(ngModel)]="formdata.szereloszallit" [disabled]="!writeable">Szerelő szállít?</mat-checkbox>
					<mat-checkbox matInput name="inputEmelogep" labelPosition="before" [(ngModel)]="formdata.emelogep" [disabled]="!writeable">Emelőgép kell a szereléshez?</mat-checkbox>
					<mat-checkbox matInput name="inputKulfoldi" labelPosition="before" [(ngModel)]="formdata.kulfoldi" [disabled]="!writeable">Külföldi szállítás vagy szerelés?</mat-checkbox>
			   </form>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" [disabled]="!dataForm.valid" *ngIf="writeable">Mentés</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" [disabled]="!dataForm.valid" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
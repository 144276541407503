import {Component, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {GablerSzamlazasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszamlazas/gablerszamlazas.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {ListsModel} from 'src/app/model/lists.model';


export interface Szamla {
	id: string,
	projectid: string;
	taskid:number;
	szamlatipus: number;
	sorszam: string;
	teljesites: any;
	kiallitas: any;
	hatarido:any,
	penznem:string,
	brutto:number,
	szamla:string,
	kifizetve:number,
	beerkezes:any,
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}



@Component({
	selector: 'form-gablerszamlazas',
	templateUrl: './gablerszamlazas.component.html',
	styleUrls: ['./gablerszamlazas.component.scss']
})
export class GablerSzamlazasComponent {

	public static readonly TAG: string = 'form-gablerszamlazas';
	public static readonly NAME: string = 'Számlázás (Gabler)';
	public static readonly configComponent = null;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	formdata: Array<Szamla> = [];
	

	selectedForm: number = 0;
	szamlatypes:Array<any>=[
		{text:'Díjbekérő-előlegszámla', value:1},
		{text:'Számla', value:2}
	];
	calculableColumns:any={BRUTTOOSSZEG:'brutto',KIFIZETETTOSSZEG:'kifizetve'};
	brutto:number=0
	kifizetve:number=0;
	constructor(
		public gablerSzamlazasService: GablerSzamlazasService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.gablerSzamlazasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpSzamlak: Array<Szamla> = [];

		this.formdata.forEach((szamla) => {
			let tmpSzamla: Szamla = {...szamla};
			for (let key in tmpSzamla) {
				tmpSzamla[key] = this.transform(tmpSzamla[key]);
			}
			tmpSzamlak.push(tmpSzamla);
		});

		return tmpSzamlak;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerSzamlazasService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	save() {
		this.increaseLoading.emit(null);
		this.gablerSzamlazasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerSzamlazasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
					res => this.handleSaveDoneResponse(res),
					error => this.handleSaveDoneError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerSzamlazasService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.formdata = [];

		for (let i = 0; i < serverResponse.length; i++) {
			let tmpSzamla: Szamla = {
				id:serverResponse[i].id,
				projectid:serverResponse[i].projectid,
				taskid:serverResponse[i].taskid,
				szamlatipus:Number(serverResponse[i].szamlatipus),
				sorszam:serverResponse[i].sorszam,
				teljesites:CommonModel.isDate(serverResponse[i].teljesites) ? (new Date(Date.parse(serverResponse[i].teljesites))) : null,
				kiallitas:CommonModel.isDate(serverResponse[i].kiallitas) ? (new Date(Date.parse(serverResponse[i].kiallitas))) : null,
				hatarido:CommonModel.isDate(serverResponse[i].hatarido) ? (new Date(Date.parse(serverResponse[i].hatarido))) : null,
				penznem:serverResponse[i].penznem,
				brutto:Number(serverResponse[i].brutto),
				szamla:serverResponse[i].szamla,
				kifizetve:Number(serverResponse[i].kifizetve),
				beerkezes:CommonModel.isDate(serverResponse[i].beerkezes) ? (new Date(Date.parse(serverResponse[i].beerkezes))):null,
				modifiedby:serverResponse[i].modifiedby,
				modifiedtime:serverResponse[i].modifiedtime,
				deletedby:serverResponse[i].deletedby,
				deletedtime:serverResponse[i].deletedtime
			};
			this.formdata.push(tmpSzamla);
		}
		this.calculateColumn(this.calculableColumns.BRUTTOOSSZEG);
		this.calculateColumn(this.calculableColumns.KIFIZETETTOSSZEG);
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
		this.increaseLoading.emit(null);
		this.gablerSzamlazasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.gablerSzamlazasService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

	addTetel() {
		let tmpSzamla: Szamla = {
			id: null,
			projectid: null,
			taskid:this.taskObject.getId(),
			szamlatipus: Number("1"),
			sorszam: null,
			teljesites: null,
			kiallitas: null,
			hatarido:null,
			penznem:"HUF",
			brutto:null,
			szamla:null,
			kifizetve:null,
			beerkezes:null,
			modifiedby: null,
			modifiedtime: null,
			deletedby: null,
			deletedtime: null,
		};

		this.formdata.splice(0, 0, tmpSzamla);
	}

	removeTetel(szamlaIdx) {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli ezt a tételt?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.formdata.splice(szamlaIdx, 1);
			}
			this.confirmDialogRef = null;
		});
	}

	getSzamlaTypeName(tipus){
		let text=""

		this.szamlatypes.forEach(elem=>{if(elem.value==Number(tipus)){
		   text= elem.text
		 }});
		 return text

	}

	formatNumber(value){
		return Number(value).toLocaleString('hu-HU');
	}

	bruttoChange(value,szamlaIdX){
		this.formdata[szamlaIdX].brutto=value;
		this.calculateColumn(this.calculableColumns.BRUTTOOSSZEG);
	}

	kifizetveChange(value,szamlaIdX){
		this.formdata[szamlaIdX].kifizetve=value;
		this.calculateColumn(this.calculableColumns.KIFIZETETTOSSZEG);
	}

	calculateColumn(column){
		this[column]=this.formdata.map(elem=>Number(elem[column])).reduce((previous,current)=>previous+current,0);
	}
	
	/*private _filterTetelek(value: string): Szamla[] {
		const filterValue = CommonModel.replaceHunChars(String(value).toLowerCase());

		return this.tetelek.filter(tetel => tetel.megnevezes != null ? CommonModel.replaceHunChars(tetel.megnevezes.toLowerCase()).includes(filterValue) : false);
	}*/

	

}
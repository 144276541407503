import {Component, Inject, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {TaskFormsModule} from 'src/app/components/task/dialog/task-dialog/taskforms/taskforms.module';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-taskform-config-dialog',
	templateUrl: './taskform-config-dialog.html',
	styleUrls: ['./taskform-config-dialog.scss']
})
export class TaskformConfigDialog implements AfterViewInit {

	@ViewChild('configHost', {
		read: ViewContainerRef
	}) configHost: ViewContainerRef;

	loading: number = 0;

	alertDialogRef: MatDialogRef<AlertDialog>;

	templateId: any;
	objecttype: any;

	constructor(
		private dialogRef: MatDialogRef<TaskformConfigDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private resolver: ComponentFactoryResolver
	) {}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.templateId = this.data.templateId;
			this.objecttype = this.data.objecttype;

			if (!this.templateId || !this.objecttype) {
				this.closeDialog();
			} else {
				this.loadTaskConfigComponent(this.objecttype);
			}
		});
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	loadTaskConfigComponent(component: string) {
		this.configHost.clear();

		if (component in TaskFormsModule.compMap) {
			if (TaskFormsModule.compMap[component].configComponent != null) {
				const compFactory = this.resolver.resolveComponentFactory(TaskFormsModule.compMap[component].configComponent);
				const compRef = this.configHost.createComponent(compFactory);

				(compRef.instance as any).closeParentDialog.subscribe(evt => this.closeDialog());
				(compRef.instance as any).increaseLoading.subscribe(evt => this.increaseLoading(evt));
				(compRef.instance as any).decreaseLoading.subscribe(evt => this.decreaseLoading(evt));
				(compRef.instance as any).setTemplateId(this.templateId);
			} else {
				this.closeDialog();
			}
		} else {
			if (component == null) {
				this.closeDialog();
			} else {
				this.alertDialogRef = this.dialog.open(AlertDialog, {
					disableClose: true,
					panelClass: 'alertDialogWindow',
					autoFocus: false
				});

				this.alertDialogRef.componentInstance.dialogMessage = "Ismeretlen feladat típus";
				this.alertDialogRef.afterClosed().subscribe(result => {
					this.alertDialogRef = null;
					this.closeDialog();
				});
				throw new Error(`Unknown ${component} form type`);
			}
		}
	}

	increaseLoading(param) {
		this.loading++;
	}

	decreaseLoading(param) {
		this.loading--;
	}

	closeDialog() {
		this.dialogRef.close(false);
	}
}
import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.html',
	styleUrls: ['./alert-dialog.scss']
})
export class AlertDialog {

	constructor(public dialogRef: MatDialogRef<AlertDialog>) {}

	public dialogMessage: string = '';
	public dialogTitle: string = '';
	public dialogOkButton: string = "Ok";

	hasTitle() {
		if (this.dialogTitle == undefined || this.dialogTitle == null || this.dialogTitle == '')
			return false;
		else
			return true;
	}

}

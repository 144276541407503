import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {SzerelesekService} from 'src/app/components/szereles/service/szerelesek.service';

import {SzerelesekComponent} from 'src/app/components/szereles/component/szerelesek/szerelesek.component';

import {SzerelesDialog} from 'src/app/components/szereles/dialog/szereles-dialog/szereles-dialog';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		SzerelesekComponent,
		SzerelesDialog
	],
	entryComponents: [
		SzerelesekComponent,
		SzerelesDialog
	],
	providers: [
		SzerelesekService
	],
	exports: [
		SzerelesekComponent,
		SzerelesDialog
	]
})
export class SzerelesekModule {}
<div class="loading-wrapper">
	<div class="loading-shade" *ngIf="loading!=0">
		 <mat-spinner></mat-spinner>
	</div>
	<mat-toolbar>
		 <mat-toolbar-row>
			  <span>{{ getSettingName() }}</span>
		 </mat-toolbar-row>
	</mat-toolbar>
	<form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			  <mat-label>A mérés</mat-label>
			  <input matInput name="inputAmeres" [ngModel]="targetObj.getAmeres()" (ngModelChange)="targetObj.setAmeres($event)" disabled>
		 </mat-form-field>
		 <br>
		 <mat-form-field appearance="standard">
			   <mat-label>B mérés</mat-label>
			   <input matInput name="inputBmeres" [ngModel]="targetObj.getBmeres()" (ngModelChange)="targetObj.setBmeres($event)" disabled>
			</mat-form-field>
			<br/>
		 <mat-form-field>
			   <mat-label>Időpont</mat-label>
			   <input matInput name="inputIdopont" [ngModel]="targetObj.getIdopont()" (ngModelChange)="targetObj.setIdopont($event)" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" disabled>
			   <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
		 </mat-form-field>  
	</form>
	<div class="dialogButtons">
		 <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		 <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	</div>
</div>
<div class="loading-wrapper tableContainer my-4">
	<div class="loading-shade" *ngIf="loading!=0">
		<mat-spinner></mat-spinner>
	</div>

	<div class="filter-container">
		<app-table-filter style="margin-left: 10px; width: 100%;" filterId="szamlalist" [(filter)]="filterValue" (keyupFn)="applyFilter()" placeholder="Szűrő..."></app-table-filter>
		<mat-checkbox class="mobile-view-hide" style="margin-top: 0.5em" [(ngModel)]="closed" (change)="loadData()">Lezárt projektek számlái is</mat-checkbox>
		<mat-checkbox class="mobile-view-hide" style="margin-top: 0.5em" [(ngModel)]="outstanding" (change)="isOutstanding()">Kifizetetlen számlák</mat-checkbox>
	</div>
	<div class="filter-container normal-view-hide">
		<mat-checkbox style="margin-top: 0.5em" [(ngModel)]="closed" (change)="loadData()">Lezárt projektek számlái is</mat-checkbox>
		<mat-checkbox style="margin-top: 0.5em" [(ngModel)]="outstanding" (change)="isOutstanding()">Kifizetetlen számlák</mat-checkbox>
   </div>

	<div class="table-wrapper mat-elevation-z8" style="overflow: auto">
		<table mat-table [dataSource]="dataSource" matSort>

			<!-- ID Column -->
			<ng-container matColumnDef="id" sticky>
				<th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						#
					</div>
				</th>
				<td class="col-id" mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getId()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Parner Column -->
			<ng-container matColumnDef="pa_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Partner
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getProjectModel().getPartnerModel().getNev()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>


			<!-- Number Column -->
			<ng-container matColumnDef="p_number">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Munkaszám
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getProjectModel().getNumber()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Szamlatipus Column -->
			<ng-container matColumnDef="szamlatipus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Számlatípus
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{getSzamlaTypeName(row.getSzamlatipus())}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Sorszam Column -->
			<ng-container matColumnDef="sorszam">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Alap bizonylat
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getSorszam()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Teljesites Column -->
			<ng-container matColumnDef="teljesites">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Teljesítés dátuma
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getTeljesites()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Kiallitas Column -->
			<ng-container matColumnDef="kiallitas">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Kiállítás dátuma
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getKiallitas()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Hatarido Column -->
			<ng-container matColumnDef="hatarido">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Fizetési határidő
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getHatarido()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Penznem Column -->
			<ng-container matColumnDef="penznem">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Pénznem
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getPenznem()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Brutto Column -->
			<ng-container matColumnDef="brutto">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Bruttó összeg
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper right-alignment">
						{{formatNumber(row.getBrutto())}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper right-alignment">
						{{formatNumber(brutto)}}
					</div> 
				</td>
			</ng-container>

			<!-- Kifizetve Column -->
			<ng-container matColumnDef="kifizetve">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Kifizetett összeg
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper right-alignment">
						{{formatNumber(row.getKifizetve())}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper right-alignment">
						{{formatNumber(kifizetve)}}
					</div> 
				</td>
			</ng-container>

			<!-- Különbözet Column -->
			<ng-container matColumnDef="kulonbozet">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Bruttó-kifizetett különbözet
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper right-alignment">
						{{formatNumber((row.getBrutto()-row.getKifizetve()))}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper right-alignment">
						{{formatNumber(brutto-kifizetve)}}
					</div> 
				</td>
			</ng-container>

			<!-- Szamla Column -->
			<ng-container matColumnDef="szamla">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Számla sorszám
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getSorszam()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Beerkezes Column -->
			<ng-container matColumnDef="beerkezes">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>
					<div class="data-wrapper">
						Beérkezés dátuma
					</div>
				</th>
				<td mat-cell *matCellDef="let row">
					<div class="data-wrapper">
						{{row.getBeerkezes()}}
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<!-- Action Column -->
			<ng-container matColumnDef="action" stickyEnd>
				<th class="col-action" mat-header-cell *matHeaderCellDef>
					<div class="data-wrapper">
						<fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadData();" matTooltip="Frissítés">
						</fa-icon>
					</div>
				</th>
				<td class="col-action" mat-cell *matCellDef="let row;">
					<div class="data-wrapper" *hasPermission="['TaskForm:Szamlak:mod']">
						<fa-icon [icon]="['fas', 'pencil-alt']" size="lg" (click)="open(row.getId());"
							matTooltip="Számla megnyitása"></fa-icon>
					</div>
					<div class="data-wrapper" *hasPermission="['TaskForm:Szamlak:mod']">
						<fa-icon [icon]="['fas', 'file-invoice-dollar']" size="lg" (click)="equate(row.getId());"
							matTooltip="Számla kiegyenlítése"></fa-icon>
					</div>
				</td>
				<td mat-footer-cell *matFooterCellDef>
					<div class="data-wrapper">
					</div> 
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
		</table>

		<mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
	</div>
</div>
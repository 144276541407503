<div>
	 <!-- <mat-card class="mx-2 my-2">
		 <mat-card-content>
			 <p *ngIf="taskObject.getDetails().length > 0">Feladat leírása:</p>
			 <p *ngIf="taskObject.getDetails().length > 0">{{taskObject.getDetails()}}</p>
		 </mat-card-content>
	 </mat-card> -->
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
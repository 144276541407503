import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

import {SzamlazasService} from 'src/app/components/szamlazas/service/szamlazas.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';

import {ListsModel} from 'src/app/model/lists.model';
import {SzamlaModel} from 'src/app/components/szamlazas/model/szamla.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {SzamlazasDialog} from 'src/app/components/szamlazas/dialog/szamlazas-dialog/szamlazas-dialog';



@Component({
	selector: 'app-szamlazas',
	templateUrl: './szamlazas.component.html',
	styleUrls: ['./szamlazas.component.scss']
})
export class SzamlazasComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;
	szamlaDialogRef:MatDialogRef<SzamlazasDialog>;

	loading: number = 0;
	closed:boolean=false;
	outstanding:boolean=true;

	displayedColumns: string[] = ['id', 'pa_name', 'p_number', 'szamlatipus', 'sorszam', 'teljesites', 'kiallitas','hatarido','penznem','brutto','kifizetve','kulonbozet','szamla','beerkezes', 'action'];
	dataSource: MatTableDataSource<SzamlaModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	listsModel: any = ListsModel;

	filterValue: string = "";
	szamlatypes:Array<any>=[
		{text:"Díjbekérő-előlegszámla", value:1},
		{text:"Számla", value:2}
	];
	calculableColumns:any={BRUTTOOSSZEG:'brutto',KIFIZETETTOSSZEG:'kifizetve'};
	brutto:number=0;
	kifizetve:number=0;
	kulonbozet:number=0;

	constructor(
		private szamlazasService: SzamlazasService,
		private router: Router,
		private dialog: MatDialog,
		private _snackBar:MatSnackBar
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getProjectModel().getPartnerModel().getNev(),
				row.getProjectModel().getNumber(),
				String(row.getSzamlatipus()),
				row.getSorszam(),
				row.getTeljesites(),
				row.getKiallitas(),
				row.getHatarido(),
				row.getPenznem(),
				String(row.getBrutto()),
				String(row.getKifizetve()),
				String(row.getBrutto()-row.getKifizetve()),
				row.getSzamla(),
				row.getBeerkezes()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
		this.calculateColumns();
		
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<SzamlaModel> = [];
		let tmpObj: SzamlaModel = new SzamlaModel({});

		for (let i = 0; i < serverResponse.length; i++) {
			
			tmpObj = new SzamlaModel(serverResponse[i]);
			//if (tmpObj.getStatus() == ListsModel.ProjectStatus_FOLYAMATBAN.getValue()) {
				objects.push(tmpObj);
			//}
		}
		

		this.dataSource.data = this.outstanding? this.filterDataByKulonbozet(objects): objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'pa_name': return item.getProjectModel().getPartnerModel().getNev();
				case 'p_number': return item.getProjectModel().getNumber();
				case 'szamlatipus': return item.getSzamlatipus();
				case 'sorszam': return item.getSorszam();
				case 'teljesites': return item.getTeljesites();
				case 'kiallitas': return item.getKiallitas();
				case 'hatarido': return item.getHatarido();
				case 'penznem': return item.getPenznem();
				case 'brutto': return item.getBrutto();
				case 'kifizetve': return item.getKifizetve();
				case 'kulonbozet' :return item.getBrutto()-item.getKifizetve();
				case 'szamla': return item.getSzamla();
				case 'beerkezes': return item.getBeerkezes();
				
				default: return item[property];
			}
		};
		this.sort.sort(({ id: 'id', start: 'desc'}) as MatSortable);
		this.dataSource.sort = this.sort;
		this.applyFilter();
		
		
	}
	handleEquateResponse(serverResponse){
		this._snackBar.open('Számla kiegyenlítve', null, {
			duration: 2000,
		});
		this.loadData();

	}
	handleEquateError(serverError){
		this.handleError(serverError);
	}

	loadData() {
		console.log('load data')
		this.loading++;
		this.szamlazasService.getAll(this.closed).subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	open(id) {
		this.szamlaDialogRef = this.dialog.open(SzamlazasDialog, {
			disableClose: true,
			panelClass: 'szamlaDialogWindow',
			autoFocus: false,
			data:{szamlaId:id}
		});
		this.szamlaDialogRef.afterClosed().subscribe(result => {
			this.loadData();
			this.szamlaDialogRef = null;
		});
	}

	equate(id){
		this.szamlazasService.equate(id).subscribe(
			res=>this.handleEquateResponse(res),
			error=>this.handleEquateError(error)
		)
	}
	
	getSzamlaTypeName(tipus){
		let text=""

		this.szamlatypes.forEach(elem=>{if(elem.value==Number(tipus)){
		   text= elem.text
		 }});
		 return text

	}

	formatNumber(value){
		return Number(value).toLocaleString('hu-HU');
	}

	calculateColumn(column){
		this[column]=this.dataSource.filteredData.map(elem=>Number(elem[column])).reduce((previous,current)=>previous+current,0)
				
	}

	calculateColumns(){
		this.calculateColumn(this.calculableColumns.BRUTTOOSSZEG);
		this.calculateColumn(this.calculableColumns.KIFIZETETTOSSZEG);
	}
	
	filterDataByKulonbozet(objects){
		let returnValue=objects.filter((obj:SzamlaModel)=>(obj.getBrutto()-obj.getKifizetve())>0)
		return returnValue;
	}
	isOutstanding(){
		if(this.outstanding){
			this.dataSource.data=this.filterDataByKulonbozet(this.dataSource.filteredData);
			this.calculateColumns();
		}
		else{
			this.loadData();
		}
	}

}

import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezGyartoComponent} from 'src/app/components/settings/raktar/lemezgyarto/component/addlemezgyarto/addlemezgyarto.component';
import {ModLemezGyartoComponent} from 'src/app/components/settings/raktar/lemezgyarto/component/modlemezgyarto/modlemezgyarto.component';
import {LemezGyartokComponent} from 'src/app/components/settings/raktar/lemezgyarto/component/lemezgyartok/lemezgyartok.component';

import {LemezGyartoService} from 'src/app/components/settings/raktar/lemezgyarto/service/lemezgyarto.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezGyartoComponent,
		ModLemezGyartoComponent,
		LemezGyartokComponent
	],
	entryComponents: [
		AddLemezGyartoComponent,
		ModLemezGyartoComponent,
		LemezGyartokComponent
	],
	providers: [
		LemezGyartoService
	],
	exports: [
		AddLemezGyartoComponent,
		ModLemezGyartoComponent,
		LemezGyartokComponent
	],
})
export class LemezGyartoModule {
	public static readonly compArray = [
		AddLemezGyartoComponent,
		ModLemezGyartoComponent,
		LemezGyartokComponent
	];

	public static readonly compMap = LemezGyartoModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezGyartoModule.compMap) ? LemezGyartoModule.compMap[TAG].NAME : '';
	}
}
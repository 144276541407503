import {RoleModel} from "src/app/components/settings/role/model/role.model";

export class UserModel {

	private id: number = null;
	private username: string = '';
	private u_firstname: string = '';
	private u_lastname: string = '';
	private u_email: string = '';
	private u_phone: string = '';
	private u_language: string = '';
	private u_deputy: number = null;
	private u_hidden: any = null;
	private u_disabled: any = null;

	private u_modifiedby: number = null;
	private u_modifiedtime: string = '';

	private roles: Array<RoleModel> = [];

	private password: string = '';
	private password_confirm: string = '';

	constructor(json) {
		this.id = json && json.id || null;
		this.username = json && json.username || '';
		this.u_firstname = json && json.u_firstname || '';
		this.u_lastname = json && json.u_lastname || '';
		this.u_email = json && json.u_email || '';
		this.u_phone = json && json.u_phone || '';
		this.u_language = json && json.u_language || '';
		this.u_deputy = json && json.u_deputy || null;

		this.u_hidden = json && json.u_hidden || false;
		this.u_hidden = (this.u_hidden === true || this.u_hidden == 1 || this.u_hidden == 'true') ? true : false;
		this.u_disabled = json && json.u_disabled || false;
		this.u_disabled = (this.u_disabled === true || this.u_disabled == 1 || this.u_disabled == 'true') ? true : false;

		this.u_modifiedby = json && json.u_modifiedby || null;
		this.u_modifiedtime = json && json.u_modifiedtime || '';

		let tmpRoles: Array<object> = json && json.roles || [];
		tmpRoles.forEach(role => {
			this.roles.push(new RoleModel(role));
		});
	}

	getId() {
		return this.id;
	}

	getUsername() {
		return this.username;
	}

	setUsername(value) {
		this.username = value;
	}

	getFirstName() {
		return this.u_firstname;
	}

	setFirstName(value) {
		this.u_firstname = value;
	}

	getLastName() {
		return this.u_lastname;
	}

	setLastName(value) {
		this.u_lastname = value;
	}

	getFullName() {
		return this.u_lastname + " " + this.u_firstname;
	}

	getEmail() {
		return this.u_email;
	}

	setEmail(value) {
		this.u_email = value;
	}

	getPhone() {
		return this.u_phone;
	}

	setPhone(value) {
		this.u_phone = value;
	}

	getLanguage() {
		return this.u_language;
	}

	setLanguage(value) {
		this.u_language = value;
	}

	getDeputy() {
		return this.u_deputy;
	}

	setDeputy(value) {
		this.u_deputy = value;
	}

	isHidden() {
		return this.u_hidden;
	}

	setHidden(value) {
		this.u_hidden = (value === true || value == 1 || value == 'true') ? true : false;
	}

	isDisabled() {
		return this.u_disabled;
	}

	setDisabled(value) {
		this.u_disabled = (value === true || value == 1 || value == 'true') ? true : false;
	}

	getPassword() {
		return this.password;
	}

	setPassword(value) {
		this.password = value;
	}

	getPasswordConfirm() {
		return this.password_confirm;
	}

	setPasswordConfirm(value) {
		this.password_confirm = value;
	}

	getRoles() {
		return this.roles;
	}

	setRoles(value) {
		this.roles = value;
	}

	getRolesId() {
		let tmpIds: Array<number> = [];
		this.roles.forEach(role => {
			tmpIds.push(role.getId());
		});
		return tmpIds;
	}
}

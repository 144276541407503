import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {LemezSzallitasModel} from 'src/app/components/raktar/model/lemezszallitas.model';

@Injectable()
export class LemezSzallitasService {

	public static readonly API_TYPE: string = 'lemezszallitas';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + LemezSzallitasService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + LemezSzallitasService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + LemezSzallitasService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + LemezSzallitasService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + LemezSzallitasService.API_TYPE + '/del_request';
	
	public static readonly LIST_TAG: string = 'setting-lemezszallitasok';
	public static readonly ADD_TAG: string = 'setting-addlemezszallitas';
	public static readonly MOD_TAG: string = 'setting-modlemezszallitas';
	public static readonly DEL_TAG: string = 'setting-dellemezszallitas';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezSzallitasService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezSzallitasService.GET_API, params, {params: parameters});
	}

	add(obj: LemezSzallitasModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('lemezgyartoid',String(obj.getLemezGyartoid()))
		params.append('lemezszinid',String(obj.getLemezSzinid()))
		params.append('lemezanyagminosegid',String(obj.getLemezAnyagminosegid()))
		params.append('lemezszelessegid',String(obj.getLemezSzelessegid()))
		params.append('lemezvastagsagid',String(obj.getLemezVastagsagid()))
		params.append('idopont', obj.getIdopontString());
		params.append('szallitolevel', obj.getSzallitolevel());
		params.append('tomeg', String(obj.getTomeg()));
		params.append('aktualistomeg', String(obj.getAktualistomeg()));
		params.append('nettoar', String(obj.getNettoar()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezSzallitasService.ADD_API, params, {params: parameters});
	}
	mod(obj: LemezSzallitasModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id',String(obj.getId()))
		params.append('lemezgyartoid',String(obj.getLemezGyartoid()))
		params.append('lemezszinid',String(obj.getLemezSzinid()))
		params.append('lemezanyagminosegid',String(obj.getLemezAnyagminosegid()))
		params.append('lemezszelessegid',String(obj.getLemezSzelessegid()))
		params.append('lemezvastagsagid',String(obj.getLemezVastagsagid()))
		params.append('idopont', obj.getIdopontString());
		params.append('szallitolevel', obj.getSzallitolevel());
		params.append('tomeg', String(obj.getTomeg()));
		params.append('aktualistomeg', String(obj.getAktualistomeg()));
		params.append('nettoar', String(obj.getNettoar()));
		

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezSzallitasService.MOD_API, params, {params: parameters});
	}

	del(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezSzallitasService.DEL_API, params, {params: parameters});
	}
}

import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';

export class TaskRefModel {

	private id: number = null;

	private tre_parent: number = null;
	private parent: TaskTemplateModel = new TaskTemplateModel({});
	private tre_child: number = null;
	private child: TaskTemplateModel = new TaskTemplateModel({});
	private tre_assigntype: number = null;

	private tre_modifiedby: number = null;
	private tre_modifiedtime: string = '';

	private owner: number = null;
	private deadline: Date = null;

	constructor(json) {
		this.id = json && json.id || null;

		this.tre_parent = json && json.tre_parent || null;
		this.parent = new TaskTemplateModel(json && json.parent || {});
		this.tre_child = json && json.tre_child || null;
		this.child = new TaskTemplateModel(json && json.child || {});
		this.tre_assigntype = json && json.tre_assigntype || null;

		this.tre_modifiedby = json && json.tre_modifiedby || null;
		this.tre_modifiedtime = json && json.tre_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getParent() {
		return this.tre_parent;
	}

	setParent(value) {
		this.tre_parent = value;
	}

	getParentModel() {
		return this.parent;
	}

	getChild() {
		return this.tre_child;
	}

	setChild(value) {
		this.tre_child = value;
	}

	getChildModel() {
		return this.child;
	}

	getAssigntype() {
		return this.tre_assigntype;
	}

	setAssigntype(value) {
		this.tre_assigntype = value;
	}

	getOwner() {
		return this.owner;
	}

	setOwner(value) {
		this.owner = value;
	}

	getDeadline() {
		return this.deadline;
	}

	setDeadline(value) {
		this.deadline = value;
	}
}

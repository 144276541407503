import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {TaskDeadlineDialogModule} from 'src/app/components/task/dialog/task-deadline-dialog/task-deadline-dialog.module';
import {TaskDialogModule} from 'src/app/components/task/dialog/task-dialog/task-dialog.module';
import {TaskformConfigDialogModule} from 'src/app/components/task/dialog/taskform-config-dialog/taskform-config-dialog.module';

import {TasksComponent} from 'src/app/components/task/component/tasks/tasks.component';

import {TaskService} from 'src/app/components/task/service/task.service';
import {TaskCommentService} from 'src/app/components/task/service/taskcomment.service';

@NgModule({
	imports: [
		CommonModules,
		TaskDeadlineDialogModule,
		TaskDialogModule,
		TaskformConfigDialogModule
	],
	declarations: [
		TasksComponent
	],
	providers: [
		TaskService,
		TaskCommentService
	],
	exports: [
		TasksComponent
	]
})
export class TaskModule {}
<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Lemez beemelés</mat-card-title>
		  </mat-card-header>
		  <div *ngIf="filterPage && szallitasok.length > 0">
			   <mat-card-content>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>Lemez</mat-card-subtitle>
						 <mat-card-content>
							  <mat-form-field appearance="standard">
								   <mat-label>Gyártó</mat-label>
								   <mat-select name="inputGyarto" [(ngModel)]="filterGyarto">
										<mat-option [value]='noFilterValue'>Minden gyártó</mat-option>
										<mat-option *ngFor="let gyarto of gyartok | customsort:'getGyarto'" [value]="gyarto.getId()">{{gyarto.getGyarto()}}</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Szín</mat-label>
								   <mat-select name="inputSzin" [(ngModel)]="filterSzin">
										<mat-option [value]='noFilterValue'>Minden szín</mat-option>
										<mat-option *ngFor="let szin of szinek | customsort:'getSzin'" [value]="szin.getId()">{{szin.getSzin()}}</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Anyagminőség</mat-label>
								   <mat-select name="inputAnyagminoseg" [(ngModel)]="filterAnyagminoseg">
										<mat-option [value]='noFilterValue'>Minden anyagminőség</mat-option>
										<mat-option *ngFor="let anyagminoseg of anyagminosegek | customsort:'getFajsuly'" [value]="anyagminoseg.getId()">{{anyagminoseg.getFajsuly()}} kg/m3</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Szélesség</mat-label>
								   <mat-select name="inputSzelesseg" [(ngModel)]="filterSzelesseg">
										<mat-option [value]='noFilterValue'>Minden szélesség</mat-option>
										<mat-option *ngFor="let szelesseg of szelessegek | customsort:'getSzelesseg'" [value]="szelesseg.getId()">{{szelesseg.getSzelesseg()}} mm</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Vastagság</mat-label>
								   <mat-select name="inputVastagsag" [(ngModel)]="filterVastagsag">
										<mat-option [value]='noFilterValue'>Minden vastagság</mat-option>
										<mat-option *ngFor="let vastagsag of vastagsagok | customsort:'getVastagsag'" [value]="vastagsag.getId()">{{vastagsag.getVastagsag()}} mm</mat-option>
								   </mat-select>
							  </mat-form-field>
						 </mat-card-content>
					</div>
			   </mat-card-content>
			   <mat-card-actions style="text-align: right;">
					<button mat-raised-button (click)="filter()">Keresés</button>
					<button mat-raised-button routerLink="/raktar">Mégsem</button>
			   </mat-card-actions>
		  </div>



		  <div *ngIf="!filterPage && filteredSzallitasok.length > 0">
			   <mat-card-content>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>Lemez</mat-card-subtitle>
						 <mat-card-content>
							  <mat-label>Gyártó: {{ filteredSzallitasok[selectedIdx].getLemezGyartoModel().getGyarto() }} </mat-label>
							  <br>
							  <mat-label>Szín: {{ filteredSzallitasok[selectedIdx].getLemezSzinModel().getSzin() }} </mat-label>
							  <br>
							  <mat-label>Fajsúly: {{ filteredSzallitasok[selectedIdx].getLemezAnyagminosegModel().getFajsuly() }} kg/m3 </mat-label>
							  <br>
							  <mat-label>Szélesség: {{ filteredSzallitasok[selectedIdx].getLemezSzelessegModel().getSzelesseg() }} mm </mat-label>
							  <br>
							  <mat-label>Vastagság: {{ filteredSzallitasok[selectedIdx].getLemezVastagsagModel().getVastagsag() }} mm </mat-label>
							  <br>
							  <mat-label>Aktuális tömeg: {{ filteredSzallitasok[selectedIdx].getAktualistomeg() }} kg </mat-label>
							  <br>
							  <mat-label>Szállítólevél száma: {{ filteredSzallitasok[selectedIdx].getSzallitolevel() }} </mat-label>
							  <br>
							  <mat-label>Raktár: {{ filteredSzallitasok[selectedIdx].getLemezTaroloModel().getLemezRaktarModel().getRaktar() }} </mat-label>
							  <br>
							  <mat-label>Tároló: {{ filteredSzallitasok[selectedIdx].getLemezTaroloModel().getTarolo() }} </mat-label>
						 </mat-card-content>
					</div>
			   </mat-card-content>
			   <mat-card-actions style="display: flex; justify-content: space-around; width: 50%; margin: 0 auto;">
					<button mat-mini-fab color="disabled" (click)="prev()">
						 <fa-icon [icon]="['fas', 'chevron-left']" size="lg"></fa-icon>
					</button>
					<div style="display: flex; justify-content: center; flex-direction: column;"> {{ selectedIdx + 1 }} / {{ filteredSzallitasok.length }}</div>
					<button mat-mini-fab color="disabled" (click)="next()">
						 <fa-icon [icon]="['fas', 'chevron-right']" size="lg"></fa-icon>
					</button>
			   </mat-card-actions>
			   <mat-card-content>
					<form #dataForm="ngForm">
						 <mat-form-field>
							  <mat-label>Időpont</mat-label>
							  <input matInput name="inputIdopont" [(ngModel)]="idopont" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" required>
							  <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
						 </mat-form-field>
					</form>
			   </mat-card-content>
			   <mat-card-actions style="text-align: right;">
					<button mat-raised-button (click)="back()">Új keresés</button>
					<button mat-raised-button (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
					<button mat-raised-button routerLink="/raktar">Mégsem</button>
			   </mat-card-actions>
		  </div>
	 </mat-card>
</div>

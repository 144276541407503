<div class="content-wrapper loading-wrapper mx-auto my-3">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <div id="calendar-print" class="calendar-wrapper" *ngIf="calendarView">
		  <full-calendar [options]="calendarOptions"></full-calendar>
	 </div>
	 <div id="gantt-print" class="gantt-wrapper" *ngIf="!calendarView">
		  <div class="fc fc-media-screen fc-direction-ltr no-print">
			   <div class="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
					<div class="fc-toolbar-chunk">
						 <button class="fc-button fc-button-primary" (click)="ganttJumpToday()" [disabled]="ganttDiff == 0">Aktuális hét</button>
						 <button class="fc-button fc-button-primary" (click)="switchView()"><span>Naptár</span></button>
						 <button class="fc-button fc-button-primary" (click)="printView()">Nyomtatás</button>
					</div>
					<div class="fc-toolbar-chunk">
						 <h2 class="fc-toolbar-title">{{ganttYearNumber}}</h2>
					</div>
					<div class="fc-toolbar-chunk">
						 <div class="fc-button-group">
							  <button class="fc-prev-button fc-button fc-button-primary" (click)="ganttPrevWeek()"><span class="fc-icon fc-icon-chevron-left"></span></button>
							  <button class="fc-next-button fc-button fc-button-primary" (click)="ganttNextWeek()"><span class="fc-icon fc-icon-chevron-right"></span></button>
						 </div>
					</div>
			   </div>
		  </div>
		  <table class="mat-elevation-z4">
			   <tr class="gantt-header">
					<td rowspan="2"></td>
					<td rowspan="2"><span>Projekt</span></td>
					<td rowspan="2"><span>Megrendelő</span></td>
					<td rowspan="2"><span>Helyszín</span></td>
					<td rowspan="2"><span>Szerelő</span></td>
					<td colspan="7" style="width: 25%"><span>{{ ganttWeekNumber1 > 0 ? ganttWeekNumber1 + '. hét' : '' }}</span></td>
					<td colspan="7" style="width: 25%"><span>{{ ganttWeekNumber2 > 0 ? ganttWeekNumber2 + '. hét' : '' }}</span></td>
			   </tr>
			   <tr class="gantt-header">
					<td *ngFor="let ganttDay of ganttDays" class="gantt-day" [ngClass]="isFreeday(ganttDay)?'gantt-freeday':''">
						<div *ngIf="isToday(ganttDay)" class="gantt-today no-print">
						 </div>
						 <span>{{("0" + (ganttDay.getMonth() + 1)).substr(-2) + ". " + ("0" + ganttDay.getDate()).substr(-2) + "."}}</span>
					</td>
			   </tr>
			   <tr class="gantt-row" *ngFor="let szereles of ganttSzerelesek; let szerelesIdx = index;" (click)="openSzerelesDialog(szereles.id)" [ngClass]="szereles.allapot==listsModel.SzerelesAllapot_ELOJEGYEZVE.getValue()?((authService.getUser().getId()==toNumber(szereles.szerelo))?'my-gantt-row':''):'closed-gantt-row'">
				   <td><span>{{szerelesIdx+1}}.</span></td>
					<td><span>{{szereles.projectModel.getName()}}</span></td>
					<td><span>{{szereles.projectModel.getPartnerModel().getNev()}}</span></td>
					<td><span>{{szereles.iranyitoszam}} {{szereles.varos}}{{(szereles.iranyitoszam!=null || szereles.varos!=null) && (szereles.utca!=null || szereles.hazszam!=null)?',':''}} {{szereles.utca}} {{szereles.hazszam}}</span></td>
					<td><span>{{szereles.szereloModel.getFullName()}}{{szereles.segitok > 0 ? ' + ' + szereles.segitok + ' fő' : ''}}</span></td>
					<td class="gantt-day" *ngFor="let ganttDay of ganttDays;" [ngClass]="isFreeday(ganttDay)?'gantt-freeday':''">
						<div *ngIf="isToday(ganttDay)" class="gantt-today no-print">
						 </div>
						 <div class="gnatt-work-day-wrapper" *ngIf="checkWorkDay(szereles.kezdes, szereles.befejezes, ganttDay); let workType">
							  <div class="gantt-work-day-grayscale" [ngClass]="{'start':'gantt-work-start','middle':'gantt-work-middle','end':'gantt-work-end','both':'gantt-work-both'}[workType]"></div>
							  <div class="gantt-work-day no-print" [ngClass]="{'start':'gantt-work-start','middle':'gantt-work-middle','end':'gantt-work-end','both':'gantt-work-both'}[workType]"></div>
						 </div>
					</td>

			   </tr>
		  </table>
	 </div>
</div>
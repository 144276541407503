export class LemezGyartoModel {

	private id: number = null;

	private gyarto: string = '';

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.gyarto = json && json.gyarto || '';

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getGyarto() {
		return this.gyarto;
	}

	setGyarto(value) {
		this.gyarto = value;
	}
}

import {CommonModel} from "src/app/model/common.model";
import {HabMinosegModel} from "src/app/components/settings/raktar/habminoseg/model/habminoseg.model";

export class HabSzintModel {

	private id: number = null;

	private habminosegid: number = null;
	private habminoseg: HabMinosegModel = new HabMinosegModel({});
	private idopont: any = null;
	private ameres: number = null;
	private bmeres: number = null;

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json, habminoseg: HabMinosegModel) {
		this.id = json && json.id || null;

		this.habminosegid = json && json.habminosegid || null;
		if (habminoseg != null) {
			this.habminoseg = habminoseg;
		} else {
			this.habminoseg = new HabMinosegModel(json && json.habminoseg || {});
		}
		this.idopont = json && json.idopont || null;
		this.idopont = CommonModel.isDateTime(this.idopont) ? (new Date(Date.parse(this.idopont))) : null;
		this.ameres = json && json.ameres || null;
		this.bmeres = json && json.bmeres || null;

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getHabminosegid() {
		return this.habminosegid;
	}

	setHabminosegid(value) {
		this.habminosegid = value;
	}

	getHabMinosegModel() {
		return this.habminoseg;
	}

	getIdopont() {
		return this.idopont;
	}

	setIdopont(value) {
		this.idopont = new Date(value);
	}

	getIdopontString() {
		if (this.idopont instanceof Date) {
			return (this.idopont.getFullYear() + "-" + ("0" + (this.idopont.getMonth() + 1)).substr(-2) + "-" + ("0" + this.idopont.getDate()).substr(-2) + " " + ("0" + this.idopont.getHours()).substr(-2) + ":" + ("0" + this.idopont.getMinutes()).substr(-2) + ":00");
		} else {
			return '';
		}
	}

	getAmeres() {
		return this.ameres;
	}

	setAmeres(value) {
		this.ameres = value;
	}

	getAmertKg(avalto: number, atartaly: number) {
		if (this.ameres >= 0 && avalto > 0 && atartaly > 0 && this.ameres <= atartaly) {
			return Math.round((atartaly - this.ameres) * avalto);
		} else {
			return 0;
		}
	}

	getAhianyKg(avalto: number, atartaly: number, bvalto: number, btartaly: number) {
		if (this.ameres >= 0 && this.bmeres >= 0 && avalto > 0 && atartaly > 0 && bvalto > 0 && btartaly > 0 && this.habminoseg.getBarany() > 0) {
			let amert = this.getAmertKg(avalto, atartaly);
			let bmert = this.getBmertKg(bvalto, btartaly);

			if (bmert > (amert * (this.habminoseg.getBarany() / 100))) {
				return Math.round((amert + bmert - this.getMixMennyisegeKg(avalto, atartaly, bvalto, btartaly)) / (this.habminoseg.getBarany() / 100));
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	getAhelyKg(avalto: number, atartaly: number) {
		if (this.ameres >= 0 && avalto > 0 && atartaly > 0 && this.ameres <= atartaly) {
			return Math.round(this.ameres * avalto);
		} else {
			return 0;
		}
	}

	getBmeres() {
		return this.bmeres;
	}

	setBmeres(value) {
		this.bmeres = value;
	}

	getBmertKg(bvalto: number, btartaly: number) {
		if (this.bmeres >= 0 && bvalto > 0 && btartaly > 0 && this.bmeres <= btartaly) {
			return Math.round((btartaly - this.bmeres) * bvalto);
		} else {
			return 0;
		}
	}

	getBhianyKg(avalto: number, atartaly: number, bvalto: number, btartaly: number) {
		if (this.ameres >= 0 && this.bmeres >= 0 && avalto > 0 && atartaly > 0 && bvalto > 0 && btartaly > 0 && this.habminoseg.getBarany() > 0) {
			let amert = this.getAmertKg(avalto, atartaly);
			let bmert = this.getBmertKg(bvalto, btartaly);

			if (bmert < (amert * (this.habminoseg.getBarany() / 100))) {
				return Math.round((amert + bmert - this.getMixMennyisegeKg(avalto, atartaly, bvalto, btartaly)) * (this.habminoseg.getBarany() / 100));
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	getBhelyKg(bvalto: number, btartaly: number) {
	console.log(btartaly);
		if (this.bmeres >= 0 && bvalto > 0 && btartaly > 0 && this.bmeres <= btartaly) {
			return Math.round(this.bmeres * bvalto);
		} else {
			return 0;
		}
	}

	getMixMennyisegeKg(avalto: number, atartaly: number, bvalto: number, btartaly: number) {
		if (this.ameres >= 0 && this.bmeres >= 0 && avalto > 0 && atartaly > 0 && bvalto > 0 && btartaly > 0 && this.habminoseg.getBarany() > 0) {
			let amert = this.getAmertKg(avalto, atartaly);
			let bmert = this.getBmertKg(bvalto, btartaly);

			if (bmert > (amert * (this.habminoseg.getBarany() / 100))) {
				return Math.floor(amert + (amert * (this.habminoseg.getBarany() / 100)));
			} else {
				return Math.floor(bmert + (bmert / (this.habminoseg.getBarany() / 100)));
			}
		} else {
			return 0;
		}
	}

	getNapraEleg(avalto: number, atartaly: number, bvalto: number, btartaly: number, fogyasztas: number) {
		let mix = this.getMixMennyisegeKg(avalto, atartaly, bvalto, btartaly);
		if (mix > 0) {
			return Math.floor(mix / fogyasztas);
		} else {
			return 0;
		}
	}
}

import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {GablerProjektInditasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.service';
import {GablerSzerelesService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszereles/gablerszereles.service';
import {SzerelesekService} from 'src/app/components/szereles/service/szerelesek.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {ListsModel} from 'src/app/model/lists.model';

export interface Defaults {
	emelogep: number;
	name: string;
	phone: string;
	email: string;
	contactconcat: string;
}

export interface Szereles {
	id: string;
	projectid: string;
	szerelo: number;
	kezdes: any;
	befejezes: any;
	iranyitoszam: string;
	varos: string;
	utca: string;
	hazszam: string;
	kontakt: string;
	telefon: string;
	email: string;
	szallitas: number;
	segitok: number;
	emelogep: any;
	eszkozigeny: string;
	megjegyzes: string;
	allapot: number;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
	editable: boolean;
}

export interface ReservedDate {
	date: string;
	count: number;
}

@Component({
	selector: 'form-gablerszereles',
	templateUrl: './gablerszereles.component.html',
	styleUrls: ['./gablerszereles.component.scss']
})
export class GablerSzerelesComponent {

	public static readonly TAG: string = 'form-gablerszereles';
	public static readonly NAME: string = 'Szerelés (Gabler)';
	public static readonly configComponent = null;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	defaults: Defaults = {
		emelogep: 0,
		name: "",
		email: "",
		phone: "",
		contactconcat: ""
	};
	formdata: Array<Szereles> = [];
	otherProjectSzereles: Array<Szereles> = [];

	szerelok: Array<UserModel> = [];
	filteredSzerelok: Array<UserModel> = [];

	selectedForm: number = 0;

	reservedDates: SafeHtml;
	freeDates: SafeHtml;

	freedays: Array<string> = [];

	constructor(
		public gablerProjektInditasService: GablerProjektInditasService,
		public gablerSzerelesService: GablerSzerelesService,
		public szerelesekService: SzerelesekService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private sanitizer: DomSanitizer
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.szerelesekService.getFreedays().subscribe(
			res => this.handleGetFreedaysResponse(res),
			error => this.handleGetFreedaysError(error)
		);

		this.increaseLoading.emit(null);
		this.gablerProjektInditasService.getDefaultsData(this.taskObject.getProjectModel().getId()).subscribe(
			res => this.handleGetDefaultsDataResponse(res),
			error => this.handleGetDefaultsDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpSzerelesek: Array<Szereles> = [];

		this.formdata.forEach((szereles) => {
			let tmpSzereles: Szereles = {...szereles};
			for (let key in tmpSzereles) {
				tmpSzereles[key] = this.transform(tmpSzereles[key]);
			}
			tmpSzerelesek.push(tmpSzereles);
		});

		return tmpSzerelesek;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerSzerelesService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	save() {
		let tmpSzerelesek: Array<Szereles> = this.dataTransform();
		let save: boolean = true;
		let nomoreask: boolean = false;

		tmpSzerelesek.forEach((szereles, idx) => {
			if (!save) {
				return;
			}

			if (!szereles.szerelo || !szereles.kezdes || !szereles.befejezes) {
				this.selectedForm = idx;

				this.alertDialogRef = this.dialog.open(AlertDialog, {
					disableClose: true,
					panelClass: 'alertDialogWindow',
					autoFocus: false
				});

				this.alertDialogRef.componentInstance.dialogMessage = "A szerelés vezető, kezdés és befejezés mezők kitöltése kötelező";

				save = false;
			}

			if (save && !nomoreask && this.checkFreedays(idx, false)) {
				this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
					disableClose: true,
					panelClass: 'confirmDialogWindow',
					autoFocus: false
				});
				this.confirmDialogRef.componentInstance.dialogMessage = "Megadott kezdő és befejező dátum között munkaszünetinap található. Biztosan menti a szereléseket?";

				this.confirmDialogRef.afterClosed().subscribe(result => {
					if (result) {
						nomoreask = true;
					} else {
						this.selectedForm = idx;
						save = false;
					}
					this.confirmDialogRef = null;
				});
			}
		});

		if (save) {
			this.increaseLoading.emit(null);
			this.gablerSzerelesService.save(this.taskObject.getId(), tmpSzerelesek).subscribe(
				res => this.handleSaveResponse(res),
				error => this.handleSaveError(error)
			);
		}
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				let tmpSzerelesek: Array<Szereles> = this.dataTransform();
				let save: boolean = true;
				let nomoreask: boolean = false;

				tmpSzerelesek.forEach((szereles, idx) => {
					if (!save) {
						return;
					}

					if (!szereles.szerelo || !szereles.kezdes || !szereles.befejezes) {
						this.selectedForm = idx;

						this.alertDialogRef = this.dialog.open(AlertDialog, {
							disableClose: true,
							panelClass: 'alertDialogWindow',
							autoFocus: false
						});

						this.alertDialogRef.componentInstance.dialogMessage = "A szerelés vezető, kezdés és befejezés mezők kitöltése kötelező";

						save = false;
					}

					if (save && !nomoreask && this.checkFreedays(idx, false)) {
						this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
							disableClose: true,
							panelClass: 'confirmDialogWindow',
							autoFocus: false
						});
						this.confirmDialogRef.componentInstance.dialogMessage = "Megadott kezdő és befejező dátum között munkaszünetinap található. Biztosan menti a szereléseket?";

						this.confirmDialogRef.afterClosed().subscribe(result => {
							if (result) {
								nomoreask = true;
							} else {
								this.selectedForm = idx;
								save = false;
							}
							this.confirmDialogRef = null;
						});
					}
				});

				if (save) {
					this.gablerSzerelesService.save(this.taskObject.getId(), tmpSzerelesek).subscribe(
						res => this.handleSaveDoneResponse(res),
						error => this.handleSaveDoneError(error)
					);
				}
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerSzerelesService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getfreedays
	handleGetFreedaysError(serverError) {
		this.decreaseLoading.emit(null);
	}

	handleGetFreedaysResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.freedays = [];

		for (let value of serverResponse.freedays) {
			this.freedays.push(value);
		}

		let styles: string = '';

		this.freedays.forEach((freeDate) => {
			styles += '.szerelesDatumPicker [aria-label="' + (new Date(freeDate).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric'})) + '"] span {text-decoration: underline; color: red;}';
		})

		this.freeDates = this.sanitizer.bypassSecurityTrustHtml('<style>' + styles + '</style>');
	}

	//getdefaultsdata
	handleGetDefaultsDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.defaults = {
			emelogep: (serverResponse.emelogep === true || serverResponse.emelogep == 1 || serverResponse.emelogep == 'true') ? 1 : 0,
			name: serverResponse.name || '',
			phone: serverResponse.phone || '',
			email: serverResponse.email || '',
			contactconcat: serverResponse.contactconcat || ''
		};

		this.increaseLoading.emit(null);
		this.gablerSzerelesService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleGetDefaultsDataError(serverError) {
		this.decreaseLoading.emit(null);

		this.increaseLoading.emit(null);
		this.gablerSzerelesService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.formdata = [];
		this.szerelok = [];

		for (let i = 0; i < serverResponse.szerelesek.length; i++) {
			let tmpSzereles: Szereles = {
				id: serverResponse.szerelesek[i].id,
				projectid: serverResponse.szerelesek[i].projectid,
				szerelo: Number(serverResponse.szerelesek[i].szerelo),
				kezdes: CommonModel.isDate(serverResponse.szerelesek[i].kezdes) ? (new Date(Date.parse(serverResponse.szerelesek[i].kezdes))) : null,
				befejezes: CommonModel.isDate(serverResponse.szerelesek[i].befejezes) ? (new Date(Date.parse(serverResponse.szerelesek[i].befejezes))) : null,
				iranyitoszam: serverResponse.szerelesek[i].iranyitoszam,
				varos: serverResponse.szerelesek[i].varos,
				utca: serverResponse.szerelesek[i].utca,
				hazszam: serverResponse.szerelesek[i].hazszam,
				kontakt: serverResponse.szerelesek[i].kontakt,
				telefon: serverResponse.szerelesek[i].telefon,
				email: serverResponse.szerelesek[i].email,
				szallitas: serverResponse.szerelesek[i].szallitas,
				segitok: serverResponse.szerelesek[i].segitok,
				emelogep: (serverResponse.szerelesek[i].emelogep === true || serverResponse.szerelesek[i].emelogep == 1 || serverResponse.szerelesek[i].emelogep == 'true') ? 1 : 0,
				eszkozigeny: serverResponse.szerelesek[i].eszkozigeny,
				megjegyzes: serverResponse.szerelesek[i].megjegyzes,
				allapot: serverResponse.szerelesek[i].allapot,
				modifiedby: serverResponse.szerelesek[i].modifiedby,
				modifiedtime: serverResponse.szerelesek[i].modifiedtime,
				deletedby: serverResponse.szerelesek[i].deletedby,
				deletedtime: serverResponse.szerelesek[i].deletedtime,
				editable: serverResponse.szerelesek[i].allapot == ListsModel.SzerelesAllapot_VEGLEGESITVE.getValue() ? false : true
			};

			if (Number(tmpSzereles.projectid) == this.taskObject.getProjectModel().getId()) {
				this.formdata.push(tmpSzereles);
			} else {
				this.otherProjectSzereles.push(tmpSzereles);
			}
		}

		for (let i = 0; i < serverResponse.szerelok.length; i++) {
			this.szerelok.push(new UserModel(serverResponse.szerelok[i]));
		}

		this.filteredSzerelok = this.szerelok.filter((obj) => {return true;});
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
		this.increaseLoading.emit(null);
		this.gablerSzerelesService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.gablerSzerelesService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

	szerelesSelected() {
		this.setReservedDates(this.formdata[this.selectedForm].szerelo);
	}

	szereloSelected(szerelesIdx, event) {
		this.formdata[szerelesIdx].kezdes = null;
		this.formdata[szerelesIdx].befejezes = null;
		this.setReservedDates(event.value);
	}

	addSzereles() {
		let tmpSzereles: Szereles = {
			id: null,
			projectid: null,
			szerelo: null,
			kezdes: null,
			befejezes: null,
			iranyitoszam: this.formdata.length > 0 ? this.formdata[this.formdata.length - 1].iranyitoszam : null,
			varos: this.formdata.length > 0 ? this.formdata[this.formdata.length - 1].varos : null,
			utca: this.formdata.length > 0 ? this.formdata[this.formdata.length - 1].utca : null,
			hazszam: this.formdata.length > 0 ? this.formdata[this.formdata.length - 1].hazszam : null,
			kontakt: this.formdata.length > 0 ? (this.formdata[this.formdata.length - 1].kontakt != null && this.formdata[this.formdata.length - 1].kontakt.length > 0 ? this.formdata[this.formdata.length - 1].kontakt : (this.defaults.name.length > 0 ? this.defaults.name : null)) : (this.defaults.name.length > 0 ? this.defaults.name : null),
			telefon: this.formdata.length > 0 ? (this.formdata[this.formdata.length - 1].telefon != null && this.formdata[this.formdata.length - 1].telefon.length > 0 ? this.formdata[this.formdata.length - 1].telefon : (this.defaults.phone.length > 0 ? this.defaults.phone : null)) : (this.defaults.phone.length > 0 ? this.defaults.phone : null),
			email: this.formdata.length > 0 ? (this.formdata[this.formdata.length - 1].email != null && this.formdata[this.formdata.length - 1].email.length > 0 ? this.formdata[this.formdata.length - 1].email : (this.defaults.email.length > 0 ? this.defaults.email : null)) : (this.defaults.email.length > 0 ? this.defaults.email : null),
			szallitas: null,
			segitok: null,
			emelogep: this.defaults.emelogep,
			eszkozigeny: null,
			megjegyzes: null,
			allapot: ListsModel.SzerelesAllapot_ELOJEGYEZVE.getValue(),
			modifiedby: null,
			modifiedtime: null,
			deletedby: null,
			deletedtime: null,
			editable: true
		};

		this.formdata.push(tmpSzereles);
		this.selectedForm = this.formdata.length - 1;
	}

	removeSzereles() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a kiválasztott szerelést?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (this.selectedForm >= 0 && this.formdata.length > 0) {
					if (this.formdata[this.selectedForm].editable || this.authService.isAdmin()) {
						let setReserved: boolean = true;
						if (this.formdata.length == this.selectedForm + 1) {
							setReserved = false;
						}

						this.formdata.splice(this.selectedForm, 1);

						if (setReserved) {
							this.setReservedDates(this.formdata[this.selectedForm - 1].szerelo);
						}
					} else {
						this.alertDialogRef = this.dialog.open(AlertDialog, {
							disableClose: true,
							panelClass: 'alertDialogWindow',
							autoFocus: false
						});

						this.alertDialogRef.componentInstance.dialogMessage = "Véglegesített szerelést csak rendszergazda törölhet";
					}
				}
			}
			this.confirmDialogRef = null;
		});
	}

	setKezdes(szerelesIdx, event) {
		this.formdata[szerelesIdx].kezdes = new Date(event);
		if (this.formdata[szerelesIdx].befejezes != null && typeof this.formdata[szerelesIdx].befejezes == 'object' && this.formdata[szerelesIdx].befejezes.constructor.name == 'Date') {
			if (this.formdata[szerelesIdx].kezdes.getTime() > this.formdata[szerelesIdx].befejezes.getTime()) {
				this.formdata[szerelesIdx].befejezes = new Date(event);
			}
		} else {
			this.formdata[szerelesIdx].befejezes = new Date(event);
		}
		this.setReservedDates(this.formdata[szerelesIdx].szerelo);

		this.checkFreedays(szerelesIdx, true);
	}

	setBefejezes(szerelesIdx, event) {
		this.formdata[szerelesIdx].befejezes = new Date(event);
		if (this.formdata[szerelesIdx].kezdes != null && typeof this.formdata[szerelesIdx].kezdes == 'object' && this.formdata[szerelesIdx].kezdes.constructor.name == 'Date') {
			if (this.formdata[szerelesIdx].kezdes.getTime() > this.formdata[szerelesIdx].befejezes.getTime()) {
				this.formdata[szerelesIdx].kezdes = new Date(event);
			}
		} else {
			this.formdata[szerelesIdx].kezdes = new Date(event);
		}
		this.setReservedDates(this.formdata[szerelesIdx].szerelo);

		this.checkFreedays(szerelesIdx, true);
	}

	setReservedDates(userid) {
		let reservedDates: Array<ReservedDate> = [];

		let allData: Array<Szereles> = this.formdata.concat(this.otherProjectSzereles);

		allData.forEach((szereles) => {
			if (szereles.kezdes != null && typeof szereles.kezdes == 'object' && szereles.kezdes.constructor.name == 'Date' &&
				szereles.befejezes != null && typeof szereles.befejezes == 'object' && szereles.befejezes.constructor.name == 'Date' &&
				userid != null && szereles.szerelo == userid) {
				let tmpKezdes = new Date(szereles.kezdes);
				let tmpBefejezes = new Date(szereles.befejezes);
				let diff = CommonModel.dateDiff(tmpKezdes, tmpBefejezes);
				if (diff > 0) {
					diff++;
				}
				do {
					let tmpValue = tmpKezdes.toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric'});
					let found: boolean = false;
					for (let i = 0; i < reservedDates.length; i++) {
						if (tmpValue == reservedDates[i].date) {
							found = true;
							reservedDates[i].count = reservedDates[i].count + 1;
							break;
						}
					}

					if (!found) {
						reservedDates.push({date: tmpValue, count: 1});
					}
					tmpKezdes.setDate(tmpKezdes.getDate() + 1);
					diff--;
				} while (diff > 0)
			}
		});

		let styles: string = '';

		reservedDates.forEach((reservedDate) => {
			if (reservedDate.count == 1) {
				styles += '.szerelesDatumPicker [aria-label="' + reservedDate.date + '"] {background: lightgreen;}';
			} else if (reservedDate.count == 2) {
				styles += '.szerelesDatumPicker [aria-label="' + reservedDate.date + '"] {background: lightsalmon;}';
				styles += '.szerelesDatumPicker [aria-label="' + reservedDate.date + '"] span {color: black !important;}';
			} else {
				styles += '.szerelesDatumPicker [aria-label="' + reservedDate.date + '"] {background: #ff0000;}';
				styles += '.szerelesDatumPicker [aria-label="' + reservedDate.date + '"] span {color: black !important;}';
			}
		})

		this.reservedDates = this.sanitizer.bypassSecurityTrustHtml('<style>' + styles + '</style>');
	}

	checkFreedays(szerelesIdx, withAlert: boolean) {
		if (this.formdata[szerelesIdx].kezdes != null && typeof this.formdata[szerelesIdx].kezdes == 'object' && this.formdata[szerelesIdx].kezdes.constructor.name == 'Date' &&
			this.formdata[szerelesIdx].befejezes != null && typeof this.formdata[szerelesIdx].befejezes == 'object' && this.formdata[szerelesIdx].befejezes.constructor.name == 'Date') {
			if (this.formdata[szerelesIdx].kezdes.getTime() <= this.formdata[szerelesIdx].befejezes.getTime()) {
				let tmpDate = new Date(this.formdata[szerelesIdx].kezdes);
				let found: boolean = false;
				let valueText = '';

				do {
					valueText = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2));
					for (let freeday of this.freedays) {
						if (freeday == valueText || found) {
							found = true;
							break;
						}
					}
					tmpDate.setDate(tmpDate.getDate() + 1);
				} while (tmpDate.getTime() <= this.formdata[szerelesIdx].befejezes.getTime() && !found);

				if (withAlert && found) {
					this.alertDialogRef = this.dialog.open(AlertDialog, {
						disableClose: true,
						panelClass: 'alertDialogWindow',
						autoFocus: false
					});

					this.alertDialogRef.componentInstance.dialogMessage = "Megadott kezdő és befejező dátum között munkaszünetinap található";
				}
				return found;
			}
		}
	}

	filterSzerelok(val) {
		this.filteredSzerelok = this.szerelok.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
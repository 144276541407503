<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <div style="text-align: right;">
					<button mat-raised-button color="primary" (click)="addSzereles()" *ngIf="writeable">Új szerelés</button>
					<button mat-raised-button color="warn" (click)="removeSzereles()" *ngIf="writeable">Szerelés törlése</button>
			   </div>
			   <mat-tab-group [(selectedIndex)]="selectedForm" (selectedTabChange)="szerelesSelected()">
					<mat-tab *ngFor="let szereles of formdata; let szerelesIdx = index" [label]="(szerelesIdx+1)+'. szerelés'">
						<div class="szereles-wrapper px-2 py-2">
							  <div class="col-6 mat-elevation-z4 my-2 py-4">
								   <form>
										<mat-form-field appearance="standard">
											 <mat-label>Szerelés vezető</mat-label>
											 <mat-select name="inputSzerelesvezeto" [(ngModel)]="szereles.szerelo" (selectionChange)="szereloSelected(szerelesIdx, $event)" (openedChange)="szereloFilter.value=null; filterSzerelok(null);" [disabled]="!writeable || !szereles.editable">
														 <input matInput #szereloFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterSzerelok($event.target.value)" (keydown)="$event.stopPropagation()" />
													<mat-option *ngFor="let szerelo of filteredSzerelok | customsort:'getFullName'" [value]="szerelo.getId()">{{szerelo.getFullName()}}</mat-option>
											 </mat-select>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Kezdés</mat-label>
											 <input matInput name="inputKezdes" [ngModel]="szereles.kezdes" (ngModelChange)="setKezdes(szerelesIdx, $event)" [owlDateTime]="inputKezdesPicker" [owlDateTimeTrigger]="inputKezdesPicker" [disabled]="!writeable || !szereles.editable || !szereles.szerelo">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputKezdesPicker panelClass="szerelesDatumPicker"></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Befejezés</mat-label>
											 <input matInput name="inputBefejezes" [ngModel]="szereles.befejezes" (ngModelChange)="setBefejezes(szerelesIdx, $event)" [owlDateTime]="inputBefejezesPicker" [owlDateTimeTrigger]="inputBefejezesPicker" [disabled]="!writeable || !szereles.editable || !szereles.kezdes || !szereles.szerelo">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputBefejezesPicker panelClass="szerelesDatumPicker"></owl-date-time>
										</mat-form-field>
										<br>
										<!-- <mat-checkbox name="inputSzallitas" [(ngModel)]="szereles.szallitas" [disabled]="!writeable || !szereles.editable">Szállítással</mat-checkbox>
										<br> -->
										<mat-form-field appearance="standard">
											 <mat-label>Segítők száma</mat-label>
											 <input matInput name="inputSegitok" type="number" step="1" [(ngModel)]="szereles.segitok" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-checkbox name="inputEmelogep" [(ngModel)]="szereles.emelogep" [disabled]="!writeable || !szereles.editable">Emelőgép kell a szereléshez?</mat-checkbox>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Eszközigény</mat-label>
											 <textarea matInput name="inputEszkozigeny" rows="5" [(ngModel)]="szereles.eszkozigeny" [disabled]="!writeable || !szereles.editable"></textarea>
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Megjegyzés</mat-label>
											 <textarea matInput name="inputMegjegyzes" rows="5" [(ngModel)]="szereles.megjegyzes" [disabled]="!writeable || !szereles.editable"></textarea>
										</mat-form-field>
								   </form>
							  </div>
							  <div class="col-6 mat-elevation-z4 my-2 py-4">
								   <form>
										<mat-form-field appearance="standard">
											 <mat-label>Irányítószám</mat-label>
											 <input matInput name="inputIranyitoszam" [(ngModel)]="szereles.iranyitoszam" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Város</mat-label>
											 <input matInput name="inputVaros" [(ngModel)]="szereles.varos" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Utca</mat-label>
											 <input matInput name="inputUtca" [(ngModel)]="szereles.utca" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Házszám</mat-label>
											 <input matInput name="inputHazszam" [(ngModel)]="szereles.hazszam" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Építés vezető</mat-label>
											 <input matInput name="inputKontakt" [(ngModel)]="szereles.kontakt" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Telefon</mat-label>
											 <input matInput name="inputTelefon" [(ngModel)]="szereles.telefon" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>E-mail</mat-label>
											 <input matInput name="inputEmail" [(ngModel)]="szereles.email" [disabled]="!writeable || !szereles.editable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Állapot</mat-label>
											 <mat-select name="inputAllapot" [(ngModel)]="szereles.allapot" [disabled]="!writeable || !szereles.editable">
												  <mat-option *ngFor="let szerelesallapot of listsModel.SzerelesAllapotList | customsort:'getName'" [value]="szerelesallapot.getValue()">{{szerelesallapot.getName()}}</mat-option>
											 </mat-select>
										</mat-form-field>
								   </form>
							  </div>
						 </div>
					</mat-tab>
			   </mat-tab-group>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" *ngIf="writeable">Mentés</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
<div [innerHTML]="reservedDates"></div>
<div [innerHTML]="freeDates"></div>
import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezRaktarService} from 'src/app/components/settings/raktar/lemezraktar/service/lemezraktar.service';
import {LemezTaroloService} from 'src/app/components/settings/raktar/lemeztarolo/service/lemeztarolo.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {LemezRaktarModel} from 'src/app/components/settings/raktar/lemezraktar/model/lemezraktar.model';
import {LemezTaroloModel} from 'src/app/components/settings/raktar/lemeztarolo/model/lemeztarolo.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: LemezTaroloService.MOD_TAG,
	templateUrl: './modlemeztarolo.component.html',
	styleUrls: ['./modlemeztarolo.component.scss']
})
export class ModLemezTaroloComponent implements OnDestroy {

	public static readonly TAG = LemezTaroloService.MOD_TAG;
	public static readonly NAME = "Lemez Tároló módosítása";
	getSettingName() {return ModLemezTaroloComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: LemezTaroloModel = new LemezTaroloModel({});
	lemezraktarak: Array<LemezRaktarModel> = [];

	constructor(
		private lemezRaktarService: LemezRaktarService,
		private lemezTaroloService: LemezTaroloService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.lemezRaktarService.getAll().subscribe(
				res => this.handleGetLemezRaktarakResponse(res),
				error => this.handleGetLemezRaktarakError(error)
			);
			this.loading++;
			this.lemezTaroloService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetLemezRaktarakError(serverError) {
		this.handleError(serverError);
	}

	handleGetLemezRaktarakResponse(serverResponse) {
		this.loading--;
		this.lemezraktarak = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.lemezraktarak.push(new LemezRaktarModel(serverResponse[i]));
		}
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new LemezTaroloModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.lemezTaroloService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODLEMEZTAROLO_LEMEZTAROLOID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODLEMEZTAROLO_LEMEZTAROLOID);
	}

	back() {
		this.loadSettingComponent.emit(LemezTaroloService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
<mat-expansion-panel *ngIf="loaded">
	 <mat-expansion-panel-header>
		  <mat-panel-title>
			   További adatok
		  </mat-panel-title>
	 </mat-expansion-panel-header>
	 <table class="table">
		  <thead>
			   <tr>
					<th *ngFor="let header of tableHeader">{{header}}</th>
			   </tr>
		  </thead>
		  <tbody>
			   <tr *ngFor="let row of tableData">
				   <td *ngFor="let data of row">{{data}}</td>
			   </tr>
		  </tbody>
	 </table>
</mat-expansion-panel>
<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Lemez áthelyezés</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field>
						 <mat-label>Időpont</mat-label>
						 <input matInput name="inputIdopont" [(ngModel)]="idopont" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" required>
						 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
					</mat-form-field>
					<br>
					<mat-form-field>
						 <mat-label>Forrás raktár/tároló</mat-label>
						 <mat-select name="inputOld" [(ngModel)]="oldlemeztaroloid" (openedChange)="foglalttaroloFilter.value=null; filterFoglalttarolok(null);" required>
									 <input matInput #foglalttaroloFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterFoglalttarolok($event.target.value)" (keydown)="$event.stopPropagation()" />
								<mat-option *ngFor="let foglalttarolo of filteredFoglalttarolok | customsort:'getRaktarTarolo'" [value]="foglalttarolo.getId()">{{foglalttarolo.getRaktarTarolo()}}</mat-option>
						 </mat-select>
					</mat-form-field>
					<br>
					<mat-form-field>
						 <mat-label>Áthelyezés típusa</mat-label>
						 <mat-select name="inputMozgastipus" [(ngModel)]="mozgastipus" required>
							  <mat-option value="A">Áthelyezés</mat-option>
							  <mat-option value="E">Eladás</mat-option>
						 </mat-select>
					</mat-form-field>
					<br>
					<mat-form-field *ngIf="mozgastipus=='A'">
						 <mat-label>Cél raktár/tároló</mat-label>
						 <mat-select name="inputNew" [(ngModel)]="newlemeztaroloid" (openedChange)="szabadtaroloFilter.value=null; filterSzabadtarolok(null);" required>
									 <input matInput #szabadtaroloFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterSzabadtarolok($event.target.value)" (keydown)="$event.stopPropagation()" />
								<mat-option *ngFor="let szabadtarolo of filteredSzabadtarolok | customsort:'getRaktarTarolo'" [value]="szabadtarolo.getId()">{{szabadtarolo.getRaktarTarolo()}}</mat-option>
						 </mat-select>
					</mat-form-field>
			   </form>
		  </mat-card-content>
		  <mat-card-actions style="text-align: right;">
			   <button mat-raised-button (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
			   <button mat-raised-button routerLink="/raktar">Mégsem</button>
		  </mat-card-actions>
	 </mat-card>
</div>

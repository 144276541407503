<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Hab bevételezése</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field>
						 <mat-label>Időpont</mat-label>
						 <input matInput name="inputIdopont" [ngModel]="targetObj.getIdopont()" (ngModelChange)="targetObj.setIdopont($event)" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" required>
						 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
					</mat-form-field>
					<br>
					<mat-form-field appearance="standard">
						 <mat-label>Szállítólevél</mat-label>
						 <input matInput name="inputSzallitolevel" [ngModel]="targetObj.getSzallitolevel()" (ngModelChange)="targetObj.setSzallitolevel($event)">
					</mat-form-field>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>A komponens</mat-card-subtitle>
						 <mat-form-field appearance="standard">
							  <mat-label>Szállított mennyiség (Kg)</mat-label>
							  <input matInput name="inputAmennyiseg" type="number" step="1" min="0" [ngModel]="targetObj.getAmennyiseg()" (ngModelChange)="targetObj.setAmennyiseg($event)" required>
						 </mat-form-field>
						 <mat-form-field appearance="standard">
							  <mat-label>Nettó díj (Ft)</mat-label>
							  <input matInput name="inputAnetto" type="number" step="1" min="0" [ngModel]="targetObj.getAnetto()" (ngModelChange)="targetObj.setAnetto($event)" required>
						 </mat-form-field>
						 <div class="row" style="justify-content: space-around;">
							  <div class="col-5">
								   <mat-form-field appearance="standard">
										<mat-label>mérés (mm)</mat-label>
										<input matInput name="inputAmeres" type="number" step="1" min="0" [ngModel]="targetObj.getAmeres()" (ngModelChange)="targetObj.setAmeres($event)" required>
								   </mat-form-field>
							  </div>
							  <div class="col-5" *ngIf="targetObj.getAmeres() != null">
								   <mat-label>mért: {{ targetObj.getAmertKg(raktarModel.HabAvalto, raktarModel.HabAtartaly) }} kg</mat-label>
							  </div>
							  <div class="col-5" *ngIf="targetObj.getAmeres() == null">
								   <mat-label>mért: - kg</mat-label>
							  </div>
						 </div>
					</div>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>B komponens</mat-card-subtitle>
						 <mat-form-field appearance="standard">
							  <mat-label>Szállított mennyiség (Kg)</mat-label>
							  <input matInput name="inputBmennyiseg" type="number" step="1" min="0" [ngModel]="targetObj.getBmennyiseg()" (ngModelChange)="targetObj.setBmennyiseg($event)" required>
						 </mat-form-field>
						 <mat-form-field appearance="standard">
							  <mat-label>Nettó díj (Ft)</mat-label>
							  <input matInput name="inputBnetto" type="number" step="1" min="0" [ngModel]="targetObj.getBnetto()" (ngModelChange)="targetObj.setBnetto($event)" required>
						 </mat-form-field>
						 <div class="row" style="justify-content: space-around;">
							  <div class="col-5">
								   <mat-form-field appearance="standard">
										<mat-label>mérés (mm)</mat-label>
										<input matInput name="inputBmeres" type="number" step="1" min="0" [ngModel]="targetObj.getBmeres()" (ngModelChange)="targetObj.setBmeres($event)" required>
								   </mat-form-field>
							  </div>
							  <div class="col-5" *ngIf="targetObj.getBmeres() != null">
								   <mat-label>mért: {{ targetObj.getBmertKg(raktarModel.HabAvalto, raktarModel.HabAtartaly) }} kg</mat-label>
							  </div>
							  <div class="col-5" *ngIf="targetObj.getBmeres() == null">
								   <mat-label>mért: - kg</mat-label>
							  </div>
						 </div>
					</div>
			   </form>
		  </mat-card-content>
		  <mat-card-actions style="text-align: right;">
			   <button mat-raised-button (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
			   <button mat-raised-button routerLink="/raktar">Mégsem</button>
		  </mat-card-actions>
	 </mat-card>
</div>

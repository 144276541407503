import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router, Event, NavigationEnd, ActivatedRoute} from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {ProfileDialog} from 'src/app/dialog/profile-dialog/profile-dialog';
import {SettingsDialog} from 'src/app/components/settings/settings-dialog/settings-dialog';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	profileDialogRef: MatDialogRef<ProfileDialog>;
	settingsDialogRef: MatDialogRef<SettingsDialog>;

	url: string = '/feladatok';

	loggedIn: boolean;
	name: string = '';

	enabled: boolean = false;

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		public dialog: MatDialog
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.url = event.urlAfterRedirects;
				this.enabled = this.route.root.firstChild.snapshot.data['navbar'];
			}
		});
	}

	ngOnInit() {
		this.authService.authStatus.subscribe(value => this.loggedIn = value);
		this.authService.authUser.subscribe(value => this.name = value.getFullName());
	}

	openProfile() {
		this.profileDialogRef = this.dialog.open(ProfileDialog, {
			disableClose: true,
			panelClass: 'profileDialogWindow',
			autoFocus: false
		});
	}

	openSettings() {
		this.settingsDialogRef = this.dialog.open(SettingsDialog, {
			disableClose: true,
			panelClass: 'settingsDialogWindow',
			autoFocus: false
		});
	}
	
	openFlowchart() {
		window.location.href = AppConstants.BASE_URL + "/flowchart"
	}

}

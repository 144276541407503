<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Feladat összerendelés</mat-label>
			   <mat-select name="inputTaskref" [ngModel]="targetObj.getTaskrefid()" (ngModelChange)="targetObj.setTaskrefid($event)" (openedChange)="taskrefFilter.value=null; filterTaskrefs(null);" required>
						   <input matInput #taskrefFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterTaskrefs($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let taskref of filteredTaskrefs" [value]="taskref.getId()">{{taskref.getParentModel().getName()}} -><br/>{{taskref.getChildModel().getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Feltétel</mat-label>
			   <textarea matInput name="inputCondition" rows="5" [ngModel]="targetObj.getCondition()" (ngModelChange)="targetObj.setCondition($event)" required></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Leírás</mat-label>
			   <textarea matInput name="inputDesc" rows="3" [ngModel]="targetObj.getDesc()" (ngModelChange)="targetObj.setDesc($event)"></textarea>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {JovahagyasService} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/jovahagyas.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

export interface Config {
	donteslabel: string;
	dontesidopontjalabel: string;
	donequery: string;
}

@Component({
	selector: 'config-jovahagyas',
	templateUrl: './jovahagyasconfig.component.html',
	styleUrls: ['./jovahagyasconfig.component.scss']
})
export class JovahagyasConfigComponent {

	public static readonly TAG: string = 'config-jovahagyas';
	public static readonly NAME: string = 'Jóváhagyás űrlap beállítások';

	@Output() closeParentDialog = new EventEmitter<string>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	alertDialogRef: MatDialogRef<AlertDialog>;
	templateId: number;

	formdata: Config = {
		donteslabel: null,
		dontesidopontjalabel: null,
		donequery: null
	};

	constructor(
		public jovahagyasService: JovahagyasService,
		public dialog: MatDialog
	) {}

	setTemplateId(templateId: number) {
		this.templateId = templateId;

		this.increaseLoading.emit(null);
		this.jovahagyasService.getConf(this.templateId).subscribe(
			res => this.handleGetConfResponse(res),
			error => this.handleGetConfError(error)
		);
	}

	save() {
		this.increaseLoading.emit(null);
		this.jovahagyasService.setConf(this.templateId, this.formdata).subscribe(
			res => this.handleSetConfResponse(res),
			error => this.handleSetConfError(error)
		);
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
		this.alertDialogRef.afterClosed().subscribe(result => {
			this.alertDialogRef = null;
			this.closeParentDialog.emit(null);
		});
	}

	handleGetConfResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.formdata = serverResponse;
	}

	handleGetConfError(serverError) {
		this.handleError(serverError);
	}

	handleSetConfResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.closeParentDialog.emit(null);
	}

	handleSetConfError(serverError) {
		this.handleError(serverError);
	}

}
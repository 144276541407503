export class LemezVastagsagModel {

	private id: number = null;

	private vastagsag: number = null;

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.vastagsag = json && json.vastagsag || null;

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getVastagsag() {
		return this.vastagsag;
	}

	setVastagsag(value) {
		this.vastagsag = value;
	}
}

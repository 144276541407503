<div class="loading-wrapper">
	<div class="loading-shade" *ngIf="loading!=0">
		 <mat-spinner></mat-spinner>
	</div>
	<mat-toolbar>
		 <mat-toolbar-row>
			  <span>{{ getSettingName() }}</span>
		 </mat-toolbar-row>
	</mat-toolbar>
	<form #targetForm="ngForm">
		    <mat-form-field appearance="standard">
			   <mat-label>Szállítólevél </mat-label>
			   <input matInput name="inputSzallitolevel" [ngModel]="targetObj.getSzallitolevel()" (ngModelChange)="targetObj.setSzallitolevel($event)" disabled>
		  	</mat-form-field>
		    <br>
			<mat-form-field appearance="standard">
				<mat-label>Habszint mérés időpontja előtte</mat-label>
				<mat-select name="inputHabszintidelotte" [ngModel]="targetObj.getHabSzintidelotte()" (ngModelChange)="targetObj.setHabSzintidelotte($event)" disabled>
					<mat-option *ngFor="let habszint of habszintekElotte" [value]="habszint.getId()">{{habszint.getIdopontString()}}</mat-option>
				</mat-select>
			</mat-form-field>
			<br/>
			<mat-form-field appearance="standard">
				<mat-label>Habszint mérés időpontja utána</mat-label>
				<mat-select name="inputHabszintidutana" [ngModel]="targetObj.getHabSzintidutana()" (ngModelChange)="targetObj.setHabSzintidutana($event)" disabled>
					<mat-option *ngFor="let habszint of habszintekUtana" [value]="habszint.getId()">{{habszint.getIdopontString()}}</mat-option>
				</mat-select>
			</mat-form-field>
			<br/>
		  	<mat-form-field appearance="standard">
				<mat-label>A mennyiség</mat-label>
				<input matInput name="inputAmennyiseg" [ngModel]="targetObj.getAmennyiseg()" (ngModelChange)="targetObj.setAmennyiseg($event)" disabled>
	   	  	</mat-form-field>
		  	<br/>
		  	<mat-form-field appearance="standard">
				<mat-label>A nettó</mat-label>
				<input matInput name="inputAnetto" [ngModel]="targetObj.getAnetto()" (ngModelChange)="targetObj.setAnetto($event)" disabled>
			</mat-form-field>
			<br>
			<mat-form-field appearance="standard">
				<mat-label>B mennyiség</mat-label>
				<input matInput name="inputBmennyiseg" [ngModel]="targetObj.getBmennyiseg()" (ngModelChange)="targetObj.setBmennyiseg($event)" disabled>
			</mat-form-field>
	   	  	<br/>
			<mat-form-field appearance="standard">
				<mat-label>B nettó</mat-label>
				<input matInput name="inputBnetto" [ngModel]="targetObj.getBnetto()" (ngModelChange)="targetObj.setBnetto($event)" disabled>
			</mat-form-field>
			<br>
		  	<mat-form-field>
				<mat-label>Időpont</mat-label>
				<input matInput name="inputIdopont" [ngModel]="targetObj.getIdopont()" (ngModelChange)="targetObj.setIdopont($event)" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" disabled>
				<owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
		  	</mat-form-field>
	 </form>
	<div class="dialogButtons">
		 <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		 <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	</div>
</div>
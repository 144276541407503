import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskConditionModel} from 'src/app/components/settings/taskcondition/model/taskcondition.model';

@Injectable()
export class TaskConditionService {

	public static readonly API_TYPE: string = 'taskcondition';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + TaskConditionService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + TaskConditionService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + TaskConditionService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + TaskConditionService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + TaskConditionService.API_TYPE + '/del_request';

	public static readonly LIST_TAG: string = 'setting-taskconditions';
	public static readonly ADD_TAG: string = 'setting-addtaskcondition';
	public static readonly MOD_TAG: string = 'setting-modtaskcondition';
	public static readonly DEL_TAG: string = 'setting-deltaskcondition';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskConditionService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskConditionService.GET_API, params, {params: parameters});
	}

	add(obj: TaskConditionModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tc_taskrefid', String(obj.getTaskrefid()));
		params.append('tc_condition', obj.getCondition());
		params.append('tc_desc', obj.getDesc());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskConditionService.ADD_API, params, {params: parameters});
	}

	mod(obj: TaskConditionModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('tc_taskrefid', String(obj.getTaskrefid()));
		params.append('tc_condition', obj.getCondition());
		params.append('tc_desc', obj.getDesc());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskConditionService.MOD_API, params, {params: parameters});
	}

	del(objid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(objid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskConditionService.DEL_API, params, {params: parameters});
	}
}

import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzintService} from 'src/app/components/raktar/service/habszint.service';

import {HabSzintModel} from 'src/app/components/raktar/model/habszint.model';
import {HabMinosegModel} from 'src/app/components/settings/raktar/habminoseg/model/habminoseg.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: HabSzintService.ADD_TAG,
	templateUrl: './addhabszint.component.html',
	styleUrls: ['./addhabszint.component.scss']
})
export class AddHabSzintComponent {

	public static readonly TAG = HabSzintService.ADD_TAG;
	public static readonly NAME = "Habszint létrehozása";
	getSettingName() {return AddHabSzintComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: HabSzintModel = new HabSzintModel({},new HabMinosegModel({}));

	constructor(
		private habSzintService: HabSzintService,
		public dialog: MatDialog
	) {}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	submit() {
		this.loading++;
		this.habSzintService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(HabSzintService.LIST_TAG);
	}

}
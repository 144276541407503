import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezRaktarComponent} from 'src/app/components/settings/raktar/lemezraktar/component/addlemezraktar/addlemezraktar.component';
import {ModLemezRaktarComponent} from 'src/app/components/settings/raktar/lemezraktar/component/modlemezraktar/modlemezraktar.component';
import {LemezRaktarakComponent} from 'src/app/components/settings/raktar/lemezraktar/component/lemezraktarak/lemezraktarak.component';

import {LemezRaktarService} from 'src/app/components/settings/raktar/lemezraktar/service/lemezraktar.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezRaktarComponent,
		ModLemezRaktarComponent,
		LemezRaktarakComponent
	],
	entryComponents: [
		AddLemezRaktarComponent,
		ModLemezRaktarComponent,
		LemezRaktarakComponent
	],
	providers: [
		LemezRaktarService
	],
	exports: [
		AddLemezRaktarComponent,
		ModLemezRaktarComponent,
		LemezRaktarakComponent
	],
})
export class LemezRaktarModule {
	public static readonly compArray = [
		AddLemezRaktarComponent,
		ModLemezRaktarComponent,
		LemezRaktarakComponent
	];

	public static readonly compMap = LemezRaktarModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezRaktarModule.compMap) ? LemezRaktarModule.compMap[TAG].NAME : '';
	}
}
import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {UserService} from 'src/app/components/settings/user/service/user.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

@Component({
	selector: UserService.LIST_TAG,
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent {

	public static readonly TAG = UserService.LIST_TAG;
	public static readonly NAME = "Felhasználók";
	getSettingName() {return UsersComponent.NAME;}

	public static readonly _requiredPermissions = ['Modules:User:list', 'Modules:User:add', 'Modules:User:mod'];
	public static readonly _adminOnly = false;
	public static readonly _icon = ['fas', 'users'];
	public static readonly _category = "Szervezet";

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	displayedColumns: string[] = ['id', 'username', 'u_lastname', 'u_firstname', 'u_email', 'u_phone', 'action'];
	dataSource: MatTableDataSource<UserModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	filterValue: string = "";

	constructor(
		private userService: UserService,
		public dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getUsername(),
				row.getLastName(),
				row.getFirstName(),
				row.getEmail(),
				row.getPhone()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<UserModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new UserModel(serverResponse[i]));
		}

		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'username': return item.getUsername();
				case 'u_lastname': return item.getLastName();
				case 'u_firstname': return item.getFirstName();
				case 'u_email': return item.getEmail();
				case 'u_phone': return item.getPhone();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.userService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	add() {
		this.loadSettingComponent.emit(UserService.ADD_TAG);
	}

	mod(userid) {
		sessionStorage.setItem(AppConstants.PARAM_MODUSER_USERID, userid);
		this.loadSettingComponent.emit(UserService.MOD_TAG);
	}

}

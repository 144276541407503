export class ListItemModel {

	private value: number = null;
	private name: string = '';

	constructor(value: number, name: string) {
		this.value = value;
		this.name = name;
	}

	getName() {
		return this.name;
	}

	getValue() {
		return this.value;
	}
}

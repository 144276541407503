import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import { HabSzintModel } from 'src/app/components/raktar/model/habszint.model';


@Component({
	selector: HabSzallitasService.DEL_TAG,
	templateUrl: './delhabszallitas.component.html',
	styleUrls: ['./delhabszallitas.component.scss']
})
export class DelHabSzallitasComponent implements OnDestroy {

	public static readonly TAG = HabSzallitasService.DEL_TAG;
	public static readonly NAME = "Habszállítás törlése";
	getSettingName() {return DelHabSzallitasComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: HabSzallitasModel = new HabSzallitasModel({});
	habszintekElotte:Array<HabSzintModel>=[];
	habszintekUtana:Array<HabSzintModel>=[];
	constructor(
		private habSzallitasService: HabSzallitasService,
		public dialog: MatDialog
	) {
		if (!this.getDelID()) {
			this.back();
		} else {
			this.loading++;
			this.habSzallitasService.get(Number(this.getDelID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new HabSzallitasModel(serverResponse.habszallitas);
		for(let i=0; i<serverResponse.habszintekelotte.length; i++){
			this.habszintekElotte.push(new HabSzintModel(serverResponse.habszintekelotte[i],serverResponse.habszintekelotte[i].habminoseg))
		}
		for(let i=0; i<serverResponse.habszintekutana.length; i++){
			this.habszintekUtana.push(new HabSzintModel(serverResponse.habszintekutana[i],serverResponse.habszintekutana[i].habminoseg))
		}
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a habszallitaset?";	
		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.habSzallitasService.del(this.targetObj.getId()).subscribe(
					res => this.handleDeleteResponse(res),
					error => this.handleDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}
	

	handleDeleteResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleDeleteError(serverError) {
		this.handleError(serverError);
	}

	getDelID() {
		return sessionStorage.getItem(AppConstants.PARAM_DELHABSZALLITAS_HABSZALLITASID);
	}

	delDelID() {
		sessionStorage.removeItem(AppConstants.PARAM_DELHABSZALLITAS_HABSZALLITASID);
	}

	back() {
		this.loadSettingComponent.emit(HabSzallitasService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delDelID();
	}

}
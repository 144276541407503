<mat-expansion-panel *ngIf="loaded">
	 <mat-expansion-panel-header>
		  <mat-panel-title>
			   További adatok
		  </mat-panel-title>
	 </mat-expansion-panel-header>
	 <table class="table">
		  <tr *ngFor="let row of tableData">
			  <th>{{row.header}}</th>
			   <td *ngFor="let field of row.data">{{field}}</td>
		  </tr>
	 </table>
</mat-expansion-panel>
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddCategoryComponent} from 'src/app/components/settings/category/component/addcategory/addcategory.component';
import {ModCategoryComponent} from 'src/app/components/settings/category/component/modcategory/modcategory.component';
import {CategoriesComponent} from 'src/app/components/settings/category/component/categories/categories.component';

import {CategoryService} from 'src/app/components/settings/category/service/category.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddCategoryComponent,
		ModCategoryComponent,
		CategoriesComponent
	],
	entryComponents: [
		AddCategoryComponent,
		ModCategoryComponent,
		CategoriesComponent
	],
	providers: [
		CategoryService
	],
	exports: [
		AddCategoryComponent,
		ModCategoryComponent,
		CategoriesComponent
	],
})
export class CategoryModule {
	public static readonly compArray = [
		AddCategoryComponent,
		ModCategoryComponent,
		CategoriesComponent
	];

	public static readonly compMap = CategoryModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in CategoryModule.compMap) ? CategoryModule.compMap[TAG].NAME : '';
	}
}
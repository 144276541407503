<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>Új feladat</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <mat-dialog-content>
		  <form #targetForm="ngForm">
			   <mat-form-field appearance="standard">
					<mat-label>Projekt</mat-label>
					<mat-select name="inputProject" [(ngModel)]="projectId" (openedChange)="projectFilter.value=null; filterProjects(null);" required>
								<input matInput #projectFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterProjects($event.target.value)" (keydown)="$event.stopPropagation()" />
						   <mat-option *ngFor="let project of filteredProjects | customsort:'getName'" [value]="project.getId()">{{project.getName()}}</mat-option>
					</mat-select>
			   </mat-form-field>
			   <br>
			   <mat-form-field appearance="standard">
					<mat-label>Feladat sablon</mat-label>
					<mat-select name="inputTemplate" [(ngModel)]="templateId" (ngModelChange)="templateChanged()" (openedChange)="templateFilter.value=null; filterTemplates(null);" required>
								<input matInput #templateFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterTemplates($event.target.value)" (keydown)="$event.stopPropagation()" />
						   <mat-option *ngFor="let template of filteredTemplates | customsort:'getName'" [value]="template.getId()">{{template.getName()}}</mat-option>
					</mat-select>
			   </mat-form-field>
			   <br>
			   <mat-form-field>
					<mat-label>Felelős</mat-label>
					<mat-select name="inputOwner" [(ngModel)]="owner" (openedChange)="userFilter.value=null; filterUsers(null);">
								<input matInput #userFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterUsers($event.target.value)" (keydown)="$event.stopPropagation()" />
						   <mat-option [value]="-1"></mat-option>
						 <mat-option *ngFor="let user of filteredUsers | customsort:'getFullName'" [value]="user.getId()">{{user.getFullName()}}</mat-option>
					</mat-select>
			   </mat-form-field>
			   <br>
			   <mat-form-field appearance="standard">
					<mat-label>Leírás</mat-label>
					<textarea matInput name="inputDetails" rows="5" [(ngModel)]="details"></textarea>
			   </mat-form-field>
			   <br>
			   <mat-form-field>
					<mat-label>Határidő</mat-label>
					<input matInput name="inputDeadline" [(ngModel)]="deadline" [owlDateTime]="inputDeadlinePicker" [owlDateTimeTrigger]="inputDeadlinePicker" required>
					<owl-date-time firstDayOfWeek="1" pickerType="both" #inputDeadlinePicker></owl-date-time>
			   </mat-form-field>
		  </form>
		  <div class="dialogButtons">
			   <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Hozzáadás</button>
			   <button mat-flat-button color="warn" (click)="dialogRef.close(false)">Mégsem</button>
		  </div>
	 </mat-dialog-content>
</div>
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

@Injectable()
export class SzamlazasService {

	public static readonly API_TYPE: string = 'szamlazas';

	//public static readonly GET_FREEDAYS_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/get_freedays_request';
	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/get_request';
	public static readonly EQUATE_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/equate_request';
	//public static readonly SET_DATUM_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/set_datum_request';
	public static readonly SAVE_DATA_API: string = AppConstants.BASE_URL + '/api/' + SzamlazasService.API_TYPE + '/save_data_request';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}



	getAll(closed:boolean) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('closed', String(closed ? 1 : -1));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(SzamlazasService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(SzamlazasService.GET_API, params, {params: parameters});
	}
	equate(id:number){
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(SzamlazasService.EQUATE_API, params, {params: parameters});
	}
	saveData(data: object) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('formdata', JSON.stringify(data));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(SzamlazasService.SAVE_DATA_API, params, {params: parameters});
	}
}

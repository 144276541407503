<mat-toolbar color="primary">
	 <mat-toolbar-row>
		  <span>Beállítások</span>
		  <span class="spacer"></span>
		  <button mat-icon-button *ngIf="backStack.length > 0" (click)="backSettings()">
				  <fa-icon [fixedWidth]="true" [icon]="['fas', 'undo']"></fa-icon>
		  </button>
		  <button mat-icon-button (click)="closeDialog()">
				  <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
		  </button>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
	 <div #settingsHost></div>
</mat-dialog-content>
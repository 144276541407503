<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="buttons-container">
	 <button mat-raised-button routerLink="/feladatok" class="module-button col-9 col-md-5 col-lg-4 col-xl-3">
		  <div>
			   <span>Projekt modul</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/raktar" class="module-button col-9 col-md-5 col-lg-4 col-xl-3" *hasPermission="[
			 'Modules:HabMinoseg:list', 'Modules:HabMinoseg:add', 'Modules:HabMinoseg:mod', 
			 'Modules:LemezAnyagminoseg:list', 'Modules:LemezAnyagminoseg:add', 'Modules:LemezAnyagminoseg:mod', 
			 'Modules:LemezGyarto:list', 'Modules:LemezGyarto:add', 'Modules:LemezGyarto:mod', 
			 'Modules:LemezRaktar:list', 'Modules:LemezRaktar:add', 'Modules:LemezRaktar:mod', 
			 'Modules:LemezSzelesseg:list', 'Modules:LemezSzelesseg:add', 'Modules:LemezSzelesseg:mod', 
			 'Modules:LemezSzin:list', 'Modules:LemezSzin:add', 'Modules:LemezSzin:mod', 
			 'Modules:LemezTarolo:list', 'Modules:LemezTarolo:add', 'Modules:LemezTarolo:mod', 
			 'Modules:LemezVastagsag:list', 'Modules:LemezVastagsag:add', 'Modules:LemezVastagsag:mod']">
		  <div>
			   <span>Stratégiai készlet</span>
		  </div>
	 </button>
</div>
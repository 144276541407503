import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';

export class TaskConditionModel {

	private id: number = null;

	private tc_taskrefid: number = null;
	private taskref: TaskRefModel = new TaskRefModel({});
	private tc_condition: string = '';
	private tc_desc: string = '';

	private tc_modifiedby: number = null;
	private tc_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.tc_taskrefid = json && json.tc_taskrefid || null;
		this.taskref = new TaskRefModel(json && json.taskref || {});
		this.tc_condition = json && json.tc_condition || '';
		this.tc_desc = json && json.tc_desc || '';

		this.tc_modifiedby = json && json.tc_modifiedby || null;
		this.tc_modifiedtime = json && json.tc_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getTaskrefid() {
		return this.tc_taskrefid;
	}

	setTaskrefid(value) {
		this.tc_taskrefid = value;
	}

	getTaskrefModel() {
		return this.taskref;
	}

	getCondition() {
		return this.tc_condition;
	}

	setCondition(value) {
		this.tc_condition = value;
	}

	getDesc() {
		return this.tc_desc;
	}

	setDesc(value) {
		this.tc_desc = value;
	}
}

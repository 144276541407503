import {Component, Inject} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {SzerelesekService} from 'src/app/components/szereles/service/szerelesek.service';

import {CommonModel} from 'src/app/model/common.model';

import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {ListsModel} from 'src/app/model/lists.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {AuthService} from 'src/app/services/auth.service';

export interface Szereles {
	id: string;
	projectid: string;
	projectModel: ProjectModel;
	szerelo: number;
	szereloModel: UserModel;
	kezdes: any;
	befejezes: any;
	iranyitoszam: string;
	varos: string;
	utca: string;
	hazszam: string;
	kontakt: string;
	telefon: string;
	email: string;
	szallitas: number;
	segitok: number;
	emelogep: any;
	eszkozigeny: string;
	megjegyzes: string;
	allapot: number;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}

@Component({
	selector: 'app-szereles-dialog',
	templateUrl: './szereles-dialog.html',
	styleUrls: ['./szereles-dialog.scss']
})
export class SzerelesDialog {

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	loading: number = 0;

	writeable:boolean=false;

	targetObj: Szereles = {
		id: null,
		projectid: null,
		projectModel: new ProjectModel({}),
		szerelo: null,
		szereloModel: new UserModel({}),
		kezdes: null,
		befejezes: null,
		iranyitoszam: null,
		varos: null,
		utca: null,
		hazszam: null,
		kontakt: null,
		telefon: null,
		email: null,
		szallitas: null,
		segitok: null,
		emelogep: null,
		eszkozigeny: null,
		megjegyzes: null,
		allapot: null,
		modifiedby: null,
		modifiedtime: null,
		deletedby: null,
		deletedtime: null
	};

	freeDates: SafeHtml;

	freedays: Array<string> = [];

	szerelok: Array<UserModel> = [];
	filteredSzerelok: Array<UserModel> = [];

	listsModel: any = ListsModel;

	constructor(
		private szerelesekService: SzerelesekService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<SzerelesDialog>,
		private sanitizer: DomSanitizer,
		private authService:AuthService
	) {
		let szerelesId = this.data.szerelesId;

		this.loading++;
		this.szerelesekService.getFreedays().subscribe(
			res => this.handleGetFreedaysResponse(res),
			error => this.handleGetFreedaysError(error)
		);

		this.loading++;
		this.szerelesekService.get(Number(szerelesId)).subscribe(
			res => this.handleGetSzerelesResponse(res),
			error => this.handleGetSzerelesError(error)
		);
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpSzereles: Szereles = {...this.targetObj};
		for (let key in tmpSzereles) {
			tmpSzereles[key] = this.transform(tmpSzereles[key]);
		}

		return tmpSzereles;
	}

	szereloSelected(event) {
		this.targetObj.kezdes = null;
		this.targetObj.befejezes = null;
		for (let user of this.szerelok) {
			if (event.value == user.getId()) {
				this.targetObj.szereloModel = user;
				break;
			}
		}
		//this.setReservedDates(event.value);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
		this.alertDialogRef.afterClosed().subscribe(result => {
			this.alertDialogRef = null;
			//this.dialogRef.close(false);
		});
	}

	handleGetFreedaysError(serverError) {
		this.loading--;
	}

	handleGetFreedaysResponse(serverResponse) {
		this.loading--;

		this.freedays = [];

		for (let value of serverResponse.freedays) {
			this.freedays.push(value);
		}

		let styles: string = '';

		this.freedays.forEach((freeDate) => {
			styles += '.szerelesDatumPicker [aria-label="' + (new Date(freeDate).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric'})) + '"] span {text-decoration: underline; color: red;}';
		})

		this.freeDates = this.sanitizer.bypassSecurityTrustHtml('<style>' + styles + '</style>');
	}

	handleGetSzerelesError(serverError) {
		this.handleError(serverError);
	}

	handleGetSzerelesResponse(serverResponse) {
		this.loading--;
		this.targetObj = {
			id: serverResponse.szereles.id,
			projectid: serverResponse.szereles.projectid,
			projectModel: new ProjectModel(serverResponse.szereles.project),
			szerelo: Number(serverResponse.szereles.szerelo),
			szereloModel: new UserModel(serverResponse.szereles.szerelesvezeto),
			kezdes: CommonModel.isDate(serverResponse.szereles.kezdes) ? (new Date(Date.parse(serverResponse.szereles.kezdes))) : null,
			befejezes: CommonModel.isDate(serverResponse.szereles.befejezes) ? (new Date(Date.parse(serverResponse.szereles.befejezes))) : null,
			iranyitoszam: serverResponse.szereles.iranyitoszam,
			varos: serverResponse.szereles.varos,
			utca: serverResponse.szereles.utca,
			hazszam: serverResponse.szereles.hazszam,
			kontakt: serverResponse.szereles.kontakt,
			telefon: serverResponse.szereles.telefon,
			email: serverResponse.szereles.email,
			szallitas: serverResponse.szereles.szallitas,
			segitok: serverResponse.szereles.segitok,
			emelogep: (serverResponse.szereles.emelogep === true || serverResponse.szereles.emelogep == 1 || serverResponse.szereles.emelogep == 'true') ? 1 : 0,
			eszkozigeny: serverResponse.szereles.eszkozigeny,
			megjegyzes: serverResponse.szereles.megjegyzes,
			allapot: serverResponse.szereles.allapot,
			modifiedby: serverResponse.szereles.modifiedby,
			modifiedtime: serverResponse.szereles.modifiedtime,
			deletedby: serverResponse.szereles.deletedby,
			deletedtime: serverResponse.szereles.deletedtime
		};

		this.szerelok = [];

		for (let i = 0; i < serverResponse.szerelok.length; i++) {
			this.szerelok.push(new UserModel(serverResponse.szerelok[i]));
		}

		this.filteredSzerelok = this.szerelok.filter((obj) => {return true;});
		this.setWriteable();
	}

	setKezdes(event) {
		this.targetObj.kezdes = new Date(event);
		if (this.targetObj.befejezes != null && typeof this.targetObj.befejezes == 'object' && this.targetObj.befejezes.constructor.name == 'Date') {
			if (this.targetObj.kezdes.getTime() > this.targetObj.befejezes.getTime()) {
				this.targetObj.befejezes = new Date(event);
			}
		} else {
			this.targetObj.befejezes = new Date(event);
		}

		this.checkFreedays(true);
	}

	setBefejezes(event) {
		this.targetObj.befejezes = new Date(event);
		if (this.targetObj.kezdes != null && typeof this.targetObj.kezdes == 'object' && this.targetObj.kezdes.constructor.name == 'Date') {
			if (this.targetObj.kezdes.getTime() > this.targetObj.befejezes.getTime()) {
				this.targetObj.kezdes = new Date(event);
			}
		} else {
			this.targetObj.kezdes = new Date(event);
		}

		this.checkFreedays(true);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.dialogRef.close(true);
	}

	save() {

		/*if (this.targetObj.kezdes != null && typeof this.targetObj.kezdes == 'object' && this.targetObj.kezdes.constructor.name ==                          this.targetObj.befejezes != null && typeof this.targetObj.befejezes == 'object' && this.targetObj.befejezes.constructor.name ==                          let tmpKezdes: Date = new Date(this.targetObj                         let tmpBefejezes: Date = new Date(this.targetObj.be                         let tmpKezdesText = (tmpKezdes.getFullYear() + "-" + ("0" + (tmpKezdes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpKezdes.getDate()).sub                         let tmpBefejezesText = (tmpBefejezes.getFullYear() + "-" + ("0" + (tmpBefejezes.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpBefejezes.getDate()).subs                         this.l                         this.szerelesekService.setDatum(Number(this.targetObj.id), tmpKezdesText, tmpBefejezesText).s                              res => this.handleSaveRespo                              error => this.handleSaveErr                                                              this.alertDialogRef = this.dialog.open(Alert                              disableClo                              panelClass: 'alertDialo                              autoFoc                                             this.alertDialogRef.componentInstance.dialogMessage = 'Érvénytelen                    }*/

		let tmpSzereles: Szereles = this.dataTransform();
		let save: boolean = true;

		if (!tmpSzereles.szerelo || !tmpSzereles.kezdes || !tmpSzereles.befejezes) {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = "A szerelés vezető, kezdés és befejezés mezők kitöltése kötelező";

			save = false;
		}

		if (save) {
			if (this.checkFreedays(false)) {
				this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
					disableClose: true,
					panelClass: 'confirmDialogWindow',
					autoFocus: false
				});
				this.confirmDialogRef.componentInstance.dialogMessage = "Megadott kezdő és befejező dátum között munkaszünetinap található. Biztosan menti a szerelést?";

				this.confirmDialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.saveFunction(tmpSzereles);
					}
					this.confirmDialogRef = null;
				});
			} else {
				this.saveFunction(tmpSzereles);
			}
		}
	}

	saveFunction(tmpSzereles) {
		this.loading++;
		this.szerelesekService.saveData(tmpSzereles).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	checkFreedays(withAlert: boolean) {
		if (this.targetObj.kezdes != null && typeof this.targetObj.kezdes == 'object' && this.targetObj.kezdes.constructor.name == 'Date' &&
			this.targetObj.befejezes != null && typeof this.targetObj.befejezes == 'object' && this.targetObj.befejezes.constructor.name == 'Date') {
			if (this.targetObj.kezdes.getTime() <= this.targetObj.befejezes.getTime()) {
				let tmpDate = new Date(this.targetObj.kezdes);
				let found: boolean = false;
				let valueText = '';

				do {
					valueText = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2));
					for (let freeday of this.freedays) {
						if (freeday == valueText || found) {
							found = true;
							break;
						}
					}
					tmpDate.setDate(tmpDate.getDate() + 1);
				} while (tmpDate.getTime() <= this.targetObj.befejezes.getTime() && !found);

				if (withAlert && found) {
					this.alertDialogRef = this.dialog.open(AlertDialog, {
						disableClose: true,
						panelClass: 'alertDialogWindow',
						autoFocus: false
					});

					this.alertDialogRef.componentInstance.dialogMessage = "Megadott kezdő és befejező dátum között munkaszünetinap található";
				}
				return found;
			}
		}
	}

	filterSzerelok(val) {
		this.filteredSzerelok = this.szerelok.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	setWriteable(){
		this.writeable=(this.authService.hasPermission(['TaskForm:Szereles:datamodify'])||this.authService.isAdmin())?true:false;
	}

}

import {Component, Inject, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FileUploader} from 'ng2-file-upload';

import {TaskService} from 'src/app/components/task/service/task.service';
import {TaskCommentService} from 'src/app/components/task/service/taskcomment.service';
import {AuthService} from 'src/app/services/auth.service';

import {CommonModel} from 'src/app/model/common.model';
import {TaskFormsModule} from 'src/app/components/task/dialog/task-dialog/taskforms/taskforms.module';
import {ExtraDataModule} from 'src/app/components/task/dialog/task-dialog/extradata/extradata.module';

import {ListsModel} from 'src/app/model/lists.model';
import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';
import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskCommentModel} from 'src/app/components/task/model/taskcomment.model';
import {TaskFileModel} from 'src/app/components/task/model/taskfile.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';

@Component({
	selector: 'app-task-dialog',
	templateUrl: './task-dialog.html',
	styleUrls: ['./task-dialog.scss']
})
export class TaskDialog implements AfterViewInit {

	@ViewChild('taskHost', {
		read: ViewContainerRef
	}) taskHost: ViewContainerRef;

	@ViewChild('extraDataHost', {
		read: ViewContainerRef
	}) extraDataHost: ViewContainerRef;

	loading: number = 0;

	tableData = {
		feladat: null,
		statusz: null,
		hatarido: null,
		munkaszam: null,
		projekt: null
	}

	displayedColumns: string[] = ['feladat', 'statusz', 'hatarido', 'munkaszam', 'projekt'];
	dataSource: MatTableDataSource<any>;

	commentDisplayedColumns: string[] = ['id', 'username', 'tc_comment', 'datetime'];
	commentDataSource: MatTableDataSource<TaskCommentModel>;

	@ViewChild('commentPaginator', {static: false}) commentPaginator: MatPaginator;
	@ViewChild('commentSort', {static: false}) commentSort: MatSort;

	commentFilterValue: string = "";

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	private taskId: number;
	taskObject: TaskModel = new TaskModel({});

	uploader: FileUploader = new FileUploader({isHTML5: true});

	assignable: boolean = false;
	writeable: boolean = false;

	comment: string = '';

	listsModel: any = ListsModel;

	constructor(
		private dialogRef: MatDialogRef<TaskDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private taskService: TaskService,
		private taskCommentService: TaskCommentService,
		private authService: AuthService,
		public dialog: MatDialog,
		private resolver: ComponentFactoryResolver
	) {}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.dataSource = new MatTableDataSource([this.tableData]);

			this.taskId = this.data.taskId;

			if (!this.taskId) {
				this.closeDialog();
			} else {
				this.uploader.onAfterAddingFile = (item) => {
					let fileTypeArray: Array<FileTypeModel> = this.taskObject.getTemplateModel().getFiletypes();
					if (fileTypeArray.length == 1) {
						for (let filetype of fileTypeArray) {
							item.formData = filetype.getId();
							break;
						}
					} else {
						item.formData = -1;
					}
				}

				this.loading++;
				this.taskService.getMyTask(this.taskId).subscribe(
					res => this.handleGetMyTaskResponse(res),
					error => this.handleGetMyTaskError(error)
				);
			}
		});
	}

	applyCommentFilter() {
		this.commentDataSource.filter = CommonModel.replaceHunChars(this.commentFilterValue.trim().toLowerCase());

		if (this.commentDataSource.paginator && this.commentDataSource.paginator.pageIndex > this.commentDataSource.paginator.pageSize) {
			this.commentDataSource.paginator.lastPage();
		}
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	uploadFiles() {
		for (let i = 0; i < this.uploader.queue.length; i++) {
			let fileItem = this.uploader.queue[i];
			let file = fileItem._file
			if (fileItem.formData == null || fileItem.formData < 1) {
				this.alertDialogRef = this.dialog.open(AlertDialog, {
					disableClose: true,
					panelClass: 'alertDialogWindow',
					autoFocus: false
				});

				this.alertDialogRef.componentInstance.dialogMessage = "Fájl típus megadása kötelező '" + file.name + "' nevű fájlnál";

				break;
			} else {
				this.loading++;
				this.taskService.uploadFile(this.taskObject.getProjectModel().getId(), this.taskId, fileItem.formData, file).subscribe(
					res => this.handleFileUploadResponse(res),
					error => this.handleFileUploadError(error)
				);
			}
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleFileUploadError(serverError) {
		this.handleError(serverError);
	}

	handleFileUploadResponse(serverResponse) {
		this.loading--;
		let uploadedFile: TaskFileModel = new TaskFileModel(serverResponse.file);
		for (let i = 0; i < this.uploader.queue.length; i++) {
			if (this.uploader.queue[i]._file.name == uploadedFile.getOriginalname()) {
				this.uploader.queue[i].remove();
			}
		}

		if (this.uploader.queue.length < 1) {
			this.loading++;
			this.taskService.getMyTask(this.taskId).subscribe(
				res => this.handleGetMyTaskFilesResponse(res),
				error => this.handleGetMyTaskError(error)
			);
		}
	}

	downloadFile(fileid) {
		let newTab = window.open(TaskService.DOWNLOAD_FILE_API + '/' + this.authService.getToken() + '/' + this.taskObject.getProjectModel().getId() + '/' + fileid);
		if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = "Fájl letöltése előtt engedélyeznie kell az oldalon a felugró ablakokat";
		}
	}

	deleteFile(fileid) {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli ezt a fájlt?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.taskService.deleteFile(fileid).subscribe(
					res => this.handleFileDeleteResponse(res),
					error => this.handleFileDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	handleFileDeleteError(serverError) {
		this.handleError(serverError);
	}

	handleFileDeleteResponse(serverResponse) {
		this.loading--;
		this.loading++;
		this.taskService.getMyTask(this.taskId).subscribe(
			res => this.handleGetMyTaskResponse(res),
			error => this.handleGetMyTaskError(error)
		);
	}

	handleGetMyTaskError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
		this.alertDialogRef.afterClosed().subscribe(result => {
			this.alertDialogRef = null;
			this.closeDialog();
		});
	}

	handleGetMyTaskResponse(serverResponse) {
		this.loading--;
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.fillTaskDataTable();
		this.loadTaskComponent(this.taskObject.getTemplateModel().getObjectType());
		if (this.taskObject.getTemplateModel().getExtraDataType() != null && this.taskObject.getTemplateModel().getExtraDataType().length > 0) {
			if (this.taskObject.getTemplateModel().getExtraDataQuery() != null && this.taskObject.getTemplateModel().getExtraDataQuery().length > 0) {
				this.loadExtraDataComponent(this.taskObject.getTemplateModel().getExtraDataType());
			}
		}

		this.loading++;
		/*this.taskCommentService.getAllByTemplateId(Number(this.taskObject.getTemplateModel().getId()), Number(this.taskObject.getProjectModel().getId())).subscribe(
			 res => this.handleGetTaskCommentResponse(res),
			 error => this.handleGetTaskCommentError(error)
		);*/
		this.taskCommentService.getAllByProjectId(Number(this.taskObject.getProjectModel().getId())).subscribe(
			res => this.handleGetTaskCommentResponse(res),
			error => this.handleGetTaskCommentError(error)
		);
	}

	handleGetTaskCommentError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskCommentResponse(serverResponse) {
		this.loading--;
		let objects: Array<TaskCommentModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new TaskCommentModel(serverResponse[i]));
		}

		this.commentDataSource = new MatTableDataSource(objects);
		this.commentDataSource.paginator = this.commentPaginator;
		this.commentDataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'username': return item.getUserModel().getFullName();
				case 'tc_comment': return item.getComment();
				case 'datetime': return item.getDateTime();

				default: return item[property];
			}
		};
		this.commentDataSource.sort = this.commentSort;
		this.commentDataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getComment(),
				row.getUserModel().getFullName(),
				row.getDateTime()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.applyCommentFilter();
	}

	handleGetMyTaskFilesResponse(serverResponse) {
		this.loading--;
		this.taskObject.setFiles(serverResponse.taskfiles);
	}

	addComment() {
		this.loading++;
		this.taskCommentService.add(Number(this.taskObject.getId()), this.comment).subscribe(
			res => this.handleAddCommentResponse(res),
			error => this.handleAddCommentError(error)
		);
	}

	handleAddCommentError(serverError) {
		this.handleError(serverError);
	}

	handleAddCommentResponse(serverResponse) {
		this.loading--;
		this.comment = '';

		this.loading++;
		this.taskCommentService.getAllByTemplateId(Number(this.taskObject.getTemplateModel().getId()), Number(this.taskObject.getProjectModel().getId())).subscribe(
			res => this.handleGetTaskCommentResponse(res),
			error => this.handleGetTaskCommentError(error)
		);
	}

	loadTaskComponent(component: string) {

		if (component in TaskFormsModule.compMap) {
			this.taskHost.clear();
			const compFactory = this.resolver.resolveComponentFactory(TaskFormsModule.compMap[component]);
			const compRef = this.taskHost.createComponent(compFactory);

			(compRef.instance as any).loadTaskComponent.subscribe(evt => this.loadTaskComponent(evt));
			(compRef.instance as any).refreshTask.subscribe(evt => this.refreshTaskData(evt));
			(compRef.instance as any).increaseLoading.subscribe(evt => this.increaseLoading(evt));
			(compRef.instance as any).decreaseLoading.subscribe(evt => this.decreaseLoading(evt));
			(compRef.instance as any).setTaskObject(this.taskObject);
		} else {
			if (component == null) {
				this.closeDialog();
			} else {
				this.alertDialogRef = this.dialog.open(AlertDialog, {
					disableClose: true,
					panelClass: 'alertDialogWindow',
					autoFocus: false
				});

				this.alertDialogRef.componentInstance.dialogMessage = "Ismeretlen feladat típus";
				this.alertDialogRef.afterClosed().subscribe(result => {
					this.alertDialogRef = null;
					this.closeDialog();
				});
				throw new Error(`Unknown ${component} form type`);
			}
		}
	}

	loadExtraDataComponent(component: string) {
		this.extraDataHost.clear();

		if (component in ExtraDataModule.compMap) {
			const compFactory = this.resolver.resolveComponentFactory(ExtraDataModule.compMap[component]);
			const compRef = this.extraDataHost.createComponent(compFactory);

			(compRef.instance as any).setTaskObject(this.taskObject);
		} else {
			throw new Error(`Unknown ${component} extra data type`);
		}
	}

	fillTaskDataTable() {
		this.tableData.feladat = this.taskObject.getTemplateModel().getName();
		this.tableData.statusz = ListsModel.getListItem(this.taskObject.getStatus(), ListsModel.TaskStatus).getName();
		this.tableData.hatarido = this.taskObject.getDeadline();
		this.tableData.munkaszam = this.taskObject.getProjectModel().getNumber();
		this.tableData.projekt = this.taskObject.getProjectModel().getName();
	}

	refreshTaskData(newTask: TaskModel) {
		this.taskObject = newTask;
		this.setWriteableAssignable();
		this.fillTaskDataTable();
	}

	increaseLoading(param) {
		this.loading++;
	}

	decreaseLoading(param) {
		this.loading--;
	}

	closeDialog() {
		if (this.uploader.queue.length > 0) {
			this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
				disableClose: true,
				panelClass: 'confirmDialogWindow',
				autoFocus: false
			});
			this.confirmDialogRef.componentInstance.dialogMessage = this.uploader.queue.length + " fájl feltöltésre vár, biztosan bezárja a feladatot?";

			this.confirmDialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.dialogRef.close(this.taskObject);
				}
				this.confirmDialogRef = null;
			});
		} else {
			this.dialogRef.close(this.taskObject);
		}
	}
}
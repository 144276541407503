import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {GablerAruRendelesComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerarurendeles/gablerarurendeles.component';

import {GablerAruRendelesService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerarurendeles/gablerarurendeles.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		GablerAruRendelesComponent
	],
	entryComponents: [
		GablerAruRendelesComponent
	],
	providers: [
		GablerAruRendelesService
	],
	exports: [
		GablerAruRendelesComponent
	],
})
export class GablerAruRendelesModule {
	public static readonly component = GablerAruRendelesComponent;
}
<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Lemez bevétel</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
			   <div class="mat-elevation-z2 px-2 py-2">
					<form #dataForm="ngForm">
						 <mat-card-content>
							  <mat-form-field>
								   <mat-label>Időpont</mat-label>
								   <input matInput name="inputIdopont" [(ngModel)]="idopont" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" required>
								   <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
							  </mat-form-field>
							  <br>
							  <mat-form-field>
								   <mat-label>Tekercs kód</mat-label>
								   <input matInput name="inputSzallitolevel" [(ngModel)]="szallitolevel" required>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Gyártó</mat-label>
								   <mat-select name="inputGyarto" [(ngModel)]="lemezgyartoid" required>
										<mat-option *ngFor="let gyarto of gyartok | customsort:'getGyarto'" [value]="gyarto.getId()">{{gyarto.getGyarto()}}</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Szín</mat-label>
								   <mat-select name="inputSzin" [(ngModel)]="lemezszinid" required>
										<mat-option *ngFor="let szin of szinek | customsort:'getSzin'" [value]="szin.getId()">{{szin.getSzin()}}</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Anyagminőség</mat-label>
								   <mat-select name="inputAnyagminoseg" [(ngModel)]="lemezanyagminosegid" required>
										<mat-option *ngFor="let anyagminoseg of anyagminosegek | customsort:'getFajsuly'" [value]="anyagminoseg.getId()">{{anyagminoseg.getFajsuly()}} kg/m3</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Szélesség</mat-label>
								   <mat-select name="inputSzelesseg" [(ngModel)]="lemezszelessegid" required>
										<mat-option *ngFor="let szelesseg of szelessegek | customsort:'getSzelesseg'" [value]="szelesseg.getId()">{{szelesseg.getSzelesseg()}} mm</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Vastagság</mat-label>
								   <mat-select name="inputVastagsag" [(ngModel)]="lemezvastagsagid" required>
										<mat-option *ngFor="let vastagsag of vastagsagok | customsort:'getVastagsag'" [value]="vastagsag.getId()">{{vastagsag.getVastagsag()}} mm</mat-option>
								   </mat-select>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Tömeg (kg)</mat-label>
								   <input matInput name="inputTomeg" type="number" step="1" min="0" [(ngModel)]="tomeg" required>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard" class="hidden">
								   <mat-label>Nettó ár (ft)</mat-label>
								   <input matInput name="inputNettoar" type="number" step="1" min="0" [(ngModel)]="nettoar" required>
							  </mat-form-field>
							  <br>
							  <mat-form-field appearance="standard">
								   <mat-label>Cél raktár/tároló</mat-label>
								   <mat-select name="inputRaktarTarolo" [(ngModel)]="lemeztaroloid" (openedChange)="lemeztarolokFilter.value=null; filterLemeztarolok(null);" required>
											   <input matInput #lemeztarolokFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterLemeztarolok($event.target.value)" (keydown)="$event.stopPropagation()" />
										  <mat-option *ngFor="let lemeztarolo of filteredLemeztarolok | customsort:'getRaktarTarolo'" [value]="lemeztarolo.getId()">{{lemeztarolo.getRaktarTarolo()}}</mat-option>
								   </mat-select>
							  </mat-form-field>
						 </mat-card-content>
					</form>
			   </div>
		  </mat-card-content>
		  <mat-card-actions style="text-align: right;">
			   <button mat-raised-button (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
			   <button mat-raised-button (click)="saveAndClose()" [disabled]="!dataForm.valid">Mentés és bezárás</button>
			   <button mat-raised-button routerLink="/raktar">Mégsem</button>
		  </mat-card-actions>
	 </mat-card>
</div>

import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {GablerSzerelesComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszereles/gablerszereles.component';

import {GablerSzerelesService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszereles/gablerszereles.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		GablerSzerelesComponent
	],
	entryComponents: [
		GablerSzerelesComponent
	],
	providers: [
		GablerSzerelesService
	],
	exports: [
		GablerSzerelesComponent
	],
})
export class GablerSzerelesModule {
	public static readonly component = GablerSzerelesComponent;
}
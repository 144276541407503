import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddRoleComponent} from 'src/app/components/settings/role/component/addrole/addrole.component';
import {ModRoleComponent} from 'src/app/components/settings/role/component/modrole/modrole.component';
import {RolesComponent} from 'src/app/components/settings/role/component/roles/roles.component';

import {AddRoleAssignComponent} from 'src/app/components/settings/role/component/addroleassign/addroleassign.component';
import {ModRoleAssignComponent} from 'src/app/components/settings/role/component/modroleassign/modroleassign.component';
import {DelRoleAssignComponent} from 'src/app/components/settings/role/component/delroleassign/delroleassign.component';
import {RoleAssignsComponent} from 'src/app/components/settings/role/component/roleassigns/roleassigns.component';

import {RoleService} from 'src/app/components/settings/role/service/role.service';
import {RoleAssignService} from 'src/app/components/settings/role/service/roleassign.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddRoleComponent,
		ModRoleComponent,
		RolesComponent,

		AddRoleAssignComponent,
		ModRoleAssignComponent,
		DelRoleAssignComponent,
		RoleAssignsComponent
	],
	entryComponents: [
		AddRoleComponent,
		ModRoleComponent,
		RolesComponent,

		AddRoleAssignComponent,
		ModRoleAssignComponent,
		DelRoleAssignComponent,
		RoleAssignsComponent
	],
	providers: [
		RoleService,

		RoleAssignService
	],
	exports: [
		AddRoleComponent,
		ModRoleComponent,
		RolesComponent,

		AddRoleAssignComponent,
		ModRoleAssignComponent,
		DelRoleAssignComponent,
		RoleAssignsComponent
	],
})
export class RoleModule {
	public static readonly compArray = [
		AddRoleComponent,
		ModRoleComponent,
		RolesComponent,

		AddRoleAssignComponent,
		ModRoleAssignComponent,
		DelRoleAssignComponent,
		RoleAssignsComponent
	];

	public static readonly compMap = RoleModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in RoleModule.compMap) ? RoleModule.compMap[TAG].NAME : '';
	}
}
import {LemezRaktarModel} from 'src/app/components/settings/raktar/lemezraktar/model/lemezraktar.model';

export class LemezTaroloModel {

	private id: number = null;

	private lemezraktarid: number = null;
	private lemezraktar: LemezRaktarModel = new LemezRaktarModel({});
	private tarolo: string = '';

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.lemezraktarid = json && json.lemezraktarid || null;
		this.lemezraktar = new LemezRaktarModel(json && json.lemezraktar || {});
		this.tarolo = json && json.tarolo || '';

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getLemezraktarid() {
		return this.lemezraktarid;
	}

	setLemezraktarid(value) {
		this.lemezraktarid = value;
	}

	getLemezRaktarModel() {
		return this.lemezraktar;
	}

	getTarolo() {
		return this.tarolo;
	}

	setTarolo(value) {
		this.tarolo = value;
	}

	getRaktarTarolo() {
		return this.lemezraktar.getRaktar() + ' - ' + this.tarolo;
	}
}

import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {SettingsDialog} from 'src/app/components/settings/settings-dialog/settings-dialog';

import {SettingsMainComponent} from 'src/app/components/settings/settings-main/settings-main.component';

import {CategoryModule} from 'src/app/components/settings/category/module/category.module';
import {FileTypeModule} from 'src/app/components/settings/filetype/module/filetype.module';
import {PartnerModule} from 'src/app/components/settings/partner/module/partner.module';
import {ProjectModule} from 'src/app/components/settings/project/module/project.module';
import {HabMinosegModule} from 'src/app/components/settings/raktar/habminoseg/module/habminoseg.module';
import {HabSzintModule} from 'src/app/components/settings/raktar/habszint/module/habszint.module';
import {HabSzallitasModule} from 'src/app/components/settings/raktar/habszallitas/module/habszallitas.module';
import {LemezAnyagminosegModule} from 'src/app/components/settings/raktar/lemezanyagminoseg/module/lemezanyagminoseg.module';
import {LemezGyartoModule} from 'src/app/components/settings/raktar/lemezgyarto/module/lemezgyarto.module';
import {LemezRaktarModule} from 'src/app/components/settings/raktar/lemezraktar/module/lemezraktar.module';
import { LemezSzallitasModule } from 'src/app/components/settings/raktar/lemezszallitas/module/lemezszallitas.module';
import {LemezSzelessegModule} from 'src/app/components/settings/raktar/lemezszelesseg/module/lemezszelesseg.module';
import {LemezSzinModule} from 'src/app/components/settings/raktar/lemezszin/module/lemezszin.module';
import {LemezTaroloModule} from 'src/app/components/settings/raktar/lemeztarolo/module/lemeztarolo.module';
import {LemezVastagsagModule} from 'src/app/components/settings/raktar/lemezvastagsag/module/lemezvastagsag.module';
import {RoleModule} from 'src/app/components/settings/role/module/role.module';
import {TaskConditionModule} from 'src/app/components/settings/taskcondition/module/taskcondition.module';
import {TaskRefModule} from 'src/app/components/settings/taskref/module/taskref.module';
import {TaskRoleModule} from 'src/app/components/settings/taskrole/module/taskrole.module';
import {TaskTemplateModule} from 'src/app/components/settings/tasktemplate/module/tasktemplate.module';
import {UserModule} from 'src/app/components/settings/user/module/user.module';

@NgModule({
	imports: [
		CommonModules,

		CategoryModule,
		FileTypeModule,
		PartnerModule,
		ProjectModule,
		HabMinosegModule,
		HabSzintModule,
		HabSzallitasModule,
		LemezAnyagminosegModule,
		LemezGyartoModule,
		LemezRaktarModule,
		LemezSzallitasModule,
		LemezSzelessegModule,
		LemezSzinModule,
		LemezTaroloModule,
		LemezVastagsagModule,
		RoleModule,
		TaskConditionModule,
		TaskRefModule,
		TaskRoleModule,
		TaskTemplateModule,
		UserModule
	],
	declarations: [
		SettingsMainComponent,
		SettingsDialog
	],
	entryComponents: [
		SettingsMainComponent,
		SettingsDialog
	],
	exports: [
		SettingsMainComponent,
		SettingsDialog
	],
	providers: [
		{provide: SettingsMainComponent.compArrayToken, useValue: SettingsModule.compArray},
		{provide: SettingsDialog.compMapToken, useValue: SettingsModule.compMap}
	]
})
export class SettingsModule {
	public static readonly compArray = [
		SettingsMainComponent,
		...CategoryModule.compArray,
		...FileTypeModule.compArray,
		...PartnerModule.compArray,
		...ProjectModule.compArray,
		...HabMinosegModule.compArray,
		...HabSzintModule.compArray,
		...HabSzallitasModule.compArray,
		...LemezAnyagminosegModule.compArray,
		...LemezGyartoModule.compArray,
		...LemezRaktarModule.compArray,
		...LemezSzallitasModule.compArray,
		...LemezSzelessegModule.compArray,
		...LemezSzinModule.compArray,
		...LemezTaroloModule.compArray,
		...LemezVastagsagModule.compArray,
		...RoleModule.compArray,
		...TaskConditionModule.compArray,
		...TaskRefModule.compArray,
		...TaskRoleModule.compArray,
		...TaskTemplateModule.compArray,
		...UserModule.compArray
	];

	public static readonly compMap = SettingsModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in SettingsModule.compMap) ? SettingsModule.compMap[TAG].NAME : '';
	}
}

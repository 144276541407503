import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

export class TaskFileModel {

	private id: number = null;

	private tf_taskid: number = null;
	//private task: TaskModel = new TaskModel({});
	private tf_projectid: number = null;
	//private project: ProjectModel = new ProjectModel({});
	private tf_originalname: string = '';
	private tf_newname: string = '';
	private tf_type: number = null;
	private filetype: FileTypeModel = new FileTypeModel({});

	private tf_uploadedby: number = null;
	private uploader: UserModel = new UserModel({});
	private tf_uploadedtime: string = '';

	private tf_deletedby: number = null;
	private remover: UserModel = new UserModel({});
	private tf_deletedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.tf_taskid = json && json.tf_taskid || null;
		//this.task = new TaskModel(json && json.task || {});
		this.tf_projectid = json && json.tf_projectid || null;
		//this.project = new ProjectModel(json && json.project || {});
		this.tf_originalname = json && json.tf_originalname || '';
		this.tf_newname = json && json.tf_newname || '';
		this.tf_type = json && json.tf_type || null;
		this.filetype = new FileTypeModel(json && json.filetype || {});

		this.tf_uploadedby = json && json.tf_uploadedby || null;
		this.uploader = new UserModel(json && json.uploader || {});
		this.tf_uploadedtime = json && json.tf_uploadedtime || '';

		this.tf_deletedby = json && json.tf_deletedby || null;
		this.remover = new UserModel(json && json.remover || {});
		this.tf_deletedtime = json && json.tf_deletedtime || '';
	}

	getId() {
		return this.id;
	}

	getTaskid() {
		return this.tf_taskid;
	}

	setTaskid(value) {
		this.tf_taskid = value;
	}

	/*getTaskModel() {
		 return this.task;
	}*/

	getProjectid() {
		return this.tf_projectid;
	}

	setProjectid(value) {
		this.tf_projectid = value;
	}

	/*getProjectModel() {
		 return this.project;
	}*/

	getOriginalname() {
		return this.tf_originalname;
	}

	setOriginalname(value) {
		this.tf_originalname = value;
	}

	getNewname() {
		return this.tf_newname;
	}

	setNewname(value) {
		this.tf_newname = value;
	}

	getType() {
		return this.tf_type;
	}

	setType(value) {
		this.tf_type = value;
	}

	getFileTypeModel() {
		return this.filetype;
	}

	getUploader() {
		return this.tf_uploadedby;
	}

	setUploader(value) {
		this.tf_uploadedby = value;
	}

	getUploaderModel() {
		return this.uploader;
	}

	getUploadTime() {
		return this.tf_uploadedtime;
	}

	setUploadTime(value) {
		this.tf_uploadedtime = value;
	}

	getRemover() {
		return this.tf_deletedby;
	}

	setRemover(value) {
		this.tf_deletedby = value;
	}

	getRemoverModel() {
		return this.remover;
	}

	getRemoveTime() {
		return this.tf_deletedtime;
	}

	setRemoveTime(value) {
		this.tf_deletedtime = value;
	}
}

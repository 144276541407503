import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezVastagsagService} from 'src/app/components/settings/raktar/lemezvastagsag/service/lemezvastagsag.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {LemezVastagsagModel} from 'src/app/components/settings/raktar/lemezvastagsag/model/lemezvastagsag.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: LemezVastagsagService.MOD_TAG,
	templateUrl: './modlemezvastagsag.component.html',
	styleUrls: ['./modlemezvastagsag.component.scss']
})
export class ModLemezVastagsagComponent implements OnDestroy {

	public static readonly TAG = LemezVastagsagService.MOD_TAG;
	public static readonly NAME = "Lemez Vastagság módosítása";
	getSettingName() {return ModLemezVastagsagComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: LemezVastagsagModel = new LemezVastagsagModel({});

	constructor(
		private lemezVastagsagService: LemezVastagsagService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.lemezVastagsagService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new LemezVastagsagModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.lemezVastagsagService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODLEMEZVASTAGSAG_LEMEZVASTAGSAGID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODLEMEZVASTAGSAG_LEMEZVASTAGSAGID);
	}

	back() {
		this.loadSettingComponent.emit(LemezVastagsagService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
import {CategoryModel} from 'src/app/components/settings/category/model/category.model';
import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';

export class TaskTemplateModel {

	private id: number = null;

	private tt_name: string = '';
	private tt_desc: string = '';
	private tt_uploadrequired: any = null;
	private rolesletrehozas: Array<RoleModel> = [];
	private rolesolvasas: Array<RoleModel> = [];
	private rolesiras: Array<RoleModel> = [];
	private filetypes: Array<FileTypeModel> = [];
	private tt_maxtask: number = null;
	private tt_extradatatype: string = '';
	private tt_extradataquery: string = '';
	private tt_objecttype: string = '';
	private tt_category: number = null;
	private category: CategoryModel = new CategoryModel({});
	private tt_deadlinequery: string = '';
	private deadline: string = '';
	private tt_timeshift: number = null;

	private tt_modifiedby: number = null;
	private tt_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.tt_name = json && json.tt_name || '';
		this.tt_desc = json && json.tt_desc || '';
		this.tt_uploadrequired = json && json.tt_uploadrequired || false;
		this.tt_uploadrequired = (this.tt_uploadrequired === true || this.tt_uploadrequired == 1 || this.tt_uploadrequired == 'true') ? true : false;

		let tmpTemplateRolesLetrehozas: Array<object> = json && json.rolesletrehozas || [];
		tmpTemplateRolesLetrehozas.forEach(role => {
			this.rolesletrehozas.push(new RoleModel(role));
		});

		let tmpTemplateRolesOlvasas: Array<object> = json && json.rolesolvasas || [];
		tmpTemplateRolesOlvasas.forEach(role => {
			this.rolesolvasas.push(new RoleModel(role));
		});

		let tmpTemplateRolesIras: Array<object> = json && json.rolesiras || [];
		tmpTemplateRolesIras.forEach(role => {
			this.rolesiras.push(new RoleModel(role));
		});

		let tmpTemplateFiletypes: Array<object> = json && json.filetypes || [];
		tmpTemplateFiletypes.forEach(filetype => {
			this.filetypes.push(new FileTypeModel(filetype));
		});

		this.tt_maxtask = json && json.tt_maxtask || 0;
		this.tt_extradatatype = json && json.tt_extradatatype || '';
		this.tt_extradataquery = json && json.tt_extradataquery || '';
		this.tt_objecttype = json && json.tt_objecttype || '';
		this.tt_category = json && json.tt_category || null;
		this.category = new CategoryModel(json && json.category || {});
		this.tt_deadlinequery = json && json.tt_deadlinequery || '';
		this.deadline = json && json.deadline || '';
		this.tt_timeshift = json && json.tt_timeshift || null;

		this.tt_modifiedby = json && json.tt_modifiedby || null;
		this.tt_modifiedtime = json && json.tt_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.tt_name;
	}

	setName(value) {
		this.tt_name = value;
	}

	getDesc() {
		return this.tt_desc;
	}

	setDesc(value) {
		this.tt_desc = value;
	}

	isUploadRequired() {
		return this.tt_uploadrequired;
	}

	setUploadRequired(value) {
		this.tt_uploadrequired = (value === true || value == 1 || value == 'true') ? true : false;
	}

	getRolesLetrehozas() {
		return this.rolesletrehozas;
	}

	setRolesLetrehozas(value) {
		this.rolesletrehozas = value;
	}

	getRolesLetrehozasId() {
		let tmpIds: Array<number> = [];
		this.rolesletrehozas.forEach(role => {
			tmpIds.push(Number(role.getId()));
		});
		return tmpIds;
	}

	getRolesOlvasas() {
		return this.rolesolvasas;
	}

	setRolesOlvasas(value) {
		this.rolesolvasas = value;
	}

	getRolesOlvasasId() {
		let tmpIds: Array<number> = [];
		this.rolesolvasas.forEach(role => {
			tmpIds.push(Number(role.getId()));
		});
		return tmpIds;
	}

	getRolesIras() {
		return this.rolesiras;
	}

	setRolesIras(value) {
		this.rolesiras = value;
	}

	getRolesIrasId() {
		let tmpIds: Array<number> = [];
		this.rolesiras.forEach(role => {
			tmpIds.push(Number(role.getId()));
		});
		return tmpIds;
	}

	getFiletypes() {
		return this.filetypes;
	}

	setFiletypes(value) {
		this.filetypes = value;
	}

	getFiletypesId() {
		let tmpIds: Array<number> = [];
		this.filetypes.forEach(filetype => {
			tmpIds.push(Number(filetype.getId()));
		});
		return tmpIds;
	}

	getMaxTask() {
		return this.tt_maxtask;
	}

	setMaxTask(value) {
		this.tt_maxtask = value;
	}

	getObjectType() {
		return this.tt_objecttype;
	}

	setObjectType(value) {
		this.tt_objecttype = value;
	}

	getExtraDataType() {
		return this.tt_extradatatype;
	}

	setExtraDataType(value) {
		this.tt_extradatatype = value;
	}

	getExtraDataQuery() {
		return this.tt_extradataquery;
	}

	setExtraDataQuery(value) {
		this.tt_extradataquery = value;
	}

	getCategory() {
		return this.tt_category;
	}

	setCategory(value) {
		this.tt_category = value;
	}

	getCategoryModel() {
		return this.category;
	}

	getDeadlineQuery() {
		return this.tt_deadlinequery;
	}

	setDeadlineQuery(value) {
		this.tt_deadlinequery = value;
	}

	getDeadline() {
		return this.deadline;
	}

	getTimeshift() {
		return this.tt_timeshift;
	}

	setTimeshift(value) {
		this.tt_timeshift = value;
	}
}

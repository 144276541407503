<mat-toolbar color="primary">
	 <mat-toolbar-row>
		  <span style="max-width: 90%; white-space: pre-wrap;">{{taskObject.getTemplateModel().getName()}}</span>
		  <span class="spacer"></span>
		  <button mat-icon-button (click)="closeDialog()">
				  <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
		  </button>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <div class="table-wrapper" stlye="overflow: auto;">
		  <table class="col-10 mx-auto my-2 mat-elevation-z2" mat-table [dataSource]="dataSource">
				 <!-- feladat Column -->
				 <ng-container matColumnDef="feladat" sticky>
					<th class="col-id" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Feladat
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.feladat}}
						 </div>
					</td>
			   </ng-container>

			   <!-- statusz Column -->
			   <ng-container matColumnDef="statusz">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Státusz
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.statusz}}
						 </div>
					</td>
			   </ng-container>

			   <!-- hatarido Column -->
			   <ng-container matColumnDef="hatarido">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Határidő
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.hatarido}}
						 </div>
					</td>
			   </ng-container>

			   <!-- munkaszam Column -->
			   <ng-container matColumnDef="munkaszam">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Munkaszám
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.munkaszam}}
						 </div>
					</td>
			   </ng-container>

			   <!-- projekt Column -->
			   <ng-container matColumnDef="projekt" stickyEnd>
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Projekt
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.projekt}}
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>
	 </div>
	 <mat-accordion>

		  <mat-expansion-panel *ngIf="taskObject.getStatus() == listsModel.TaskStatus_SZTORNOZVA.getValue() && taskObject.getCancelledtime().length > 0">
			  <mat-expansion-panel-header>
					<mat-panel-title>
						 Sztornózás részletei
					</mat-panel-title>
			   </mat-expansion-panel-header>
			   <div>
					<p>Sztornózta: {{taskObject.getCancelledbyModel().getFullName()}}</p>
					<p>Sztornózva: {{taskObject.getCancelledtime()}}</p>
					<p>Indoklás: {{taskObject.getCancellednote()}}</p>
			   </div>
		  </mat-expansion-panel>

		  <mat-expansion-panel *ngIf="taskObject.getDetails().length > 0">
			   <mat-expansion-panel-header>
					<mat-panel-title>
						 Feladat leírása
					</mat-panel-title>
			   </mat-expansion-panel-header>
			   <div>
					<p>{{taskObject.getDetails()}}</p>
			   </div>
		  </mat-expansion-panel>

		  <mat-expansion-panel>
			   <mat-expansion-panel-header>
					<mat-panel-title>
						 Megjegyzések
					</mat-panel-title>
			   </mat-expansion-panel-header>
			   <div class="comment-table-wrapper">
					<div class="filter-container">
						 <app-table-filter style="margin-left: 10px; width: 100%;" filterId="task_dialog" [(filter)]="commentFilterValue" (keyupFn)="applyCommentFilter()" placeholder="Szűrő..."></app-table-filter>
					</div>
					<div class="table-wrapper mat-elevation-z8">
						 <table mat-table [dataSource]="commentDataSource" #commentSort="matSort" matSort>

								<!-- ID Column -->
								<ng-container matColumnDef="id">
								   <th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 #
										</div>
								   </th>
								   <td class="col-id" mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getId()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- username Column -->
							  <ng-container matColumnDef="username">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Munkatárs
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getUserModel().getFullName()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- tc_comment Column -->
							  <ng-container matColumnDef="tc_comment">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Megjegyzés
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper" style="white-space: pre-line">
											 {{row.getComment()}}
										</div>
								   </td>
							  </ng-container>

							  <!-- datetime Column -->
							  <ng-container matColumnDef="datetime">
								   <th mat-header-cell *matHeaderCellDef mat-sort-header>
									   <div class="data-wrapper">
											 Időpont
										</div>
								   </th>
								   <td mat-cell *matCellDef="let row">
									   <div class="data-wrapper">
											 {{row.getDateTime()}}
										</div>
								   </td>
							  </ng-container>

							  <tr mat-header-row *matHeaderRowDef="commentDisplayedColumns"></tr>
							  <tr mat-row *matRowDef="let row; columns: commentDisplayedColumns;"></tr>
						 </table>

						 <mat-paginator #commentPaginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
					</div>
			   </div>
			   <br/>
			   <mat-form-field appearance="fill" style="width: 100%">
					<mat-label>Új megjegyzés</mat-label>
					<textarea matInput [(ngModel)]="comment"></textarea>
			   </mat-form-field>
			   <div style="text-align: right;">
					<button mat-raised-button color="primary" (click)="addComment()" [disabled]="loading!=0">Megjegyzés mentése</button>
			   </div>
		  </mat-expansion-panel>

		  <mat-expansion-panel #extraDataHost></mat-expansion-panel>

		  <mat-expansion-panel *ngIf="writeable && (taskObject.getTemplateModel().getFiletypes().length > 0)">
			   <mat-expansion-panel-header>
					<mat-panel-title>
						 Fájl feltöltés
					</mat-panel-title>
			   </mat-expansion-panel-header>
			   <div class="col-12" style="margin-bottom: 40px">
					<input type="file" name="inputFileUpload" ng2FileSelect [uploader]="uploader" multiple  /><br/>
			   </div> 
			   <div class="col-12" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length > 0">
					<p>Fájlok száma: {{ uploader?.queue?.length }}</p>
					<div style="overflow: auto;">
						 <table class="table">
							  <thead>
								   <tr>
										<th width="25%">Fájlnév</th>
										<th>Méret</th>
										<th>Típus</th>
										<th></th>
								   </tr>
							  </thead>
							  <tbody>
								   <tr *ngFor="let item of uploader.queue">
									   <td><strong>{{ item?.file?.name }}</strong></td>
										<td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
										<td>
											 <div>
												  <mat-select name="inputFileType" [(ngModel)]="item.formData" placeholder="-- Típus --">
													   <mat-option *ngFor="let filetype of taskObject.getTemplateModel().getFiletypes() | customsort:'getName'" [value]="filetype.getId()">{{filetype.getName()}}</mat-option>
												  </mat-select>
											 </div>
										</td>
										<td nowrap>
											 <span style="cursor: pointer;"><fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Fájl törlése" (click)="item.remove()"></fa-icon></span>
										</td>
								   </tr>
							  </tbody>
						 </table>
					</div>

					<div class="text-align: right;">
						 <button mat-mini-fab color="primary" (click)="uploadFiles()" [disabled]="!uploader.getNotUploadedItems().length">
							  <fa-icon [icon]="['fas', 'upload']" size="lg" matTooltip="Összes fájl feltöltése"></fa-icon>
						 </button>
						 <button mat-mini-fab color="warn" class="mx-2" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
							  <fa-icon [icon]="['fas', 'trash']" size="lg" matTooltip="Összes fájl törlése"></fa-icon>
						 </button>
					</div>

			   </div>
		  </mat-expansion-panel>

		  <mat-expansion-panel *ngIf="taskObject.getFiles().length > 0">
			   <mat-expansion-panel-header>
					<mat-panel-title>
						 Feltöltött fájlok
					</mat-panel-title>
			   </mat-expansion-panel-header>
			   <div class="col-12">
					<p>Feltöltött fájlok száma: {{ taskObject.getFiles().length }}</p>
					<div style="overflow: auto;">
						 <table class="table">
							  <thead>
								   <tr>
										<th width="25%">Fájlnév</th>
										<th>Típus</th>
										<th>Feltöltő</th>
										<th>Feltöltve</th>
										<th></th>
								   </tr>
							  </thead>
							  <tbody>
								   <tr *ngFor="let taskFile of taskObject.getFiles()">
									   <td>{{ taskFile.getOriginalname() }}</td>
										<td>{{ taskFile.getFileTypeModel().getName() }}</td>
										<td>{{ taskFile.getUploaderModel().getFullName() }}</td>
										<td>{{ taskFile.getUploadTime() }}</td>
										<td nowrap>
											 <span style="cursor: pointer;" (click)="downloadFile(taskFile.getId())"><fa-icon [icon]="['fas', 'download']" size="lg" matTooltip="Letöltés"></fa-icon></span>
											 <span *hasPermission="'Modules:TaskFile:deletefile'">&nbsp;</span>
											 <span *hasPermission="'Modules:TaskFile:deletefile'"><span style="cursor: pointer;" (click)="deleteFile(taskFile.getId())" *ngIf="writeable"><fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Törlés"></fa-icon></span></span>
										</td>
								   </tr>
							  </tbody>
						 </table>
					</div>
			   </div>
		  </mat-expansion-panel>
	 </mat-accordion>
	 <div #taskHost></div>
</mat-dialog-content>
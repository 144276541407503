<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Szerepkör megnevezése</mat-label>
			   <input matInput name="inputName" [ngModel]="targetObj.getName()" (ngModelChange)="targetObj.setName($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Leírás</mat-label>
			   <textarea matInput name="inputDesc" rows="5" [ngModel]="targetObj.getDesc()" (ngModelChange)="targetObj.setDesc($event)"></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Jogosultságok</mat-label>
			   <mat-select name="inputPermissions" [ngModel]="targetObj.getPermissions()" (ngModelChange)="targetObj.setPermissions($event)" [compareWith]="permissionSelectCompare" multiple>
					<mat-optgroup *ngFor="let permissioncategory of permissioncategories" [label]="permissioncategory.name">
						<mat-option *ngFor="let permission of permissioncategory.permissions | customsort:'getName'" [value]="permission">{{permission.getName()}}</mat-option>
					</mat-optgroup>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
export class PartnerModel {

	private id: number = null;

	private pa_kod: string = '';
	private pa_nev: string = '';
	private pa_varos: string = '';
	private pa_iranyitoszam: string = '';
	private pa_utca: string = '';
	private pa_kozterulet: string = '';
	private pa_hazszam: string = '';
	private pa_adoszam: string = '';
	private pa_bankszamla: string = '';
	private pa_weboldal: string = '';
	private pa_vevo: any = null;
	private pa_gyarto: any = null;
	private pa_beszallito: any = null;
	private pa_viszontelado: any = null;
	private pa_kedvezmeny: number = null;

	private pa_modifiedby: number = null;
	private pa_modifiedtime: string = '';

	constructor(json) {

		this.id = json && json.id || null;

		this.pa_kod = json && json.pa_kod || '';
		this.pa_nev = json && json.pa_nev || '';
		this.pa_varos = json && json.pa_varos || '';
		this.pa_iranyitoszam = json && json.pa_iranyitoszam || '';
		this.pa_utca = json && json.pa_utca || '';
		this.pa_kozterulet = json && json.pa_kozterulet || '';
		this.pa_hazszam = json && json.pa_hazszam || '';
		this.pa_adoszam = json && json.pa_adoszam || '';
		this.pa_bankszamla = json && json.pa_bankszamla || '';
		this.pa_weboldal = json && json.pa_weboldal || '';
		this.pa_vevo = json && json.pa_vevo || false;
		this.pa_vevo = (this.pa_vevo === true || this.pa_vevo == 1 || this.pa_vevo == 'true') ? true : false;
		this.pa_gyarto = json && json.pa_gyarto || false;
		this.pa_gyarto = (this.pa_gyarto === true || this.pa_gyarto == 1 || this.pa_gyarto == 'true') ? true : false;
		this.pa_beszallito = json && json.pa_beszallito || false;
		this.pa_beszallito = (this.pa_beszallito === true || this.pa_beszallito == 1 || this.pa_beszallito == 'true') ? true : false;
		this.pa_viszontelado = json && json.pa_viszontelado || false;
		this.pa_viszontelado = (this.pa_viszontelado === true || this.pa_viszontelado == 1 || this.pa_viszontelado == 'true') ? true : false;
		this.pa_kedvezmeny = json && json.pa_kedvezmeny || null;

		this.pa_modifiedby = json && json.pa_modifiedby || null;
		this.pa_modifiedtime = json && json.pa_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getKod() {
		return this.pa_kod;
	}

	setKod(value) {
		this.pa_kod = value;
	}

	getNev() {
		return this.pa_nev;
	}

	setNev(value) {
		this.pa_nev = value;
	}

	getVaros() {
		return this.pa_varos;
	}

	setVaros(value) {
		this.pa_varos = value;
	}

	getIranyitoszam() {
		return this.pa_iranyitoszam;
	}

	setIranyitoszam(value) {
		this.pa_iranyitoszam = value;
	}

	getUtca() {
		return this.pa_utca;
	}

	setUtca(value) {
		this.pa_utca = value;
	}

	getKozterulet() {
		return this.pa_kozterulet;
	}

	setKozterulet(value) {
		this.pa_kozterulet = value;
	}

	getHazszam() {
		return this.pa_hazszam;
	}

	setHazszam(value) {
		this.pa_hazszam = value;
	}

	getAdoszam() {
		return this.pa_adoszam;
	}

	setAdoszam(value) {
		this.pa_adoszam = value;
	}

	getBankszamla() {
		return this.pa_bankszamla;
	}

	setBankszamla(value) {
		this.pa_bankszamla = value;
	}

	getWeboldal() {
		return this.pa_weboldal;
	}

	setWeboldal(value) {
		this.pa_weboldal = value;
	}

	isVevo() {
		return this.pa_vevo;
	}

	setVevo(value) {
		this.pa_vevo = (value === true || value == 1 || value == 'true') ? true : false;
	}

	isGyarto() {
		return this.pa_gyarto;
	}

	setGyarto(value) {
		this.pa_gyarto = (value === true || value == 1 || value == 'true') ? true : false;
	}

	isBeszallito() {
		return this.pa_beszallito;
	}

	setBeszallito(value) {
		this.pa_beszallito = (value === true || value == 1 || value == 'true') ? true : false;
	}

	isViszontelado() {
		return this.pa_viszontelado;
	}

	setViszontelado(value) {
		this.pa_viszontelado = (value === true || value == 1 || value == 'true') ? true : false;
	}

	getKedvezmeny() {
		return this.pa_kedvezmeny;
	}

	setKedvezmeny(value) {
		this.pa_kedvezmeny = value;
	}
}

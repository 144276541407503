import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FileTypeService} from 'src/app/components/settings/filetype/service/filetype.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: FileTypeService.MOD_TAG,
	templateUrl: './modfiletype.component.html',
	styleUrls: ['./modfiletype.component.scss']
})
export class ModFileTypeComponent implements OnDestroy {

	public static readonly TAG = FileTypeService.MOD_TAG;
	public static readonly NAME = "Fájl típus módosítás";
	getSettingName() {return ModFileTypeComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: FileTypeModel = new FileTypeModel({});

	constructor(
		private fileTypeService: FileTypeService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.fileTypeService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new FileTypeModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.fileTypeService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODFILETYPE_FILETYPEID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODFILETYPE_FILETYPEID);
	}

	back() {
		this.loadSettingComponent.emit(FileTypeService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
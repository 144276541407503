import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {TaskDeadlineDialog} from 'src/app/components/task/dialog/task-deadline-dialog/task-deadline-dialog';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		TaskDeadlineDialog
	],
	entryComponents: [
		TaskDeadlineDialog
	],
	exports: [
		TaskDeadlineDialog
	],
})
export class TaskDeadlineDialogModule {}
import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';

import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import { HabSzintModel } from 'src/app/components/raktar/model/habszint.model';
import { HabSzintService } from 'src/app/components/raktar/service/habszint.service';

@Component({
	selector: HabSzallitasService.ADD_TAG,
	templateUrl: './addhabszallitas.component.html',
	styleUrls: ['./addhabszallitas.component.scss']
})
export class AddHabSzallitasComponent {

	public static readonly TAG = HabSzallitasService.ADD_TAG;
	public static readonly NAME = "Habszállítás létrehozása";
	getSettingName() {return AddHabSzallitasComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: HabSzallitasModel = new HabSzallitasModel({});
	habszintekElotte:Array<HabSzintModel>=[];
	habszintekUtana:Array<HabSzintModel>=[];

	constructor(
		private habSzallitasService: HabSzallitasService,
		private habSzintService :HabSzintService,
		public dialog: MatDialog
	) {

		this.loading++;
		this.habSzintService.getAllByDate(new Date()).subscribe(
			res => this.handleGetHabSzintekResponse(res),
			error => this.handleError(error)
		);
	}

	handleGetHabSzintekResponse(serverResponse){
		this.loading--;
		for (let i = 0; i < serverResponse.habszintek.length; i++) {
			this.habszintekElotte.push(new HabSzintModel(serverResponse.habszintek[i],serverResponse.habminoseg));
			this.habszintekUtana.push(new HabSzintModel(serverResponse.habszintek[i],serverResponse.habminoseg));
		}
		
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	submit() {
		this.loading++;
		this.habSzallitasService.addWithHabszintek(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(HabSzallitasService.LIST_TAG);
	}

}
import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {RoleService} from 'src/app/components/settings/role/service/role.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';
import {RolePermissionModel} from 'src/app/components/settings/role/model/rolepermission.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

export interface PermissionCategory {
	name: string;
	permissions: Array<RolePermissionModel>;
}

@Component({
	selector: RoleService.MOD_TAG,
	templateUrl: './modrole.component.html',
	styleUrls: ['./modrole.component.scss']
})
export class ModRoleComponent implements OnDestroy {

	public static readonly TAG = RoleService.MOD_TAG;
	public static readonly NAME = "Szerepkör módosítás";
	getSettingName() {return ModRoleComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: RoleModel = new RoleModel({});
	permissioncategories: Array<PermissionCategory> = [];

	constructor(
		private roleService: RoleService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.roleService.getPermissions().subscribe(
				res => this.handleGetPermissionsResponse(res),
				error => this.handleGetPermissionsError(error)
			);

			this.loading++;
			this.roleService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetPermissionsError(serverError) {
		this.handleError(serverError);
	}

	handleGetPermissionsResponse(serverResponse) {
		this.loading--;
		this.permissioncategories = [];

		for (let i = 0; i < serverResponse.length; i++) {
			let tmpRolePermission = new RolePermissionModel(serverResponse[i]);
			let categoryExists: number = -1;

			this.permissioncategories.forEach((category, idx) => {
				if (category.name == tmpRolePermission.getCategory()) {
					categoryExists = idx;
					return;
				}
			});

			if (categoryExists > -1) {
				this.permissioncategories[categoryExists].permissions.push(tmpRolePermission);
			} else {
				let tmpCategory: PermissionCategory = {
					name: tmpRolePermission.getCategory(),
					permissions: [
						tmpRolePermission
					]
				};
				this.permissioncategories.push(tmpCategory);
			}
		}
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new RoleModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.roleService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODROLE_ROLEID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODROLE_ROLEID);
	}

	back() {
		this.loadSettingComponent.emit(RoleService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

	permissionSelectCompare(permission1, permission2) {
		return permission1.getPermission() == permission2.getPermission();
	}

}
export class LemezSzelessegModel {

	private id: number = null;

	private szelesseg: number = null;

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.szelesseg = json && json.szelesseg || null;

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getSzelesseg() {
		return this.szelesseg;
	}

	setSzelesseg(value) {
		this.szelesseg = value;
	}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';

@Injectable()
export class TaskRefService {

	public static readonly API_TYPE: string = 'taskref';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + TaskRefService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + TaskRefService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + TaskRefService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + TaskRefService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + TaskRefService.API_TYPE + '/del_request';

	public static readonly LIST_TAG: string = 'setting-taskrefs';
	public static readonly ADD_TAG: string = 'setting-addtaskref';
	public static readonly MOD_TAG: string = 'setting-modtaskref';
	public static readonly DEL_TAG: string = 'setting-deltaskref';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskRefService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskRefService.GET_API, params, {params: parameters});
	}

	add(obj: TaskRefModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tre_parent', String(obj.getParent()));
		params.append('tre_child', String(obj.getChild()));
		params.append('tre_assigntype', String(obj.getAssigntype()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRefService.ADD_API, params, {params: parameters});
	}

	mod(obj: TaskRefModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('tre_parent', String(obj.getParent()));
		params.append('tre_child', String(obj.getChild()));
		params.append('tre_assigntype', String(obj.getAssigntype()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRefService.MOD_API, params, {params: parameters});
	}

	del(objid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(objid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskRefService.DEL_API, params, {params: parameters});
	}
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from 'src/app/components/login/login.component';
import {LogoutComponent} from 'src/app/components/logout/logout.component';
import {TasksComponent} from 'src/app/components/task/component/tasks/tasks.component';
import {ProjectListComponent} from 'src/app/components/projectlist/projectlist.component';
import {ProjectInfoComponent} from 'src/app/components/projectinfo/projectinfo.component';
import {SzerelesekComponent} from 'src/app/components/szereles/component/szerelesek/szerelesek.component';
import {SzamlazasComponent} from 'src/app/components/szamlazas/component/szamlazas/szamlazas.component';
import {RaktarMenuComponent} from 'src/app/components/raktar/component/raktarmenu/raktarmenu.component';
import {WelcomeComponent} from 'src/app/components/welcome/welcome.component';

import {AnonymousGuardService} from 'src/app/services/anonymous-guard.service';
import {AuthGuardService} from 'src/app/services/auth-guard.service';
import {AdminGuardService} from 'src/app/services/admin-guard.service';


const appRoutes: Routes = [
	{
		path: 'bejelentkezes',
		component: LoginComponent,
		canActivate: [AnonymousGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'kijelentkezes',
		component: LogoutComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: 'feladatok',
		component: TasksComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: true
		}
	},
	{
		path: 'projektek',
		component: ProjectListComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: true
		}
	},
	{
		path: 'projekt',
		component: ProjectInfoComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: true
		}
	},
	{
		path: 'szerelesek',
		component: SzerelesekComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: true
		}
	},
	{
		path:'szamlak',
		component:SzamlazasComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar:true
		}

	},
	{
		path: '',
		component: WelcomeComponent,
		canActivate: [AuthGuardService],
		data: {
			navbar: false
		}
	},
	{
		path: '**',
		canActivate: [AuthGuardService],
		redirectTo: '',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}

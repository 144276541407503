export class LemezSzinModel {

	private id: number = null;

	private szin: string = '';

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.szin = json && json.szin || '';

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getSzin() {
		return this.szin;
	}

	setSzin(value) {
		this.szin = value;
	}
}

import {UserModel} from 'src/app/components/settings/user/model/user.model';

export class TaskCommentModel {

	private id: number = null;

	private tc_projectid: number = null;
	private tc_taskid: number = null;
	private tc_comment: string = '';

	private tc_modifiedby: number = null;
	private user: UserModel = new UserModel({});
	private tc_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.tc_projectid = json && json.tc_projectid || null;
		this.tc_taskid = json && json.tc_taskid || null;
		this.tc_comment = json && json.tc_comment || '';

		this.tc_modifiedby = json && json.tc_modifiedby || null;
		this.user = new UserModel(json && json.user || {});
		this.tc_modifiedtime = json && json.tc_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getProjectid() {
		return this.tc_projectid;
	}

	setProjectid(value) {
		this.tc_projectid = value;
	}

	getTaskid() {
		return this.tc_taskid;
	}

	setTaskid(value) {
		this.tc_taskid = value;
	}

	getComment() {
		return this.tc_comment;
	}

	setComment(value) {
		this.tc_comment = value;
	}

	getUserModel() {
		return this.user;
	}

	getDateTime() {
		return this.tc_modifiedtime;
	}
}

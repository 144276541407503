import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

@Injectable()
export class GablerSzerelesService {

	public static readonly API_TYPE: string = 'form';
	public static readonly FORM_TYPE: string = 'gablerszereles';

	public static readonly GET_DATA_API: string = AppConstants.BASE_URL + '/api/' + GablerSzerelesService.API_TYPE + '/' + GablerSzerelesService.FORM_TYPE + '/get_data_request';
	public static readonly ASSIGN_API: string = AppConstants.BASE_URL + '/api/' + GablerSzerelesService.API_TYPE + '/' + GablerSzerelesService.FORM_TYPE + '/assign_request';
	public static readonly SAVE_API: string = AppConstants.BASE_URL + '/api/' + GablerSzerelesService.API_TYPE + '/' + GablerSzerelesService.FORM_TYPE + '/save_request';
	public static readonly DONE_API: string = AppConstants.BASE_URL + '/api/' + GablerSzerelesService.API_TYPE + '/' + GablerSzerelesService.FORM_TYPE + '/done_request';
	public static readonly STORNO_API: string = AppConstants.BASE_URL + '/api/' + GablerSzerelesService.API_TYPE + '/' + GablerSzerelesService.FORM_TYPE + '/storno_request';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getData(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(GablerSzerelesService.GET_DATA_API, params, {params: parameters});
	}

	assign(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(GablerSzerelesService.ASSIGN_API, params, {params: parameters});
	}

	save(id: number, formdata: object) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));
		params.append('formdata', JSON.stringify(formdata));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(GablerSzerelesService.SAVE_API, params, {params: parameters});
	}

	done(id: number, taskRefs: Array<object>) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));
		params.append('taskrefs', JSON.stringify(taskRefs));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(GablerSzerelesService.DONE_API, params, {params: parameters});
	}

	storno(id: number, note: string) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));
		params.append('note', note);

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(GablerSzerelesService.STORNO_API, params, {params: parameters});
	}
}

import {Component, AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, InjectionToken, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SettingsMainComponent} from 'src/app/components/settings/settings-main/settings-main.component';

@Component({
	selector: 'app-settings-dialog',
	templateUrl: './settings-dialog.html',
	styleUrls: ['./settings-dialog.scss']
})
export class SettingsDialog implements AfterViewInit {

	public static readonly compMapToken = new InjectionToken<Array<Object>>('compMap');

	@ViewChild('settingsHost', {
		read: ViewContainerRef
	}) settingsHost: ViewContainerRef;

	backStack: Array<string> = [];

	constructor(
		private dialogRef: MatDialogRef<SettingsDialog>,
		private resolver: ComponentFactoryResolver,
		@Inject(SettingsDialog.compMapToken) public compMap
	) {}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => this.loadComponent(null));
	}

	loadComponent(component: string) {
		if (component == null) {
			component = SettingsMainComponent.TAG;
		}

		if (this.backStack.length > 1 && this.backStack[this.backStack.length - 2] == component) {
			this.backSettings();
			return;
		}

		this.settingsHost.clear();

		if (component in this.compMap) {
			const compFactory = this.resolver.resolveComponentFactory(this.compMap[component]);
			const compRef = this.settingsHost.createComponent(compFactory);

			if (component != SettingsMainComponent.TAG && this.backStack[this.backStack.length - 1] != component) {
				this.backStack.push(component);
			}

			(compRef.instance as any).loadSettingComponent.subscribe(evt => this.loadComponent(evt));
		}
	}

	backSettings() {
		this.backStack.pop();
		if (this.backStack.length > 0) {
			this.loadComponent(this.backStack[this.backStack.length - 1]);
		} else {
			this.loadComponent(null);
		}
	}

	closeDialog() {
		this.dialogRef.close(false);
	}
}
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {LemezRaktarModel} from 'src/app/components/settings/raktar/lemezraktar/model/lemezraktar.model';

@Injectable()
export class LemezRaktarService {

	public static readonly API_TYPE: string = 'lemezraktar';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + LemezRaktarService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + LemezRaktarService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + LemezRaktarService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + LemezRaktarService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-lemezraktarak';
	public static readonly ADD_TAG: string = 'setting-addlemezraktar';
	public static readonly MOD_TAG: string = 'setting-modlemezraktar';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezRaktarService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezRaktarService.GET_API, params, {params: parameters});
	}

	add(obj: LemezRaktarModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('raktar', obj.getRaktar());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezRaktarService.ADD_API, params, {params: parameters});
	}

	mod(obj: LemezRaktarModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('raktar', obj.getRaktar());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezRaktarService.MOD_API, params, {params: parameters});
	}
}

import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {SzamlazasService} from 'src/app/components/szamlazas/service/szamlazas.service';

import {SzamlazasComponent} from 'src/app/components/szamlazas/component/szamlazas/szamlazas.component';

import {SzamlazasDialog} from 'src/app/components/szamlazas/dialog/szamlazas-dialog/szamlazas-dialog';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		SzamlazasComponent,
		SzamlazasDialog
	],
	entryComponents: [
		SzamlazasComponent,
		SzamlazasDialog
	],
	providers: [
		SzamlazasService
	],
	exports: [
		SzamlazasComponent,
		SzamlazasDialog
	]
})
export class SzamlazasModule {}
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddHabMinosegComponent} from 'src/app/components/settings/raktar/habminoseg/component/addhabminoseg/addhabminoseg.component';
import {ModHabMinosegComponent} from 'src/app/components/settings/raktar/habminoseg/component/modhabminoseg/modhabminoseg.component';
import {HabMinosegekComponent} from 'src/app/components/settings/raktar/habminoseg/component/habminosegek/habminosegek.component';

import {HabMinosegService} from 'src/app/components/settings/raktar/habminoseg/service/habminoseg.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddHabMinosegComponent,
		ModHabMinosegComponent,
		HabMinosegekComponent
	],
	entryComponents: [
		AddHabMinosegComponent,
		ModHabMinosegComponent,
		HabMinosegekComponent
	],
	providers: [
		HabMinosegService
	],
	exports: [
		AddHabMinosegComponent,
		ModHabMinosegComponent,
		HabMinosegekComponent
	],
})
export class HabMinosegModule {
	public static readonly compArray = [
		AddHabMinosegComponent,
		ModHabMinosegComponent,
		HabMinosegekComponent
	];

	public static readonly compMap = HabMinosegModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in HabMinosegModule.compMap) ? HabMinosegModule.compMap[TAG].NAME : '';
	}
}
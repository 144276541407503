import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {LemezSzallitasService} from 'src/app/components/raktar/service/lemezszallitas.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';
import {LemezSzallitasModel} from 'src/app/components/raktar/model/lemezszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: LemezSzallitasService.LIST_TAG,
	templateUrl: './lemezszallitasok.component.html',
	styleUrls: ['./lemezszallitasok.component.scss']
})
export class LemezSzallitasokComponent {

	public static readonly TAG = LemezSzallitasService.LIST_TAG;
	public static readonly NAME = "Lemezszállitások";
	getSettingName() {return LemezSzallitasokComponent.NAME;}

	public static readonly _requiredPermissions = ['Modules:LemezSzallitas:list', 'Modules:LemezSzallitas:add', 'Modules:LemezSzallitas:mod','Modules:LemezSzallitas:del'];
	public static readonly _adminOnly = false;
	public static readonly _icon = ['fas', 'truck'];
	public static readonly _category = "Raktár";

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	displayedColumns: string[] = ['id', 'gyarto', 'szin', 'szelesseg', 'vastagsag', 'szallitolevel', 'tomeg', 'aktualistomeg', 'nettoar', 'idopont', 'action'];
	dataSource: MatTableDataSource<LemezSzallitasModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	filterValue: string = "";

	constructor(
		private lemezSzallitasService: LemezSzallitasService,
		public dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getLemezGyartoModel().getGyarto(),
				row.getLemezSzinModel().getSzin(),
				String(row.getLemezSzelessegModel().getSzelesseg()),
				String(row.getLemezVastagsagModel().getVastagsag()),
				row.getSzallitolevel(),
				String(row.getTomeg()),
				String(row.getAktualistomeg()),
				String(row.getNettoar()),
				row.getIdopontString()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<LemezSzallitasModel> = [];
		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new LemezSzallitasModel(serverResponse[i]));
		}
		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
 				case 'gyarto': return item.getLemezGyartoModel().getGyarto();
 				case 'szin': return item.getLemezSzinModel().getSzin();
 				case 'szelesseg': return item.getLemezSzelessegModel().getSzelesseg();
 				case 'vastagsag': return item.getLemezVastagsagModel().getVastagsag();
				case 'szallitolevel' : return item.getSzallitolevel();
				case 'tomeg' : return item.getTomeg();
				case 'aktualistomeg' : return item.getAktualistomeg();
				case 'nettoar' : return item.getNettoar();
 				case 'idopont': return item.getIdopontString();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.lemezSzallitasService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	add() {
		this.loadSettingComponent.emit(LemezSzallitasService.ADD_TAG);
	}

	mod(id) {
		sessionStorage.setItem(AppConstants.PARAM_MODLEMEZSZALLITAS_LEMEZSZALLITASID, id);
		this.loadSettingComponent.emit(LemezSzallitasService.MOD_TAG);
	}

	del(id) {
		sessionStorage.setItem(AppConstants.PARAM_DELLEMEZSZALLITAS_LEMEZSZALLITASID, id);
		this.loadSettingComponent.emit(LemezSzallitasService.DEL_TAG);
	}

}

import {Component, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {GablerAruRendelesService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerarurendeles/gablerarurendeles.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {ListsModel} from 'src/app/model/lists.model';

export interface RendelesFej {
	id: string;
	projectid: string;

	tetelek: Array<RendelesTetel>;

	szallito: RendelesSzallito;
	szallitoCtrl: FormControl;
	filteredSzallitok: Observable<RendelesSzallito[]>;

	rendelesidopont: any;
	szallitasihatarido: any;
	ellenorzesidopontja: any;
	beerkezesidopontja: any;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}

export interface RendelesTetel {
	id: string;

	tetelCtrl: FormControl;
	filteredTetelek: Observable<RendelesTetel[]>;

	rendelesid: string;
	megnevezes: string;
	egyseg: string;
	mennyiseg: string;
	egysegar: string;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}

export interface RendelesSzallito {
	id: string;
	szallitonev: string;
	iranyitoszam: string;
	varos: string;
	utca: string;
	hazszam: string;
	kapcsolattarto: string;
	telefon: string;
	email: string;
	modifiedby: string;
	modifiedtime: any;
}

@Component({
	selector: 'form-gablerarurendeles',
	templateUrl: './gablerarurendeles.component.html',
	styleUrls: ['./gablerarurendeles.component.scss']
})
export class GablerAruRendelesComponent {

	public static readonly TAG: string = 'form-gablerarurendeles';
	public static readonly NAME: string = 'Áru rendelés (Gabler)';
	public static readonly configComponent = null;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	formdata: Array<RendelesFej> = [];
	tetelek: Array<RendelesTetel> = [];
	szallitok: Array<RendelesSzallito> = [];

	selectedForm: number = 0;

	constructor(
		public gablerAruRendelesService: GablerAruRendelesService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.gablerAruRendelesService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpRendelesek: Array<RendelesFej> = [];

		this.formdata.forEach((rendeles) => {
			let tmpRendeles: RendelesFej = {...rendeles};
			delete tmpRendeles['szallitoCtrl'];
			delete tmpRendeles['filteredSzallitok'];
			for (let key in tmpRendeles) {
				tmpRendeles[key] = this.transform(tmpRendeles[key]);
			}

			let tmpTetelek: Array<RendelesTetel> = [];

			rendeles.tetelek.forEach((tetel) => {
				let tmpTetel: RendelesTetel = {...tetel};
				delete tmpTetel['tetelCtrl'];
				delete tmpTetel['filteredTetelek'];
				for (let key in tmpTetel) {
					tmpTetel[key] = this.transform(tmpTetel[key]);
				}
				tmpTetelek.push(tmpTetel);
			});

			tmpRendeles.tetelek = tmpTetelek;

			tmpRendelesek.push(tmpRendeles);
		});

		return tmpRendelesek;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerAruRendelesService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	save() {
		this.increaseLoading.emit(null);
		this.gablerAruRendelesService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerAruRendelesService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
					res => this.handleSaveDoneResponse(res),
					error => this.handleSaveDoneError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerAruRendelesService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.szallitok = [];

		for (let i = 0; i < serverResponse.szallitok.length; i++) {
			this.szallitok.push({
				id: serverResponse.szallitok[i].id,
				szallitonev: serverResponse.szallitok[i].szallitonev,
				iranyitoszam: serverResponse.szallitok[i].iranyitoszam,
				varos: serverResponse.szallitok[i].varos,
				utca: serverResponse.szallitok[i].utca,
				hazszam: serverResponse.szallitok[i].hazszam,
				kapcsolattarto: serverResponse.szallitok[i].kapcsolattarto,
				telefon: serverResponse.szallitok[i].telefon,
				email: serverResponse.szallitok[i].email,
				modifiedby: serverResponse.szallitok[i].modifiedby,
				modifiedtime: serverResponse.szallitok[i].modifiedtime
			});
		}

		this.tetelek = [];

		for (let i = 0; i < serverResponse.tetelek.length; i++) {
			this.tetelek.push({
				id: null,

				tetelCtrl: null,
				filteredTetelek: null,

				rendelesid: null,
				megnevezes: serverResponse.tetelek[i].megnevezes,
				egyseg: null,
				mennyiseg: null,
				egysegar: null,
				modifiedby: null,
				modifiedtime: null,
				deletedby: null,
				deletedtime: null
			});
		}

		this.formdata = [];

		for (let i = 0; i < serverResponse.rendelesek.length; i++) {
			let tmpRendeles: RendelesFej = {
				id: serverResponse.rendelesek[i].id,
				projectid: serverResponse.rendelesek[i].projectid,
				tetelek: [],
				szallito: {
					id: null,
					szallitonev: serverResponse.rendelesek[i].szallitonev,
					iranyitoszam: serverResponse.rendelesek[i].iranyitoszam,
					varos: serverResponse.rendelesek[i].varos,
					utca: serverResponse.rendelesek[i].utca,
					hazszam: serverResponse.rendelesek[i].hazszam,
					kapcsolattarto: serverResponse.rendelesek[i].kapcsolattarto,
					telefon: serverResponse.rendelesek[i].telefon,
					email: serverResponse.rendelesek[i].email,
					modifiedby: null,
					modifiedtime: null
				},
				szallitoCtrl: new FormControl(),
				filteredSzallitok: null,
				rendelesidopont: CommonModel.isDate(serverResponse.rendelesek[i].rendelesidopont) ? (new Date(Date.parse(serverResponse.rendelesek[i].rendelesidopont))) : null,
				szallitasihatarido: CommonModel.isDate(serverResponse.rendelesek[i].szallitasihatarido) ? (new Date(Date.parse(serverResponse.rendelesek[i].szallitasihatarido))) : null,
				ellenorzesidopontja: CommonModel.isDate(serverResponse.rendelesek[i].ellenorzesidopontja) ? (new Date(Date.parse(serverResponse.rendelesek[i].ellenorzesidopontja))) : null,
				beerkezesidopontja: CommonModel.isDate(serverResponse.rendelesek[i].beerkezesidopontja) ? (new Date(Date.parse(serverResponse.rendelesek[i].beerkezesidopontja))) : null,
				modifiedby: serverResponse.rendelesek[i].modifiedby,
				modifiedtime: serverResponse.rendelesek[i].modifiedtime,
				deletedby: serverResponse.rendelesek[i].deletedby,
				deletedtime: serverResponse.rendelesek[i].deletedtime
			};

			tmpRendeles.filteredSzallitok = tmpRendeles.szallitoCtrl.valueChanges
				.pipe(
					startWith(''),
					map(szallito => szallito ? this._filterSzallitok(szallito) : this.szallitok.slice())
				);

			for (let j = 0; j < serverResponse.rendelesek[i].tetelek.length; j++) {
				let tmpTetel: RendelesTetel = {
					id: serverResponse.rendelesek[i].tetelek[j].id,

					tetelCtrl: new FormControl(),
					filteredTetelek: null,

					rendelesid: serverResponse.rendelesek[i].tetelek[j].rendelesid,
					megnevezes: serverResponse.rendelesek[i].tetelek[j].megnevezes,
					egyseg: serverResponse.rendelesek[i].tetelek[j].egyseg,
					mennyiseg: serverResponse.rendelesek[i].tetelek[j].mennyiseg,
					egysegar: serverResponse.rendelesek[i].tetelek[j].egysegar,
					modifiedby: null,
					modifiedtime: null,
					deletedby: null,
					deletedtime: null
				};

				tmpTetel.filteredTetelek = tmpTetel.tetelCtrl.valueChanges
					.pipe(
						startWith(''),
						map(tetel => tetel ? this._filterTetelek(tetel) : this.tetelek.slice())
					);

				tmpRendeles.tetelek.push(tmpTetel);
			}

			this.formdata.push(tmpRendeles);
		}
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
		this.increaseLoading.emit(null);
		this.gablerAruRendelesService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.gablerAruRendelesService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

	addRendeles() {
		let tmpRendeles: RendelesFej = {
			id: null,
			projectid: null,
			tetelek: [],
			szallito: {
				id: null,
				szallitonev: null,
				iranyitoszam: null,
				varos: null,
				utca: null,
				hazszam: null,
				kapcsolattarto: null,
				telefon: null,
				email: null,
				modifiedby: null,
				modifiedtime: null
			},
			szallitoCtrl: new FormControl(),
			filteredSzallitok: null,
			rendelesidopont: null,
			szallitasihatarido: null,
			ellenorzesidopontja: null,
			beerkezesidopontja: null,
			modifiedby: null,
			modifiedtime: null,
			deletedby: null,
			deletedtime: null
		};

		tmpRendeles.filteredSzallitok = tmpRendeles.szallitoCtrl.valueChanges
			.pipe(
				startWith(''),
				map(szallito => szallito ? this._filterSzallitok(szallito) : this.szallitok.slice()),
			);

		this.formdata.push(tmpRendeles);
		this.selectedForm = this.formdata.length - 1;
	}

	removeRendeles() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a kiválasztott rendelést?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (this.selectedForm >= 0 && this.formdata.length > 0) {
					this.formdata.splice(this.selectedForm, 1);
				}
			}
			this.confirmDialogRef = null;
		});
	}

	onSzallitoSelect(event: MatAutocompleteSelectedEvent, rendelesIdx) {
		let szallitoId = event.option.value;
		this.formdata[rendelesIdx].szallito.szallitonev = '';

		this.szallitok.forEach((szallito) => {
			if (szallito.id == szallitoId) {
				this.formdata[rendelesIdx].szallito.szallitonev = szallito.szallitonev;
				this.formdata[rendelesIdx].szallito.iranyitoszam = szallito.iranyitoszam;
				this.formdata[rendelesIdx].szallito.varos = szallito.varos;
				this.formdata[rendelesIdx].szallito.utca = szallito.utca;
				this.formdata[rendelesIdx].szallito.hazszam = szallito.hazszam;
				this.formdata[rendelesIdx].szallito.kapcsolattarto = szallito.kapcsolattarto;
				this.formdata[rendelesIdx].szallito.telefon = szallito.telefon;
				this.formdata[rendelesIdx].szallito.email = szallito.email;
			}
		});
	}

	addTetel(rendelesIdx) {
		let tmpTetel: RendelesTetel = {
			id: null,

			tetelCtrl: new FormControl(),
			filteredTetelek: null,

			rendelesid: null,
			megnevezes: null,
			egyseg: null,
			mennyiseg: null,
			egysegar: null,
			modifiedby: null,
			modifiedtime: null,
			deletedby: null,
			deletedtime: null
		};

		tmpTetel.filteredTetelek = tmpTetel.tetelCtrl.valueChanges
			.pipe(
				startWith(''),
				map(tetel => tetel ? this._filterTetelek(tetel) : this.tetelek.slice())
			);

		this.formdata[rendelesIdx].tetelek.splice(0, 0, tmpTetel);
	}

	removeTetel(rendelesIdx, tetelIdx) {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli ezt a tételt?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.formdata[rendelesIdx].tetelek.splice(tetelIdx, 1);
			}
			this.confirmDialogRef = null;
		});
	}

	private _filterTetelek(value: string): RendelesTetel[] {
		const filterValue = CommonModel.replaceHunChars(String(value).toLowerCase());

		return this.tetelek.filter(tetel => tetel.megnevezes != null ? CommonModel.replaceHunChars(tetel.megnevezes.toLowerCase()).includes(filterValue) : false);
	}

	private _filterSzallitok(value: string): RendelesSzallito[] {
		const filterValue = CommonModel.replaceHunChars(String(value).toLowerCase());

		return this.szallitok.filter(szallito => szallito.szallitonev != null ? CommonModel.replaceHunChars(szallito.szallitonev.toLowerCase()).includes(filterValue) : false);
	}

}
<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar color="primary">
		  <mat-toolbar-row>
			   <span style="max-width: 90%; white-space: pre-wrap;">{{targetObj.project.getName()}} számla</span>
			   <span class="spacer"></span>
			   <button mat-icon-button (click)="dialogRef.close(false)">
					   <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
			   </button>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <mat-dialog-content>
		  <div class="szamla-wrapper px-2 py-2">
					<form>
						 <mat-form-field appearance="standard">
							  <mat-label>Számla típus</mat-label>
							  <mat-select name="inputSzamlatipus" [(ngModel)]="targetObj.szamlatipus">
									 <mat-option *ngFor="let szamlatype of szamlatypes" [value]="szamlatype.value">{{szamlatype.text}}</mat-option>
							  </mat-select>
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
								<mat-label>Alap bizonylat</mat-label>
								<input matInput name="inputSorszam" [(ngModel)]="targetObj.sorszam">
					   	</mat-form-field>
						 <br>
						 <mat-form-field>
							  <mat-label>Teljesítés dátuma</mat-label>
							  <input matInput name="inputTeljesites" [(ngModel)]="targetObj.teljesites" [owlDateTime]="inputTeljesitesPicker" [owlDateTimeTrigger]="inputTeljesitesPicker">
							  <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputTeljesitesPicker></owl-date-time>
						 </mat-form-field>
						 <br>
						 <mat-form-field>
							  <mat-label>Kiállítás dátuma</mat-label>
							  <input matInput name="inputKiallitas" [(ngModel)]="targetObj.kiallitas" [owlDateTime]="inputKiallitasPicker" [owlDateTimeTrigger]="inputKiallitasPicker">
							  <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputKiallitasPicker></owl-date-time>
						 </mat-form-field>
						 <br>
						 <mat-form-field>
							<mat-label>Fizetési határidő</mat-label>
							<input matInput name="inputHatarido" [(ngModel)]="targetObj.hatarido" [owlDateTime]="inputHataridoPicker" [owlDateTimeTrigger]="inputHataridoPicker">
							<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputHataridoPicker></owl-date-time>
					   	 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
								<mat-label>Pénznem</mat-label>
								<input matInput name="inputPenznem" [(ngModel)]="targetObj.penznem">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							<mat-label>Bruttó összeg</mat-label>
							<input matInput type="number" step="1" name="inputBrutto" [ngModel]="targetObj.brutto" (ngModelChange)="bruttoChange($event)">
					     </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
								<mat-label>Kifizetve-bruttó</mat-label>
								<input matInput type="number" step="1" name="inputKifizetve" [ngModel]="targetObj.kifizetve" (ngModelChange)="kifizetveChange($event)">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							<mat-label>Különbözet</mat-label>
							<input matInput type="number" step="1" name="inputKulonbozet" [ngModel]="kulonbozet" readonly>
					 	</mat-form-field>
						 <br>
					     <mat-form-field appearance="standard">
							<mat-label>Sorszám</mat-label>
							<input matInput name="inputVaros" [(ngModel)]="targetObj.szamla">
				   		 </mat-form-field>
						 <br>
						 <mat-form-field>
							<mat-label>Beérkezés dátuma</mat-label>
							<input matInput name="inputBeérkezés" [(ngModel)]="targetObj.beerkezes" [owlDateTime]="inputBeerkezesPicker" [owlDateTimeTrigger]="inputBeerkezesPicker">
							<owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputBeerkezesPicker></owl-date-time>
					   </mat-form-field>
					</form>
		  </div>
		  <br>
		  <div class="dialogButtons">
			   <button mat-flat-button color="primary" (click)="save()">Mentés</button>
			   <button mat-flat-button color="warn" (click)="dialogRef.close(false)">Mégsem</button>
		  </div>
	 </mat-dialog-content>
</div>

import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddTaskConditionComponent} from 'src/app/components/settings/taskcondition/component/addtaskcondition/addtaskcondition.component';
import {ModTaskConditionComponent} from 'src/app/components/settings/taskcondition/component/modtaskcondition/modtaskcondition.component';
import {DelTaskConditionComponent} from 'src/app/components/settings/taskcondition/component/deltaskcondition/deltaskcondition.component';
import {TaskConditionsComponent} from 'src/app/components/settings/taskcondition/component/taskconditions/taskconditions.component';

import {TaskConditionService} from 'src/app/components/settings/taskcondition/service/taskcondition.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddTaskConditionComponent,
		ModTaskConditionComponent,
		DelTaskConditionComponent,
		TaskConditionsComponent
	],
	entryComponents: [
		AddTaskConditionComponent,
		ModTaskConditionComponent,
		DelTaskConditionComponent,
		TaskConditionsComponent
	],
	providers: [
		TaskConditionService
	],
	exports: [
		AddTaskConditionComponent,
		ModTaskConditionComponent,
		DelTaskConditionComponent,
		TaskConditionsComponent
	],
})
export class TaskConditionModule {
	public static readonly compArray = [
		AddTaskConditionComponent,
		ModTaskConditionComponent,
		DelTaskConditionComponent,
		TaskConditionsComponent
	];

	public static readonly compMap = TaskConditionModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in TaskConditionModule.compMap) ? TaskConditionModule.compMap[TAG].NAME : '';
	}
}
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezBeemelesService} from 'src/app/components/raktar/service/lemezbeemeles.service';

import {LemezSzallitasModel} from 'src/app/components/raktar/model/lemezszallitas.model';
import {LemezGyartoModel} from "src/app/components/settings/raktar/lemezgyarto/model/lemezgyarto.model";
import {LemezSzinModel} from "src/app/components/settings/raktar/lemezszin/model/lemezszin.model";
import {LemezAnyagminosegModel} from "src/app/components/settings/raktar/lemezanyagminoseg/model/lemezanyagminoseg.model";
import {LemezSzelessegModel} from "src/app/components/settings/raktar/lemezszelesseg/model/lemezszelesseg.model";
import {LemezVastagsagModel} from "src/app/components/settings/raktar/lemezvastagsag/model/lemezvastagsag.model";

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-lemezbeemeles',
	templateUrl: './lemezbeemeles.component.html',
	styleUrls: ['./lemezbeemeles.component.scss']
})
export class LemezBeemelesComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	selectedIdx: number = -1;

	szallitasok: Array<LemezSzallitasModel> = [];
	filteredSzallitasok: Array<LemezSzallitasModel> = [];

	idopont: any = new Date;

	gyartok: Array<LemezGyartoModel> = [];
	szinek: Array<LemezSzinModel> = [];
	anyagminosegek: Array<LemezAnyagminosegModel> = [];
	szelessegek: Array<LemezSzelessegModel> = [];
	vastagsagok: Array<LemezVastagsagModel> = [];

	filterGyarto: number = -1;
	filterSzin: number = -1;
	filterAnyagminoseg: number = -1;
	filterSzelesseg: number = -1;
	filterVastagsag: number = -1;
	noFilterValue: number = -1;

	filterPage: boolean = true;

	constructor(
		private lemezBeemelesService: LemezBeemelesService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.loading++;
		this.lemezBeemelesService.getSzallitasok().subscribe(
			res => this.handleGetSzallitasokResponse(res),
			error => this.handleGetSzallitasokError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetSzallitasokError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetSzallitasokResponse(serverResponse) {
		this.loading--;

		this.filterPage = true;

		let gyartok: Array<LemezGyartoModel> = [];

		for (let i = 0; i < serverResponse.gyartok.length; i++) {
			gyartok.push(new LemezGyartoModel(serverResponse.gyartok[i]));
		}

		this.gyartok = gyartok;

		let szinek: Array<LemezSzinModel> = [];

		for (let i = 0; i < serverResponse.szinek.length; i++) {
			szinek.push(new LemezSzinModel(serverResponse.szinek[i]));
		}

		this.szinek = szinek;

		let anyagminosegek: Array<LemezAnyagminosegModel> = [];

		for (let i = 0; i < serverResponse.anyagminosegek.length; i++) {
			anyagminosegek.push(new LemezAnyagminosegModel(serverResponse.anyagminosegek[i]));
		}

		this.anyagminosegek = anyagminosegek;

		let szelessegek: Array<LemezSzelessegModel> = [];

		for (let i = 0; i < serverResponse.szelessegek.length; i++) {
			szelessegek.push(new LemezSzelessegModel(serverResponse.szelessegek[i]));
		}

		this.szelessegek = szelessegek;

		let vastagsagok: Array<LemezVastagsagModel> = [];

		for (let i = 0; i < serverResponse.vastagsagok.length; i++) {
			vastagsagok.push(new LemezVastagsagModel(serverResponse.vastagsagok[i]));
		}

		this.vastagsagok = vastagsagok;

		let szallitasok: Array<LemezSzallitasModel> = [];

		for (let i = 0; i < serverResponse.szallitasok.length; i++) {
			szallitasok.push(new LemezSzallitasModel(serverResponse.szallitasok[i]));
		}

		this.szallitasok = szallitasok;
	}

	prev() {
		if (this.selectedIdx > 0) {
			this.selectedIdx--;
		}
	}

	next() {
		if (this.selectedIdx + 1 < this.filteredSzallitasok.length) {
			this.selectedIdx++;
		}
	}

	filter() {
		let filteredSzallitasok: Array<LemezSzallitasModel> = [...this.szallitasok];

		if (this.filterGyarto != -1) {
			filteredSzallitasok = filteredSzallitasok.filter(item => item.getLemezGyartoid() == this.filterGyarto);
		}

		if (this.filterSzin != -1) {
			filteredSzallitasok = filteredSzallitasok.filter(item => item.getLemezSzinid() == this.filterSzin);
		}

		if (this.filterAnyagminoseg != -1) {
			filteredSzallitasok = filteredSzallitasok.filter(item => item.getLemezAnyagminosegid() == this.filterAnyagminoseg);
		}

		if (this.filterSzelesseg != -1) {
			filteredSzallitasok = filteredSzallitasok.filter(item => item.getLemezSzelessegid() == this.filterSzelesseg);
		}

		if (this.filterVastagsag != -1) {
			filteredSzallitasok = filteredSzallitasok.filter(item => item.getLemezVastagsagid() == this.filterVastagsag);
		}

		this.filteredSzallitasok = [...filteredSzallitasok];

		if (this.filteredSzallitasok.length > 0) {
			this.selectedIdx = this.filteredSzallitasok.length - 1;
			this.filterPage = false;
		} else {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = 'Nincs a szűrő feltételnek megfelelő lemez';
		}
	}

	back() {
		this.filterPage = true;
	}

	save() {
		this.loading++;
		this.lemezBeemelesService.lemezBeemeles(this.filteredSzallitasok[this.selectedIdx].getId(), this.idopont).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = 'Lemez beemelve';

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

}

import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {AltalanosFeladatModule} from 'src/app/components/task/dialog/task-dialog/taskforms/altalanosfeladat/altalanosfeladat.module';
import {GablerAruRendelesModule} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerarurendeles/gablerarurendeles.module';
import {GablerProjektInditasModule} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.module';
import {GablerSzamlazasModule} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszamlazas/gablerszamlazas.module';
import {GablerSzerelesModule} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerszereles/gablerszereles.module';
import {JovahagyasModule} from 'src/app/components/task/dialog/task-dialog/taskforms/jovahagyas/jovahagyas.module';
import {SzallitasModule} from 'src/app/components/task/dialog/task-dialog/taskforms/szallitas/szallitas.module';


@NgModule({
	imports: [
		CommonModules,
		AltalanosFeladatModule,
		GablerAruRendelesModule,
		GablerProjektInditasModule,
		GablerSzamlazasModule,
		GablerSzerelesModule,
		JovahagyasModule,
		SzallitasModule
	]
})

export class TaskFormsModule {
	public static readonly compArray = [
		AltalanosFeladatModule.component,
		GablerAruRendelesModule.component,
		GablerProjektInditasModule.component,
		GablerSzamlazasModule.component,
		GablerSzerelesModule.component,
		JovahagyasModule.component,
		SzallitasModule.component
	];

	public static readonly compMap = TaskFormsModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in TaskFormsModule.compMap) ? TaskFormsModule.compMap[TAG].NAME : '';
	}
}
<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Partner kód</mat-label>
			   <input matInput name="inputKod" [ngModel]="targetObj.getKod()" (ngModelChange)="targetObj.setKod($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Partner neve</mat-label>
			   <input matInput name="inputNev" [ngModel]="targetObj.getNev()" (ngModelChange)="targetObj.setNev($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Város</mat-label>
			   <input matInput name="inputVaros" [ngModel]="targetObj.getVaros()" (ngModelChange)="targetObj.setVaros($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Irányítószám</mat-label>
			   <input matInput name="inputIranyitoszam" [ngModel]="targetObj.getIranyitoszam()" (ngModelChange)="targetObj.setIranyitoszam($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Utca</mat-label>
			   <input matInput name="inputUtca" [ngModel]="targetObj.getUtca()" (ngModelChange)="targetObj.setUtca($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Közterület</mat-label>
			   <input matInput name="inputKozterulet" [ngModel]="targetObj.getKozterulet()" (ngModelChange)="targetObj.setKozterulet($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Házszám</mat-label>
			   <input matInput name="inputHazszam" [ngModel]="targetObj.getHazszam()" (ngModelChange)="targetObj.setHazszam($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Adószám</mat-label>
			   <input matInput name="inputAdoszam" [ngModel]="targetObj.getAdoszam()" (ngModelChange)="targetObj.setAdoszam($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Bankszámla</mat-label>
			   <input matInput name="inputBankszamla" [ngModel]="targetObj.getBankszamla()" (ngModelChange)="targetObj.setBankszamla($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Weboldal</mat-label>
			   <input matInput name="inputWeboldal" [ngModel]="targetObj.getWeboldal()" (ngModelChange)="targetObj.setWeboldal($event)">
		  </mat-form-field>
		  <br>
		  <mat-checkbox name="inputVevo" [ngModel]="targetObj.isVevo()" (ngModelChange)="targetObj.setVevo($event)">Vevő</mat-checkbox>
		  <br>
		  <mat-checkbox name="inputGyarto" [ngModel]="targetObj.isGyarto()" (ngModelChange)="targetObj.setGyarto($event)">Gyártó</mat-checkbox>
		  <br>
		  <mat-checkbox name="inputBeszallito" [ngModel]="targetObj.isBeszallito()" (ngModelChange)="targetObj.setBeszallito($event)">Beszállító</mat-checkbox>
		  <br>
		  <mat-checkbox name="inputViszontelado" [ngModel]="targetObj.isViszontelado()" (ngModelChange)="targetObj.setViszontelado($event)">Viszonteladó</mat-checkbox>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Kedvezmény</mat-label>
			   <input matInput name="inputKedvezmeny" type="number" step="1" [ngModel]="targetObj.getKedvezmeny()" (ngModelChange)="targetObj.setKedvezmeny($event)">
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
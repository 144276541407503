import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

import {AppConstants} from 'src/app/model/appconstants.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	public static readonly LOGIN_API: string = AppConstants.BASE_URL + '/api/login';
	public static readonly LOGOUT_API: string = AppConstants.BASE_URL + '/api/logout';

	public static readonly PROP_TOKEN: string = 'properties_logged_user_token';
	public static readonly PROP_USER: string = 'properties_logged_user';

	private loggedInVar = new BehaviorSubject<boolean>(this.loggedIn());
	private userVar = new BehaviorSubject<UserModel>(this.getUser());

	authStatus = this.loggedInVar.asObservable();
	authUser = this.userVar.asObservable();

	constructor(
		private http: HttpClient
	) {}

	changeAuthStatus(value: boolean) {
		this.loggedInVar.next(value);
	}

	changeUser(value: UserModel) {
		this.userVar.next(value);
	}

	login(username: string, password: string) {
		let params = new FormData();
		params.append('username', username);
		params.append('password', password);

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(AuthService.LOGIN_API, params, {params: parameters});
	}

	logout() {
		let data = {
			'token': this.getToken()
		};
		return this.http.post(AuthService.LOGOUT_API, data);
	}

	handle(token, user) {
		this.setToken(token);
		this.setUser(user);

		return this.isValid();
	}

	decode(payload) {
		return JSON.parse(atob(payload));
	}

	payload(token: string) {
		const payload: string = token.split('.')[1];
		return this.decode(payload);
	}

	isValid() {
		const token = this.getToken();
		if (token) {
			const payload = this.payload(token);
			if (payload) {
				return payload.iss == AuthService.LOGIN_API ? true : false;
			}
		}
		return false;
	}

	loggedIn() {
		return this.isValid();
	}

	setToken(token) {
		sessionStorage.setItem(AuthService.PROP_TOKEN, token);
	}

	getToken() {
		return sessionStorage.getItem(AuthService.PROP_TOKEN);
	}

	delToken() {
		sessionStorage.removeItem(AuthService.PROP_TOKEN);
		this.delUser();
	}

	setUser(user: UserModel) {
		sessionStorage.setItem(AuthService.PROP_USER, JSON.stringify(user));
		this.changeUser(this.getUser());
	}

	getUser() {
		return new UserModel(JSON.parse(sessionStorage.getItem(AuthService.PROP_USER)) || {});
	}

	delUser() {
		sessionStorage.removeItem(AuthService.PROP_USER);
	}

	getRoles() {
		return this.getUser().getRoles();
	}

	getPermissions() {
		let tmpPermissions: Array<string> = [];

		this.getRoles().forEach(role => {
			role.getPermissions().forEach(permission => {
				tmpPermissions.push(permission.getPermission());
			})
		});

		return tmpPermissions;
	}

	hasPermission(permissions: string | Array<string>) {
		let tmpHasPermission: boolean = false;
		let tmpPermissions: Array<string>;

		if (permissions == undefined || permissions == null) {
			return true;
		}

		if (typeof permissions == 'string') {
			tmpPermissions = [permissions as string];
		} else {
			tmpPermissions = permissions;
		}

		let userPermissions = this.getPermissions();

		if (this.isAdmin()) {
			return true;
		} else if (tmpPermissions.length > 0) {
			tmpPermissions.forEach(permission => {
				if (typeof permission == 'string' && userPermissions.includes(permission)) {
					tmpHasPermission = true;
					return;
				}
			});
		}

		return tmpHasPermission;
	}

	isAdmin() {
		let returnVal: boolean = false;

		this.getUser().getRoles().forEach(role => {
			if (role.getId() === 1) {
				returnVal = true;
				return;
			}
		});

		return returnVal;
	}
}

<mat-toolbar color="primary">
	 <mat-toolbar-row>
		  <span>Soron következő feladatok</span>
		  <span class="spacer"></span>
		  <button mat-icon-button (click)="closeDialog()">
				  <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
		  </button>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
	 <div class="loading-wrapper table-wrapper mat-elevation-z8">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <table mat-table [dataSource]="dataSource">
				 <!-- tt_name Column -->
				 <ng-container matColumnDef="tt_name">
					<th class="col-id" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Feladat megnevezése
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{ row.getChildModel().getName() }}
						 </div>
					</td>
			   </ng-container>

			   <!-- owner Column -->
			   <ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Felelős
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  <mat-form-field appearance="standard">
								   <mat-select name="inputOwner" [ngModel]="row.getOwner()" (ngModelChange)="row.setOwner($event)">
										<mat-option [value]="-1"></mat-option>
										<mat-optgroup label="Szerkesztő felhasználók">
											 <mat-option *ngFor="let user of getDelegateUsers(row.getChildModel().getId(), 1) | customsort:'getFullName'" [value]="user.getId()">{{user.getFullName()}}</mat-option>
										</mat-optgroup>
										<mat-optgroup label="További felhasználók">
											 <mat-option *ngFor="let user of getDelegateUsers(row.getChildModel().getId(), 0) | customsort:'getFullName'" [value]="user.getId()">{{user.getFullName()}}</mat-option>
										</mat-optgroup>
								   </mat-select>
							  </mat-form-field>
						 </div>
					</td>
			   </ng-container>

			   <!-- deadline Column -->
			   <ng-container matColumnDef="deadline">
					<th mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  Határidő
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  <mat-form-field>
								   <input matInput name="inputDeadline" [ngModel]="row.getDeadline()" (ngModelChange)="row.setDeadline($event)" [owlDateTime]="inputDeadlinePicker" [owlDateTimeTrigger]="inputDeadlinePicker">
								   <owl-date-time firstDayOfWeek="1" pickerType="both" #inputDeadlinePicker></owl-date-time>
							  </mat-form-field>
						 </div>
					</td>
			   </ng-container>

			   <!-- Action Column -->
			   <ng-container matColumnDef="action">
					<th class="col-action" mat-header-cell *matHeaderCellDef></th>
					<td class="col-action" mat-cell *matCellDef="let row;">
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'trash-alt']" size="lg" (click)="deleteTaskRef(row.getId());" matTooltip="Soron következő feladat törlése"></fa-icon>
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>
	 </div>
	 <br>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="createTasks()">Létrehozás</button>
		  <button mat-flat-button color="warn" (click)="closeDialog()">Bezárás</button>
	 </div>
</mat-dialog-content>
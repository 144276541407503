import {CommonModel} from "src/app/model/common.model";
import {LemezGyartoModel} from "src/app/components/settings/raktar/lemezgyarto/model/lemezgyarto.model";
import {LemezSzinModel} from "src/app/components/settings/raktar/lemezszin/model/lemezszin.model";
import {LemezAnyagminosegModel} from "src/app/components/settings/raktar/lemezanyagminoseg/model/lemezanyagminoseg.model";
import {LemezSzelessegModel} from "src/app/components/settings/raktar/lemezszelesseg/model/lemezszelesseg.model";
import {LemezVastagsagModel} from "src/app/components/settings/raktar/lemezvastagsag/model/lemezvastagsag.model";
import {LemezTaroloModel} from "src/app/components/settings/raktar/lemeztarolo/model/lemeztarolo.model";

export class LemezSzallitasModel {

	private id: number = null;

	private lemezgyartoid: number = null;
	private lemezgyarto: LemezGyartoModel = new LemezGyartoModel({});
	private lemezszinid: number = null;
	private lemezszin: LemezSzinModel = new LemezSzinModel({});
	private lemezanyagminosegid: number = null;
	private lemezanyagminoseg: LemezAnyagminosegModel = new LemezAnyagminosegModel({});
	private lemezszelessegid: number = null;
	private lemezszelesseg: LemezSzelessegModel = new LemezSzelessegModel({});
	private lemezvastagsagid: number = null;
	private lemezvastagsag: LemezVastagsagModel = new LemezVastagsagModel({});
	private lemeztarolo: LemezTaroloModel = new LemezTaroloModel({});
	private tomeg: number = null;
	private aktualistomeg: number = null;
	private nettoar: number = null;
	private idopont: any = null;
	private szallitolevel: string = '';

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.lemezgyartoid = json && json.lemezgyartoid || null;
		this.lemezgyarto = new LemezGyartoModel(json && json.lemezgyarto || {});
		this.lemezszinid = json && json.lemezszinid || null;
		this.lemezszin = new LemezSzinModel(json && json.lemezszin || {});
		this.lemezanyagminosegid = json && json.lemezanyagminosegid || null;
		this.lemezanyagminoseg = new LemezAnyagminosegModel(json && json.lemezanyagminoseg || {});
		this.lemezszelessegid = json && json.lemezszelessegid || null;
		this.lemezszelesseg = new LemezSzelessegModel(json && json.lemezszelesseg || {});
		this.lemezvastagsagid = json && json.lemezvastagsagid || null;
		this.lemezvastagsag = new LemezVastagsagModel(json && json.lemezvastagsag || {});
		this.lemeztarolo = new LemezTaroloModel(json && json.lemeztarolo || {});

		this.tomeg = json && json.tomeg || null;
		this.aktualistomeg = json && json.aktualistomeg || null;
		this.nettoar = json && json.nettoar || null;
		this.idopont = json && json.idopont || null;
		this.idopont = CommonModel.isDateTime(this.idopont) ? (new Date(Date.parse(this.idopont))) : null;
		this.szallitolevel = json && json.szallitolevel || '';

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getLemezGyartoid() {
		return this.lemezgyartoid;
	}

	setLemezGyartoid(value) {
		this.lemezgyartoid = value;
	}

	getLemezGyartoModel() {
		return this.lemezgyarto;
	}

	getLemezSzinid() {
		return this.lemezszinid;
	}

	setLemezSzinid(value) {
		this.lemezszinid = value;
	}

	getLemezSzinModel() {
		return this.lemezszin;
	}

	getLemezAnyagminosegid() {
		return this.lemezanyagminosegid;
	}

	setLemezAnyagminosegid(value) {
		this.lemezanyagminosegid = value;
	}

	getLemezAnyagminosegModel() {
		return this.lemezanyagminoseg;
	}

	getLemezSzelessegid() {
		return this.lemezszelessegid;
	}

	setLemezSzelessegid(value) {
		this.lemezszelessegid = value;
	}

	getLemezSzelessegModel() {
		return this.lemezszelesseg;
	}

	getLemezVastagsagid() {
		return this.lemezvastagsagid;
	}

	setLemezVastagsagid(value) {
		this.lemezvastagsagid = value;
	}

	getLemezVastagsagModel() {
		return this.lemezvastagsag;
	}

	getLemezTaroloModel() {
		return this.lemeztarolo;
	}

	getTomeg() {
		return this.tomeg;
	}

	setTomeg(value) {
		this.tomeg = value;
	}

	getAktualistomeg() {
		return this.aktualistomeg;
	}

	setAktualistomeg(value) {
		this.aktualistomeg = value;
	}

	getNettoar() {
		return this.nettoar;
	}

	setNettoar(value) {
		this.nettoar = value;
	}

	getIdopont() {
		return this.idopont;
	}

	setIdopont(value) {
		this.idopont = new Date(value);
	}

	getIdopontString() {
		if (this.idopont instanceof Date) {
			return (this.idopont.getFullYear() + "-" + ("0" + (this.idopont.getMonth() + 1)).substr(-2) + "-" + ("0" + this.idopont.getDate()).substr(-2) + " " + ("0" + this.idopont.getHours()).substr(-2) + ":" + ("0" + this.idopont.getMinutes()).substr(-2) + ":00");
		} else {
			return '';
		}
	}

	getSzallitolevel() {
		return this.szallitolevel;
	}

	setSzallitolevel(value) {
		this.szallitolevel = value;
	}
}

import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {ExtraDataVizszintesComponent} from 'src/app/components/task/dialog/task-dialog/extradata/extradatavizszintes/extradatavizszintes.component';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		ExtraDataVizszintesComponent
	],
	entryComponents: [
		ExtraDataVizszintesComponent
	],
	exports: [
		ExtraDataVizszintesComponent
	],
})
export class ExtraDataVizszintesModule {
	public static readonly component = ExtraDataVizszintesComponent;
}
<mat-toolbar *ngIf="hasTitle()" color="primary">
	 <mat-toolbar-row>
		  <span>{{dialogTitle}}</span>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content>
	 <p class="dialogText">{{dialogMessage}}</p>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">{{dialogOkButton}}</button>
	 </div>
</mat-dialog-content>
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';
import {PreviousRouteService} from 'src/app/services/previous-route.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	private loggedIn: boolean;

	constructor(
		private authService: AuthService,
		private previousRouteService: PreviousRouteService,
		private router: Router
	) {}

	ngOnInit() {
		this.authService.authStatus.subscribe(value => this.loggedIn = value);

		if (!this.loggedIn) {
			this.router.navigateByUrl('/bejelentkezes');
		}
	}
}

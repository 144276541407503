export class FileTypeModel {

	private id: number = null;

	private ft_name: string = '';

	private ft_modifiedby: number = null;
	private ft_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.ft_name = json && json.ft_name || '';

		this.ft_modifiedby = json && json.ft_modifiedby || null;
		this.ft_modifiedtime = json && json.ft_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.ft_name;
	}

	setName(value) {
		this.ft_name = value;
	}
}

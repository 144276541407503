import {NgModule} from '@angular/core'

import {CustomSortPipe} from 'src/app/pipes/customsort.pipe';

@NgModule({
	declarations: [
		CustomSortPipe
	],
	exports: [
		CustomSortPipe
	],
})
export class CustomPipesModule {}
<div class="loading-wrapper tableContainer my-4">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>

	 <div class="filter-container">
		  <app-table-filter style="margin-left: 10px; width: 100%;" filterId="projectlist" [(filter)]="filterValue" (keyupFn)="applyFilter()" placeholder="Szűrő..."></app-table-filter>
	 </div>

	 <div class="table-wrapper mat-elevation-z8" style="overflow: auto">
		  <table mat-table [dataSource]="dataSource" matSort>

				 <!-- ID Column -->
				 <ng-container matColumnDef="id" sticky>
					<th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  #
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getId()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Number Column -->
			   <ng-container matColumnDef="p_number">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Munkaszám
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getNumber()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Name Column -->
			   <ng-container matColumnDef="p_name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Megnevezés
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Desc Column -->
			   <ng-container matColumnDef="p_desc">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Leírás
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getDesc()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Managername Column -->
			   <ng-container matColumnDef="managername">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Projekt vezető
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getManagerModel().getFullName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Partnernev Column -->
			   <ng-container matColumnDef="partnernev">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Partner
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getPartnerModel().getNev()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Status Column -->
			   <ng-container matColumnDef="p_status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Státusz
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{listsModel.getListItem(row.getStatus(), listsModel.ProjectStatus).getName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Action Column -->
			   <ng-container matColumnDef="action" stickyEnd>
					<th class="col-action" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadData();" matTooltip="Frissítés"></fa-icon>
						 </div>
					</th>
					<td class="col-action" mat-cell *matCellDef="let row;">
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'folder-open']" size="lg" (click)="open(row.getId());" matTooltip="Projekt megnyitása"></fa-icon>
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>

		  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
	 </div>
</div>
import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskRefService} from 'src/app/components/settings/taskref/service/taskref.service';
import {TaskTemplateService} from 'src/app/components/settings/tasktemplate/service/tasktemplate.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {ListsModel} from 'src/app/model/lists.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: TaskRefService.ADD_TAG,
	templateUrl: './addtaskref.component.html',
	styleUrls: ['./addtaskref.component.scss']
})
export class AddTaskRefComponent {

	public static readonly TAG = TaskRefService.ADD_TAG;
	public static readonly NAME = "Feladat összerendelés";
	getSettingName() {return AddTaskRefComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: TaskRefModel = new TaskRefModel({});
	templates: Array<TaskTemplateModel> = [];
	filteredParents: Array<TaskTemplateModel> = [];
	filteredChilds: Array<TaskTemplateModel> = [];
	listsModel: any = ListsModel;

	constructor(
		private taskRefService: TaskRefService,
		private taskTemplateService: TaskTemplateService,
		public dialog: MatDialog
	) {
		this.loading++;
		this.taskTemplateService.getAll().subscribe(
			res => this.handleGetTaskTemplatesResponse(res),
			error => this.handleGetTaskTemplatesError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetTaskTemplatesError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskTemplatesResponse(serverResponse) {
		this.loading--;
		this.templates = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.templates.push(new TaskTemplateModel(serverResponse[i]));
		}

		this.filteredParents = this.templates.filter((obj) => {return true;});
		this.filteredChilds = this.templates.filter((obj) => {return true;});
	}

	submit() {
		this.loading++;
		this.taskRefService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(TaskRefService.LIST_TAG);
	}

	filterParents(val) {
		this.filteredParents = this.templates.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterChilds(val) {
		this.filteredChilds = this.templates.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
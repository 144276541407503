<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Felhasználónév</mat-label>
			   <input matInput name="inputUsername" [ngModel]="targetObj.getUsername()" (ngModelChange)="targetObj.setUsername($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Vezetéknév</mat-label>
			   <input matInput name="inputLastName" [ngModel]="targetObj.getLastName()" (ngModelChange)="targetObj.setLastName($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Keresztnév</mat-label>
			   <input matInput name="inputFirstName" [ngModel]="targetObj.getFirstName()" (ngModelChange)="targetObj.setFirstName($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>E-mail cím</mat-label>
			   <input matInput type="email" name="inputEmail" [ngModel]="targetObj.getEmail()" (ngModelChange)="targetObj.setEmail($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Telefon</mat-label>
			   <input matInput name="inputPhone" [ngModel]="targetObj.getPhone()" (ngModelChange)="targetObj.setPhone($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Jelszó</mat-label>
			   <input matInput type="password" name="inputPassword" [ngModel]="targetObj.getPassword()" (ngModelChange)="targetObj.setPassword($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Jelszó megerősítés</mat-label>
			   <input matInput type="password" name="inputPasswordConfirm" [ngModel]="targetObj.getPasswordConfirm()" (ngModelChange)="targetObj.setPasswordConfirm($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field>
			   <mat-label>Helyettes</mat-label>
			   <mat-select name="inputDeputy" [ngModel]="targetObj.getDeputy()" (ngModelChange)="targetObj.setDeputy($event)" (openedChange)="deputyFilter.value=null; filterDeputies(null);">
						   <input matInput #deputyFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterDeputies($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option [value]="-1">Nincs helyettes</mat-option>
					<mat-option *ngFor="let user of filteredDeputies | customsort:'getFullName'" [value]="user.getId()">{{user.getFullName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-checkbox name="inputHidden" [ngModel]="targetObj.isHidden()" (ngModelChange)="targetObj.setHidden($event)">Rejtett felhasználó</mat-checkbox>
		  <br>
		  <mat-checkbox name="inputDisabled" [ngModel]="targetObj.isDisabled()" (ngModelChange)="targetObj.setDisabled($event)">Inaktív felhasználó</mat-checkbox>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>Határidő módosítása</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <mat-dialog-content>
		  <br>
		  <form #targetForm="ngForm">
			   <mat-form-field>
					<mat-label>Határidő</mat-label>
					<input matInput name="inputDeadline" [(ngModel)]="deadline" [owlDateTime]="inputDeadlinePicker" [owlDateTimeTrigger]="inputDeadlinePicker" required>
					<owl-date-time firstDayOfWeek="1" pickerType="both" #inputDeadlinePicker></owl-date-time>
			   </mat-form-field>
		  </form>
		  <div class="dialogButtons">
			   <button mat-flat-button color="primary" (click)="save()" [disabled]="!targetForm.valid">Mentés</button>
			   <button mat-flat-button color="warn" (click)="dialogRef.close(false)">Mégsem</button>
		  </div>
	 </mat-dialog-content>
</div>
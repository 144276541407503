import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezFelhasznalasService} from 'src/app/components/raktar/service/lemezfelhasznalas.service';

import {LemezSzallitasModel} from 'src/app/components/raktar/model/lemezszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-lemezfelhasznalas',
	templateUrl: './lemezfelhasznalas.component.html',
	styleUrls: ['./lemezfelhasznalas.component.scss']
})
export class LemezFelhasznalasComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	selectedIdx: number = -1;

	szallitasok: Array<LemezSzallitasModel> = [];

	idopont: any = new Date;
	felhasznaltmennyiseg: string;
	belsoatmero: number;
	falvastagsag: number;

	constructor(
		private lemezFelhasznalasService: LemezFelhasznalasService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.loading++;
		this.lemezFelhasznalasService.getSzallitasok().subscribe(
			res => this.handleGetSzallitasokResponse(res),
			error => this.handleGetSzallitasokError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetSzallitasokError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetSzallitasokResponse(serverResponse) {
		this.loading--;
		let objects: Array<LemezSzallitasModel> = [];

		for (let i = 0; i < serverResponse.szallitasok.length; i++) {
			objects.push(new LemezSzallitasModel(serverResponse.szallitasok[i]));
		}

		this.szallitasok = objects;
		this.selectedIdx = this.szallitasok.length - 1;
	}

	prev() {
		if (this.selectedIdx > 0) {
			this.selectedIdx--;
		}
	}

	next() {
		if (this.selectedIdx + 1 < this.szallitasok.length) {
			this.selectedIdx++;
		}
	}

	save() {
		if (this.selectedIdx >= 0) {
			if (this.felhasznaltmennyiseg == 'T') {
				this.falvastagsag = 0;
				this.belsoatmero = 0;
			}

			this.loading++;
			this.lemezFelhasznalasService.lemezFelhasznalas(this.szallitasok[this.selectedIdx].getId(), this.idopont, this.belsoatmero, this.falvastagsag).subscribe(
				res => this.handleSaveResponse(res),
				error => this.handleSaveError(error)
			);
		}
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = serverResponse.message;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

}

import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskTemplateService} from 'src/app/components/settings/tasktemplate/service/tasktemplate.service';
import {CategoryService} from 'src/app/components/settings/category/service/category.service';
import {FileTypeService} from 'src/app/components/settings/filetype/service/filetype.service';
import {RoleService} from 'src/app/components/settings/role/service/role.service';

import {ExtraDataModule} from 'src/app/components/task/dialog/task-dialog/extradata/extradata.module';
import {TaskFormsModule} from 'src/app/components/task/dialog/task-dialog/taskforms/taskforms.module';

import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';
import {CategoryModel} from 'src/app/components/settings/category/model/category.model';
import {FileTypeModel} from 'src/app/components/settings/filetype/model/filetype.model';
import {RoleModel} from 'src/app/components/settings/role/model/role.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: TaskTemplateService.ADD_TAG,
	templateUrl: './addtasktemplate.component.html',
	styleUrls: ['./addtasktemplate.component.scss']
})
export class AddTaskTemplateComponent {

	public static readonly TAG = TaskTemplateService.ADD_TAG;
	public static readonly NAME = "Feladat sablon hozzáadás";
	getSettingName() {return AddTaskTemplateComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: TaskTemplateModel = new TaskTemplateModel({});
	categories: Array<CategoryModel> = [];
	filteredCategories: Array<CategoryModel> = [];
	filetypes: Array<FileTypeModel> = [];
	roles: Array<RoleModel> = [];
	extraDataModule: any = ExtraDataModule;
	taskFormsModule: any = TaskFormsModule;

	constructor(
		private taskTemplateService: TaskTemplateService,
		private categoryService: CategoryService,
		private fileTypeService: FileTypeService,
		private roleService: RoleService,
		public dialog: MatDialog
	) {
		this.loading++;
		this.categoryService.getAll().subscribe(
			res => this.handleGetCategoriesResponse(res),
			error => this.handleGetCategoriesError(error)
		);

		this.loading++;
		this.fileTypeService.getAll().subscribe(
			res => this.handleGetFileTypesResponse(res),
			error => this.handleGetFileTypesError(error)
		);

		this.loading++;
		this.roleService.getAll().subscribe(
			res => this.handleGetRolesResponse(res),
			error => this.handleGetRolesError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetCategoriesError(serverError) {
		this.handleError(serverError);
	}

	handleGetCategoriesResponse(serverResponse) {
		this.loading--;
		this.categories = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.categories.push(new CategoryModel(serverResponse[i]));
		}

		this.filteredCategories = this.categories.filter((obj) => {return true;});
	}

	handleGetFileTypesError(serverError) {
		this.handleError(serverError);
	}

	handleGetFileTypesResponse(serverResponse) {
		this.loading--;
		this.filetypes = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.filetypes.push(new FileTypeModel(serverResponse[i]));
		}
	}

	handleGetRolesError(serverError) {
		this.handleError(serverError);
	}

	handleGetRolesResponse(serverResponse) {
		this.loading--;
		this.roles = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.roles.push(new RoleModel(serverResponse[i]));
		}
	}

	submit() {
		this.loading++;
		this.taskTemplateService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(TaskTemplateService.LIST_TAG);
	}

	filetypeSelectCompare(filetype1, filetype2) {
		return filetype1.getId() == filetype2.getId();
	}

	roleSelectCompare(role1, role2) {
		return role1.getId() == role2.getId();
	}

	filterCategories(val) {
		this.filteredCategories = this.categories.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
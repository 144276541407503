import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {GablerProjektInditasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {ListsModel} from 'src/app/model/lists.model';

export interface GablerProject {
	kontaktneve: string;
	kontakttel: string;
	kontaktemail: string;
	kooperacio: any;
	kooperacioleiras: string;
	beszerzes: any;
	beszerzesleirasa: string;
	felmeres: any;
	felmereskertidopont: any;
	tervezes: any;
	egyebtervezes: any;
	elolegvan: any;
	masodikelolegvan: any;
	ugyfelgyartasihatarido: any;
	szallitas: any;
	szallitaskertidopontja: any;
	szereles: any;
	szereleskertidopontja: any;
	szereloszallit: any;
	emelogep: any;
	kulfoldi: any;
	modifiedby: string;
	modifiedtime: any;
}

@Component({
	selector: 'form-gablerprojektinditas',
	templateUrl: './gablerprojektinditas.component.html',
	styleUrls: ['./gablerprojektinditas.component.scss']
})
export class GablerProjektInditasComponent {

	public static readonly TAG: string = 'form-gablerprojektinditas';
	public static readonly NAME: string = 'Projekt indítás (Gabler)';
	public static readonly configComponent = null;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	formdata: GablerProject = {
		kontaktneve: null,
		kontakttel: null,
		kontaktemail: null,
		kooperacio: null,
		kooperacioleiras: null,
		beszerzes: null,
		beszerzesleirasa: null,
		felmeres: null,
		felmereskertidopont: null,
		tervezes: null,
		egyebtervezes: null,
		elolegvan: null,
		masodikelolegvan: null,
		ugyfelgyartasihatarido: null,
		szallitas: null,
		szallitaskertidopontja: null,
		szereles: null,
		szereleskertidopontja: null,
		szereloszallit: null,
		emelogep: null,
		kulfoldi: null,
		modifiedby: null,
		modifiedtime: null
	};

	constructor(
		public gablerProjektInditasService: GablerProjektInditasService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.gablerProjektInditasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpFormdata = {...this.formdata};
		for (let key in tmpFormdata) {
			tmpFormdata[key] = this.transform(tmpFormdata[key]);
		}

		return tmpFormdata;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerProjektInditasService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	save() {
		this.increaseLoading.emit(null);
		this.gablerProjektInditasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerProjektInditasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
					res => this.handleSaveDoneResponse(res),
					error => this.handleSaveDoneError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.gablerProjektInditasService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.formdata = serverResponse;

		if (CommonModel.isDate(this.formdata.felmereskertidopont)) {
			this.formdata.felmereskertidopont = new Date(Date.parse(this.formdata.felmereskertidopont));
		}
		if (CommonModel.isDate(this.formdata.ugyfelgyartasihatarido)) {
			this.formdata.ugyfelgyartasihatarido = new Date(Date.parse(this.formdata.ugyfelgyartasihatarido));
		}
		if (CommonModel.isDate(this.formdata.szallitaskertidopontja)) {
			this.formdata.szallitaskertidopontja = new Date(Date.parse(this.formdata.szallitaskertidopontja));
		}
		if (CommonModel.isDate(this.formdata.szereleskertidopontja)) {
			this.formdata.szereleskertidopontja = new Date(Date.parse(this.formdata.szereleskertidopontja));
		}

		this.formdata.kooperacio = (this.formdata.kooperacio === true || this.formdata.kooperacio == 1 || this.formdata.kooperacio == 'true') ? 1 : 0;
		this.formdata.beszerzes = (this.formdata.beszerzes === true || this.formdata.beszerzes == 1 || this.formdata.beszerzes == 'true') ? 1 : 0;
		this.formdata.felmeres = (this.formdata.felmeres === true || this.formdata.felmeres == 1 || this.formdata.felmeres == 'true') ? 1 : 0;
		this.formdata.tervezes = (this.formdata.tervezes === true || this.formdata.tervezes == 1 || this.formdata.tervezes == 'true') ? 1 : 0;
		this.formdata.egyebtervezes = (this.formdata.egyebtervezes === true || this.formdata.egyebtervezes == 1 || this.formdata.egyebtervezes == 'true') ? 1 : 0;
		this.formdata.elolegvan = (this.formdata.elolegvan === true || this.formdata.elolegvan == 1 || this.formdata.elolegvan == 'true') ? 1 : 0;
		this.formdata.masodikelolegvan = (this.formdata.masodikelolegvan === true || this.formdata.masodikelolegvan == 1 || this.formdata.masodikelolegvan == 'true') ? 1 : 0;
		this.formdata.szallitas = (this.formdata.szallitas === true || this.formdata.szallitas == 1 || this.formdata.szallitas == 'true') ? 1 : 0;
		this.formdata.szereles = (this.formdata.szereles === true || this.formdata.szereles == 1 || this.formdata.szereles == 'true') ? 1 : 0;
		this.formdata.szereloszallit = (this.formdata.szereloszallit === true || this.formdata.szereloszallit == 1 || this.formdata.szereloszallit == 'true') ? 1 : 0;
		this.formdata.emelogep = (this.formdata.emelogep === true || this.formdata.emelogep == 1 || this.formdata.emelogep == 'true') ? 1 : 0;
		this.formdata.kulfoldi = (this.formdata.kulfoldi === true || this.formdata.kulfoldi == 1 || this.formdata.kulfoldi == 'true') ? 1 : 0;
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.gablerProjektInditasService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

}
import {Component, Input} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.html',
	styleUrls: ['./confirm-dialog.scss']
})
export class ConfirmDialog {

	constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {}

	public dialogMessage: string = '';
	public dialogTitle: string = "Megerősítés";
	public dialogYesButton: string = "Igen";
	public dialogNoButton: string = "Nem";

	hasTitle() {
		if (this.dialogTitle == undefined || this.dialogTitle == null || this.dialogTitle == '')
			return false;
		else
			return true;
	}

}

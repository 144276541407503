import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskConditionService} from 'src/app/components/settings/taskcondition/service/taskcondition.service';
import {TaskRefService} from 'src/app/components/settings/taskref/service/taskref.service';

import {CommonModel} from 'src/app/model/common.model';

import {TaskConditionModel} from 'src/app/components/settings/taskcondition/model/taskcondition.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: TaskConditionService.ADD_TAG,
	templateUrl: './addtaskcondition.component.html',
	styleUrls: ['./addtaskcondition.component.scss']
})
export class AddTaskConditionComponent {

	public static readonly TAG = TaskConditionService.ADD_TAG;
	public static readonly NAME = "Feladat feltétel létrehozása";
	getSettingName() {return AddTaskConditionComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: TaskConditionModel = new TaskConditionModel({});
	taskrefs: Array<TaskRefModel> = [];
	filteredTaskrefs: Array<TaskRefModel> = [];

	constructor(
		private taskConditionService: TaskConditionService,
		private taskRefService: TaskRefService,
		public dialog: MatDialog
	) {
		this.loading++;
		this.taskRefService.getAll().subscribe(
			res => this.handleGetTaskRefsResponse(res),
			error => this.handleGetTaskRefsError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetTaskRefsError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskRefsResponse(serverResponse) {
		this.loading--;
		this.taskrefs = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.taskrefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.taskrefs = this.taskrefs.sort((n1, n2) => {
			let n1name: string = CommonModel.replaceHunChars(n1.getParentModel().getName().toLowerCase());
			let n2name: string = CommonModel.replaceHunChars(n2.getParentModel().getName().toLowerCase());

			if (n1name > n2name) {
				return 1;
			} else if (n1name < n2name) {
				return -1;
			} else {
				let n1childname: string = CommonModel.replaceHunChars(n1.getChildModel().getName().toLowerCase());
				let n2childname: string = CommonModel.replaceHunChars(n2.getChildModel().getName().toLowerCase());
				if (n1childname > n2childname) {
					return 1;
				} else if (n1childname < n2childname) {
					return -1;
				} else {
					return 0;
				}
			}
		});

		this.filteredTaskrefs = this.taskrefs.filter((obj) => {return true;});
	}

	submit() {
		this.loading++;
		this.taskConditionService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(TaskConditionService.LIST_TAG);
	}

	filterTaskrefs(val) {
		this.filteredTaskrefs = this.taskrefs.filter((obj) => {
			return (val == null || obj.getChildModel().getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Feladat összerendelés</mat-label>
			   <mat-select name="inputTaskref" [ngModel]="targetObj.getTaskrefid()" disabled>
					<mat-option *ngFor="let taskref of taskrefs" [value]="taskref.getId()">{{taskref.getParentModel().getName()}} -> {{taskref.getChildModel().getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Feltétel</mat-label>
			   <textarea matInput name="inputCondition" rows="5" [ngModel]="targetObj.getCondition()" disabled></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Leírás</mat-label>
			   <textarea matInput name="inputDesc" rows="3" [ngModel]="targetObj.getDesc()" disabled></textarea>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {Component, Inject, AfterViewInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

import {CommonModel} from "src/app/model/common.model";

@Component({
	selector: 'app-task-deadline-dialog',
	templateUrl: './task-deadline-dialog.html',
	styleUrls: ['./task-deadline-dialog.scss']
})
export class TaskDeadlineDialog implements AfterViewInit {

	loading: number = 0;

	alertDialogRef: MatDialogRef<AlertDialog>;

	private taskId: number;
	deadline: Date;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private taskService: TaskService,
		private authService: AuthService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<TaskDeadlineDialog>
	) {}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => {
			this.taskId = this.data.taskId;

			if (!this.taskId) {
				this.dialogRef.close(false);
			} else {
				let tmpDeadline: string = this.data.deadline;
				this.deadline = CommonModel.isDateTime(tmpDeadline) ? (new Date(Date.parse(tmpDeadline))) : null
			}
		});
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		this.dialogRef.close(true);
	}

	save() {
		if (this.deadline instanceof Date) {
			let tmpValue: string = (this.deadline.getFullYear() + "-" + ("0" + (this.deadline.getMonth() + 1)).substr(-2) + "-" + ("0" + this.deadline.getDate()).substr(-2) + " " + ("0" + this.deadline.getHours()).substr(-2) + ":" + ("0" + this.deadline.getMinutes()).substr(-2) + ":00");


			this.loading++;
			this.taskService.modDeadline(this.taskId, tmpValue).subscribe(
				res => this.handleResponse(res),
				error => this.handleError(error)
			);
		}
	}
}
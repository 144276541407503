import {Injectable} from '@angular/core';
import {Router, Event, NavigationEnd} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class PreviousRouteService {

	private previousUrl: string;
	private currentUrl: string;

	constructor(private router: Router) {
		this.currentUrl = this.router.url;
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	public getPreviousUrl() {
		return this.previousUrl;
	}
}
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';
import {HabSzintService} from 'src/app/components/raktar/service/habszint.service';
import {HabMinosegService} from 'src/app/components/settings/raktar/habminoseg/service/habminoseg.service';

import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';
import {HabSzintModel} from 'src/app/components/raktar/model/habszint.model';
import {HabMinosegModel} from 'src/app/components/settings/raktar/habminoseg/model/habminoseg.model';
import {RaktarModel} from 'src/app/components/settings/raktar/habminoseg/model/raktar.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-habbetoltes',
	templateUrl: './habbetoltes.component.html',
	styleUrls: ['./habbetoltes.component.scss']
})
export class HabBetoltesComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	habszint: HabSzintModel = new HabSzintModel({}, null);
	habminoseg: HabMinosegModel = new HabMinosegModel({});

	targetObj: HabSzallitasModel = new HabSzallitasModel({});

	raktarModel: any = RaktarModel;

	constructor(
		private habSzallitasService: HabSzallitasService,
		private habSzintService: HabSzintService,
		private habMinosegService: HabMinosegService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.loading++;
		this.habSzintService.getLast().subscribe(
			res => this.handleGetLastHabSzintResponse(res),
			error => this.handleGetLastHabSzintError(error)
		);

		this.loading++;
		this.habMinosegService.getLast().subscribe(
			res => this.handleGetLastHabMinosegResponse(res),
			error => this.handleGetLastHabMinosegError(error)
		);

		this.targetObj.setIdopont(new Date);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetLastHabSzintError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetLastHabSzintResponse(serverResponse) {
		this.loading--;
		this.habszint = new HabSzintModel(serverResponse, null);

		if ((this.habszint.getIdopont() as Date).getTime() < ((Date.now()) - (60 * 60 * 1000))) {
			this.alertDialogRef = this.dialog.open(AlertDialog, {
				disableClose: true,
				panelClass: 'alertDialogWindow',
				autoFocus: false
			});

			this.alertDialogRef.componentInstance.dialogMessage = 'A hab szintek utolsó mérése több mint 1 órája történt, kérjük végezzen el friss mérést';

			this.alertDialogRef.afterClosed().subscribe(result => {
				this.router.navigateByUrl('/habmeres');
			});
		}
	}

	handleGetLastHabMinosegError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetLastHabMinosegResponse(serverResponse) {
		this.loading--;
		this.habminoseg = new HabMinosegModel(serverResponse);
	}

	save() {
		this.loading++;
		this.habSzallitasService.add(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = 'Szállítás elmentve';

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

}

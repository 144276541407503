<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <div style="text-align: right;">
					<button mat-raised-button color="primary" (click)="addRendeles()" *ngIf="writeable">Új rendelés</button>
					<button mat-raised-button color="warn" (click)="removeRendeles()" *ngIf="writeable">Rendelés törlése</button>
			   </div>
			   <mat-tab-group [(selectedIndex)]="selectedForm">
					<mat-tab *ngFor="let rendeles of formdata; let rendelesIdx = index" [label]="(rendeles.szallito != null && rendeles.szallito.szallitonev != null && rendeles.szallito.szallitonev.length > 0) ? rendeles.szallito.szallitonev : (rendelesIdx+1)+'. rendelés'">
						<div class="rendeles-wrapper px-2 py-2">
							  <div class="col-3 mat-elevation-z4 my-2">
								   <form>
										<mat-form-field appearance="standard" *ngIf="writeable">
											 <mat-label>Szállító neve</mat-label>
											 <input matInput name="inputSzallitonev" 
													[(ngModel)]="rendeles.szallito.szallitonev" 
													[matAutocomplete]="autocompleteSzallito"
													[formControl]="rendeles.szallitoCtrl">
											 <mat-autocomplete #autocompleteSzallito="matAutocomplete" (optionSelected)="onSzallitoSelect($event, rendelesIdx)">
												 <mat-option class="mat-option-szallito mat-elevation-z1" *ngFor="let szallito of rendeles.filteredSzallitok | async | customsort:'szallitonev'" [value]="szallito.id">
														 <div>
															<span>{{szallito.szallitonev}}</span>
													   </div>
													   <div>
															<small>{{szallito.iranyitoszam}} {{szallito.varos}}{{(szallito.iranyitoszam!=null || szallito.varos!=null) && (szallito.utca!=null || szallito.hazszam!=null)?',':''}} {{szallito.utca}} {{szallito.hazszam}}</small>
													   </div>
													   <div>
															<small>{{szallito.kapcsolattarto}} {{szallito.email!=null?'('+szallito.email+')':szallito.telefon!=null?'('+szallito.telefon+')':''}}</small>
													   </div>
												  </mat-option>
											 </mat-autocomplete>
										</mat-form-field>
										<mat-form-field appearance="standard" *ngIf="!writeable">
											 <mat-label>Szállító neve</mat-label>
											 <input matInput name="inputSzallitonev" [(ngModel)]="rendeles.szallito.szallitonev" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Szállító irányítószám</mat-label>
											 <input matInput name="inputIranyitoszam" [(ngModel)]="rendeles.szallito.iranyitoszam" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Szállító város</mat-label>
											 <input matInput name="inputVaros" [(ngModel)]="rendeles.szallito.varos" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Szállító utca</mat-label>
											 <input matInput name="inputUtca" [(ngModel)]="rendeles.szallito.utca" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Szállító házszám</mat-label>
											 <input matInput name="inputHazszam" [(ngModel)]="rendeles.szallito.hazszam" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Kapcsolattartó neve</mat-label>
											 <input matInput name="inputKapcsolattarto" [(ngModel)]="rendeles.szallito.kapcsolattarto" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Kapcsolattartó telefon</mat-label>
											 <input matInput name="inputTelefon" [(ngModel)]="rendeles.szallito.telefon" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Kapcsolattartó email</mat-label>
											 <input matInput name="inputEmail" [(ngModel)]="rendeles.szallito.email" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Rendelés időpontja</mat-label>
											 <input matInput name="inputRendelesidopont" [(ngModel)]="rendeles.rendelesidopont" [owlDateTime]="inputRendelesidopontPicker" [owlDateTimeTrigger]="inputRendelesidopontPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputRendelesidopontPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Szállítási határidő</mat-label>
											 <input matInput name="inputSzallitasihatarido" [(ngModel)]="rendeles.szallitasihatarido" [owlDateTime]="inputSzallitasihataridoPicker" [owlDateTimeTrigger]="inputSzallitasihataridoPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputSzallitasihataridoPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Ellenőrzés időpontja</mat-label>
											 <input matInput name="inputEllenorzesidopontja" [(ngModel)]="rendeles.ellenorzesidopontja" [owlDateTime]="inputEllenorzesidopontjaPicker" [owlDateTimeTrigger]="inputEllenorzesidopontjaPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputEllenorzesidopontjaPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Beérkezés időpontja</mat-label>
											 <input matInput name="inputBeerkezesidopontja" [(ngModel)]="rendeles.beerkezesidopontja" [owlDateTime]="inputBeerkezesidopontjaPicker" [owlDateTimeTrigger]="inputBeerkezesidopontjaPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputBeerkezesidopontjaPicker></owl-date-time>
										</mat-form-field>
								   </form>
							  </div>
							  <div class="col-9 mat-elevation-z4 my-2">
								   <table class="table tetelek-table">
										<thead>
											 <tr>
												  <th>Megnevezés</th>
												  <th>Egység</th>
												  <th>Mennyiség</th>
												  <th>Egységár</th>
												  <th *ngIf="writeable">
													   <span style="cursor: pointer;" (click)="addTetel(rendelesIdx)"><fa-icon [icon]="['fas', 'plus']" size="lg" matTooltip="Új tétel"></fa-icon></span>
												  </th>
											 </tr>
										</thead>
										<tbody>
											 <tr *ngFor="let tetel of rendeles.tetelek; let tetelIdx = index">
												 <td *ngIf="writeable">
													   <input matInput name="inputMegnevezes" 
															  [(ngModel)]="tetel.megnevezes" 
															  placeholder="-- Megnevezés --" 
															  [matAutocomplete]="autocompleteTetel"
															  [formControl]="tetel.tetelCtrl">
										<mat-autocomplete #autocompleteTetel="matAutocomplete">
											 <mat-option class="mat-option-tetel mat-elevation-z1" *ngFor="let filteredTetel of tetel.filteredTetelek | async | customsort:'megnevezes'" [value]="filteredTetel.megnevezes">
														 <span>{{filteredTetel.megnevezes}}</span>
											 </mat-option>
										</mat-autocomplete>
										</td>
										<td *ngIf="!writeable"><span> {{ tetel.megnevezes }} </span></td>
										<td *ngIf="writeable">
											 <input matInput name="inputEgyseg" [(ngModel)]="tetel.egyseg" placeholder="-- Egység --">
										</td>
										<td *ngIf="!writeable"><span> {{ tetel.egyseg }} </span></td>
										<td *ngIf="writeable">
											 <input matInput type="number" step="1" name="inputMennyiseg" [(ngModel)]="tetel.mennyiseg" placeholder="-- Mennyiség --">
										</td>
										<td *ngIf="!writeable"><span> {{ tetel.mennyiseg }} </span></td>
										<td *ngIf="writeable">
											 <input matInput type="number" step="1" name="inputEgysegar" [(ngModel)]="tetel.egysegar" placeholder="-- Egységár --">
										</td>
										<td *ngIf="!writeable"><span> {{ tetel.egysegar }} </span></td>
										<td *ngIf="writeable">
											 <span style="cursor: pointer;" (click)="removeTetel(rendelesIdx, tetelIdx)">
												   <fa-icon [icon]="['fas', 'times']" size="lg" matTooltip="Tétel törlése"></fa-icon>
											 </span>
										</td>
										</tr>
										</tbody>
								   </table>
							  </div>
						 </div>
					</mat-tab>
			   </mat-tab-group>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" *ngIf="writeable">Mentés</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
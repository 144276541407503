<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Lemez felhasználás</mat-card-title>
		  </mat-card-header>
		  <mat-card-content *ngIf="szallitasok.length > 0">
			   <div class="mat-elevation-z2 px-2 py-2">
					<mat-card-subtitle>Lemez</mat-card-subtitle>
					<mat-card-content>
						 <mat-label>Gyártó: {{ szallitasok[selectedIdx].getLemezGyartoModel().getGyarto() }} </mat-label>
						 <br>
						 <mat-label>Szín: {{ szallitasok[selectedIdx].getLemezSzinModel().getSzin() }} </mat-label>
						 <br>
						 <mat-label>Fajsúly (kg/m3): {{ szallitasok[selectedIdx].getLemezAnyagminosegModel().getFajsuly() }} </mat-label>
						 <br>
						 <mat-label>Szélesség: {{ szallitasok[selectedIdx].getLemezSzelessegModel().getSzelesseg() }} mm </mat-label>
						 <br>
						 <mat-label>Vastagság: {{ szallitasok[selectedIdx].getLemezVastagsagModel().getVastagsag() }} mm </mat-label>
						 <br>
						 <mat-label>Aktuális tömeg: {{ szallitasok[selectedIdx].getAktualistomeg() }} kg </mat-label>
						 <br>
						 <mat-label>Szállítólevél száma: {{ szallitasok[selectedIdx].getSzallitolevel() }} </mat-label>
					</mat-card-content>
			   </div>
		  </mat-card-content>
		  <mat-card-actions style="display: flex; justify-content: space-around; width: 50%; margin: 0 auto;">
			   <button mat-mini-fab color="disabled" (click)="prev()">
					<fa-icon [icon]="['fas', 'chevron-left']" size="lg"></fa-icon>
			   </button>
			   <div style="display: flex; justify-content: center; flex-direction: column;"> {{ selectedIdx + 1 }} / {{ szallitasok.length }}</div>
			   <button mat-mini-fab color="disabled" (click)="next()">
					<fa-icon [icon]="['fas', 'chevron-right']" size="lg"></fa-icon>
			   </button>
		  </mat-card-actions>
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field>
						 <mat-label>Időpont</mat-label>
						 <input matInput name="inputIdopont" [(ngModel)]="idopont" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" required>
						 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
					</mat-form-field>
					<br>
					<mat-form-field>
						 <mat-label>Felhasznált mennyiség</mat-label>
						 <mat-select name="inputFelhasznaltmennyiseg" [(ngModel)]="felhasznaltmennyiseg" required>
							  <mat-option value="T">Teljes tekercs</mat-option>
							  <mat-option value="R">Részleges felhasználás</mat-option>
						 </mat-select>
					</mat-form-field>
					<br>
					<mat-form-field *ngIf="felhasznaltmennyiseg=='R'">
						 <mat-label>Belső átmérő (mm)</mat-label>
						 <input matInput name="inputBelsoatmero" type="number" step="1" min="0" [(ngModel)]="belsoatmero" required>
					</mat-form-field>
					<br>
					<mat-form-field *ngIf="felhasznaltmennyiseg=='R'">
						 <mat-label>Fal vastagság (mm)</mat-label>
						 <input matInput name="inputFalvastagsag" type="number" step="1" min="0" [(ngModel)]="falvastagsag" required>
					</mat-form-field>
			   </form>
		  </mat-card-content>
		  <mat-card-actions style="text-align: right;">
			   <button mat-raised-button (click)="save()" [disabled]="!dataForm.valid">Mentés</button>
			   <button mat-raised-button routerLink="/raktar">Mégsem</button>
		  </mat-card-actions>
	 </mat-card>
</div>

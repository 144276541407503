export class AppConstants {

	//appsettings
	//public static readonly BASE_URL: string = 'http://gabler.test';
	public static readonly BASE_URL: string = 'https://gabler.veloxnet.hu';

	//user-properties

	//params
	//user
	public static readonly PARAM_MODUSER_USERID: string = 'param_moduser_userid';
	//category
	public static readonly PARAM_MODCATEGORY_CATEGORYID: string = 'param_modcategory_categoryid';
	//filetype
	public static readonly PARAM_MODFILETYPE_FILETYPEID: string = 'param_modfiletype_filetypeid';
	//partner
	public static readonly PARAM_MODPARTNER_PARTNERID: string = 'param_modpartner_partnerid';
	//project
	public static readonly PARAM_MODPROJECT_PROJECTID: string = 'param_modproject_projectid';
	public static readonly PARAM_OPENPROJECTINFO_PROJECTID: string = 'param_openprojectinfo_projectid';
	//role
	public static readonly PARAM_MODROLE_ROLEID: string = 'param_modrole_roleid';
	//roleassign
	public static readonly PARAM_MODROLEASSIGN_ROLEASSIGNID: string = 'param_modroleassign_roleassignid';
	public static readonly PARAM_DELROLEASSIGN_ROLEASSIGNID: string = 'param_delroleassign_roleassignid';
	//taskcondition
	public static readonly PARAM_MODTASKCONDITION_TASKCONDITIONID: string = 'param_modtaskcondition_taskconditionid';
	public static readonly PARAM_DELTASKCONDITION_TASKCONDITIONID: string = 'param_deltaskcondition_taskconditionid';
	//taskref
	public static readonly PARAM_MODTASKREF_TASKREFID: string = 'param_modtaskref_taskrefid';
	public static readonly PARAM_DELTASKREF_TASKREFID: string = 'param_deltaskref_taskrefid';
	//taskrole
	public static readonly PARAM_MODTASKROLE_TASKROLEID: string = 'param_modtaskrole_taskroleid';
	public static readonly PARAM_DELTASKROLE_TASKROLEID: string = 'param_deltaskrole_taskroleid';
	//tasktemplate
	public static readonly PARAM_MODTASKTEMPLATE_TASKTEMPLATEID: string = 'param_modtasktemplate_tasktemplateid';
	//raktar
	//habminoseg
	public static readonly PARAM_MODHABMINOSEG_HABMINOSEGID: string = 'param_modhabminoseg_habminosegid';
	//habszint
	public static readonly PARAM_MODHABSZINT_HABSZINTID: string = 'param_modhabszint_habszintid';
	public static readonly PARAM_DELHABSZINT_HABSZINTID: string = 'param_delhabszint_habszintid';
	//habszallitas
	public static readonly PARAM_MODHABSZALLITAS_HABSZALLITASID: string = 'param_modhabszallitas_habszallitasid';
	public static readonly PARAM_DELHABSZALLITAS_HABSZALLITASID: string = 'param_delhabszallitas_habszallitasid';
	//lemezanyagminoseg
	public static readonly PARAM_MODLEMEZANYAGMINOSEG_LEMEZANYAGMINOSEGID: string = 'param_modlemezanyagminoseg_lemezanyagminosegid';
	//lemezgyarto
	public static readonly PARAM_MODLEMEZGYARTO_LEMEZGYARTOID: string = 'param_modhabminoseg_habminosegid';
	//lemezraktar
	public static readonly PARAM_MODLEMEZRAKTAR_LEMEZRAKTARID: string = 'param_modlemezraktar_lemezraktarid';
	//lemezszelesseg
	public static readonly PARAM_MODLEMEZSZELESSEG_LEMEZSZELESSEGID: string = 'param_modlemezszelesseg_lemezszelessegid';
	//lemezszin
	public static readonly PARAM_MODLEMEZSZIN_LEMEZSZINID: string = 'param_modlemezszin_lemezszinid';
	//lemeztarolo
	public static readonly PARAM_MODLEMEZTAROLO_LEMEZTAROLOID: string = 'param_modlemeztarolo_lemeztaroloid';
	//lemezvastagsag
	public static readonly PARAM_MODLEMEZVASTAGSAG_LEMEZVASTAGSAGID: string = 'param_modlemezvastagsag_lemezvastagsagid';
	//lemezszallitas
	public static readonly PARAM_MODLEMEZSZALLITAS_LEMEZSZALLITASID: string = 'param_modlemezszallitas_lemezszallitasid';
	public static readonly PARAM_DELLEMEZSZALLITAS_LEMEZSZALLITASID: string = 'param_dellemezszallitas_lemezszallitasid';
	//lemezmozgas
	public static readonly PARAM_MODLEMEZMOZGAS_LEMEZMOZGASID: string = 'param_modlemezmozgas_lemezmozgasid';
	public static readonly PARAM_DELLEMEZMOZGAS_LEMEZMOZGASID: string = 'param_dellemezmozgas_lemezmozgasid';
}
import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
	selector: 'app-table-filter',
	templateUrl: './table-filter.component.html',
	styleUrls: ['./table-filter.component.css']
})
export class TableFilterComponent implements OnInit {
	@Input() filterId: string;
	@Input() filter: string;
	@Output() filterChange = new EventEmitter<string>(true);
	@Output() keyupFn = new EventEmitter<string>(true);
	@Input() placeholder: string;

	constructor() {
	}

	ngOnInit() {
		this.filter = sessionStorage.getItem('tableFilter_' + (this.filterId));
		if (this.filter == null) {
			this.filter = '';
		}
		this.keyupFunction();
	}

	keyupFunction() {
		this.valueChange();
		sessionStorage.setItem('tableFilter_' + (this.filterId), this.filter);
		this.keyupFn.emit(this.filter);
	}

	removeFilter() {
		this.filter = '';
		this.keyupFunction();
	}

	valueChange() {
		this.filterChange.emit(this.filter);
	}

}

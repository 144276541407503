import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {LemezTaroloModel} from 'src/app/components/settings/raktar/lemeztarolo/model/lemeztarolo.model';

@Injectable()
export class LemezTaroloService {

	public static readonly API_TYPE: string = 'lemeztarolo';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + LemezTaroloService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + LemezTaroloService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + LemezTaroloService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + LemezTaroloService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-lemeztarolok';
	public static readonly ADD_TAG: string = 'setting-addlemeztarolo';
	public static readonly MOD_TAG: string = 'setting-modlemeztarolo';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezTaroloService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(LemezTaroloService.GET_API, params, {params: parameters});
	}

	add(obj: LemezTaroloModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('lemezraktarid', String(obj.getLemezraktarid()));
		params.append('tarolo', obj.getTarolo());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezTaroloService.ADD_API, params, {params: parameters});
	}

	mod(obj: LemezTaroloModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('lemezraktarid', String(obj.getLemezraktarid()));
		params.append('tarolo', obj.getTarolo());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(LemezTaroloService.MOD_API, params, {params: parameters});
	}
}

import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

import {TaskService} from 'src/app/components/task/service/task.service';

import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {CommonModel} from 'src/app/model/common.model';

export interface DelegateUsers {
	templateid: number;
	writers: Array<UserModel>;
	readers: Array<UserModel>;
}

@Component({
	selector: 'app-next-tasks-dialog',
	templateUrl: './next-tasks-dialog.html',
	styleUrls: ['./next-tasks-dialog.scss']
})
export class NextTasksDialog {

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	loading: number = 0;

	displayedColumns: string[] = ['tt_name', 'owner', 'deadline', 'action'];
	dataSource: MatTableDataSource<TaskRefModel>;

	taskRefs: Array<TaskRefModel> = [];
	delegateusers: Array<DelegateUsers> = [];

	constructor(
		private dialogRef: MatDialogRef<NextTasksDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private taskService: TaskService,
		public dialog: MatDialog
	) {
		this.taskRefs = this.data.taskRefs;

		if (!this.taskRefs || this.taskRefs.length < 1) {
			this.dialogRef.close([]);
		} else {
			this.loading++;
			this.taskService.getDelegateUsers().subscribe(
				res => this.handleGetUsersResponse(res),
				error => this.handleGetUsersError(error)
			);

			this.taskRefs.forEach((value, index) => {
				if (value.getChildModel().getDeadline().length > 0) {
					if (CommonModel.isDateTime(value.getChildModel().getDeadline())) {
						let deadline: Date = new Date(Date.parse(value.getChildModel().getDeadline()));
						this.taskRefs[index].setDeadline(deadline);
						return;
					}
				}

				if (value.getChildModel().getTimeshift() > 0) {
					let deadline: Date = new Date();
					deadline.setTime(deadline.getTime() + (value.getChildModel().getTimeshift() * 60 * 60 * 1000));
					this.taskRefs[index].setDeadline(deadline);
				}
			});

			this.dataSource = new MatTableDataSource(this.taskRefs);
		}

	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.taskRefs.forEach((taskRef) => {
			let delegateuser: DelegateUsers = {
				templateid: taskRef.getChildModel().getId(),
				writers: [],
				readers: []
			}

			for (let i = 0; i < serverResponse.length; i++) {
				let found: boolean = false;
				for (let j = 0; j < serverResponse[i].writetaskroles.length; j++) {
					if (Number(serverResponse[i].writetaskroles[j]) == delegateuser.templateid) {
						found = true;
						break;
					}
				}

				if (found) {
					delegateuser.writers.push(new UserModel(serverResponse[i]));
				} else {
					delegateuser.readers.push(new UserModel(serverResponse[i]));
				}
			}

			this.delegateusers.push(delegateuser);
		});
	}

	deleteTaskRef(refId) {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli ezt a soron következő feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.taskRefs.forEach((value, index) => {
					if (value.getId() == refId) {
						this.taskRefs.splice(index, 1);
					}
				});

				if (this.taskRefs.length > 1) {
					this.dataSource.data = this.taskRefs;
				} else {
					this.dialogRef.close([]);
				}
			}
			this.confirmDialogRef = null;
		});
	}

	createTasks() {
		let taskRefsToSend: Array<object> = [];

		this.taskRefs.forEach((taskRef) => {
			let json = {
				id: taskRef.getId(),
				owner: taskRef.getOwner() == null ? -1 : taskRef.getOwner(),
				deadline: taskRef.getDeadline() == null ? -1 : (taskRef.getDeadline().getFullYear() + "-" + ("0" + (taskRef.getDeadline().getMonth() + 1)).substr(-2) + "-" + ("0" + taskRef.getDeadline().getDate()).substr(-2) + " " + ("0" + taskRef.getDeadline().getHours()).substr(-2) + ":" + ("0" + taskRef.getDeadline().getMinutes()).substr(-2) + ":00")
			};

			taskRefsToSend.push(json);
		});

		this.dialogRef.close(taskRefsToSend);
	}

	getDelegateUsers(templateid: number, writers: number) {
		for (let i = 0; i < this.delegateusers.length; i++) {
			if (templateid == this.delegateusers[i].templateid) {
				if (writers == 1) {
					return this.delegateusers[i].writers;
				} else {
					return this.delegateusers[i].readers;
				}
			}
		}

		return [];
	}

	closeDialog() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Ha bezárja a soron következő feladatok ablakot, a megnyitott feladat nem lesz készre állítva. Biztosan bezárja?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.dialogRef.close(false);
			}
			this.confirmDialogRef = null;
		});
	}
}
import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {UserService} from 'src/app/components/settings/user/service/user.service';
import {AuthService} from 'src/app/services/auth.service';

import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';

@Component({
	selector: 'app-profile-dialog',
	templateUrl: './profile-dialog.html',
	styleUrls: ['./profile-dialog.scss']
})
export class ProfileDialog {

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	loading: number = 0;

	targetObj: UserModel = new UserModel({});
	users: Array<UserModel> = [];

	constructor(
		private userService: UserService,
		private authService: AuthService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<ProfileDialog>
	) {
		this.loading++;
		this.userService.getAll().subscribe(
			res => this.handleGetUsersResponse(res),
			error => this.handleGetUsersError(error)
		);
		this.loading++;
		this.userService.get(this.authService.getUser().getId()).subscribe(
			res => this.handleGetUserResponse(res),
			error => this.handleGetUserError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}
	}

	handleGetUserError(serverError) {
		this.handleError(serverError);
	}

	handleGetUserResponse(serverResponse) {
		this.loading--;
		this.targetObj = new UserModel(serverResponse);
	}

	submit() {
		if (this.targetObj.getDeputy() > 0) {
			this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
				disableClose: true,
				panelClass: 'confirmDialogWindow',
				autoFocus: false
			});
			this.confirmDialogRef.componentInstance.dialogMessage = "Amennyiben helyettest állít be, akkor az Ön feladatait a helyettesének kell megoldani, amíg ismét nem törli a helyettesítőt. Folytatja?";

			this.confirmDialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.loading++;
					this.userService.mod(this.targetObj).subscribe(
						res => this.handleSaveResponse(res),
						error => this.handleSaveError(error)
					);
				}
				this.confirmDialogRef = null;
			});
		} else {
			this.loading++;
			this.userService.mod(this.targetObj).subscribe(
				res => this.handleSaveResponse(res),
				error => this.handleSaveError(error)
			);
		}
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.dialogRef.close(true);
	}

}

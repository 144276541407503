import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezAthelyezesService} from 'src/app/components/raktar/service/lemezathelyezes.service';

import {LemezTaroloModel} from 'src/app/components/settings/raktar/lemeztarolo/model/lemeztarolo.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-lemezathelyezes',
	templateUrl: './lemezathelyezes.component.html',
	styleUrls: ['./lemezathelyezes.component.scss']
})
export class LemezAthelyezesComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	szabadtarolok: Array<LemezTaroloModel> = [];
	filteredSzabadtarolok: Array<LemezTaroloModel> = [];
	foglalttarolok: Array<LemezTaroloModel> = [];
	filteredFoglalttarolok: Array<LemezTaroloModel> = [];

	idopont: any = new Date;
	mozgastipus: string = "A";
	oldlemeztaroloid: number;
	newlemeztaroloid: number;

	constructor(
		private lemezAthelyezesService: LemezAthelyezesService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.loading++;
		this.lemezAthelyezesService.getTarolok().subscribe(
			res => this.handleGetTarolokResponse(res),
			error => this.handleGetTarolokError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	handleGetTarolokError(serverError) {
		this.handleError(serverError);
	}

	handleGetTarolokResponse(serverResponse) {
		this.loading--;
		let objects: Array<LemezTaroloModel> = [];

		for (let i = 0; i < serverResponse.szabadtarolok.length; i++) {
			objects.push(new LemezTaroloModel(serverResponse.szabadtarolok[i]));
		}

		this.szabadtarolok = objects;
		this.filteredSzabadtarolok = this.szabadtarolok.filter((obj) => {return true;});

		objects = [];

		for (let i = 0; i < serverResponse.foglalttarolok.length; i++) {
			objects.push(new LemezTaroloModel(serverResponse.foglalttarolok[i]));
		}

		this.foglalttarolok = objects;
		this.filteredFoglalttarolok = this.foglalttarolok.filter((obj) => {return true;});
	}

	save() {
		this.loading++;
		this.lemezAthelyezesService.lemezAthelyezes(this.idopont, this.mozgastipus, this.oldlemeztaroloid, this.newlemeztaroloid).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = serverResponse.message;

		this.alertDialogRef.afterClosed().subscribe(result => {
			this.router.navigateByUrl('/raktar');
		});
	}

	filterSzabadtarolok(val) {
		this.filteredSzabadtarolok = this.szabadtarolok.filter((obj) => {
			return (val == null || obj.getRaktarTarolo().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterFoglalttarolok(val) {
		this.filteredFoglalttarolok = this.foglalttarolok.filter((obj) => {
			return (val == null || obj.getRaktarTarolo().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {ProjectModel} from 'src/app/components/settings/project/model/project.model';

@Injectable()
export class ProjectService {

	public static readonly API_TYPE: string = 'project';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/mod_request';

	public static readonly GET_TASKS_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/get_tasks_request';
	public static readonly GET_CUSTOM_DATA_API: string = AppConstants.BASE_URL + '/api/' + ProjectService.API_TYPE + '/get_custom_data_request';

	public static readonly LIST_TAG: string = 'setting-projects';
	public static readonly ADD_TAG: string = 'setting-addproject';
	public static readonly MOD_TAG: string = 'setting-modproject';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(ProjectService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(ProjectService.GET_API, params, {params: parameters});
	}

	add(obj: ProjectModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('p_number', obj.getNumber());
		params.append('p_name', obj.getName());
		params.append('p_desc', obj.getDesc());
		params.append('p_manager', String(obj.getManager()));
		params.append('p_partner', String(obj.getPartner()));
		params.append('p_status', String(obj.getStatus()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(ProjectService.ADD_API, params, {params: parameters});
	}

	mod(obj: ProjectModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('p_number', obj.getNumber());
		params.append('p_name', obj.getName());
		params.append('p_desc', obj.getDesc());
		params.append('p_manager', String(obj.getManager()));
		params.append('p_partner', String(obj.getPartner()));
		params.append('p_status', String(obj.getStatus()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(ProjectService.MOD_API, params, {params: parameters});
	}

	getTasks(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(ProjectService.GET_TASKS_API, params, {params: parameters});
	}

	getCustomData(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(ProjectService.GET_CUSTOM_DATA_API, params, {params: parameters});
	}
}

<div class="loading-wrapper tableContainer my-4">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>

	 <div class="filter-container">
		  <mat-form-field style="width: auto;">
			   <mat-select [(ngModel)]="taskType" (selectionChange)="loadData()">
					<mat-option *ngFor="let taskfilter of listsModel.TaskFilterList" [value]="taskfilter.getValue()">{{taskfilter.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <app-table-filter style="margin-left: 10px; width: 100%;" filterId="tasks" [(filter)]="filterValue" (keyupFn)="applyFilter()" placeholder="Szűrő..."></app-table-filter>
		  <mat-checkbox class="mobile-view-hide" style="margin-top: 0.5em" [(ngModel)]="myTasksOnly" (change)="loadData()">Csak saját feladatok</mat-checkbox>
	 </div>
	 <div class="filter-container normal-view-hide">
		  <mat-checkbox style="margin-top: 0.5em" [(ngModel)]="myTasksOnly" (change)="loadData()">Csak saját feladatok</mat-checkbox>
	 </div>

	 <div class="table-wrapper mat-elevation-z8" style="overflow: auto;">
		  <table mat-table [dataSource]="dataSource" matSort>

				 <!-- ID Column -->
				 <ng-container matColumnDef="id" sticky>
					<th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  #
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getId()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- project Column -->
			   <ng-container matColumnDef="project">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Projekt
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getProjectModel().getName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- projectnumber Column -->
			   <ng-container matColumnDef="projectnumber">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Munkaszám
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getProjectModel().getNumber()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- partnernev Column -->
			   <ng-container matColumnDef="partnernev">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Partner
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getProjectModel().getPartnerModel().getNev()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- taskname Column -->
			   <ng-container matColumnDef="taskname">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Feladat megnevezés
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getTemplateModel().getName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- t_deadline Column -->
			   <ng-container matColumnDef="t_deadline">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Határidő
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getDeadline()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- t_donetime Column -->
			   <ng-container matColumnDef="t_donetime">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Befejezve
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getDonetime()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- owner Column -->
			   <ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Felelős
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getOwnerModel().getFullName()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Action Column -->
			   <ng-container matColumnDef="action" stickyEnd>
					<th class="col-action" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'plus']" size="lg" (click)="openAddTaskDialog();" matTooltip="Új" *hasPermission="'Modules:Task:addmytask'"></fa-icon>
							  <fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadData();" matTooltip="Frissítés"></fa-icon>
						 </div>
					</th>
					<td class="col-action" mat-cell *matCellDef="let row;">
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'pencil-alt']" size="lg" (click)="openTaskDialog(row.getId());" matTooltip="Feladat megnyitása"></fa-icon>
							  <fa-icon *ngIf="canEditDeadline(row.getStatus(), row.getOwnerModel().getId(), row.getProjectModel().getManagerModel().getId())" [icon]="['far', 'calendar-alt']" size="lg" (click)="openTaskDeadlineDialog(row.getId(), row.getDeadline());" matTooltip="Határidő"></fa-icon>
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'completedTask' : 'completedTask', 'stornedTask' : 'stornedTask', 'expiredTask' : 'expiredTask' }[getTaskStatusClass(row)]"></tr>
		  </table>

		  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
	 </div>
</div>
<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field *ngIf="dontes_visible">
						 <mat-label>{{!formconfig.donteslabel?'Döntés':formconfig.donteslabel}}</mat-label>
						 <mat-select name="inputDontes" [(ngModel)]="formdata.dontes" [disabled]="!writeable" required>
							  <mat-option value="">-</mat-option>
							  <mat-option value="1">Jóváhagyva</mat-option>
							  <mat-option value="-1">Elutasítva</mat-option>
						 </mat-select>
					</mat-form-field>
					<mat-form-field *ngIf="dontesidopontja_visible">
						 <mat-label>{{!formconfig.dontesidopontjalabel?'Döntés időpontja':formconfig.dontesidopontjalabel}}</mat-label>
						 <input matInput name="inputDontesIdopontja" [(ngModel)]="formdata.dontesidopontja" [owlDateTime]="inputDontesIdopontjaPicker" [owlDateTimeTrigger]="inputDontesIdopontjaPicker" [disabled]="!writeable" required>
						 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputDontesIdopontjaPicker></owl-date-time>
					</mat-form-field>
			   </form>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" [disabled]="!dataForm.valid" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {GablerProjektInditasComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.component';

import {GablerProjektInditasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		GablerProjektInditasComponent
	],
	entryComponents: [
		GablerProjektInditasComponent
	],
	providers: [
		GablerProjektInditasService
	],
	exports: [
		GablerProjektInditasComponent
	],
})
export class GablerProjektInditasModule {
	public static readonly component = GablerProjektInditasComponent;
}
<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="col-12 col-md-6 col-lg-5 col-xl-4 mx-auto py-3">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <mat-card-title>Hab szintek</mat-card-title>
		  </mat-card-header>
		  <mat-card-content>
			   <form #dataForm="ngForm">
					<mat-form-field>
						 <mat-label>Mérés időpontja</mat-label>
						 <input matInput name="inputMeresIdopontja" [ngModel]="habszintek[selectedIdx].getIdopont()" (ngModelChange)="habszintek[selectedIdx].setIdopont($event)" [owlDateTime]="inputMeresIdopontjaPicker" [owlDateTimeTrigger]="inputMeresIdopontjaPicker" [disabled]="!writeable">
						 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputMeresIdopontjaPicker></owl-date-time>
					</mat-form-field>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>A komponens</mat-card-subtitle>
						 <div class="row" style="justify-content: space-around;">
							  <div class="col-5">
								   <mat-form-field appearance="standard">
										<mat-label>mérés (mm)</mat-label>
										<input matInput name="inputAMeres" type="number" step="1" min="0" [ngModel]="habszintek[selectedIdx].getAmeres()" (ngModelChange)="habszintek[selectedIdx].setAmeres($event)" [disabled]="!writeable">
								   </mat-form-field>
							  </div>
							  <div class="col-5" *ngIf="habszintek[selectedIdx].getAmeres() != null">
								   <mat-label>mért: {{ habszintek[selectedIdx].getAmertKg(raktarModel.HabAvalto, raktarModel.HabAtartaly) }} kg</mat-label>
								   <br>
								   <mat-label>hiány: {{ habszintek[selectedIdx].getAhianyKg(raktarModel.HabAvalto, raktarModel.HabAtartaly, raktarModel.HabBvalto, raktarModel.HabBtartaly) }} kg</mat-label>
								   <br>
								   <mat-label>hely: {{ habszintek[selectedIdx].getAhelyKg(raktarModel.HabAvalto, raktarModel.HabAtartaly) }} kg</mat-label>
							  </div>
							  <div class="col-5" *ngIf="habszintek[selectedIdx].getAmeres() == null">
								   <mat-label>mért: - kg</mat-label>
								   <br>
								   <mat-label>hiány: - kg</mat-label>
								   <br>
								   <mat-label>hely: - kg</mat-label>
							  </div>
						 </div>
					</div>
					<br>
					<div class="mat-elevation-z2 px-2 py-2">
						 <mat-card-subtitle>B komponens</mat-card-subtitle>
						 <div class="row" style="justify-content: space-around;">
							  <div class="col-5">
								   <mat-form-field appearance="standard">
										<mat-label>mérés (mm)</mat-label>
										<input matInput name="inputBMeres" type="number" step="1" min="0" [ngModel]="habszintek[selectedIdx].getBmeres()" (ngModelChange)="habszintek[selectedIdx].setBmeres($event)" [disabled]="!writeable">
								   </mat-form-field>
							  </div>
							  <div class="col-5" *ngIf="habszintek[selectedIdx].getBmeres() != null">
								   <mat-label>mért: {{ habszintek[selectedIdx].getBmertKg(raktarModel.HabBvalto, raktarModel.HabBtartaly) }} kg</mat-label>
								   <br>
								   <mat-label>hiány: {{ habszintek[selectedIdx].getBhianyKg(raktarModel.HabAvalto, raktarModel.HabAtartaly, raktarModel.HabBvalto, raktarModel.HabBtartaly) }} kg</mat-label>
								   <br>
								   <mat-label>hely: {{ habszintek[selectedIdx].getBhelyKg(raktarModel.HabBvalto, raktarModel.HabBtartaly) }} kg</mat-label>
							  </div>
							  <div class="col-5" *ngIf="habszintek[selectedIdx].getBmeres() == null">
								   <mat-label>mért: - kg</mat-label>
								   <br>
								   <mat-label>hiány: - kg</mat-label>
								   <br>
								   <mat-label>hely: - kg</mat-label>
							  </div>
						 </div>
					</div>
					<br>
					<div class="mat-elevation-z2 px-2 py-2">
						 <div class="row" style="justify-content: space-around;">
							  <div class="col-5">
								   <mat-label>Előállítható mix: {{ habszintek[selectedIdx].getMixMennyisegeKg(raktarModel.HabAvalto, raktarModel.HabAtartaly, raktarModel.HabBvalto, raktarModel.HabBtartaly) }} kg</mat-label>
							  </div>
							  <div class="col-5">
								   <mat-label>{{ habszintek[selectedIdx].getNapraEleg(raktarModel.HabAvalto, raktarModel.HabAtartaly, raktarModel.HabBvalto, raktarModel.HabBtartaly, raktarModel.HabNapiFogyasztas) }} napra elegendő</mat-label>
							  </div>
						 </div>
					</div>
			   </form>
		  </mat-card-content>
		  <mat-card-actions style="display: flex; justify-content: space-around; width: 50%; margin: 0 auto;">
			   <button mat-mini-fab color="disabled" (click)="prev()">
					<fa-icon [icon]="['fas', 'chevron-left']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab color="disabled" (click)="new()">
					<fa-icon [icon]="['fas', 'plus']" size="lg"></fa-icon>
			   </button>
			   <button mat-mini-fab color="disabled" (click)="next()">
					<fa-icon [icon]="['fas', 'chevron-right']" size="lg"></fa-icon>
			   </button>
		  </mat-card-actions>
		  <mat-card-actions style="text-align: right;">
			   <button mat-raised-button (click)="save()" [disabled]="!dataForm.valid || !writeable">Mentés</button>
			   <button mat-raised-button routerLink="/raktar">Mégsem</button>
		  </mat-card-actions>
	 </mat-card>
</div>

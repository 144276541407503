<div class="loading-wrapper">
	<div class="loading-shade" *ngIf="loading!=0">
		 <mat-spinner></mat-spinner>
	</div>
	<mat-toolbar>
		 <mat-toolbar-row>
			  <span>{{ getSettingName() }}</span>
		 </mat-toolbar-row>
	</mat-toolbar>
	<form #targetForm="ngForm">
		<mat-form-field appearance="standard">
			<mat-label>Gyártó</mat-label>
			<mat-select name="inputGyarto" [ngModel]="targetObj.getLemezGyartoid()" (ngModelChange)="targetObj.setLemezGyartoid($event)" disabled>
				<mat-option *ngFor="let gyarto of gyartok" [value]="gyarto.getId()">{{gyarto.getGyarto()}}</mat-option>
			</mat-select>
		</mat-form-field>
		<br/>
		<mat-form-field appearance="standard">
			<mat-label>Szín</mat-label>
			<mat-select name="inputSzin" [ngModel]="targetObj.getLemezSzinid()" (ngModelChange)="targetObj.setLemezSzinid($event)" disabled>
				<mat-option *ngFor="let szin of szinek" [value]="szin.getId()">{{szin.getSzin()}}</mat-option>
			</mat-select>
		</mat-form-field>
		<br/>
		<mat-form-field appearance="standard">
			<mat-label>Fajsúly</mat-label>
			<mat-select name="inputAnyagminoseg" [ngModel]="targetObj.getLemezAnyagminosegid()" (ngModelChange)="targetObj.setLemezAnyagminosegid($event)" disabled>
				<mat-option *ngFor="let anyagminoseg of anyagminosegek" [value]="anyagminoseg.getId()">{{anyagminoseg.getFajsuly()}}</mat-option>
			</mat-select>
		</mat-form-field>
		<br/>
		<mat-form-field appearance="standard">
			<mat-label>Szélesség</mat-label>
			<mat-select name="inputSzelesseg" [ngModel]="targetObj.getLemezSzelessegid()" (ngModelChange)="targetObj.setLemezSzelessegid($event)" disabled>
				<mat-option *ngFor="let szelesseg of szelessegek" [value]="szelesseg.getId()">{{szelesseg.getSzelesseg()}}</mat-option>
			</mat-select>
		</mat-form-field>
		<br/>
		<mat-form-field appearance="standard">
			<mat-label>Vastagság</mat-label>
			<mat-select name="inputVastagsag" [ngModel]="targetObj.getLemezVastagsagid()" (ngModelChange)="targetObj.setLemezVastagsagid($event)" disabled>
				<mat-option *ngFor="let vastagsag of vastagsagok" [value]="vastagsag.getId()">{{vastagsag.getVastagsag()}}</mat-option>
			</mat-select>
		</mat-form-field>
		<br/>
        <mat-form-field appearance="standard">
			<mat-label>Tömeg</mat-label>
			<input matInput name="inputTomeg" [ngModel]="targetObj.getTomeg()" (ngModelChange)="targetObj.setTomeg($event)" disabled>
		</mat-form-field>
		<br/>
		<mat-form-field appearance="standard">
			<mat-label>Szállítólevél</mat-label>
			<input matInput name="inputSzallitolevel" [ngModel]="targetObj.getSzallitolevel()" (ngModelChange)="targetObj.setSzallitolevel($event)" disabled>
	   	</mat-form-field>
	   	<br/>
		<mat-form-field appearance="standard">
		  <mat-label>Aktuális tömeg</mat-label>
			<input matInput name="inputAktualistomeg" [ngModel]="targetObj.getAktualistomeg()" (ngModelChange)="targetObj.setAktualistomeg($event)" disabled>
		</mat-form-field>
		<br/>
		<mat-form-field>
			<mat-label>Időpont</mat-label>
			<input matInput name="inputIdopont" [ngModel]="targetObj.getIdopont()" (ngModelChange)="targetObj.setIdopont($event)" [owlDateTime]="inputIdopontPicker" [owlDateTimeTrigger]="inputIdopontPicker" disabled>
			<owl-date-time firstDayOfWeek="1" pickerType="both" #inputIdopontPicker></owl-date-time>
		</mat-form-field>
     </form>
	<div class="dialogButtons">
		 <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		 <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	</div>
</div>
<mat-toolbar>
	 <mat-toolbar-row>
		  <span>{{ getSettingName() }}</span>
	 </mat-toolbar-row>
</mat-toolbar>
<div class="loading-wrapper tableContainer my-4">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>

	 <app-table-filter style="width: 100%;" filterId="habszintek" [(filter)]="filterValue" (keyupFn)="applyFilter()" placeholder="Szűrő..."></app-table-filter>

	 <div class="table-wrapper mat-elevation-z8">
		  <table mat-table [dataSource]="dataSource" matSort>

				 <!-- ID Column -->
				 <ng-container matColumnDef="id">
					<th class="col-id" mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  #
						 </div>
					</th>
					<td class="col-id" mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getId()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Ameres Column -->
			   <ng-container matColumnDef="ameres">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  A mérés
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getAmeres()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Bmeres Column -->
			   <ng-container matColumnDef="bmeres">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							B mérés
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getBmeres()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Idopont Column -->
			   <ng-container matColumnDef="idopont">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						<div class="data-wrapper">
							  Időpont
						 </div>
					</th>
					<td mat-cell *matCellDef="let row">
						<div class="data-wrapper">
							  {{row.getIdopontString()}}
						 </div>
					</td>
			   </ng-container>

			   <!-- Action Column -->
			   <ng-container matColumnDef="action">
					<th class="col-action" mat-header-cell *matHeaderCellDef>
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'plus']" size="lg" (click)="add();" matTooltip="Új" *hasPermission="'Modules:HabSzint:add'"></fa-icon>
							  <fa-icon [icon]="['fas', 'sync']" size="lg" (click)="loadData();" matTooltip="Frissítés"></fa-icon>
						 </div>
					</th>
					<td class="col-action" mat-cell *matCellDef="let row;">
						<div class="data-wrapper">
							  <fa-icon [icon]="['fas', 'pencil-alt']" size="lg" (click)="mod(row.getId());" matTooltip="Módosítás" *hasPermission="'Modules:HabSzint:mod'"></fa-icon>
							  <fa-icon [icon]="['fas', 'trash']" size="lg" (click)="del(row.getId());" matTooltip="Törlés" *hasPermission="'Modules:HabSzint:del'"></fa-icon>
						 </div>
					</td>
			   </ng-container>

			   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		  </table>

		  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20, 25]"></mat-paginator>
	 </div>
</div>
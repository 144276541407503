<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar color="primary">
		  <mat-toolbar-row>
			   <span style="max-width: 90%; white-space: pre-wrap;">{{targetObj.projectModel.getName()}} szerelés</span>
			   <span class="spacer"></span>
			   <button mat-icon-button (click)="dialogRef.close(false)">
					   <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
			   </button>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <mat-dialog-content>
		  <div class="szereles-wrapper px-2 py-2">
			   <div class="col-6 mat-elevation-z4 my-2 py-4">
					<form>
						 <mat-form-field appearance="standard">
							  <mat-label>Szerelés vezető2</mat-label>
							  <mat-select name="inputSzerelesvezeto" [(ngModel)]="targetObj.szerelo" (selectionChange)="szereloSelected($event)" (openedChange)="szereloFilter.value=null; filterSzerelok(null);" [disabled]="!writeable">
										  <input matInput #szereloFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterSzerelok($event.target.value)" (keydown)="$event.stopPropagation()" />
									 <mat-option *ngFor="let szerelo of filteredSzerelok | customsort:'getFullName'" [value]="szerelo.getId()">{{szerelo.getFullName()}}</mat-option>
							  </mat-select>
						 </mat-form-field>
						 <br>
						 <mat-form-field>
							  <mat-label>Kezdés</mat-label>
							  <input matInput name="inputKezdes" [ngModel]="targetObj.kezdes" (ngModelChange)="setKezdes($event)" [owlDateTime]="inputKezdesPicker" [owlDateTimeTrigger]="inputKezdesPicker" [disabled]="!writeable">
							  <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputKezdesPicker panelClass="szerelesDatumPicker"></owl-date-time>
						 </mat-form-field>
						 <br>
						 <mat-form-field>
							  <mat-label>Befejezés</mat-label>
							  <input matInput name="inputBefejezes" [ngModel]="targetObj.befejezes" (ngModelChange)="setBefejezes($event)" [owlDateTime]="inputBefejezesPicker" [owlDateTimeTrigger]="inputBefejezesPicker" [disabled]="!writeable">
							  <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputBefejezesPicker panelClass="szerelesDatumPicker"></owl-date-time>
						 </mat-form-field>
						 <br>
						 <!-- <mat-checkbox name="inputSzallitas" [(ngModel)]="targetObj.szallitas">Szállítással</mat-checkbox>
						 <br> -->
						 <mat-form-field appearance="standard">
							  <mat-label>Segítők száma</mat-label>
							  <input matInput name="inputSegitok" type="number" step="1" [(ngModel)]="targetObj.segitok" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-checkbox name="inputEmelogep" [(ngModel)]="targetObj.emelogep" [disabled]="!writeable">Emelőgép kell a szereléshez?</mat-checkbox>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Eszközigény</mat-label>
							  <textarea matInput name="inputEszkozigeny" rows="5" [(ngModel)]="targetObj.eszkozigeny" [disabled]="!writeable"></textarea>
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Megjegyzés</mat-label>
							  <textarea matInput name="inputMegjegyzes" rows="5" [(ngModel)]="targetObj.megjegyzes" [disabled]="!writeable"></textarea>
						 </mat-form-field>
					</form>
			   </div>
			   <div class="col-6 mat-elevation-z4 my-2 py-4">
					<form>
						 <mat-form-field appearance="standard">
							  <mat-label>Irányítószám</mat-label>
							  <input matInput name="inputIranyitoszam" [(ngModel)]="targetObj.iranyitoszam" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Város</mat-label>
							  <input matInput name="inputVaros" [(ngModel)]="targetObj.varos" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Utca</mat-label>
							  <input matInput name="inputUtca" [(ngModel)]="targetObj.utca" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Házszám</mat-label>
							  <input matInput name="inputHazszam" [(ngModel)]="targetObj.hazszam" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Építés vezető</mat-label>
							  <input matInput name="inputKontakt" [(ngModel)]="targetObj.kontakt" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Telefon</mat-label>
							  <input matInput name="inputTelefon" [(ngModel)]="targetObj.telefon" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>E-mail</mat-label>
							  <input matInput name="inputEmail" [(ngModel)]="targetObj.email" [disabled]="!writeable">
						 </mat-form-field>
						 <br>
						 <mat-form-field appearance="standard">
							  <mat-label>Állapot</mat-label>
							  <mat-select name="inputAllapot" [(ngModel)]="targetObj.allapot" [disabled]="!writeable">
								   <mat-option *ngFor="let szerelesallapot of listsModel.SzerelesAllapotList | customsort:'getName'" [value]="szerelesallapot.getValue()">{{szerelesallapot.getName()}}</mat-option>
							  </mat-select>
						 </mat-form-field>
					</form>
			   </div>
		  </div>
		  <br>
		  <div class="dialogButtons">
			   <button mat-flat-button color="primary" (click)="save()" [disabled]="!writeable">Mentés</button>
			   <button mat-flat-button color="warn" (click)="dialogRef.close(false)">Mégsem</button>
		  </div>
	 </mat-dialog-content>
</div>
<div [innerHTML]="freeDates"></div>
<div class="animated-cubes-container">
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
	 <div class="animated-cube">
	 </div>
</div>

<div class="buttons-container col-10 col-md-5 col-lg-4 col-xl-3 mx-auto">
	 <button mat-raised-button routerLink="/habbetoltes" class="menu-button">
		  <div>
			   <span>Hab betöltés</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/habmeres" class="menu-button">
		  <div>
			   <span>Hab mérés</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/lemezbevetel" class="menu-button">
		  <div>
			   <span>Lemez bevétel</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/lemezathelyezes" class="menu-button">
		  <div>
			   <span>Lemez áthelyezés</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/lemezbeemeles" class="menu-button">
		  <div>
			   <span>Lemez beemelés</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/lemezfelhasznalas" class="menu-button">
		  <div>
			   <span>Lemez felhasználás</span>
		  </div>
	 </button>
	 <button mat-raised-button routerLink="/feladatok" class="menu-button">
		  <div>
			   <span>Vissza</span>
		  </div>
	 </button>
</div>
import {NgModule} from '@angular/core';

import {CommonModules} from 'src/app/common.modules';

import {SzallitasComponent} from 'src/app/components/task/dialog/task-dialog/taskforms/szallitas/szallitas.component';

import {SzallitasService} from 'src/app/components/task/dialog/task-dialog/taskforms/szallitas/szallitas.service';

@NgModule({
	imports: [
		CommonModules
	],
	declarations: [
		SzallitasComponent
	],
	entryComponents: [
		SzallitasComponent
	],
	providers: [
		SzallitasService
	],
	exports: [
		SzallitasComponent
	],
})
export class SzallitasModule {
	public static readonly component = SzallitasComponent;
}
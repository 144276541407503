import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {ProjectService} from 'src/app/components/settings/project/service/project.service';
import {UserService} from 'src/app/components/settings/user/service/user.service';
import {PartnerService} from 'src/app/components/settings/partner/service/partner.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {ListsModel} from 'src/app/model/lists.model';
import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {PartnerModel} from 'src/app/components/settings/partner/model/partner.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: ProjectService.MOD_TAG,
	templateUrl: './modproject.component.html',
	styleUrls: ['./modproject.component.scss']
})
export class ModProjectComponent implements OnDestroy {

	public static readonly TAG = ProjectService.MOD_TAG;
	public static readonly NAME = "Projekt módosítás";
	getSettingName() {return ModProjectComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: ProjectModel = new ProjectModel({});
	users: Array<UserModel> = [];
	filteredManagers: Array<UserModel> = [];
	partners: Array<PartnerModel> = [];
	filteredPartners: Array<PartnerModel> = [];
	listsModel: any = ListsModel;

	constructor(
		private projectService: ProjectService,
		private userService: UserService,
		private partnerService: PartnerService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.userService.getAll().subscribe(
				res => this.handleGetUsersResponse(res),
				error => this.handleGetUsersError(error)
			);
			this.loading++;
			this.partnerService.getAll().subscribe(
				res => this.handleGetPartnersResponse(res),
				error => this.handleGetPartnersError(error)
			);
			this.loading++;
			this.projectService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}

		this.filteredManagers = this.users.filter((obj) => {return true;});
	}

	handleGetPartnersError(serverError) {
		this.handleError(serverError);
	}

	handleGetPartnersResponse(serverResponse) {
		this.loading--;
		this.partners = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.partners.push(new PartnerModel(serverResponse[i]));
		}

		this.filteredPartners = this.partners.filter((obj) => {return true;});
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new ProjectModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.projectService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODPROJECT_PROJECTID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODPROJECT_PROJECTID);
	}

	back() {
		this.loadSettingComponent.emit(ProjectService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

	filterManagers(val) {
		this.filteredManagers = this.users.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterPartners(val) {
		this.filteredPartners = this.partners.filter((obj) => {
			return (val == null || obj.getNev().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
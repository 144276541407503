import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzintService} from 'src/app/components/raktar/service/habszint.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {HabSzintModel} from 'src/app/components/raktar/model/habszint.model';
import {HabMinosegModel} from 'src/app/components/settings/raktar/habminoseg/model/habminoseg.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';


@Component({
	selector: HabSzintService.MOD_TAG,
	templateUrl: './modhabszint.component.html',
	styleUrls: ['./modhabszint.component.scss']
})
export class ModHabSzintComponent implements OnDestroy {

	public static readonly TAG = HabSzintService.MOD_TAG;
	public static readonly NAME = "Habszint módosítása";
	getSettingName() {return ModHabSzintComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: HabSzintModel = new HabSzintModel({},new HabMinosegModel({}));

	constructor(
		private habSzintService: HabSzintService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.habSzintService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			console.log(JSON.stringify(serverError));
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new HabSzintModel(serverResponse,serverResponse.habminoseg);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.habSzintService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODHABSZINT_HABSZINTID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODHABSZINT_HABSZINTID);
	}

	back() {
		this.loadSettingComponent.emit(HabSzintService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
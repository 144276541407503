import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezAnyagminosegComponent} from 'src/app/components/settings/raktar/lemezanyagminoseg/component/addlemezanyagminoseg/addlemezanyagminoseg.component';
import {ModLemezAnyagminosegComponent} from 'src/app/components/settings/raktar/lemezanyagminoseg/component/modlemezanyagminoseg/modlemezanyagminoseg.component';
import {LemezAnyagminosegekComponent} from 'src/app/components/settings/raktar/lemezanyagminoseg/component/lemezanyagminosegek/lemezanyagminosegek.component';

import {LemezAnyagminosegService} from 'src/app/components/settings/raktar/lemezanyagminoseg/service/lemezanyagminoseg.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezAnyagminosegComponent,
		ModLemezAnyagminosegComponent,
		LemezAnyagminosegekComponent
	],
	entryComponents: [
		AddLemezAnyagminosegComponent,
		ModLemezAnyagminosegComponent,
		LemezAnyagminosegekComponent
	],
	providers: [
		LemezAnyagminosegService
	],
	exports: [
		AddLemezAnyagminosegComponent,
		ModLemezAnyagminosegComponent,
		LemezAnyagminosegekComponent
	],
})
export class LemezAnyagminosegModule {
	public static readonly compArray = [
		AddLemezAnyagminosegComponent,
		ModLemezAnyagminosegComponent,
		LemezAnyagminosegekComponent
	];

	public static readonly compMap = LemezAnyagminosegModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezAnyagminosegModule.compMap) ? LemezAnyagminosegModule.compMap[TAG].NAME : '';
	}
}
import {NgModule} from '@angular/core'

import {CommonModules} from 'src/app/common.modules';

import {TaskformConfigDialog} from 'src/app/components/task/dialog/taskform-config-dialog/taskform-config-dialog';

@NgModule({
	imports: [
		CommonModules,
	],
	declarations: [
		TaskformConfigDialog
	],
	entryComponents: [
		TaskformConfigDialog
	],
	exports: [
		TaskformConfigDialog
	],
})
export class TaskformConfigDialogModule {}
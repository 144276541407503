import {OnInit, ElementRef, Input, Directive} from '@angular/core';

@Directive({
	selector: '[autoFocus]'
})
export class autoFocusDirective implements OnInit {

	@Input('autoFocus') autoFocus: boolean;

	constructor(private hostElement: ElementRef) {}

	ngOnInit() {
		if (this.autoFocus) {
			const input: HTMLInputElement = this.hostElement.nativeElement as HTMLInputElement;
			input.focus();
			input.select();
		}
	}
}
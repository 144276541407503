import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-raktarmenu',
	templateUrl: './raktarmenu.component.html',
	styleUrls: ['./raktarmenu.component.scss']
})
export class RaktarMenuComponent {

	constructor(
		private router: Router
	) {}

}

import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CommonModel} from 'src/app/model/common.model';
import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: HabSzallitasService.LIST_TAG,
	templateUrl: './habszallitasok.component.html',
	styleUrls: ['./habszallitasok.component.scss']
})
export class HabSzallitasokComponent {

	public static readonly TAG = HabSzallitasService.LIST_TAG;
	public static readonly NAME = "Habszállítások";
	getSettingName() {return HabSzallitasokComponent.NAME;}

	public static readonly _requiredPermissions = ['Modules:HabSzallitas:list', 'Modules:HabSzallitas:add', 'Modules:HabSzallitas:mod','Modules:HabSzallitas:del'];
	public static readonly _adminOnly = false;
	public static readonly _icon = ['fas', 'truck'];
	public static readonly _category = "Raktár";

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	displayedColumns: string[] = ['id', 'szallitolevel', 'amennyiseg', 'anetto', 'bmennyiseg', 'bnetto', 'akulonbseg', 'bkulonbseg', 'idopont', 'action'];
	dataSource: MatTableDataSource<HabSzallitasModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	filterValue: string = "";

	constructor(
		private habSzallitasService: HabSzallitasService,
		public dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getSzallitolevel(),
				String(row.getAmennyiseg()),
				String(row.getAnetto()),
				String(row.getBmennyiseg()),
				String(row.getBnetto()),
				String(row.getAmertKgDiff()),
				String(row.getBmertKgDiff()),
				row.getIdopontString()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<HabSzallitasModel> = [];
		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new HabSzallitasModel(serverResponse[i]));
		}
		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'szallitolevel': return item.getSzallitolevel();
				case 'amennyiseg': return item.getAmennyiseg();
				case 'anetto': return item.getAnetto();
				case 'bmennyiseg': return item.getBmennyiseg();
				case 'bnetto': return item.getBnetto();
				case 'akulonbseg': return item.getAmertKgDiff();
				case 'bkulonbseg': return item.getBmertKgDiff();
				case 'idopont': return item.getIdopontString();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.habSzallitasService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	add() {
		this.loadSettingComponent.emit(HabSzallitasService.ADD_TAG);
	}

	mod(id) {
		sessionStorage.setItem(AppConstants.PARAM_MODHABSZALLITAS_HABSZALLITASID, id);
		this.loadSettingComponent.emit(HabSzallitasService.MOD_TAG);
	}

	del(id) {
		sessionStorage.setItem(AppConstants.PARAM_DELHABSZALLITAS_HABSZALLITASID, id);
		this.loadSettingComponent.emit(HabSzallitasService.DEL_TAG);
	}

}

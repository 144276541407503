import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {HabSzallitasService} from 'src/app/components/raktar/service/habszallitas.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {HabSzallitasModel} from 'src/app/components/raktar/model/habszallitas.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import { HabSzintModel } from 'src/app/components/raktar/model/habszint.model';


@Component({
	selector: HabSzallitasService.MOD_TAG,
	templateUrl: './modhabszallitas.component.html',
	styleUrls: ['./modhabszallitas.component.scss']
})
export class ModHabSzallitasComponent implements OnDestroy {

	public static readonly TAG = HabSzallitasService.MOD_TAG;
	public static readonly NAME = "Habszállítás módosítása";
	getSettingName() {return ModHabSzallitasComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: HabSzallitasModel = new HabSzallitasModel({});
	habszintekElotte:Array<HabSzintModel>=[];
	habszintekUtana:Array<HabSzintModel>=[];

	constructor(
		private habSzallitasService: HabSzallitasService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.habSzallitasService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			console.log(JSON.stringify(serverError));
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new HabSzallitasModel(serverResponse.habszallitas);
		for(let i=0; i<serverResponse.habszintekelotte.length; i++){
			this.habszintekElotte.push(new HabSzintModel(serverResponse.habszintekelotte[i],serverResponse.habszintekelotte[i].habminoseg))
		}
		for(let i=0; i<serverResponse.habszintekutana.length; i++){
			this.habszintekUtana.push(new HabSzintModel(serverResponse.habszintekutana[i],serverResponse.habszintekutana[i].habminoseg))
		}
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
	
		this.habSzallitasService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODHABSZALLITAS_HABSZALLITASID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_DELHABSZALLITAS_HABSZALLITASID);
	}

	back() {
		this.loadSettingComponent.emit(HabSzallitasService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
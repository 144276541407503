import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddTaskRefComponent} from 'src/app/components/settings/taskref/component/addtaskref/addtaskref.component';
import {ModTaskRefComponent} from 'src/app/components/settings/taskref/component/modtaskref/modtaskref.component';
import {DelTaskRefComponent} from 'src/app/components/settings/taskref/component/deltaskref/deltaskref.component';
import {TaskRefsComponent} from 'src/app/components/settings/taskref/component/taskrefs/taskrefs.component';

import {TaskRefService} from 'src/app/components/settings/taskref/service/taskref.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddTaskRefComponent,
		ModTaskRefComponent,
		DelTaskRefComponent,
		TaskRefsComponent
	],
	entryComponents: [
		AddTaskRefComponent,
		ModTaskRefComponent,
		DelTaskRefComponent,
		TaskRefsComponent
	],
	providers: [
		TaskRefService
	],
	exports: [
		AddTaskRefComponent,
		ModTaskRefComponent,
		DelTaskRefComponent,
		TaskRefsComponent
	],
})
export class TaskRefModule {
	public static readonly compArray = [
		AddTaskRefComponent,
		ModTaskRefComponent,
		DelTaskRefComponent,
		TaskRefsComponent
	];

	public static readonly compMap = TaskRefModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in TaskRefModule.compMap) ? TaskRefModule.compMap[TAG].NAME : '';
	}
}
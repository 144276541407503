import {CommonModel} from "src/app/model/common.model";

export class HabMinosegModel {

	private id: number = null;

	private szallito: string = '';
	private tipus: string = '';
	private aarany: number = null;
	private barany: number = null;
	private kezdete: any = null;

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.szallito = json && json.szallito || '';
		this.tipus = json && json.tipus || '';
		this.aarany = json && json.aarany || null;
		this.barany = json && json.barany || null;
		this.kezdete = json && json.kezdete || null;
		this.kezdete = CommonModel.isDate(this.kezdete) ? (new Date(Date.parse(this.kezdete))) : null;

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getSzallito() {
		return this.szallito;
	}

	setSzallito(value) {
		this.szallito = value;
	}

	getTipus() {
		return this.tipus;
	}

	setTipus(value) {
		this.tipus = value;
	}

	getAarany() {
		return this.aarany;
	}

	setAarany(value) {
		this.aarany = value;
	}

	getBarany() {
		return this.barany;
	}

	setBarany(value) {
		this.barany = value;
	}

	getKezdete() {
		return this.kezdete;
	}

	setKezdete(value) {
		this.kezdete = new Date(value);
	}

	getKezdeteString() {
		if (this.kezdete instanceof Date) {
			return (this.kezdete.getFullYear() + "-" + ("0" + (this.kezdete.getMonth() + 1)).substr(-2) + "-" + ("0" + this.kezdete.getDate()).substr(-2));
		} else {
			return '';
		}
	}
}

import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {ProjectService} from 'src/app/components/settings/project/service/project.service';

import {AppConstants} from 'src/app/model/appconstants.model';

import {CommonModel} from 'src/app/model/common.model';
import {ListsModel} from 'src/app/model/lists.model';
import {ProjectModel} from 'src/app/components/settings/project/model/project.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: 'app-projectlist',
	templateUrl: './projectlist.component.html',
	styleUrls: ['./projectlist.component.scss']
})
export class ProjectListComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	displayedColumns: string[] = ['id', 'p_number', 'p_name', 'p_desc', 'managername', 'partnernev', 'p_status', 'action'];
	dataSource: MatTableDataSource<ProjectModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	listsModel: any = ListsModel;

	filterValue: string = "";

	constructor(
		private projectService: ProjectService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getNumber(),
				row.getName(),
				row.getDesc(),
				row.getManagerModel().getFullName(),
				row.getPartnerModel().getNev(),
				ListsModel.getListItem(row.getStatus(), ListsModel.ProjectStatus).getName()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<ProjectModel> = [];
		let tmpObj: ProjectModel = new ProjectModel({});

		for (let i = 0; i < serverResponse.length; i++) {
			tmpObj = new ProjectModel(serverResponse[i]);
			//if (tmpObj.getStatus() == ListsModel.ProjectStatus_FOLYAMATBAN.getValue()) {
				objects.push(tmpObj);
			//}
		}

		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'p_number': return item.getNumber();
				case 'p_name': return item.getName();
				case 'p_desc': return item.getDesc();
				case 'managername': return item.getManagerModel().getFullName();
				case 'partnernev': return item.getPartnerModel().getNev();
				case 'p_status': return ListsModel.getListItem(item.getStatus(), ListsModel.ProjectStatus).getName();

				default: return item[property];
			}
		};
		this.sort.sort(({ id: 'id', start: 'desc'}) as MatSortable);
		this.dataSource.sort = this.sort;
		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.projectService.getAll().subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	open(id) {
		sessionStorage.setItem(AppConstants.PARAM_OPENPROJECTINFO_PROJECTID, id);
		this.router.navigateByUrl('/projekt');
	}

}

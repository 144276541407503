export class LemezRaktarModel {

	private id: number = null;

	private raktar: string = '';

	private modifiedby: number = null;
	private modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.raktar = json && json.raktar || '';

		this.modifiedby = json && json.modifiedby || null;
		this.modifiedtime = json && json.modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getRaktar() {
		return this.raktar;
	}

	setRaktar(value) {
		this.raktar = value;
	}
}

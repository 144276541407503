<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Munkaszám</mat-label>
			   <input matInput name="inputNumber" [ngModel]="targetObj.getNumber()" (ngModelChange)="targetObj.setNumber($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Projekt megnevezése</mat-label>
			   <input matInput name="inputName" [ngModel]="targetObj.getName()" (ngModelChange)="targetObj.setName($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Leírás</mat-label>
			   <textarea matInput name="inputDesc" rows="5" [ngModel]="targetObj.getDesc()" (ngModelChange)="targetObj.setDesc($event)"></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Projekt vezető</mat-label>
			   <mat-select name="inputManager" [ngModel]="targetObj.getManager()" (ngModelChange)="targetObj.setManager($event)" (openedChange)="managerFilter.value=null; filterManagers(null);" required>
						   <input matInput #managerFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterManagers($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let user of filteredManagers | customsort:'getFullName'" [value]="user.getId()">{{user.getFullName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Partner</mat-label>
			   <mat-select name="inputPartner" [ngModel]="targetObj.getPartner()" (ngModelChange)="targetObj.setPartner($event)" (openedChange)="partnerFilter.value=null; filterPartners(null);">
						   <input matInput #partnerFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterPartners($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option value="-1">-</mat-option>
					<mat-option *ngFor="let partner of filteredPartners | customsort:'getNev'" [value]="partner.getId()">{{partner.getNev()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Státusz</mat-label>
			   <mat-select name="inputStatus" [ngModel]="targetObj.getStatus()" (ngModelChange)="targetObj.setStatus($event)" required>
					<mat-option *ngFor="let projectstatus of listsModel.ProjectStatusList | customsort:'getName'" [value]="projectstatus.getValue()">{{projectstatus.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';

import {ProjectService} from 'src/app/components/settings/project/service/project.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {UserService} from 'src/app/components/settings/user/service/user.service';

import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {CommonModel} from 'src/app/model/common.model';

@Component({
	selector: 'app-add-task-dialog',
	templateUrl: './add-task-dialog.html',
	styleUrls: ['./add-task-dialog.scss']
})
export class AddTaskDialog {

	alertDialogRef: MatDialogRef<AlertDialog>;

	loading: number = 0;

	projectId: number;
	templateId: number;
	details: string = "";
	deadline: Date;
	owner: number;

	autocompleteProjectCtrl = new FormControl();
	projects: Array<ProjectModel> = [];
	filteredProjects: Array<ProjectModel> = [];
	templates: Array<TaskTemplateModel> = [];
	filteredTemplates: Array<TaskTemplateModel> = [];
	users: Array<UserModel> = [];
	filteredUsers: Array<UserModel> = [];

	constructor(
		private projectService: ProjectService,
		private taskService: TaskService,
		private userService: UserService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<AddTaskDialog>
	) {
		this.loading++;
		this.projectService.getAll().subscribe(
			res => this.handleGetProjectsResponse(res),
			error => this.handleGetProjectsError(error)
		);
		this.loading++;
		this.taskService.getMyTemplates().subscribe(
			res => this.handleGetMyTemplatesResponse(res),
			error => this.handleGetMyTemplatesError(error)
		);
		this.loading++;
		this.userService.getAll().subscribe(
			res => this.handleGetUsersResponse(res),
			error => this.handleGetUsersError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetProjectsError(serverError) {
		this.handleError(serverError);
	}

	handleGetProjectsResponse(serverResponse) {
		this.loading--;
		this.projects = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.projects.push(new ProjectModel(serverResponse[i]));
		}

		/*this.filteredProjects = this.autocompleteProjectCtrl.valueChanges.pipe(
			 startWith(''),
			 map(value => this._filterProjects(value))
		);*/

		this.filteredProjects = this.projects.filter((obj) => {return true;});
	}

	handleGetMyTemplatesError(serverError) {
		this.handleError(serverError);
	}

	handleGetMyTemplatesResponse(serverResponse) {
		this.loading--;
		this.templates = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.templates.push(new TaskTemplateModel(serverResponse[i]));
		}

		this.filteredTemplates = this.templates.filter((obj) => {return true;});
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}

		this.filteredUsers = this.users.filter((obj) => {return true;});
	}

	submit() {
		this.loading++;

		this.deadline.setSeconds(0);
		this.taskService.addMyTask(this.projectId, this.templateId, this.owner, this.details, (this.deadline.getFullYear() + "-" + ("0" + (this.deadline.getMonth() + 1)).substr(-2) + "-" + ("0" + this.deadline.getDate()).substr(-2) + " " + ("0" + this.deadline.getHours()).substr(-2) + ":" + ("0" + this.deadline.getMinutes()).substr(-2) + ":00")).subscribe(
			res => this.handleAddMyTaskResponse(res),
			error => this.handleAddMyTaskError(error)
		);
	}

	handleAddMyTaskError(serverError) {
		this.handleError(serverError);
	}

	handleAddMyTaskResponse(serverResponse) {
		this.loading--;
		this.dialogRef.close(true);
	}

	templateChanged() {
		this.templates.forEach((value) => {
			if (this.templateId == value.getId()) {
				this.details = '';
				this.deadline = null;

				if (value.getDesc() != null && value.getDesc() != undefined && value.getDesc().length > 0) {
					this.details = value.getDesc();
				}

				if (value.getTimeshift() > 0) {
					let deadline: Date = new Date();
					deadline.setTime(deadline.getTime() + (value.getTimeshift() * 60 * 60 * 1000));
					this.deadline = deadline;
				}
			}
		});
	}

	/*projectClick(event: any) {
		 this.project = event.option.value;
	}

	checkProject() {
		 setTimeout(() => {
			  if (!this.project || this.project !== this.autocompleteProjectCtrl.value) {
				   this.project = undefined;
				   this.autocompleteProjectCtrl.setValue(null);
			  }
		 }, 1500);
	}*/

	/*private _filterProjects(value: string): ProjectModel[] {
		 const filterValue = CommonModel.replaceHunChars(String(value).toLowerCase());

		 return this.projects.filter(project => project.getName() != null ? value == null || value == '' || CommonModel.replaceHunChars(project.getName().toLowerCase()).includes(filterValue) : false);
	}*/

	displayProjectOption(project: ProjectModel) {
		return project && project.getName() || '';
	}

	filterProjects(val) {
		this.filteredProjects = this.projects.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterTemplates(val) {
		this.filteredTemplates = this.templates.filter((obj) => {
			return (val == null || obj.getName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

	filterUsers(val) {
		this.filteredUsers = this.users.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}

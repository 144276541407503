import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezTaroloComponent} from 'src/app/components/settings/raktar/lemeztarolo/component/addlemeztarolo/addlemeztarolo.component';
import {ModLemezTaroloComponent} from 'src/app/components/settings/raktar/lemeztarolo/component/modlemeztarolo/modlemeztarolo.component';
import {LemezTarolokComponent} from 'src/app/components/settings/raktar/lemeztarolo/component/lemeztarolok/lemeztarolok.component';

import {LemezRaktarService} from 'src/app/components/settings/raktar/lemezraktar/service/lemezraktar.service';
import {LemezTaroloService} from 'src/app/components/settings/raktar/lemeztarolo/service/lemeztarolo.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezTaroloComponent,
		ModLemezTaroloComponent,
		LemezTarolokComponent
	],
	entryComponents: [
		AddLemezTaroloComponent,
		ModLemezTaroloComponent,
		LemezTarolokComponent
	],
	providers: [
		LemezRaktarService,
		LemezTaroloService
	],
	exports: [
		AddLemezTaroloComponent,
		ModLemezTaroloComponent,
		LemezTarolokComponent
	],
})
export class LemezTaroloModule {
	public static readonly compArray = [
		AddLemezTaroloComponent,
		ModLemezTaroloComponent,
		LemezTarolokComponent
	];

	public static readonly compMap = LemezTaroloModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezTaroloModule.compMap) ? LemezTaroloModule.compMap[TAG].NAME : '';
	}
}
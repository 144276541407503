import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';

@Injectable()
export class TaskService {

	public static readonly API_TYPE: string = 'task';

	public static readonly GET_EXTRADATA_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_extradata_request';
	public static readonly GET_MYTASKS_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_mytasks_request';
	public static readonly GET_MYTASK_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_mytask_request';
	public static readonly GET_MYTEMPLATES_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_mytemplates_request';
	public static readonly ADD_MYTASK_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/add_mytask_request';
	public static readonly GET_MANUAL_TASKS_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_manual_tasks_request';
	public static readonly GET_DELEGATE_USERS_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/get_delegate_users_request';
	public static readonly UPLOAD_FILE_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/upload_file_request';
	public static readonly DELETE_FILE_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/delete_file_request';
	public static readonly MOD_DEADLINE_API: string = AppConstants.BASE_URL + '/api/' + TaskService.API_TYPE + '/mod_deadline_request';
	public static readonly DOWNLOAD_FILE_API: string = AppConstants.BASE_URL + '/api/download';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getExtraData(taskid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(taskid));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_EXTRADATA_API, params, {params: parameters});
	}

	getMyTasks(taskType: number, myTasksOnly: boolean) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tasktype', String(taskType));
		params.append('mytasksonly', String(myTasksOnly ? 1 : -1));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_MYTASKS_API, params, {params: parameters});
	}

	getMyTask(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_MYTASK_API, params, {params: parameters});
	}

	getMyTemplates() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_MYTEMPLATES_API, params, {params: parameters});
	}

	addMyTask(projectId: number, templateId: number, owner: number, details: string, deadline: string) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('projectid', String(projectId));
		params.append('templateid', String(templateId));
		if (owner > 0) {
			params.append('owner', String(owner));
		}
		params.append('details', details);
		params.append('deadline', deadline);

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.ADD_MYTASK_API, params, {params: parameters});
	}

	getManualTasks(taskId: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('taskid', String(taskId));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_MANUAL_TASKS_API, params, {params: parameters});
	}

	getDelegateUsers() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.GET_DELEGATE_USERS_API, params, {params: parameters});
	}

	uploadFile(projectid: number, taskid: number, filetypeid: number, file: File) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('projectid', String(projectid));
		if (taskid > 0) {
			params.append('taskid', String(taskid));
		}
		params.append('tf_type', String(filetypeid));
		params.append('document', file);

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskService.UPLOAD_FILE_API, params, {params: parameters});
	}

	deleteFile(fileid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('documentid', String(fileid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskService.DELETE_FILE_API, params, {params: parameters});
	}

	modDeadline(id: number, deadline: string) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));
		params.append('deadline', deadline);

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskService.MOD_DEADLINE_API, params, {params: parameters});
	}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskCommentModel} from 'src/app/components/task/model/taskcomment.model';

@Injectable()
export class TaskCommentService {

	public static readonly API_TYPE: string = 'taskcomment';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/get_all_request';
	public static readonly GET_ALL_BY_PROJECTID_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/get_all_by_projectid_request';
	public static readonly GET_ALL_BY_TASKID_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/get_all_by_taskid_request';
	public static readonly GET_ALL_BY_TEMPLATEID_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/get_all_by_templateid_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + TaskCommentService.API_TYPE + '/add_request';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskCommentService.GET_ALL_API, params, {params: parameters});
	}

	getAllByProjectId(projectid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('projectid', String(projectid));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskCommentService.GET_ALL_BY_PROJECTID_API, params, {params: parameters});
	}

	getAllByTaskId(taskid: number, projectid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('taskid', String(taskid));
		params.append('projectid', String(projectid));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskCommentService.GET_ALL_BY_TASKID_API, params, {params: parameters});
	}

	getAllByTemplateId(templateid: number, projectid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('templateid', String(templateid));
		params.append('projectid', String(projectid));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskCommentService.GET_ALL_BY_TEMPLATEID_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskCommentService.GET_API, params, {params: parameters});
	}

	add(taskid: number, comment: string) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tc_taskid', String(taskid));
		params.append('tc_comment', comment);

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskCommentService.ADD_API, params, {params: parameters});
	}
}

import {TaskFileModel} from 'src/app/components/task/model/taskfile.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';
import {ProjectModel} from 'src/app/components/settings/project/model/project.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

export class TaskModel {

	private id: number = null;

	private t_templateid: number = null;
	private template: TaskTemplateModel = new TaskTemplateModel({});
	private t_details: string = '';
	private t_projectid: number = null;
	private project: ProjectModel = new ProjectModel({});
	private t_objectid: number = null;
	private t_deadline: string = '';
	private t_status: number = null;
	private t_progress: number = null;
	private t_owner: number = null;
	private owner: UserModel = new UserModel({});
	private t_plannedtime: string = '';
	private t_createdby: number = null;
	private createdby: UserModel = new UserModel({});
	private t_createdtime: string = '';
	private t_assignedby: number = null;
	private assignedby: UserModel = new UserModel({});
	private t_assignedtime: string = '';
	private t_doneby: number = null;
	private doneby: UserModel = new UserModel({});
	private t_donetime: string = '';
	private t_cancelledby: number = null;
	private cancelledby: UserModel = new UserModel({});
	private t_cancelledtime: string = '';
	private t_cancellednote: string = '';

	private taskfiles: Array<TaskFileModel> = [];

	private t_modifiedby: number = null;
	private t_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;

		this.t_templateid = json && json.t_templateid || null;
		this.template = new TaskTemplateModel(json && json.template || {});
		this.t_details = json && json.t_details || '';
		this.t_projectid = json && json.t_projectid || null;
		this.project = new ProjectModel(json && json.project || {});
		this.t_objectid = json && json.t_objectid || null;
		this.t_deadline = json && json.t_deadline || '';
		this.t_status = json && json.t_status || null;
		this.t_progress = json && json.t_progress || null;
		this.t_owner = json && json.t_owner || null;
		this.owner = new UserModel(json && json.owner || {});
		this.t_plannedtime = json && json.t_plannedtime || '';
		this.t_createdby = json && json.t_createdby || null;
		this.createdby = new UserModel(json && json.createdby || {});
		this.t_createdtime = json && json.t_createdtime || '';
		this.t_assignedby = json && json.t_assignedby || null;
		this.assignedby = new UserModel(json && json.assignedby || {});
		this.t_assignedtime = json && json.t_assignedtime || '';
		this.t_doneby = json && json.t_doneby || null;
		this.doneby = new UserModel(json && json.doneby || {});
		this.t_donetime = json && json.t_donetime || '';
		this.t_cancelledby = json && json.t_cancelledby || null;
		this.cancelledby = new UserModel(json && json.cancelledby || {});
		this.t_cancelledtime = json && json.t_cancelledtime || '';
		this.t_cancellednote = json && json.t_cancellednote || '';

		let taskfilesjson = json && json.taskfiles || '';

		if (taskfilesjson != null && taskfilesjson != undefined && taskfilesjson.length > 0) {
			this.taskfiles = [];

			(taskfilesjson as Array<any>).forEach((value) => {
				this.taskfiles.push(new TaskFileModel(value));
			});
		}

		this.t_modifiedby = json && json.t_modifiedby || null;
		this.t_modifiedtime = json && json.t_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getTemplateid() {
		return this.t_templateid;
	}

	setTemplateid(value) {
		this.t_templateid = value;
	}

	getTemplateModel() {
		return this.template;
	}

	getDetails() {
		return this.t_details || this.template.getDesc() || '';
	}

	setDetails(value) {
		this.t_details = value;
	}

	getProjectid() {
		return this.t_projectid;
	}

	setProjectid(value) {
		this.t_projectid = value;
	}

	getProjectModel() {
		return this.project;
	}

	getObjectid() {
		return this.t_objectid;
	}

	setObjectid(value) {
		this.t_objectid = value;
	}

	getDeadline() {
		return this.t_deadline;
	}

	setDeadline(value) {
		this.t_deadline = value;
	}

	getStatus() {
		return this.t_status;
	}

	setStatus(value) {
		this.t_status = value;
	}

	getProgress() {
		return this.t_progress;
	}

	setProgress(value) {
		this.t_progress = value;
	}

	getOwner() {
		return this.t_owner;
	}

	setOwner(value) {
		this.t_owner = value;
	}

	getOwnerModel() {
		return this.owner;
	}

	getPlannedtime() {
		return this.t_plannedtime;
	}

	setPlannedtime(value) {
		this.t_plannedtime = value;
	}

	getCreatedby() {
		return this.t_createdby;
	}

	getCreatedbyModel() {
		return this.createdby;
	}

	setCreatedby(value) {
		this.t_createdby = value;
	}

	getCreatedtime() {
		return this.t_createdtime;
	}

	setCreatedtime(value) {
		this.t_createdtime = value;
	}

	getAssignedby() {
		return this.t_assignedby;
	}

	getAssignedbyModel() {
		return this.assignedby;
	}

	setAssignedby(value) {
		this.t_assignedby = value;
	}

	getAssignedtime() {
		return this.t_assignedtime;
	}

	setAssignedtime(value) {
		this.t_assignedtime = value;
	}

	getDoneby() {
		return this.t_doneby;
	}

	getDonebyModel() {
		return this.doneby;
	}

	setDoneby(value) {
		this.t_doneby = value;
	}

	getDonetime() {
		return this.t_donetime;
	}

	setDonetime(value) {
		this.t_donetime = value;
	}

	getCancelledby() {
		return this.t_cancelledby;
	}

	getCancelledbyModel() {
		return this.cancelledby;
	}

	setCancelledby(value) {
		this.t_cancelledby = value;
	}

	getCancelledtime() {
		return this.t_cancelledtime;
	}

	setCancelledtime(value) {
		this.t_cancelledtime = value;
	}

	getCancellednote() {
		return this.t_cancellednote;
	}

	setCancellednote(value) {
		this.t_cancellednote = value;
	}

	getFiles() {
		return this.taskfiles;
	}

	setFiles(json) {
		let taskfilesjson = json;

		if (taskfilesjson != null && taskfilesjson != undefined && taskfilesjson.length > 0) {
			this.taskfiles = [];

			(taskfilesjson as Array<any>).forEach((value) => {
				this.taskfiles.push(new TaskFileModel(value));
			});
		}
	}
}

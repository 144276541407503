<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Raktar</mat-label>
			   <mat-select name="inputRaktar" [ngModel]="targetObj.getLemezraktarid()" (ngModelChange)="targetObj.setLemezraktarid($event)" required>
					<mat-option *ngFor="let lemezraktar of lemezraktarak | customsort:'getRaktar'" [value]="lemezraktar.getId()">{{lemezraktar.getRaktar()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Tároló</mat-label>
			   <input matInput name="inputTarolo" [ngModel]="targetObj.getTarolo()" (ngModelChange)="targetObj.setTarolo($event)" required>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
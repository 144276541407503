import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {PartnerModel} from 'src/app/components/settings/partner/model/partner.model';
import {TaskFileModel} from 'src/app/components/task/model/taskfile.model';
import {ListsModel} from 'src/app/model/lists.model';

export class ProjectModel {

	private id: number = null;

	private p_number: string = '';
	private p_name: string = '';
	private p_desc: string = '';
	private p_manager: number = null;
	private manager: UserModel = new UserModel({});
	private p_partner: number = null;
	private partner: PartnerModel = new PartnerModel({});
	private p_status: number = null;

	private taskfiles: Array<TaskFileModel> = [];

	private p_modifiedby: number = null;
	private p_modifiedtime: string = '';

	constructor(json) {

		this.id = json && json.id || null;

		this.p_number = json && json.p_number || '';
		this.p_name = json && json.p_name || '';
		this.p_desc = json && json.p_desc || '';
		this.p_manager = json && json.p_manager || null;
		this.manager = new UserModel(json && json.manager || {});
		this.p_partner = json && json.p_partner || null;
		this.partner = new PartnerModel(json && json.partner || {});
		if (this.id == null) {
			this.p_status = json && json.p_status || ListsModel.ProjectStatus_FOLYAMATBAN.getValue();
		} else {
			this.p_status = json && json.p_status || null;
		}

		let taskfilesjson = json && json.taskfiles || '';

		if (taskfilesjson != null && taskfilesjson != undefined && taskfilesjson.length > 0) {
			this.taskfiles = [];

			(taskfilesjson as Array<any>).forEach((value) => {
				this.taskfiles.push(new TaskFileModel(value));
			});
		}

		this.p_modifiedby = json && json.p_modifiedby || null;
		this.p_modifiedtime = json && json.p_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getNumber() {
		return this.p_number;
	}

	setNumber(value) {
		this.p_number = value;
	}

	getName() {
		return this.p_name;
	}

	setName(value) {
		this.p_name = value;
	}

	getDesc() {
		return this.p_desc;
	}

	setDesc(value) {
		this.p_desc = value;
	}

	getManager() {
		return this.p_manager;
	}

	setManager(value) {
		this.p_manager = value;
	}

	getManagerModel() {
		return this.manager;
	}

	getPartner() {
		return this.p_partner;
	}

	setPartner(value) {
		this.p_partner = value;
	}

	getPartnerModel() {
		return this.partner;
	}

	getStatus() {
		return this.p_status;
	}

	setStatus(value) {
		this.p_status = value;
	}

	getFiles() {
		return this.taskfiles;
	}

	setFiles(json) {
		let taskfilesjson = json;

		if (taskfilesjson != null && taskfilesjson != undefined && taskfilesjson.length > 0) {
			this.taskfiles = [];

			(taskfilesjson as Array<any>).forEach((value) => {
				this.taskfiles.push(new TaskFileModel(value));
			});
		}
	}
}

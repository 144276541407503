import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddPartnerComponent} from 'src/app/components/settings/partner/component/addpartner/addpartner.component';
import {ModPartnerComponent} from 'src/app/components/settings/partner/component/modpartner/modpartner.component';
import {PartnersComponent} from 'src/app/components/settings/partner/component/partners/partners.component';

import {PartnerService} from 'src/app/components/settings/partner/service/partner.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddPartnerComponent,
		ModPartnerComponent,
		PartnersComponent
	],
	entryComponents: [
		AddPartnerComponent,
		ModPartnerComponent,
		PartnersComponent
	],
	providers: [
		PartnerService
	],
	exports: [
		AddPartnerComponent,
		ModPartnerComponent,
		PartnersComponent
	],
})
export class PartnerModule {
	public static readonly compArray = [
		AddPartnerComponent,
		ModPartnerComponent,
		PartnersComponent
	];

	public static readonly compMap = PartnerModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in PartnerModule.compMap) ? PartnerModule.compMap[TAG].NAME : '';
	}
}
import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddLemezSzelessegComponent} from 'src/app/components/settings/raktar/lemezszelesseg/component/addlemezszelesseg/addlemezszelesseg.component';
import {ModLemezSzelessegComponent} from 'src/app/components/settings/raktar/lemezszelesseg/component/modlemezszelesseg/modlemezszelesseg.component';
import {LemezSzelessegekComponent} from 'src/app/components/settings/raktar/lemezszelesseg/component/lemezszelessegek/lemezszelessegek.component';

import {LemezSzelessegService} from 'src/app/components/settings/raktar/lemezszelesseg/service/lemezszelesseg.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddLemezSzelessegComponent,
		ModLemezSzelessegComponent,
		LemezSzelessegekComponent
	],
	entryComponents: [
		AddLemezSzelessegComponent,
		ModLemezSzelessegComponent,
		LemezSzelessegekComponent
	],
	providers: [
		LemezSzelessegService
	],
	exports: [
		AddLemezSzelessegComponent,
		ModLemezSzelessegComponent,
		LemezSzelessegekComponent
	],
})
export class LemezSzelessegModule {
	public static readonly compArray = [
		AddLemezSzelessegComponent,
		ModLemezSzelessegComponent,
		LemezSzelessegekComponent
	];

	public static readonly compMap = LemezSzelessegModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in LemezSzelessegModule.compMap) ? LemezSzelessegModule.compMap[TAG].NAME : '';
	}
}
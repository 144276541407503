import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TaskConditionService} from 'src/app/components/settings/taskcondition/service/taskcondition.service';
import {TaskRefService} from 'src/app/components/settings/taskref/service/taskref.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskConditionModel} from 'src/app/components/settings/taskcondition/model/taskcondition.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';

@Component({
	selector: TaskConditionService.DEL_TAG,
	templateUrl: './deltaskcondition.component.html',
	styleUrls: ['./deltaskcondition.component.scss']
})
export class DelTaskConditionComponent implements OnDestroy {

	public static readonly TAG = TaskConditionService.DEL_TAG;
	public static readonly NAME = "Feladat feltétel törlése";
	getSettingName() {return DelTaskConditionComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: TaskConditionModel = new TaskConditionModel({});
	taskrefs: Array<TaskRefModel> = [];

	constructor(
		private taskConditionService: TaskConditionService,
		private taskRefService: TaskRefService,
		public dialog: MatDialog
	) {
		if (!this.getDelID()) {
			this.back();
		} else {
			this.loading++;
			this.taskRefService.getAll().subscribe(
				res => this.handleGetTaskRefsResponse(res),
				error => this.handleGetTaskRefsError(error)
			);
			this.loading++;
			this.taskConditionService.get(Number(this.getDelID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetTaskRefsError(serverError) {
		this.handleError(serverError);
	}

	handleGetTaskRefsResponse(serverResponse) {
		this.loading--;
		this.taskrefs = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.taskrefs.push(new TaskRefModel(serverResponse[i]));
		}
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new TaskConditionModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a feltételt?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loading++;
				this.taskConditionService.del(this.targetObj.getId()).subscribe(
					res => this.handleDeleteResponse(res),
					error => this.handleDeleteError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	handleDeleteResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleDeleteError(serverError) {
		this.handleError(serverError);
	}

	getDelID() {
		return sessionStorage.getItem(AppConstants.PARAM_DELTASKCONDITION_TASKCONDITIONID);
	}

	delDelID() {
		sessionStorage.removeItem(AppConstants.PARAM_DELTASKCONDITION_TASKCONDITIONID);
	}

	back() {
		this.loadSettingComponent.emit(TaskConditionService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delDelID();
	}

}
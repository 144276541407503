import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {PartnerService} from 'src/app/components/settings/partner/service/partner.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {ListsModel} from 'src/app/model/lists.model';
import {PartnerModel} from 'src/app/components/settings/partner/model/partner.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: PartnerService.MOD_TAG,
	templateUrl: './modpartner.component.html',
	styleUrls: ['./modpartner.component.scss']
})
export class ModPartnerComponent implements OnDestroy {

	public static readonly TAG = PartnerService.MOD_TAG;
	public static readonly NAME = "Partner módosítás";
	getSettingName() {return ModPartnerComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: PartnerModel = new PartnerModel({});
	listsModel: any = ListsModel;

	constructor(
		private partnerService: PartnerService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.partnerService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new PartnerModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.partnerService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODPARTNER_PARTNERID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODPARTNER_PARTNERID);
	}

	back() {
		this.loadSettingComponent.emit(PartnerService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Feladat sablon</mat-label>
			   <mat-select name="inputTemplate" [ngModel]="targetObj.getTemplateid()" (ngModelChange)="targetObj.setTemplateid($event)" disabled>
					<mat-option *ngFor="let template of templates" [value]="template.getId()">{{template.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Szerepkör</mat-label>
			   <mat-select name="inputRole" [ngModel]="targetObj.getRoleid()" (ngModelChange)="targetObj.setRoleid($event)" disabled>
					<mat-option *ngFor="let role of roles" [value]="role.getId()">{{role.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Szereptípus</mat-label>
			   <mat-select name="inputRoletype" [ngModel]="targetObj.getRoletype()" (ngModelChange)="targetObj.setRoletype($event)" disabled>
					<mat-option *ngFor="let roletype of listsModel.RoleTypeList" [value]="roletype.getValue()">{{roletype.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()">Törlés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {RolePermissionModel} from "src/app/components/settings/role/model/rolepermission.model";

export class RoleModel {

	private id: number = null;
	private r_name: string = '';
	private r_desc: string = '';

	private permissions: Array<RolePermissionModel> = [];

	private r_modifiedby: number = null;
	private r_modifiedtime: string = '';

	constructor(json) {
		this.id = json && json.id || null;
		this.r_name = json && json.r_name || '';
		this.r_desc = json && json.r_desc || '';

		let tmpRolePermissions: Array<object> = json && json.permissions || [];
		tmpRolePermissions.forEach(permission => {
			this.permissions.push(new RolePermissionModel(permission));
		});

		this.r_modifiedby = json && json.r_modifiedby || null;
		this.r_modifiedtime = json && json.r_modifiedtime || '';
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.r_name;
	}

	setName(value) {
		this.r_name = value;
	}

	getDesc() {
		return this.r_desc;
	}

	setDesc(value) {
		this.r_desc = value;
	}

	getPermissions() {
		return this.permissions;
	}

	setPermissions(value) {
		this.permissions = value;
	}

	getPermissionsId() {
		let tmpIds: Array<string> = [];
		this.permissions.forEach(permission => {
			tmpIds.push(permission.getPermission());
		});
		return tmpIds;
	}
}

import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezSzelessegService} from 'src/app/components/settings/raktar/lemezszelesseg/service/lemezszelesseg.service';

import {LemezSzelessegModel} from 'src/app/components/settings/raktar/lemezszelesseg/model/lemezszelesseg.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: LemezSzelessegService.ADD_TAG,
	templateUrl: './addlemezszelesseg.component.html',
	styleUrls: ['./addlemezszelesseg.component.scss']
})
export class AddLemezSzelessegComponent {

	public static readonly TAG = LemezSzelessegService.ADD_TAG;
	public static readonly NAME = "Lemez Szélesség létrehozása";
	getSettingName() {return AddLemezSzelessegComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: LemezSzelessegModel = new LemezSzelessegModel({});

	constructor(
		private lemezSzelessegService: LemezSzelessegService,
		public dialog: MatDialog
	) {}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	submit() {
		this.loading++;
		this.lemezSzelessegService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(LemezSzelessegService.LIST_TAG);
	}

}
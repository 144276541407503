import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {UserService} from 'src/app/components/settings/user/service/user.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: UserService.MOD_TAG,
	templateUrl: './moduser.component.html',
	styleUrls: ['./moduser.component.scss']
})
export class ModUserComponent implements OnDestroy {

	public static readonly TAG = UserService.MOD_TAG;
	public static readonly NAME = "Felhasználó módosítás";
	getSettingName() {return ModUserComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: UserModel = new UserModel({});

	users: Array<UserModel> = [];
	filteredDeputies: Array<UserModel> = [];

	constructor(
		private userService: UserService,
		public dialog: MatDialog
	) {
		if (!this.getModUserid()) {
			this.back();
		} else {
			this.loading++;
			this.userService.getAll().subscribe(
				res => this.handleGetUsersResponse(res),
				error => this.handleGetUsersError(error)
			);
			this.loading++;
			this.userService.get(Number(this.getModUserid())).subscribe(
				res => this.handleGetUserResponse(res),
				error => this.handleGetUserError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}

		this.filteredDeputies = this.users.filter((obj) => {return true;});
	}

	handleGetUserResponse(serverResponse) {
		this.loading--;
		this.targetObj = new UserModel(serverResponse);
	}

	handleGetUserError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.userService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModUserid() {
		return sessionStorage.getItem(AppConstants.PARAM_MODUSER_USERID);
	}

	delModUserid() {
		sessionStorage.removeItem(AppConstants.PARAM_MODUSER_USERID);
	}

	back() {
		this.loadSettingComponent.emit(UserService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModUserid();
	}

	filterDeputies(val) {
		this.filteredDeputies = this.users.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}

<div class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <mat-toolbar>
		  <mat-toolbar-row>
			   <span>{{ getSettingName() }}</span>
		  </mat-toolbar-row>
	 </mat-toolbar>
	 <form #targetForm="ngForm">
		  <mat-form-field appearance="standard">
			   <mat-label>Feladat sablon megnevezése</mat-label>
			   <input matInput name="inputName" [ngModel]="targetObj.getName()" (ngModelChange)="targetObj.setName($event)" required>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Leírás</mat-label>
			   <textarea matInput name="inputDesc" rows="5" [ngModel]="targetObj.getDesc()" (ngModelChange)="targetObj.setDesc($event)"></textarea>
		  </mat-form-field>
		  <br>
		  <mat-checkbox name="inputUploadRequired" [ngModel]="targetObj.isUploadRequired()" (ngModelChange)="targetObj.setUploadRequired($event)">Fájl feltöltés kötelező</mat-checkbox>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Feltölthető fájl típusok</mat-label>
			   <mat-select name="inputFiletypes" [ngModel]="targetObj.getFiletypes()" (ngModelChange)="targetObj.setFiletypes($event)" [compareWith]="filetypeSelectCompare" multiple [required]="targetObj.isUploadRequired()">
						   <mat-option *ngFor="let filetype of filetypes | customsort:'getName'" [value]="filetype">{{filetype.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Létrehozó szerepkörök</mat-label>
			   <mat-select name="inputRolesLetrehozas" [ngModel]="targetObj.getRolesLetrehozas()" (ngModelChange)="targetObj.setRolesLetrehozas($event)" [compareWith]="roleSelectCompare" multiple>
					<mat-option *ngFor="let role of roles | customsort:'getName'" [value]="role">{{role.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Olvasó szerepkörök</mat-label>
			   <mat-select name="inputRolesOlvasas" [ngModel]="targetObj.getRolesOlvasas()" (ngModelChange)="targetObj.setRolesOlvasas($event)" [compareWith]="roleSelectCompare" multiple>
					<mat-option *ngFor="let role of roles | customsort:'getName'" [value]="role">{{role.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Szerkesztő szerepkörök</mat-label>
			   <mat-select name="inputRolesIras" [ngModel]="targetObj.getRolesIras()" (ngModelChange)="targetObj.setRolesIras($event)" [compareWith]="roleSelectCompare" multiple>
					<mat-option *ngFor="let role of roles | customsort:'getName'" [value]="role">{{role.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Kézi indítás maximális száma</mat-label>
			   <input matInput type="number" step="1" name="inputMaxtask" [ngModel]="targetObj.getMaxTask()" (ngModelChange)="targetObj.setMaxTask($event)">
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Extra adat típusa</mat-label>
			   <mat-select name="inputExtraDataType" [ngModel]="targetObj.getExtraDataType()" (ngModelChange)="targetObj.setExtraDataType($event)">
					<mat-option value="-1">-</mat-option>
					<mat-option *ngFor="let extradatatype of extraDataModule.compMap | keyvalue" [value]="extradatatype.key">{{extradatatype.value.NAME}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Extra adat lekérdezés</mat-label>
			   <textarea matInput name="inputExtraDataQuery" rows="5" [ngModel]="targetObj.getExtraDataQuery()" (ngModelChange)="targetObj.setExtraDataQuery($event)"></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Űrlap azonosító</mat-label>
			   <mat-select name="inputObjectType" [ngModel]="targetObj.getObjectType()" (ngModelChange)="targetObj.setObjectType($event)" required>
					<mat-option *ngFor="let objecttype of taskFormsModule.compMap | keyvalue" [value]="objecttype.key">{{objecttype.value.NAME}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Kategória</mat-label>
			   <mat-select name="inputCategory" [ngModel]="targetObj.getCategory()" (ngModelChange)="targetObj.setCategory($event)" (openedChange)="categoryFilter.value=null; filterCategories(null);">
						   <input matInput #categoryFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterCategories($event.target.value)" (keydown)="$event.stopPropagation()" />
					  <mat-option *ngFor="let category of filteredCategories | customsort:'getName'" [value]="category.getId()">{{category.getName()}}</mat-option>
			   </mat-select>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Határidő lekérdezés</mat-label>
			   <textarea matInput name="inputDeadlineQuery" rows="5" [ngModel]="targetObj.getDeadlineQuery()" (ngModelChange)="targetObj.setDeadlineQuery($event)"></textarea>
		  </mat-form-field>
		  <br>
		  <mat-form-field appearance="standard">
			   <mat-label>Idő eltolás (óra)</mat-label>
			   <input matInput type="number" step="1" name="inputTimeshift" [ngModel]="targetObj.getTimeshift()" (ngModelChange)="targetObj.setTimeshift($event)">
		  </mat-form-field>
	 </form>
	 <div class="dialogButtons">
		  <button mat-flat-button color="primary" (click)="submit()" [disabled]="!targetForm.valid">Mentés</button>
		  <button mat-flat-button color="warn" (click)="back()">Mégsem</button>
	 </div>
</div>
import {Component, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {GablerProjektInditasService} from 'src/app/components/task/dialog/task-dialog/taskforms/gablerprojektinditas/gablerprojektinditas.service';
import {SzallitasService} from 'src/app/components/task/dialog/task-dialog/taskforms/szallitas/szallitas.service';
import {TaskService} from 'src/app/components/task/service/task.service';
import {AuthService} from 'src/app/services/auth.service';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {ConfirmDialog} from 'src/app/dialog/confirm-dialog/confirm-dialog';
import {NextTasksDialog} from 'src/app/dialog/next-tasks-dialog/next-tasks-dialog';
import {StornoConfirmDialog} from 'src/app/dialog/storno-confirm-dialog/storno-confirm-dialog';

import {CommonModel} from 'src/app/model/common.model';

import {TaskModel} from 'src/app/components/task/model/task.model';
import {TaskRefModel} from 'src/app/components/settings/taskref/model/taskref.model';
import {UserModel} from 'src/app/components/settings/user/model/user.model';
import {ListsModel} from 'src/app/model/lists.model';

export interface Defaults {
	emelogep: number;
	name: string;
	phone: string;
	email: string;
	contactconcat: string;
}

export interface SzallitasFej {
	id: string;
	projectid: string;
	becsultrakatmeret: string;
	szamitottrakatmeret: string;
	szallitasnapja: any;
	cimiranyitoszam: string;
	cimvaros: string;
	cimutca: string;
	cimhazszam: string;
	partnerkontakt: string;
	szallitokontakt: string;

	szallitojarmu: SzallitoJarmu;
	szallitojarmuCtrl: FormControl;
	filteredSzallitoJarmuvek: Observable<SzallitoJarmu[]>;

	fuvarelofoglalas: any;
	fuvarfoglalas: any;
	kiegyeztette: string;
	mikoregyeztette: any;
	kivelegyeztette: string;
	ekaerkell: any;
	ekaerlejelentes: any;
	ekaerlezaras: any;
	ajanlatifuvardij: number;
	gablertavolsag: number;
	gablerutdij: number;
	megtettkm: number;
	nettofuvardij: number;
	atvetelielismerveny: any;
	szamlarendben: any;
	egyebmegjegyzes: string;
	modifiedby: string;
	modifiedtime: any;
	deletedby: string;
	deletedtime: any;
}

export interface SzallitoJarmu {
	id: string;
	jarmumegnevezes: string;
	kmdij: number;
	raktermeret: string;
	modifiedby: string;
	modifiedtime: any;
}

@Component({
	selector: 'form-szallitas',
	templateUrl: './szallitas.component.html',
	styleUrls: ['./szallitas.component.scss']
})
export class SzallitasComponent {

	public static readonly TAG: string = 'form-szallitas';
	public static readonly NAME: string = 'Szállítás (Gabler)';
	public static readonly configComponent = null;

	@Output() loadTaskComponent = new EventEmitter<string>();
	@Output() refreshTask = new EventEmitter<TaskModel>();
	@Output() increaseLoading = new EventEmitter<string>();
	@Output() decreaseLoading = new EventEmitter<string>();

	taskObject: TaskModel = new TaskModel({});

	alertDialogRef: MatDialogRef<AlertDialog>;
	confirmDialogRef: MatDialogRef<ConfirmDialog>;
	nextTasksDialogRef: MatDialogRef<NextTasksDialog>;
	stornoConfirmDialogRef: MatDialogRef<StornoConfirmDialog>;

	listsModel: any = ListsModel;

	assignable: boolean = false;
	writeable: boolean = false;

	defaults: Defaults = {
		emelogep: 0,
		name: '',
		email: '',
		phone: '',
		contactconcat: '',
	};
	formdata: Array<SzallitasFej> = [];
	szallitojarmuvek: Array<SzallitoJarmu> = [];

	egyeztetok: Array<UserModel> = [];
	filteredEgyeztetok: Array<UserModel> = [];

	selectedForm: number = 0;

	constructor(
		public gablerProjektInditasService: GablerProjektInditasService,
		public szallitasService: SzallitasService,
		public taskService: TaskService,
		public authService: AuthService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	setTaskObject(taskObject: TaskModel) {
		this.taskObject = taskObject;
		this.setWriteableAssignable();

		this.increaseLoading.emit(null);
		this.gablerProjektInditasService.getDefaultsData(this.taskObject.getProjectModel().getId()).subscribe(
			res => this.handleGetDefaultsDataResponse(res),
			error => this.handleGetDefaultsDataError(error)
		);
	}

	getTaskObject(): TaskModel {
		return this.taskObject;
	}

	refreshParentTask() {
		this.refreshTask.emit(this.taskObject);
	}

	setWriteableAssignable() {
		this.assignable = this.taskObject.getStatus() == ListsModel.TaskStatus_LETREHOZVA.getValue() ? true : false;
		this.writeable = (this.taskObject.getStatus() == ListsModel.TaskStatus_FOLYAMATBAN.getValue() || this.taskObject.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) &&
			(this.taskObject.getProjectModel().getManager() == this.authService.getUser().getId() || this.taskObject.getOwner() == this.authService.getUser().getId() || this.authService.isAdmin()) ? true : false;
	}

	transform(value) {
		let tmpValue = value;

		if (tmpValue instanceof Date) {
			let tmpDate = tmpValue as Date;
			tmpValue = (tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).substr(-2) + "-" + ("0" + tmpDate.getDate()).substr(-2) + " " + ("0" + tmpDate.getHours()).substr(-2) + ":" + ("0" + tmpDate.getMinutes()).substr(-2) + ":00");
		} else if (typeof tmpValue == "boolean" || tmpValue === true || tmpValue === false) {
			tmpValue = tmpValue ? 1 : 0;
		}

		return tmpValue;
	}

	dataTransform() {
		let tmpSzallitasok: Array<SzallitasFej> = [];

		this.formdata.forEach((szallitas) => {
			let tmpSzallitas: SzallitasFej = {...szallitas};
			delete tmpSzallitas['szallitojarmuCtrl'];
			delete tmpSzallitas['filteredSzallitoJarmuvek'];
			for (let key in tmpSzallitas) {
				tmpSzallitas[key] = this.transform(tmpSzallitas[key]);
			}

			tmpSzallitasok.push(tmpSzallitas);
		});

		return tmpSzallitasok;
	}

	assign() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Feladat felvétele után más már nem tudja módosítani a feladatot. Biztosan felveszi ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.szallitasService.assign(this.taskObject.getId()).subscribe(
					res => this.handleAssignResponse(res),
					error => this.handleAssignError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	save() {
		this.increaseLoading.emit(null);
		this.szallitasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	done() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan készre jelöli ezt a feladatot?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.szallitasService.save(this.taskObject.getId(), this.dataTransform()).subscribe(
					res => this.handleSaveDoneResponse(res),
					error => this.handleSaveDoneError(error)
				);
			}
			this.confirmDialogRef = null;
		});
	}

	storno() {
		this.stornoConfirmDialogRef = this.dialog.open(StornoConfirmDialog, {
			disableClose: true,
			panelClass: 'stornoConfirmDialogWindow',
			autoFocus: false
		});
		this.stornoConfirmDialogRef.componentInstance.dialogMessage = "A feladat sztornózása után már nem lesz módosítható. Biztosan sztornózza ezt a feladatot?";

		this.stornoConfirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.increaseLoading.emit(null);
				this.szallitasService.storno(this.taskObject.getId(), result).subscribe(
					res => this.handleStornoResponse(res),
					error => this.handleStornoError(error)
				);
			}
			this.stornoConfirmDialogRef = null;
		});
	}

	closeDialog() {
		this.loadTaskComponent.emit(null);
	}

	//common
	handleResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this.taskObject = new TaskModel(serverResponse);
		this.setWriteableAssignable();
		this.refreshParentTask();
	}

	handleError(serverError) {
		this.decreaseLoading.emit(null);
		let errorText = '';

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	//getdefaultsdata
	handleGetDefaultsDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.defaults = {
			emelogep: (serverResponse.emelogep === true || serverResponse.emelogep == 1 || serverResponse.emelogep == 'true') ? 1 : 0,
			name: serverResponse.name || '',
			phone: serverResponse.phone || '',
			email: serverResponse.email || '',
			contactconcat: serverResponse.contactconcat || ''
		};

		this.increaseLoading.emit(null);
		this.szallitasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleGetDefaultsDataError(serverError) {
		this.decreaseLoading.emit(null);

		this.increaseLoading.emit(null);
		this.szallitasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	//getdata
	handleGetDataResponse(serverResponse) {
		this.decreaseLoading.emit(null);

		this.szallitojarmuvek = [];

		for (let i = 0; i < serverResponse.szallitojarmuvek.length; i++) {
			this.szallitojarmuvek.push({
				id: serverResponse.szallitojarmuvek[i].id,
				jarmumegnevezes: serverResponse.szallitojarmuvek[i].jarmumegnevezes,
				kmdij: serverResponse.szallitojarmuvek[i].kmdij,
				raktermeret: serverResponse.szallitojarmuvek[i].raktermeret,
				modifiedby: serverResponse.szallitojarmuvek[i].modifiedby,
				modifiedtime: serverResponse.szallitojarmuvek[i].modifiedtime
			});
		}

		this.formdata = [];

		for (let i = 0; i < serverResponse.szallitasok.length; i++) {
			let tmpSzallitas: SzallitasFej = {
				id: serverResponse.szallitasok[i].id,
				projectid: serverResponse.szallitasok[i].projectid,
				becsultrakatmeret: serverResponse.szallitasok[i].becsultrakatmeret,
				szamitottrakatmeret: serverResponse.szallitasok[i].szamitottrakatmeret,
				szallitasnapja: CommonModel.isDate(serverResponse.szallitasok[i].szallitasnapja) ? (new Date(Date.parse(serverResponse.szallitasok[i].szallitasnapja))) : null,
				cimiranyitoszam: serverResponse.szallitasok[i].cimiranyitoszam,
				cimvaros: serverResponse.szallitasok[i].cimvaros,
				cimutca: serverResponse.szallitasok[i].cimutca,
				cimhazszam: serverResponse.szallitasok[i].cimhazszam,
				partnerkontakt: serverResponse.szallitasok[i].partnerkontakt,
				szallitokontakt: serverResponse.szallitasok[i].szallitokontakt,
				szallitojarmu: {
					id: null,
					jarmumegnevezes: serverResponse.szallitasok[i].jarmumegnevezes,
					kmdij: serverResponse.szallitasok[i].kmdij,
					raktermeret: serverResponse.szallitasok[i].raktermeret,
					modifiedby: null,
					modifiedtime: null
				},
				szallitojarmuCtrl: new FormControl(),
				filteredSzallitoJarmuvek: null,
				fuvarelofoglalas: CommonModel.isDate(serverResponse.szallitasok[i].fuvarelofoglalas) ? (new Date(Date.parse(serverResponse.szallitasok[i].fuvarelofoglalas))) : null,
				fuvarfoglalas: CommonModel.isDate(serverResponse.szallitasok[i].fuvarfoglalas) ? (new Date(Date.parse(serverResponse.szallitasok[i].fuvarfoglalas))) : null,
				kiegyeztette: serverResponse.szallitasok[i].kiegyeztette,
				mikoregyeztette: CommonModel.isDateTime(serverResponse.szallitasok[i].mikoregyeztette) ? (new Date(Date.parse(serverResponse.szallitasok[i].mikoregyeztette))) : null,
				kivelegyeztette: serverResponse.szallitasok[i].kivelegyeztette,
				ekaerkell: (serverResponse.szallitasok[i].ekaerkell === true || serverResponse.szallitasok[i].ekaerkell == 1 || serverResponse.szallitasok[i].ekaerkell == 'true') ? 1 : 0,
				ekaerlejelentes: CommonModel.isDateTime(serverResponse.szallitasok[i].ekaerlejelentes) ? (new Date(Date.parse(serverResponse.szallitasok[i].ekaerlejelentes))) : null,
				ekaerlezaras: CommonModel.isDateTime(serverResponse.szallitasok[i].ekaerlezaras) ? (new Date(Date.parse(serverResponse.szallitasok[i].ekaerlezaras))) : null,
				ajanlatifuvardij: serverResponse.szallitasok[i].ajanlatifuvardij,
				gablertavolsag: serverResponse.szallitasok[i].gablertavolsag,
				gablerutdij: serverResponse.szallitasok[i].gablerutdij,
				megtettkm: serverResponse.szallitasok[i].megtettkm,
				nettofuvardij: serverResponse.szallitasok[i].nettofuvardij,
				atvetelielismerveny: CommonModel.isDate(serverResponse.szallitasok[i].atvetelielismerveny) ? (new Date(Date.parse(serverResponse.szallitasok[i].atvetelielismerveny))) : null,
				szamlarendben: (serverResponse.szallitasok[i].szamlarendben === true || serverResponse.szallitasok[i].szamlarendben == 1 || serverResponse.szallitasok[i].szamlarendben == 'true') ? 1 : 0,
				egyebmegjegyzes: serverResponse.szallitasok[i].egyebmegjegyzes,
				modifiedby: serverResponse.szallitasok[i].modifiedby,
				modifiedtime: serverResponse.szallitasok[i].modifiedtime,
				deletedby: serverResponse.szallitasok[i].deletedby,
				deletedtime: serverResponse.szallitasok[i].deletedtime
			};

			tmpSzallitas.filteredSzallitoJarmuvek = tmpSzallitas.szallitojarmuCtrl.valueChanges
				.pipe(
					startWith(''),
					map(szallitojarmu => szallitojarmu ? this._filterSzallitoJarmuvek(szallitojarmu) : this.szallitojarmuvek.slice())
				);

			this.formdata.push(tmpSzallitas);
		}

		this.egyeztetok = [];

		for (let i = 0; i < serverResponse.egyeztetok.length; i++) {
			this.egyeztetok.push(new UserModel(serverResponse.egyeztetok[i]));
		}

		this.filteredEgyeztetok = this.egyeztetok.filter((obj) => {return true;});
	}

	handleGetDataError(serverError) {
		this.handleError(serverError);
	}

	//assign
	handleAssignResponse(serverResponse) {
		this.handleResponse(serverResponse);
	}

	handleAssignError(serverError) {
		this.handleError(serverError);
	}

	//save
	handleSaveResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		this._snackBar.open('Feladat elmentve', null, {
			duration: 2000,
		});
		this.increaseLoading.emit(null);
		this.szallitasService.getData(this.taskObject.getId()).subscribe(
			res => this.handleGetDataResponse(res),
			error => this.handleGetDataError(error)
		);
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	//save done
	handleSaveDoneResponse(serverResponse) {
		this.handleSaveResponse(serverResponse);

		this.increaseLoading.emit(null);
		this.taskService.getManualTasks(this.taskObject.getId()).subscribe(
			res => this.handleGetManualTasksResponse(res),
			error => this.handleGetManualTasksError(error)
		);
	}

	handleSaveDoneError(serverError) {
		this.handleError(serverError);
	}

	//manual tasks
	handleGetManualTasksResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		let taskRefs: Array<TaskRefModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			taskRefs.push(new TaskRefModel(serverResponse[i]));
		}

		this.nextTasksDialogRef = this.dialog.open(NextTasksDialog, {
			disableClose: true,
			panelClass: 'nextTasksDialogWindow',
			autoFocus: false,
			data: {
				projectId: this.taskObject.getProjectid(),
				taskRefs: taskRefs
			}
		});

		this.nextTasksDialogRef.afterClosed().subscribe(result => {
			if (typeof result == 'object') {
				this.increaseLoading.emit(null);
				this.szallitasService.done(this.taskObject.getId(), result).subscribe(
					res => this.handleDoneResponse(res),
					error => this.handleDoneError(error)
				);
			}
			this.nextTasksDialogRef = null;
		});
	}

	handleGetManualTasksError(serverError) {
		this.handleError(serverError);
	}

	//done
	handleDoneResponse(serverResponse) {
		this.handleResponse(serverResponse);
		this.closeDialog();
	}

	handleDoneError(serverError) {
		this.handleError(serverError);
	}

	//storno
	handleStornoResponse(serverResponse) {
		this.decreaseLoading.emit(null);
		//this.refreshParentTask();
		this.closeDialog();
	}

	handleStornoError(serverError) {
		this.handleError(serverError);
	}

	addSzallitas() {
		let tmpSzallitas: SzallitasFej = {
			id: null,
			projectid: null,
			becsultrakatmeret: null,
			szamitottrakatmeret: null,
			szallitasnapja: null,
			cimiranyitoszam: null,
			cimvaros: null,
			cimutca: null,
			cimhazszam: null,
			partnerkontakt: this.defaults.contactconcat.length > 0 ? this.defaults.contactconcat : null,
			szallitokontakt: null,
			szallitojarmu: {
				id: null,
				jarmumegnevezes: null,
				kmdij: null,
				raktermeret: null,
				modifiedby: null,
				modifiedtime: null
			},
			szallitojarmuCtrl: new FormControl(),
			filteredSzallitoJarmuvek: null,
			fuvarelofoglalas: null,
			fuvarfoglalas: null,
			kiegyeztette: null,
			mikoregyeztette: null,
			kivelegyeztette: null,
			ekaerkell: null,
			ekaerlejelentes: null,
			ekaerlezaras: null,
			ajanlatifuvardij: null,
			gablertavolsag: null,
			gablerutdij: null,
			megtettkm: null,
			nettofuvardij: null,
			atvetelielismerveny: null,
			szamlarendben: null,
			egyebmegjegyzes: null,
			modifiedby: null,
			modifiedtime: null,
			deletedby: null,
			deletedtime: null
		};

		tmpSzallitas.filteredSzallitoJarmuvek = tmpSzallitas.szallitojarmuCtrl.valueChanges
			.pipe(
				startWith(''),
				map(szallitojarmu => szallitojarmu ? this._filterSzallitoJarmuvek(szallitojarmu) : this.szallitojarmuvek.slice()),
			);

		this.formdata.push(tmpSzallitas);
		this.selectedForm = this.formdata.length - 1;
	}

	removeSzallitas() {
		this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
			disableClose: true,
			panelClass: 'confirmDialogWindow',
			autoFocus: false
		});
		this.confirmDialogRef.componentInstance.dialogMessage = "Biztosan törli a kiválasztott rendelést?";

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (this.selectedForm >= 0 && this.formdata.length > 0) {
					this.formdata.splice(this.selectedForm, 1);
				}
			}
			this.confirmDialogRef = null;
		});
	}

	onSzallitoJarmuSelect(event: MatAutocompleteSelectedEvent, szallitasIdx) {
		let szallitojarmuId = event.option.value;
		this.formdata[szallitasIdx].szallitojarmu.jarmumegnevezes = '';

		this.szallitojarmuvek.forEach((szallitojarmu) => {
			if (szallitojarmu.id == szallitojarmuId) {
				this.formdata[szallitasIdx].szallitojarmu.jarmumegnevezes = szallitojarmu.jarmumegnevezes;
				this.formdata[szallitasIdx].szallitojarmu.kmdij = szallitojarmu.kmdij;
				this.formdata[szallitasIdx].szallitojarmu.raktermeret = szallitojarmu.raktermeret;
			}
		});
	}

	private _filterSzallitoJarmuvek(value: string): SzallitoJarmu[] {
		const filterValue = CommonModel.replaceHunChars(String(value).toLowerCase());

		return this.szallitojarmuvek.filter(szallitojarmu => szallitojarmu.jarmumegnevezes != null ? CommonModel.replaceHunChars(szallitojarmu.jarmumegnevezes.toLowerCase()).includes(filterValue) : false);
	}

	filterEgyeztetok(val) {
		this.filteredEgyeztetok = this.egyeztetok.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}
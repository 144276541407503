import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddFileTypeComponent} from 'src/app/components/settings/filetype/component/addfiletype/addfiletype.component';
import {ModFileTypeComponent} from 'src/app/components/settings/filetype/component/modfiletype/modfiletype.component';
import {FileTypesComponent} from 'src/app/components/settings/filetype/component/filetypes/filetypes.component';

import {FileTypeService} from 'src/app/components/settings/filetype/service/filetype.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddFileTypeComponent,
		ModFileTypeComponent,
		FileTypesComponent
	],
	entryComponents: [
		AddFileTypeComponent,
		ModFileTypeComponent,
		FileTypesComponent
	],
	providers: [
		FileTypeService
	],
	exports: [
		AddFileTypeComponent,
		ModFileTypeComponent,
		FileTypesComponent
	],
})
export class FileTypeModule {
	public static readonly compArray = [
		AddFileTypeComponent,
		ModFileTypeComponent,
		FileTypesComponent
	];

	public static readonly compMap = FileTypeModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in FileTypeModule.compMap) ? FileTypeModule.compMap[TAG].NAME : '';
	}
}
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {TaskTemplateModel} from 'src/app/components/settings/tasktemplate/model/tasktemplate.model';

@Injectable()
export class TaskTemplateService {

	public static readonly API_TYPE: string = 'tasktemplate';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + TaskTemplateService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + TaskTemplateService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + TaskTemplateService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + TaskTemplateService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-tasktemplates';
	public static readonly ADD_TAG: string = 'setting-addtasktemplate';
	public static readonly MOD_TAG: string = 'setting-modtasktemplate';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskTemplateService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(TaskTemplateService.GET_API, params, {params: parameters});
	}

	add(obj: TaskTemplateModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('tt_name', obj.getName());
		params.append('tt_desc', obj.getDesc());
		params.append('tt_uploadrequired', String((obj.isUploadRequired() === true || obj.isUploadRequired() == 1 || obj.isUploadRequired() == 'true') ? 1 : 0));
		params.append('rolesletrehozas', JSON.stringify(obj.getRolesLetrehozasId()));
		params.append('rolesolvasas', JSON.stringify(obj.getRolesOlvasasId()));
		params.append('rolesiras', JSON.stringify(obj.getRolesIrasId()));
		params.append('filetypes', JSON.stringify(obj.getFiletypesId()));
		params.append('tt_maxtask', String(obj.getMaxTask()));
		params.append('tt_extradatatype', String(obj.getExtraDataType()));
		params.append('tt_extradataquery', obj.getExtraDataQuery());
		params.append('tt_objecttype', obj.getObjectType());
		params.append('tt_category', String(obj.getCategory()));
		params.append('tt_deadlinequery', obj.getDeadlineQuery());
		params.append('tt_timeshift', String(obj.getTimeshift()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskTemplateService.ADD_API, params, {params: parameters});
	}

	mod(obj: TaskTemplateModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('tt_name', obj.getName());
		params.append('tt_desc', obj.getDesc());
		params.append('tt_uploadrequired', String((obj.isUploadRequired() === true || obj.isUploadRequired() == 1 || obj.isUploadRequired() == 'true') ? 1 : 0));
		params.append('rolesletrehozas', JSON.stringify(obj.getRolesLetrehozasId()));
		params.append('rolesolvasas', JSON.stringify(obj.getRolesOlvasasId()));
		params.append('rolesiras', JSON.stringify(obj.getRolesIrasId()));
		params.append('filetypes', JSON.stringify(obj.getFiletypesId()));
		params.append('tt_maxtask', String(obj.getMaxTask()));
		params.append('tt_extradatatype', String(obj.getExtraDataType()));
		params.append('tt_extradataquery', obj.getExtraDataQuery());
		params.append('tt_objecttype', obj.getObjectType());
		params.append('tt_category', String(obj.getCategory()));
		params.append('tt_deadlinequery', obj.getDeadlineQuery());
		params.append('tt_timeshift', String(obj.getTimeshift()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(TaskTemplateService.MOD_API, params, {params: parameters});
	}
}

<mat-toolbar color="primary">
	 <mat-toolbar-row>
		  <span>Űrlap beállítások</span>
		  <span class="spacer"></span>
		  <button mat-icon-button (click)="closeDialog()">
				  <fa-icon [fixedWidth]="true" [icon]="['fas', 'times']"></fa-icon>
		  </button>
	 </mat-toolbar-row>
</mat-toolbar>
<mat-dialog-content class="loading-wrapper">
	 <div class="loading-shade" *ngIf="loading!=0">
		  <mat-spinner></mat-spinner>
	 </div>
	 <div #configHost></div>
</mat-dialog-content>
import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {UserService} from 'src/app/components/settings/user/service/user.service';

import {UserModel} from 'src/app/components/settings/user/model/user.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: UserService.ADD_TAG,
	templateUrl: './adduser.component.html',
	styleUrls: ['./adduser.component.scss']
})
export class AddUserComponent {

	public static readonly TAG = UserService.ADD_TAG;
	public static readonly NAME = "Felhasználó hozzáadás";
	getSettingName() {return AddUserComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: UserModel = new UserModel({});

	users: Array<UserModel> = [];
	filteredDeputies: Array<UserModel> = [];

	constructor(
		private userService: UserService,
		public dialog: MatDialog
	) {
		this.loading++;
		this.userService.getAll().subscribe(
			res => this.handleGetUsersResponse(res),
			error => this.handleGetUsersError(error)
		);

		this.targetObj.setHidden(false);
		this.targetObj.setDisabled(false);
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetUsersError(serverError) {
		this.handleError(serverError);
	}

	handleGetUsersResponse(serverResponse) {
		this.loading--;
		this.users = [];

		for (let i = 0; i < serverResponse.length; i++) {
			this.users.push(new UserModel(serverResponse[i]));
		}

		this.filteredDeputies = this.users.filter((obj) => {return true;});
	}

	submit() {
		this.loading++;
		this.userService.add(this.targetObj).subscribe(
			res => this.handleAddUserResponse(res),
			error => this.handleAddUserError(error)
		);
	}

	handleAddUserResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddUserError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(UserService.LIST_TAG);
	}

	filterDeputies(val) {
		this.filteredDeputies = this.users.filter((obj) => {
			return (val == null || obj.getFullName().toLowerCase().indexOf(String(val).toLowerCase()) > -1) ? true : false;
		});
	}

}

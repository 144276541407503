import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddTaskTemplateComponent} from 'src/app/components/settings/tasktemplate/component/addtasktemplate/addtasktemplate.component';
import {ModTaskTemplateComponent} from 'src/app/components/settings/tasktemplate/component/modtasktemplate/modtasktemplate.component';
import {TaskTemplatesComponent} from 'src/app/components/settings/tasktemplate/component/tasktemplates/tasktemplates.component';

import {TaskTemplateService} from 'src/app/components/settings/tasktemplate/service/tasktemplate.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddTaskTemplateComponent,
		ModTaskTemplateComponent,
		TaskTemplatesComponent
	],
	entryComponents: [
		AddTaskTemplateComponent,
		ModTaskTemplateComponent,
		TaskTemplatesComponent
	],
	providers: [
		TaskTemplateService
	],
	exports: [
		AddTaskTemplateComponent,
		ModTaskTemplateComponent,
		TaskTemplatesComponent
	],
})
export class TaskTemplateModule {
	public static readonly compArray = [
		AddTaskTemplateComponent,
		ModTaskTemplateComponent,
		TaskTemplatesComponent
	];

	public static readonly compMap = TaskTemplateModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in TaskTemplateModule.compMap) ? TaskTemplateModule.compMap[TAG].NAME : '';
	}
}
import {Component, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {LemezSzinService} from 'src/app/components/settings/raktar/lemezszin/service/lemezszin.service';

import {LemezSzinModel} from 'src/app/components/settings/raktar/lemezszin/model/lemezszin.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: LemezSzinService.ADD_TAG,
	templateUrl: './addlemezszin.component.html',
	styleUrls: ['./addlemezszin.component.scss']
})
export class AddLemezSzinComponent {

	public static readonly TAG = LemezSzinService.ADD_TAG;
	public static readonly NAME = "Lemez Szín létrehozása";
	getSettingName() {return AddLemezSzinComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: LemezSzinModel = new LemezSzinModel({});

	constructor(
		private lemezSzinService: LemezSzinService,
		public dialog: MatDialog
	) {}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	submit() {
		this.loading++;
		this.lemezSzinService.add(this.targetObj).subscribe(
			res => this.handleAddResponse(res),
			error => this.handleAddError(error)
		);
	}

	handleAddResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleAddError(serverError) {
		this.handleError(serverError);
	}

	back() {
		this.loadSettingComponent.emit(LemezSzinService.LIST_TAG);
	}

}
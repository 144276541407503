import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { LemezBevetelService } from "src/app/components/raktar/service/lemezbevetel.service";

import { LemezSzallitasModel } from "src/app/components/raktar/model/lemezszallitas.model";
import { LemezRaktarModel } from "src/app/components/settings/raktar/lemezraktar/model/lemezraktar.model";
import { LemezTaroloModel } from "src/app/components/settings/raktar/lemeztarolo/model/lemeztarolo.model";
import { LemezGyartoModel } from "src/app/components/settings/raktar/lemezgyarto/model/lemezgyarto.model";
import { LemezSzinModel } from "src/app/components/settings/raktar/lemezszin/model/lemezszin.model";
import { LemezAnyagminosegModel } from "src/app/components/settings/raktar/lemezanyagminoseg/model/lemezanyagminoseg.model";
import { LemezSzelessegModel } from "src/app/components/settings/raktar/lemezszelesseg/model/lemezszelesseg.model";
import { LemezVastagsagModel } from "src/app/components/settings/raktar/lemezvastagsag/model/lemezvastagsag.model";

import { AlertDialog } from "src/app/dialog/alert-dialog/alert-dialog";

@Component({
  selector: "app-lemezbevetel",
  templateUrl: "./lemezbevetel.component.html",
  styleUrls: ["./lemezbevetel.component.scss"],
})
export class LemezBevetelComponent {
  alertDialogRef: MatDialogRef<AlertDialog>;

  loading: number = 0;

  lemeztarolok: Array<LemezTaroloModel> = [];
  filteredLemeztarolok: Array<LemezTaroloModel> = [];

  gyartok: Array<LemezGyartoModel> = [];
  szinek: Array<LemezSzinModel> = [];
  anyagminosegek: Array<LemezAnyagminosegModel> = [];
  szelessegek: Array<LemezSzelessegModel> = [];
  vastagsagok: Array<LemezVastagsagModel> = [];

  idopont: any = new Date();
  szallitolevel: string;
  lemezgyartoid: number;
  lemezanyagminosegid: number;
  lemezszinid: number;
  lemezszelessegid: number;
  lemezvastagsagid: number;
  tomeg: number;
  nettoar: number = 0; //Ez a mező elrejtésre kerül alapérrtelmezetten 0 Módosítás dátuma 2022.05.16
  lemeztaroloid: number;

  constructor(
    private lemezBevetelService: LemezBevetelService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.loading++;
    this.lemezBevetelService.get().subscribe(
      (res) => this.handleGetResponse(res),
      (error) => this.handleGetError(error)
    );
  }

  handleError(serverError) {
    this.loading--;
    let errorText = "";

    if (serverError.error.error) {
      errorText = serverError.error.error;
    } else {
      errorText = "Nem várt hiba történt";
    }

    this.alertDialogRef = this.dialog.open(AlertDialog, {
      disableClose: true,
      panelClass: "alertDialogWindow",
      autoFocus: false,
    });

    this.alertDialogRef.componentInstance.dialogMessage = errorText;
  }

  handleGetError(serverError) {
    this.loading--;
    let errorText = "";

    if (serverError.error.error) {
      errorText = serverError.error.error;
    } else {
      errorText = "Nem várt hiba történt";
    }

    this.alertDialogRef = this.dialog.open(AlertDialog, {
      disableClose: true,
      panelClass: "alertDialogWindow",
      autoFocus: false,
    });

    this.alertDialogRef.componentInstance.dialogMessage = errorText;

    this.alertDialogRef.afterClosed().subscribe((result) => {
      this.router.navigateByUrl("/raktar");
    });
  }

  handleGetResponse(serverResponse) {
    this.loading--;

    let gyartok: Array<LemezGyartoModel> = [];

    for (let i = 0; i < serverResponse.gyartok.length; i++) {
      gyartok.push(new LemezGyartoModel(serverResponse.gyartok[i]));
    }

    this.gyartok = gyartok;

    let szinek: Array<LemezSzinModel> = [];

    for (let i = 0; i < serverResponse.szinek.length; i++) {
      szinek.push(new LemezSzinModel(serverResponse.szinek[i]));
    }

    this.szinek = szinek;

    let anyagminosegek: Array<LemezAnyagminosegModel> = [];

    for (let i = 0; i < serverResponse.anyagminosegek.length; i++) {
      anyagminosegek.push(
        new LemezAnyagminosegModel(serverResponse.anyagminosegek[i])
      );
    }

    this.anyagminosegek = anyagminosegek;

    let szelessegek: Array<LemezSzelessegModel> = [];

    for (let i = 0; i < serverResponse.szelessegek.length; i++) {
      szelessegek.push(new LemezSzelessegModel(serverResponse.szelessegek[i]));
    }

    this.szelessegek = szelessegek;

    let vastagsagok: Array<LemezVastagsagModel> = [];

    for (let i = 0; i < serverResponse.vastagsagok.length; i++) {
      vastagsagok.push(new LemezVastagsagModel(serverResponse.vastagsagok[i]));
    }

    this.vastagsagok = vastagsagok;

    let lemeztarolok: Array<LemezTaroloModel> = [];

    for (let i = 0; i < serverResponse.szabadtarolok.length; i++) {
      lemeztarolok.push(new LemezTaroloModel(serverResponse.szabadtarolok[i]));
    }

    this.lemeztarolok = lemeztarolok;
    this.filteredLemeztarolok = this.lemeztarolok.filter((obj) => {
      return true;
    });
  }

  save() {
    this.loading++;
    this.lemezBevetelService
      .lemezBevetel(
        this.idopont,
        this.szallitolevel,
        this.lemezgyartoid,
        this.lemezanyagminosegid,
        this.lemezszinid,
        this.lemezszelessegid,
        this.lemezvastagsagid,
        this.tomeg,
        this.nettoar,
        this.lemeztaroloid
      )
      .subscribe(
        (res) => this.handleSaveResponse(res),
        (error) => this.handleSaveError(error)
      );
  }

  saveAndClose() {
    this.loading++;
    this.lemezBevetelService
      .lemezBevetel(
        this.idopont,
        this.szallitolevel,
        this.lemezgyartoid,
        this.lemezanyagminosegid,
        this.lemezszinid,
        this.lemezszelessegid,
        this.lemezvastagsagid,
        this.tomeg,
        this.nettoar,
        this.lemeztaroloid
      )
      .subscribe(
        (res) => this.handleSaveAndCloseResponse(res),
        (error) => this.handleSaveError(error)
      );
  }

  handleSaveError(serverError) {
    this.handleError(serverError);
  }

  handleSaveResponse(serverResponse) {
    this.loading--;

    this.alertDialogRef = this.dialog.open(AlertDialog, {
      disableClose: true,
      panelClass: "alertDialogWindow",
      autoFocus: false,
    });

    this.alertDialogRef.componentInstance.dialogMessage =
      serverResponse.message;

    this.alertDialogRef.afterClosed().subscribe((result) => {
      this.lemezgyartoid = null;
      this.lemezanyagminosegid = null;
      this.lemezszinid = null;
      this.lemezszelessegid = null;
      this.lemezvastagsagid = null;
      this.tomeg = null;
      this.nettoar = null;
      this.lemeztaroloid = null;

      this.gyartok = [];
      this.szinek = [];
      this.anyagminosegek = [];
      this.szelessegek = [];
      this.vastagsagok = [];

      this.loading++;
      this.lemezBevetelService.get().subscribe(
        (res) => this.handleGetResponse(res),
        (error) => this.handleGetError(error)
      );
    });
  }

  handleSaveAndCloseResponse(serverResponse) {
    this.loading--;

    this.alertDialogRef = this.dialog.open(AlertDialog, {
      disableClose: true,
      panelClass: "alertDialogWindow",
      autoFocus: false,
    });

    this.alertDialogRef.componentInstance.dialogMessage =
      serverResponse.message;

    this.alertDialogRef.afterClosed().subscribe((result) => {
      this.router.navigateByUrl("/raktar");
    });
  }

  filterLemeztarolok(val) {
    this.filteredLemeztarolok = this.lemeztarolok.filter((obj) => {
      return val == null ||
        obj.getRaktarTarolo().toLowerCase().indexOf(String(val).toLowerCase()) >
          -1
        ? true
        : false;
    });
  }
}

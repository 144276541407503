import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {CommonModules} from 'src/app/common.modules';

import {AddTaskRoleComponent} from 'src/app/components/settings/taskrole/component/addtaskrole/addtaskrole.component';
import {ModTaskRoleComponent} from 'src/app/components/settings/taskrole/component/modtaskrole/modtaskrole.component';
import {DelTaskRoleComponent} from 'src/app/components/settings/taskrole/component/deltaskrole/deltaskrole.component';
import {TaskRolesComponent} from 'src/app/components/settings/taskrole/component/taskroles/taskroles.component';

import {TaskRoleService} from 'src/app/components/settings/taskrole/service/taskrole.service';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		CommonModules
	],
	declarations: [
		AddTaskRoleComponent,
		ModTaskRoleComponent,
		DelTaskRoleComponent,
		TaskRolesComponent]
	,
	entryComponents: [
		AddTaskRoleComponent,
		ModTaskRoleComponent,
		DelTaskRoleComponent,
		TaskRolesComponent
	],
	providers: [
		TaskRoleService
	],
	exports: [
		AddTaskRoleComponent,
		ModTaskRoleComponent,
		DelTaskRoleComponent,
		TaskRolesComponent
	],
})
export class TaskRoleModule {
	public static readonly compArray = [
		AddTaskRoleComponent,
		ModTaskRoleComponent,
		DelTaskRoleComponent,
		TaskRolesComponent
	];

	public static readonly compMap = TaskRoleModule.compArray.map(form => [form.TAG, form]).reduce((forms, [TAG, form]) => Object.assign(forms, {[TAG as string]: form}), {});

	public static getCompName(TAG: string) {
		return (TAG in TaskRoleModule.compMap) ? TaskRoleModule.compMap[TAG].NAME : '';
	}
}
import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {CategoryService} from 'src/app/components/settings/category/service/category.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {CategoryModel} from 'src/app/components/settings/category/model/category.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';

@Component({
	selector: CategoryService.MOD_TAG,
	templateUrl: './modcategory.component.html',
	styleUrls: ['./modcategory.component.scss']
})
export class ModCategoryComponent implements OnDestroy {

	public static readonly TAG = CategoryService.MOD_TAG;
	public static readonly NAME = "Kategória módosítás";
	getSettingName() {return ModCategoryComponent.NAME;}

	alertDialogRef: MatDialogRef<AlertDialog>;

	@Output() loadSettingComponent = new EventEmitter<string>();

	loading: number = 0;

	targetObj: CategoryModel = new CategoryModel({});

	constructor(
		private categoryService: CategoryService,
		public dialog: MatDialog
	) {
		if (!this.getModID()) {
			this.back();
		} else {
			this.loading++;
			this.categoryService.get(Number(this.getModID())).subscribe(
				res => this.handleGetResponse(res),
				error => this.handleGetError(error)
			);
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleGetResponse(serverResponse) {
		this.loading--;
		this.targetObj = new CategoryModel(serverResponse);
	}

	handleGetError(serverError) {
		this.handleError(serverError);
	}

	submit() {
		this.loading++;
		this.categoryService.mod(this.targetObj).subscribe(
			res => this.handleSaveResponse(res),
			error => this.handleSaveError(error)
		);
	}

	handleSaveResponse(serverResponse) {
		this.loading--;
		this.back();
	}

	handleSaveError(serverError) {
		this.handleError(serverError);
	}

	getModID() {
		return sessionStorage.getItem(AppConstants.PARAM_MODCATEGORY_CATEGORYID);
	}

	delModID() {
		sessionStorage.removeItem(AppConstants.PARAM_MODCATEGORY_CATEGORYID);
	}

	back() {
		this.loadSettingComponent.emit(CategoryService.LIST_TAG);
	}

	ngOnDestroy() {
		this.delModID();
	}

}
import {Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {AuthService} from 'src/app/services/auth.service';
import {TaskService} from 'src/app/components/task/service/task.service';

import {CommonModel} from 'src/app/model/common.model';
import {ListsModel} from 'src/app/model/lists.model';
import {TaskModel} from 'src/app/components/task/model/task.model';

import {AlertDialog} from 'src/app/dialog/alert-dialog/alert-dialog';
import {AddTaskDialog} from 'src/app/dialog/add-task-dialog/add-task-dialog';
import {TaskDialog} from 'src/app/components/task/dialog/task-dialog/task-dialog';
import {TaskDeadlineDialog} from 'src/app/components/task/dialog/task-deadline-dialog/task-deadline-dialog';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss']
})
export class TasksComponent {

	alertDialogRef: MatDialogRef<AlertDialog>;
	addTaskDialogRef: MatDialogRef<AddTaskDialog>;
	taskDialogRef: MatDialogRef<TaskDialog>;
	taskDeadlineDialogRef: MatDialogRef<TaskDeadlineDialog>;

	loading: number = 0;

	displayedColumns: string[] = ['id', 'project', 'projectnumber', 'partnernev', 'taskname', 't_deadline', 't_donetime', 'owner', 'action'];
	dataSource: MatTableDataSource<TaskModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	listsModel: any = ListsModel;

	taskType: number = ListsModel.TaskFilter_AKTUALIS_FELADATOK.getValue();
	myTasksOnly: boolean = true;

	filterValue: string = "";

	constructor(
		private authService: AuthService,
		private taskService: TaskService,
		private dialog: MatDialog
	) {
		this.dataSource = new MatTableDataSource([]);
		this.dataSource.filterPredicate = function customFilter(row, filter: string): boolean {
			let elements: Array<string> = [
				String(row.getId()),
				row.getProjectModel().getName(),
				row.getProjectModel().getNumber(),
				row.getTemplateModel().getName(),
				row.getDeadline(),
				row.getOwnerModel().getFullName(),
				row.getProjectModel().getPartnerModel().getNev()
			];

			for (let element of elements) {
				if (CommonModel.replaceHunChars(element.toLowerCase()).indexOf(filter) !== -1) {
					return true;
				}
			}

			return false;
		}

		this.loadData();
	}

	applyFilter() {
		this.dataSource.filter = CommonModel.replaceHunChars(this.filterValue.trim().toLowerCase());

		if (this.dataSource.paginator && this.dataSource.paginator.pageIndex > this.dataSource.paginator.pageSize) {
			this.dataSource.paginator.lastPage();
		}
	}

	handleError(serverError) {
		this.loading--;
		let errorText = "";

		if (serverError.error.error) {
			errorText = serverError.error.error;
		} else {
			errorText = 'Nem várt hiba történt';
		}

		this.alertDialogRef = this.dialog.open(AlertDialog, {
			disableClose: true,
			panelClass: 'alertDialogWindow',
			autoFocus: false
		});

		this.alertDialogRef.componentInstance.dialogMessage = errorText;
	}

	handleResponse(serverResponse) {
		this.loading--;
		let objects: Array<TaskModel> = [];

		for (let i = 0; i < serverResponse.length; i++) {
			objects.push(new TaskModel(serverResponse[i]));
		}

		this.dataSource.data = objects;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {

				case 'id': return item.getId();
				case 'project': return item.getProjectModel().getName();
				case 'projectnumber': return item.getProjectModel().getNumber();
				case 'partnernev': return item.getProjectModel().getPartnerModel().getNev();
				case 'taskname': return item.getTemplateModel().getName();
				case 't_deadline': return item.getDeadline();
				case 'owner': return item.getOwnerModel().getFullName();

				default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;

		this.applyFilter();
	}

	loadData() {
		this.loading++;
		this.taskService.getMyTasks(this.taskType, this.myTasksOnly).subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	openAddTaskDialog(id: number) {
		this.addTaskDialogRef = this.dialog.open(AddTaskDialog, {
			disableClose: true,
			panelClass: 'addTaskDialogWindow',
			autoFocus: false
		});

		this.addTaskDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loadData();
			}
			this.addTaskDialogRef = null;
		});
	}

	openTaskDialog(id: number) {
		this.taskDialogRef = this.dialog.open(TaskDialog, {
			disableClose: true,
			panelClass: 'taskDialogWindow',
			autoFocus: false,
			maxHeight: '95vh',
			data: {
				taskId: id
			}
		});

		this.taskDialogRef.afterClosed().subscribe(result => {
			this.loadData();
			this.taskDialogRef = null;
		});
	}

	getTaskStatusClass(row: TaskModel) {
		if (row.getStatus() == ListsModel.TaskStatus_KESZ.getValue()) {
			return 'completedTask';
		} else if (row.getStatus() == ListsModel.TaskStatus_SZTORNOZVA.getValue()) {
			return 'stornedTask';
		} else if (CommonModel.isDateTime(row.getDeadline())) {
			let date1 = new Date(Date.parse(row.getDeadline()));
			let date2 = new Date();

			if (date1.getTime() < date2.getTime()) {
				return 'expiredTask';
			}
		}

		return '';
	}

	openTaskDeadlineDialog(id: number, deadline: string) {
		this.taskDeadlineDialogRef = this.dialog.open(TaskDeadlineDialog, {
			disableClose: true,
			panelClass: 'taskDeadlineDialogWindow',
			autoFocus: false,
			data: {
				taskId: id,
				deadline: deadline,
			}
		});

		this.taskDeadlineDialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.loadData();
			}

			this.taskDeadlineDialogRef = null;
		});
	}

	canEditDeadline(status, owner, manager) {
		let userid = this.authService.getUser().getId();
		if ((status == ListsModel.TaskStatus_LETREHOZVA.getValue() || status == ListsModel.TaskStatus_FOLYAMATBAN.getValue()) && (owner == userid || manager == userid || this.authService.isAdmin())) {
			return true;
		} else {
			return false;
		}
	}

	teszt(event) {
		console.log(event);
	}

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {HabMinosegModel} from 'src/app/components/settings/raktar/habminoseg/model/habminoseg.model';

@Injectable()
export class HabMinosegService {

	public static readonly API_TYPE: string = 'habminoseg';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + HabMinosegService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + HabMinosegService.API_TYPE + '/get_request';
	public static readonly GET_LAST_API: string = AppConstants.BASE_URL + '/api/' + HabMinosegService.API_TYPE + '/get_last_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + HabMinosegService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + HabMinosegService.API_TYPE + '/mod_request';

	public static readonly LIST_TAG: string = 'setting-habminosegek';
	public static readonly ADD_TAG: string = 'setting-addhabminoseg';
	public static readonly MOD_TAG: string = 'setting-modhabminoseg';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabMinosegService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabMinosegService.GET_API, params, {params: parameters});
	}

	getLast() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(HabMinosegService.GET_LAST_API, params, {params: parameters});
	}

	add(obj: HabMinosegModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('szallito', obj.getSzallito());
		params.append('tipus', obj.getTipus());
		params.append('aarany', String(obj.getAarany()));
		params.append('barany', String(obj.getBarany()));
		params.append('kezdete', obj.getKezdeteString());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabMinosegService.ADD_API, params, {params: parameters});
	}

	mod(obj: HabMinosegModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('szallito', obj.getSzallito());
		params.append('tipus', obj.getTipus());
		params.append('aarany', String(obj.getAarany()));
		params.append('barany', String(obj.getBarany()));
		params.append('kezdete', obj.getKezdeteString());

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(HabMinosegService.MOD_API, params, {params: parameters});
	}
}

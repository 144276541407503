<div>
	 <mat-card class="mx-2 my-2">
		  <mat-card-content>
			   <div style="text-align: right;">
					<button mat-raised-button color="primary" (click)="addSzallitas()" *ngIf="writeable">Új szállítás</button>
					<button mat-raised-button color="warn" (click)="removeSzallitas()" *ngIf="writeable">Szállítás törlése</button>
			   </div>
			   <mat-tab-group [(selectedIndex)]="selectedForm">
					<mat-tab *ngFor="let szallitas of formdata; let szallitasIdx = index" [label]="(szallitasIdx+1)+'. szállítás'">
						<div class="szallitas-wrapper px-2 py-2">
							  <div class="col-6 mat-elevation-z4 my-2">
								   <form>
										<mat-form-field appearance="standard">
											 <mat-label>Becsült rakatméret</mat-label>
											 <input matInput name="inputBecsultrakatmeret" [(ngModel)]="szallitas.becsultrakatmeret" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Számított rakatméret</mat-label>
											 <input matInput name="inputSzamitottrakatmeret" [(ngModel)]="szallitas.szamitottrakatmeret" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Szállítás napja</mat-label>
											 <input matInput name="inputSzallitasnapja" [(ngModel)]="szallitas.szallitasnapja" [owlDateTime]="inputSzallitasnapjaPicker" [owlDateTimeTrigger]="inputSzallitasnapjaPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputSzallitasnapjaPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Irányítószám (szállítás címe)</mat-label>
											 <input matInput name="inputCimiranyitoszam" [(ngModel)]="szallitas.cimiranyitoszam" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Város (szállítás címe)</mat-label>
											 <input matInput name="inputCimvaros" [(ngModel)]="szallitas.cimvaros" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Utca (szállítás címe)</mat-label>
											 <input matInput name="inputCimutca" [(ngModel)]="szallitas.cimutca" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Házszám (szállítás címe)</mat-label>
											 <input matInput name="inputCimhazszam" [(ngModel)]="szallitas.cimhazszam" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Partner kontakt</mat-label>
											 <input matInput name="inputPartnerkontakt" [(ngModel)]="szallitas.partnerkontakt" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Szállító kontakt</mat-label>
											 <input matInput name="inputSzallitokontakt" [(ngModel)]="szallitas.szallitokontakt" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard" *ngIf="writeable">
											 <mat-label>Jármű megnevezése</mat-label>
											 <input matInput name="inputJarmumegnevezes" 
													[(ngModel)]="szallitas.szallitojarmu.jarmumegnevezes" 
													[matAutocomplete]="autocompleteSzallitojarmu"
													[formControl]="szallitas.szallitojarmuCtrl">
											 <mat-autocomplete #autocompleteSzallitojarmu="matAutocomplete" (optionSelected)="onSzallitoJarmuSelect($event, szallitasIdx)">
												 <mat-option class="mat-option-szallitojarmu mat-elevation-z1" *ngFor="let szallitojarmu of szallitas.filteredSzallitoJarmuvek | async | customsort:'jarmumegnevezes'" [value]="szallitojarmu.id">
														 <div>
															<span>{{szallitojarmu.jarmumegnevezes}}</span>
													   </div>
													   <div *ngIf="szallitojarmu.raktermeret">
															<small>{{szallitojarmu.raktermeret}}</small>
													   </div>
													   <div *ngIf="szallitojarmu.kmdij">
															<small>{{szallitojarmu.kmdij}} Ft/Km</small>
													   </div>
												  </mat-option>
											 </mat-autocomplete>
										</mat-form-field>
										<mat-form-field appearance="standard" *ngIf="!writeable">
											 <mat-label>Jármű megnevezése</mat-label>
											 <input matInput name="inputJarmumegnevezes" [(ngModel)]="szallitas.szallitojarmu.jarmumegnevezes" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Jármű Km díja</mat-label>
											 <input matInput name="inputKmdij" type="number" step="1" [(ngModel)]="szallitas.szallitojarmu.kmdij" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Jármű rakterének mérete</mat-label>
											 <input matInput name="inputRaktermeret" [(ngModel)]="szallitas.szallitojarmu.raktermeret" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Fuvar előfoglalás időpontja</mat-label>
											 <input matInput name="inputFuvarelofoglalas" [(ngModel)]="szallitas.fuvarelofoglalas" [owlDateTime]="inputFuvarelofoglalasPicker" [owlDateTimeTrigger]="inputFuvarelofoglalasPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputFuvarelofoglalasPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Fuvar foglalás időpontja</mat-label>
											 <input matInput name="inputFuvarfoglalas" [(ngModel)]="szallitas.fuvarfoglalas" [owlDateTime]="inputFuvarfoglalasPicker" [owlDateTimeTrigger]="inputFuvarfoglalasPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputFuvarfoglalasPicker></owl-date-time>
										</mat-form-field>
								   </form>
							  </div>
							  <div class="col-6 mat-elevation-z4 my-2">
								   <form>
										<mat-form-field appearance="standard">
											 <mat-label>Ki egyeztette</mat-label>
											 <mat-select name="inputKiegyeztette" [(ngModel)]="szallitas.kiegyeztette" (openedChange)="egyeztetoFilter.value=null; filterEgyeztetok(null);" [disabled]="!writeable">
														 <input matInput #egyeztetoFilter class="selectFilter" placeholder="Szűrő..." (keyup)="filterEgyeztetok($event.target.value)" (keydown)="$event.stopPropagation()" />
													<mat-option *ngFor="let egyezteto of filteredEgyeztetok | customsort:'getFullName'" [value]="egyezteto.getId()">{{egyezteto.getFullName()}}</mat-option>
											 </mat-select>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Mikor egyeztette</mat-label>
											 <input matInput name="inputMikoregyeztette" [(ngModel)]="szallitas.mikoregyeztette" [owlDateTime]="inputMikoregyeztettePicker" [owlDateTimeTrigger]="inputMikoregyeztettePicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputMikoregyeztettePicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Kivel egyeztette</mat-label>
											 <input matInput name="inputKivelegyeztette" [(ngModel)]="szallitas.kivelegyeztette" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-checkbox matInput name="inputEkaerkell" labelPosition="after" [(ngModel)]="szallitas.ekaerkell" [disabled]="!writeable">EKÁER kell</mat-checkbox>
										<br>
										<mat-form-field>
											 <mat-label>EKÁER lejelentes időpontja</mat-label>
											 <input matInput name="inputEkaerlejelentes" [(ngModel)]="szallitas.ekaerlejelentes" [owlDateTime]="inputEkaerlejelentesPicker" [owlDateTimeTrigger]="inputEkaerlejelentesPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputEkaerlejelentesPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>EKÁER lezárás időpontja</mat-label>
											 <input matInput name="inputEkaerlezaras" [(ngModel)]="szallitas.ekaerlezaras" [owlDateTime]="inputEkaerlezarasPicker" [owlDateTimeTrigger]="inputEkaerlezarasPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="both" #inputEkaerlezarasPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Ajánlati fuvardíj</mat-label>
											 <input matInput name="inputSjanlatifuvardij" type="number" step="1" [(ngModel)]="szallitas.ajanlatifuvardij" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Gabler által számolt távolság (google alapján)</mat-label>
											 <input matInput name="inputGablertavolsag" type="number" step="1" [(ngModel)]="szallitas.gablertavolsag" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Gabler által számolt útdíj (HU-GO) alapján)</mat-label>
											 <input matInput name="inputGablerutdij" type="number" step="1" [(ngModel)]="szallitas.gablerutdij" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Megtett Km fuvarlevél alapján</mat-label>
											 <input matInput name="inputMegtettkm" type="number" step="1" [(ngModel)]="szallitas.megtettkm" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Nettó fuvadíj</mat-label>
											 <input matInput name="inputNettofuvardij" type="number" step="1" [(ngModel)]="szallitas.nettofuvardij" [disabled]="!writeable">
										</mat-form-field>
										<br>
										<mat-form-field>
											 <mat-label>Átvételi elismervény visszaérkezés dátuma</mat-label>
											 <input matInput name="inputAtvetelielismerveny" [(ngModel)]="szallitas.atvetelielismerveny" [owlDateTime]="inputAtvetelielismervenyPicker" [owlDateTimeTrigger]="inputAtvetelielismervenyPicker" [disabled]="!writeable">
											 <owl-date-time firstDayOfWeek="1" pickerType="calendar" #inputAtvetelielismervenyPicker></owl-date-time>
										</mat-form-field>
										<br>
										<mat-checkbox matInput name="inputSzamlarendben" labelPosition="after" [(ngModel)]="szallitas.szamlarendben" [disabled]="!writeable">Számla rendben</mat-checkbox>
										<br>
										<mat-form-field appearance="standard">
											 <mat-label>Egyéb megjegyzés</mat-label>
											 <textarea matInput name="inputEgyebmegjegyzes" rows="5" [(ngModel)]="szallitas.egyebmegjegyzes" [disabled]="!writeable"></textarea>
										</mat-form-field>
								   </form>
							  </div>
						 </div>
					</mat-tab>
			   </mat-tab-group>
		  </mat-card-content>
	 </mat-card>
	 <div class="mx-2" style="display: flex; justify-content: flex-end; padding: 5px 0;">
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="assign()" *ngIf="assignable">Felvétel</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="save()" *ngIf="writeable">Mentés</button>
		  <button mat-raised-button color="primary" style="margin: 0 5px;" (click)="done()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue()">Kész</button>
		  <button mat-raised-button color="accent" style="margin: 0 5px;" (click)="storno()" *ngIf="writeable && this.taskObject.getStatus() == listsModel.TaskStatus_FOLYAMATBAN.getValue() && authService.hasPermission('TaskForm:Altalanos:storno')">Sztornó</button>
		  <button mat-raised-button color="warn" style="margin: 0 5px;" (click)="closeDialog()">Bezárás</button>
	 </div>
</div>
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http/';

import {AuthService} from 'src/app/services/auth.service';

import {AppConstants} from 'src/app/model/appconstants.model';
import {RoleAssignModel} from 'src/app/components/settings/role/model/roleassign.model';

@Injectable()
export class RoleAssignService {

	public static readonly API_TYPE: string = 'roleassign';

	public static readonly GET_ALL_API: string = AppConstants.BASE_URL + '/api/' + RoleAssignService.API_TYPE + '/get_all_request';
	public static readonly GET_API: string = AppConstants.BASE_URL + '/api/' + RoleAssignService.API_TYPE + '/get_request';
	public static readonly ADD_API: string = AppConstants.BASE_URL + '/api/' + RoleAssignService.API_TYPE + '/add_request';
	public static readonly MOD_API: string = AppConstants.BASE_URL + '/api/' + RoleAssignService.API_TYPE + '/mod_request';
	public static readonly DEL_API: string = AppConstants.BASE_URL + '/api/' + RoleAssignService.API_TYPE + '/del_request';

	public static readonly LIST_TAG: string = 'setting-roleassigns';
	public static readonly ADD_TAG: string = 'setting-addroleassign';
	public static readonly MOD_TAG: string = 'setting-modroleassign';
	public static readonly DEL_TAG: string = 'setting-delroleassign';

	constructor(
		private authService: AuthService,
		private http: HttpClient
	) {}

	getAll() {
		let params = new FormData();
		params.append('token', this.authService.getToken());

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(RoleAssignService.GET_ALL_API, params, {params: parameters});
	}

	get(id: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(id));

		let parameters = new HttpParams()
			.set('_method', 'POST');

		return this.http.post(RoleAssignService.GET_API, params, {params: parameters});
	}

	add(obj: RoleAssignModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('ra_roleid', String(obj.getRoleid()));
		params.append('ra_userid', String(obj.getUserid()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(RoleAssignService.ADD_API, params, {params: parameters});
	}

	mod(obj: RoleAssignModel) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(obj.getId()));
		params.append('ra_roleid', String(obj.getRoleid()));
		params.append('ra_userid', String(obj.getUserid()));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(RoleAssignService.MOD_API, params, {params: parameters});
	}

	del(objid: number) {
		let params = new FormData();
		params.append('token', this.authService.getToken());
		params.append('id', String(objid));

		let parameters = new HttpParams()
			.set('_method', 'POST');
		return this.http.post(RoleAssignService.DEL_API, params, {params: parameters});
	}
}
